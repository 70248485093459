import React from 'react'
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import { StyledSourcesField } from './styled';
import Ic_Link_UnShaded from '../../../editable_icons/Ic_Link_UnShaded';

const Sources = () => {
  return (
    <div>
        <StyledSourcesField
        defaultValue="View sources referenced"
        InputProps={{
          readOnly: true,
          startAdornment: (
            <div>
                <InputAdornment position="start">
                    <Ic_Link_UnShaded/>
                </InputAdornment>
            </div>
          ),
        }}
        variant="outlined"
        />
    </div>
  )
}

export default Sources