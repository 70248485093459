
import { useEffect, useState } from 'react';
import { fetchListOfAnalystAgents, fetchListOfChatbotAgents } from '../../../modules/dashboard/logic/agents/actions';
import { getListOfAgents, getListOfAnalystAgents } from '../../../modules/dashboard/logic/agents/selectors';
import styles from './styles.module.scss'
import { connect } from "react-redux";
import Carousel from './sub-compoents/carousel';
import BottomControls from './sub-compoents/bottom_controlls';
import { CustomHeading } from '../headings';
import Apps_drop_down from './sub-compoents/apps_drop_down';
import HistoryList from './sub-compoents/history';
import { setCurrentConversationId, setCurrentRoomName, setCurrentSelectedChatbot, setListOfAppsVisibleOrInvisible, setListOfMessages, setShallCreateNewChat } from './logic/slice';
import { generateNewConversationId } from './logic/actions';
import { getCurrentChatScreenType, getCurrentSelectedChatbot, getCurrentSelectedTopic, getCurrentServerResponse, getCurrentU, getCurrentUserMessage, getCurrentUserMessageserMessage, getListOfAppsInvisibleOrVisible, getListOfMessages, getShallCreateNewChat } from './logic/selectors';
import Messages_list from './sub-compoents/messages_list';
import Ic_Back_Shaded from '../editable_icons/Ic_Back_Shaded';
import { setIsAgentPlaygroundVisible } from '../../../modules/dashboard/logic/agents/slice';
import { setListOfDocumentsToUpload } from '../../../modules/dashboard/logic/library/slice';
import CarouselTopics from './sub-compoents/carousel_topics'
import { CustomButton } from '../buttons';
import { TYPE_1 as Type_1_button } from '../buttons/button_types';
import Ic_Arrow_Right_Shaded from '../editable_icons/Ic_Arrow_Right_Shaded';
import Ic_Expand_Un_Shaded from '../editable_icons/Ic_Expand_Un_Shaded';
// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const ChatScreen = ({

    // Component parameters
    show_apps_list_in_center = true,
    show_topics_list_in_center = true,

    screen_heading_title = "Agent testing",
    screen_sub_heading_title = "Test your Agent configurations",

    // Selectors
    list_of_apps,
    list_of_analyst_agent,
    current_selected_chatbot,
    current_conversation_id,
    current_room_name,
    current_selected_topic,
    current_user_message,
    current_server_response,
    shall_create_new_chat,
    list_of_messages,
    current_chat_screen_type,
    make_list_of_apps_invisible_or_visible,
    // Reducers
    setCurrentSelectedChatbot,
    generateNewConversationId,
    loadListOfChatbotApps,
    setShallCreateNewChat,
    setIsAgentPlaygroundVisible,
    setListOfAppsVisibleOrInvisible,
    setListOfDocumentsToUpload,
    loadListOfAnalystApps,
    setListOfMessages,
    setCurrentConversationId,
    setCurrentRoomName,
    ...props }) => {


    const [listOfAppsToRender, setListOfAppsToRender] = useState([])
    const [listOfTopicsToRender, setListOfTopicsToRender] = useState([])

    const [isHeadingVisibleVisible, setIsHeadingVisibleVisible] = useState(true)
    const [isListOfAppsVisible, setIsListOfAppsVisible] = useState(false)
    const [isListOfTopicsVisible, setIsListOfTopicsVisible] = useState(false)
    const [isListOfTMessagesVisible, setIsListOMessagesVisible] = useState(false)
    const [isBottomControlsVisible, setIsBottomControlsVisible] = useState(false)

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        loadListOfChatbotApps()
        loadListOfAnalystApps()
        setListOfDocumentsToUpload([])
    }, [])

    useEffect(() => {
        console.log("isBottomControlsVisible", isBottomControlsVisible)
    }, [isBottomControlsVisible])

    useEffect(() => {
        if (current_chat_screen_type === "play_ground") {
            setIsListOMessagesVisible(true)
            setIsListOfAppsVisible(false)
            setIsListOfTopicsVisible(false)
            setIsBottomControlsVisible(true)
        } else if (current_chat_screen_type === "co-pilot") {
            setIsListOMessagesVisible(false)
            setIsListOfAppsVisible(true)
            setIsListOfTopicsVisible(false)
            setIsHeadingVisibleVisible(false)
            setIsBottomControlsVisible(false)
        } else if (current_chat_screen_type === 'chat_screen') {
            setIsListOMessagesVisible(false)
            setIsListOfAppsVisible(false)
            setIsListOfTopicsVisible(true)
            setIsHeadingVisibleVisible(true)
            setIsBottomControlsVisible(true)
        }
    }, [current_chat_screen_type])




    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const [shuffledArray, setShuffledArray] = useState([])

    useEffect(() => {
        if (list_of_apps != null && list_of_analyst_agent != null) {
            const combinedArray = [...list_of_apps, ...list_of_analyst_agent];
            setShuffledArray(shuffleArray(combinedArray))
        }
    }, [list_of_apps, list_of_analyst_agent])


    useEffect(() => {
        if (shuffledArray?.length > 0 && current_chat_screen_type !== "play_ground") {
            setListOfAppsToRender(shuffledArray)
            setCurrentSelectedChatbot(shuffledArray[0])
        }
    }, [shuffledArray])


    useEffect(() => {
        if (current_selected_chatbot != null) {
            setListOfTopicsToRender(current_selected_chatbot?.topics)
            setIsListOMessagesVisible(false)
            setIsListOfTopicsVisible(true)
            // setIsBottomControlsVisible(true)
        }
    }, [current_selected_chatbot])

    useEffect(() => {
        if (current_selected_topic != null || list_of_messages?.length > 0) {
            setIsListOfAppsVisible(false)
            setIsListOfTopicsVisible(false)
            setIsListOMessagesVisible(true)
            setIsHeadingVisibleVisible(true)
            setIsBottomControlsVisible(true)
        }
    }, [current_selected_topic, list_of_messages])

    useEffect(() => {
        console.log("current_chat_screen_type", current_chat_screen_type, make_list_of_apps_invisible_or_visible)
        if (current_chat_screen_type === "co-pilot" && make_list_of_apps_invisible_or_visible) {
            setIsListOfAppsVisible(false)
            setIsHeadingVisibleVisible(true)
            setIsBottomControlsVisible(true)
        } else if (current_chat_screen_type === "co-pilot") {
            setIsBottomControlsVisible(false)
        }
    }, [make_list_of_apps_invisible_or_visible])

    useEffect(() => {
        if (current_user_message) {
            setIsListOfTopicsVisible(false)
            setIsListOfAppsVisible(false)
            setIsListOMessagesVisible(true)
        }
    }, [current_user_message, current_server_response])

    useEffect(() => {
        if (shall_create_new_chat && current_selected_chatbot != null) {
            setIsListOMessagesVisible(false)
            setIsListOfTopicsVisible(true);
            setShallCreateNewChat(false);
            setListOfMessages([])
            setCurrentConversationId(null)
            setCurrentRoomName(null)
        }
    }, [shall_create_new_chat])

    return current_chat_screen_type === "co-pilot" ? <div className={styles.co_pilot_container}>
        <div className={styles.left_side}>
            <div className={`${styles.history_list_container} ${isSidebarOpen ? styles.open : ''}`}>
                <HistoryList />
            </div>
            <div className={styles.button_container} onClick={toggleSidebar}>
                {/* <CustomButton text='<' currentButtonType="Type_1_button" /> */}
                <Ic_Expand_Un_Shaded></Ic_Expand_Un_Shaded>
            </div>
        </div>
        <div className={styles.right_side}>
            {
                isHeadingVisibleVisible && <div className={styles.screen_heading}>
                    <div className={styles.app_container}>
                        <Apps_drop_down />
                    </div>
                </div>
            }
            {
                isListOfAppsVisible && <div className={styles.list_of_apps_container}>
                    <Carousel items={listOfAppsToRender} type='apps' />
                </div>
            }
            {
                isListOfTopicsVisible && !isListOfAppsVisible && current_chat_screen_type !== "play_ground" && <div className={styles.list_of_topics_container}>
                    <div className={styles.headings}>
                        <CustomHeading font_size='25' text='How can I help you today?' />
                        <CustomHeading font_size='15' text='Find out more about the following topics' />
                    </div>
                    <CarouselTopics items={listOfTopicsToRender} type='topics' />
                </div>
            }
            {
                isListOfTMessagesVisible && <div className={styles.list_of_messages_container}>
                    <Messages_list />
                </div>
            }

            {
                isBottomControlsVisible && <div className={styles.bottom_controller_container}>
                    <BottomControls />
                </div>
            }

        </div>

    </div> : <div className={styles.container}>
        <div className={styles.left_side}>
            {
                isHeadingVisibleVisible && <div className={styles.screen_heading}>
                    {
                        current_chat_screen_type === "play_ground" && <div className={styles.icon} onClick={() => {
                            setIsAgentPlaygroundVisible(false)
                        }}>
                            <Ic_Back_Shaded />
                        </div>
                    }
                    <div className={styles.headings_container}>
                        <CustomHeading font_size='20' font_weight='bold' text={screen_heading_title} />
                        <CustomHeading font_size='12' color='tertiary' text={screen_sub_heading_title} />
                    </div>

                </div>
            }
            {
                isListOfAppsVisible && <div className={styles.list_of_apps_container}>
                    <Carousel items={listOfAppsToRender} type='apps' />
                </div>
            }
            {
                isListOfTopicsVisible && current_chat_screen_type !== "play_ground" && <div className={styles.list_of_topics_container}>
                    <div className={styles.topic_headers}>
                    <CustomHeading font_size='25' text='How can I help you today?' />
                    <CustomHeading font_size='15' text='Find out more about the following topics' />
                    </div>
                    <div className={styles.carousel}>
                        <CarouselTopics items={listOfTopicsToRender} type='topics' />
                    </div>
                </div>
            }
            {
                isListOfTMessagesVisible && <div>
                    <Messages_list />
                </div>
            }

            {
                isBottomControlsVisible === true && <div className={styles.bottom_controller_container}>
                    <BottomControls />
                </div>
            }

        </div>
        <div className={styles.right_side}>
            <Apps_drop_down />
            <div className={styles.history_list_container}>
                <HistoryList />
            </div>
        </div>
    </div>
}

const mapState = (state) => ({
    list_of_analyst_agent: getListOfAnalystAgents(state),
    list_of_apps: getListOfAgents(state),
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    current_selected_topic: getCurrentSelectedTopic(state),
    current_user_message: getCurrentUserMessage(state),
    current_server_response: getCurrentServerResponse(state),
    shall_create_new_chat: getShallCreateNewChat(state),
    list_of_messages: getListOfMessages(state),
    current_chat_screen_type: getCurrentChatScreenType(state),
    make_list_of_apps_invisible_or_visible: getListOfAppsInvisibleOrVisible(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadListOfChatbotApps: (data) => dispatch(fetchListOfChatbotAgents(data)),
    loadListOfAnalystApps: (data) => dispatch(fetchListOfAnalystAgents(data)),
    setCurrentSelectedChatbot: (data) => dispatch(setCurrentSelectedChatbot(data)),
    generateNewConversationId: (data) => dispatch(generateNewConversationId(data)),
    setShallCreateNewChat: (data) => dispatch(setShallCreateNewChat(data)),
    setIsAgentPlaygroundVisible: (data) => dispatch(setIsAgentPlaygroundVisible(data)),
    setListOfAppsVisibleOrInvisible: (data) => dispatch(setListOfAppsVisibleOrInvisible(data)),
    setListOfDocumentsToUpload: (data) => dispatch(setListOfDocumentsToUpload(data)),
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setCurrentConversationId: (data) => dispatch(setCurrentConversationId(data)),
    setCurrentRoomName: (data) => dispatch(setCurrentRoomName(data)),

});

export default connect(mapState, mapDispatchToProps)(ChatScreen)