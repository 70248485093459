// Selector for number of users
export const getNumberOfUsers = (state) => state.dashboard?.numberOfUsers;

// Selector for loading state of number of users
export const getIsLoadingNumberOfUsers = (state) => state.dashboard?.is_loading_numberOfUsers;

// Selector for error state of number of users
export const getErrorNumberOfUsers = (state) => state.dashboard?.error_numberOfUsers;

// Selector for number of documents
export const getNumberOfDocuments = (state) => state.dashboard?.numberOfDocuments;

// Selector for loading state of number of documents
export const getIsLoadingNumberOfDocuments = (state) => state.dashboard?.is_loading_numberOfDocuments;

// Selector for error state of number of documents
export const getErrorNumberOfDocuments = (state) => state.dashboard?.error_numberOfDocuments;

// Selector for number of configurations
export const getNumberOfConfigurations = (state) => state.dashboard?.numberOfConfigurations;

// Selector for loading state of number of configurations
export const getIsLoadingNumberOfConfigurations = (state) => state.dashboard?.is_loading_numberOfConfigurations;

// Selector for error state of number of configurations
export const getErrorNumberOfConfigurations = (state) => state.dashboard?.error_numberOfConfigurations;

// Selector for number of conversations and messages
export const getNumberOfConversationsAndMessages = (state) => state.dashboard?.numberOfConversationsAndMessages;

// Selector for loading state of number of conversations and messages
export const getIsLoadingNumberOfConversationsAndMessages = (state) => state.dashboard?.is_loading_numberOfConversationsAndMessages;

// Selector for error state of number of conversations and messages
export const getErrorNumberOfConversationsAndMessages = (state) => state.dashboard?.error_numberOfConversationsAndMessages;
