import { UserAPI, UsersAPI } from "../../../api";
import { setIsUpdatingUser, setIsInvitingUser } from "../users/slice";
import { setIsLoadingUser, setUser } from "./slice";
import { fetchListOfUsers } from "../users/actions"
import { showToastMessage } from "../../../app/logic/actions";

const getUserDetails = (data) => async (dispatch) => {
    dispatch(setIsLoadingUser(true));
    try {
        const response = await UserAPI.getUserDetails(data);
        if (response.data && response.data.items) {
            const mappedData = response.data.items.map(user => ({
                role: user?.role,
                email: user?.email,
                first_name: user?.first_name,
                last_name: user?.last_name,
                avatar: user?.avatar,
                id: user?.id,
                should_update_password: user?.should_update_password
            }));
            dispatch(setUser(mappedData));
            dispatch(showToastMessage({ type: 'success', message: "Successfully fetched user information" }));
        } else {
            dispatch(showToastMessage({ type: 'error', message: "No data found" }));
        }
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: error.message }));
    } finally {
        dispatch(setIsLoadingUser(false));
    }
};

const updateUserAvatar = (data) => async (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    try {
        const response = await UserAPI.updateUserAvatar(data);
        dispatch(fetchListOfUsers({}));
        dispatch(showToastMessage({ type: 'success', message: "Successfully updated profile photo" }))
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
        dispatch(setIsLoadingUser(false));
    }
}

const updateSingleUserPassword = (data) => async (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    try {
        const response = await UserAPI.updateSingleUserPassword(data)
        dispatch(fetchListOfUsers({}));
        dispatch(showToastMessage({ type: 'success', message: "Successfully updated password" }))
    } catch (error) {
        dispatch(setIsUpdatingUser(false));
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
    }
}

const addUser = (data) => async (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    try {
        console.log("we are ading user")
        const response = await UserAPI.addUser(data)
        dispatch(fetchListOfUsers({}));
        dispatch(showToastMessage({ type: 'success', message: "Successfully added user" }))
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))

    }
}


export {
    updateUserAvatar,
    updateSingleUserPassword,
    addUser,
    getUserDetails
}