import styled from 'styled-components';

// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};
const StyledChip = styled.div`
  && {
    background-color:   ${({ backgroundColor, theme }) => backgroundColor || 'rgba(0, 255, 0, 0.1)'};
    color: ${({ color, theme }) => color || 'green'};
    border-radius: 25px;
    width: ${({ width, theme }) => width || '100%'};
    text-align: center;
    padding: 0.5rem;
    font-family: ${({fontFamily, theme }) => theme.palette.fontFamilies[fontFamily] || theme.palette.fontFamilies.primary};
    font-weight: 300;
    line-height: normal;
    font-size: ${({ fontSize }) => fontSize || '15px'};

    @media only screen and (max-width: ${breakpoints.mobile}) {
      && {
        font-size: ${({ mobileFontSize }) => mobileFontSize || '12px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      && {
        font-size: ${({ tabletFontSize }) => tabletFontSize || '13px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.laptop}) {
      && {
        font-size: ${({ laptopFontSize }) => laptopFontSize || '14px'};
      }
    }

    @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
           (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
           (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
           (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
      && {
        font-size: ${({ macbook13FontSize }) => macbook13FontSize || '15px'};
      }
    }
  }
`;



export { StyledChip };
