import styled from 'styled-components';
import { Button } from '@mui/material';

// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};

const StyledButton_Type1 = styled(Button)`
  && {
    background-color: ${({ backgroundColor, theme }) => theme.palette.buttonBackgrounds[backgroundColor] || theme.palette.buttonBackgrounds.secondary};
    &:hover {
      background-color: ${({ hoverBackgroundColor, theme }) => theme.palette.buttonBackgrounds[hoverBackgroundColor] || theme.palette.borderColors.primary};
      border: 1px solid ${({ hoverBorderColor, theme }) => theme.palette.buttonBackgrounds[hoverBorderColor] || theme.palette.buttonBackgrounds.primary};
      color: ${({ hoverTextColor, theme }) => theme.palette.textColors[hoverTextColor] || theme.palette.textColors.primary};
    }

    /* Additional styles */
    color: ${({ textColor, theme }) => theme.palette.textColors[textColor] || 'black'};
    font-size: ${({ fontSize }) => fontSize || '12px'};
    font-weight: ${({ fontWeight }) => fontWeight || '900'};
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: ${({ borderRadius }) => borderRadius || '8px'};
    padding: ${({ padding }) => padding || '8px 10px'};
    justify-content: center;
    gap: 0px;
    flex: 1 0 0;
    border: 1px solid ${({ borderColor, theme }) => theme.palette.borderColors[borderColor] || theme.palette.borderColors.secondary};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top: ${({ marginTop }) => marginTop || '10px'};

    @media only screen and (max-width: ${breakpoints.mobile}) {
      && {
        font-size: ${({ mobileFontSize }) => mobileFontSize || '12px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      && {
        font-size: ${({ tabletFontSize }) => tabletFontSize || '13px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.laptop}) {
      && {
        font-size: ${({ laptopFontSize }) => laptopFontSize || '14px'};
      }
    }

    @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
           (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
           (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
           (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
      && {
        font-size: ${({ macbook13FontSize }) => macbook13FontSize || '15px'};
      }
    }
  }
`;

export { StyledButton_Type1 };
