
import { BASE_URL, httpDelete, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance"
const USERS_BASE_URL = `${BASE_URL}/`

const getAllChatbotAgentsConfig = (data) => httpGet(`${USERS_BASE_URL}api/prompt/chat/list/`, data);
const getAllQAChatbotAgentsConfig = (data) => httpGet(`${USERS_BASE_URL}api/prompt/questions/list/`, data);
const getAllAnalystAgentsConfig = (data) => httpGet(`${USERS_BASE_URL}api/prompt/analyst/list/`, data);

const getAgentConfig = (data) => httpGet(`${USERS_BASE_URL}api/prompt/chat/${data.pro_id}`, data);
const getQAAgentConfig = (data) => httpGet(`${USERS_BASE_URL}api/prompt/question/retrieve/${data.pro_id}`, data);

const createChatBotConfig = (data) => httpPost(`${USERS_BASE_URL}api/prompt/chat/create`, data);
const createQuestionChatBotConfig = (data) => httpPost(`${USERS_BASE_URL}api/prompt/questions/create/`, data);
const deleteAgentConfig = (data) => httpDelete(`${USERS_BASE_URL}api/prompt/chat/${data}/`);
const updateAgentConfig = (data) => httpPut(`${USERS_BASE_URL}api/prompt/chat/${data.id}/`, data.payload);
const updateAgentConfigForTest = (data) => httpPut(`${USERS_BASE_URL}api/prompt/chat/extend-agent/${data.pro_id}/`, data.payload);

const updateQAAgentConfig = (data) => httpPut(`${USERS_BASE_URL}api/prompt/questions/update/${data.id}/`, data.payload);
const deleteQAAgentConfig = (data) => httpDelete(`${USERS_BASE_URL}api/prompt/questions/delete/${data}/`);

const createAnalystConfig = (data) => httpPost(`${USERS_BASE_URL}api/prompt/analyst/create/`, data)
const updateAnalystConfig = (data) => httpPut(`${USERS_BASE_URL}api/prompt/analyst/${data.id}/`, data.payload)
const deleteAnalystConfig = (data) => httpDelete(`${USERS_BASE_URL}api/prompt/analyst/${data}/`)


export {
    getAllChatbotAgentsConfig,
    createChatBotConfig,
    deleteAgentConfig,
    updateAgentConfig,
    createQuestionChatBotConfig,
    getAllQAChatbotAgentsConfig,
    updateQAAgentConfig,
    deleteQAAgentConfig,
    getAgentConfig,
    getQAAgentConfig,
    updateAgentConfigForTest,
    getAllAnalystAgentsConfig,
    createAnalystConfig,
    updateAnalystConfig,
    deleteAnalystConfig
}