import { StyledDivider, StyledSideBarContainer, StyledTagItem, StyledTagTitle, StyledTextarea, } from './styled'
import styles from './styles.module.scss'
import { useEffect, useState, useMemo } from 'react'
import { connect } from "react-redux";
import { createLibrary, deleteLibrary, updateLibrary } from '../../../logic/library/actions'
import ic_question_mark from '../../../../../assets/images/ic_question_mark.png'
import ic_percentage from '../../../../../assets/images/percentage.png'
import { getIsCreatingLibraryLoading, getIsLibraryCreated, getIsLibraryDeleted } from '../../../logic/library/selectors'
import { showToastMessage } from '../../../../app/logic/actions'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import Ic_Close_Shaded from '../../../../../shared/ui/editable_icons/Ic_Close_Shaded'
import Ic_Add_Shaded from '../../../../../shared/ui/editable_icons/Ic_Add_Shaded'
import Ic_Remove_Tag_Shaded from '../../../../../shared/ui/editable_icons/Ic_Remove_Tag_Shaded'
import Ic_Settings_Gear_Shaded from '../../../../../shared/ui/editable_icons/Ic_Settings_Gear_Shaded'
import Tooltip from '@mui/material/Tooltip';
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { CustomHeading } from '../../../../../shared/ui/headings'
import CustomTextField from '../../../../../shared/ui/text_field';
import { CustomLabel } from '../../../../../shared/ui/label'
import { TYPE_1, TYPE_2, TYPE_3 } from '../../../../../shared/ui/models_dropdowns/models_types'
import { CustomButton } from '../../../../../shared/ui/buttons'
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import { setIsLibraryCreated, setIsLibraryDeleted } from '../../../logic/library/slice';
import { SwitchLeftSharp } from '@mui/icons-material';
import { getListOfChunkingListModels } from '../../../logic/llms/selectors';
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

let listOfTags = []
const Sidebar = ({
    is_loading_create_library,
    showToastMessage,
    list_of_chunking_list_models,
    createLibrary,
    updateLibrary,
    deleteLibrary,
    current_theme,
    is_library_deleted,
    is_library_created,
    setIsSidebarVisible,
    currentSelectedLibrary,
    setIsLibraryDeleted,
    setIsLibraryCreated,
    ...props }) => {
    const [newTag, setNewTag] = useState("");
    const [libraryName, setLibraryName] = useState("");
    const [libraryDescription, setLibraryDescription] = useState("");
    const [isEditingLibrary, setIsEditingLibrary] = useState(false)
    const [tagsListToRender, setTagsListToRender] = useState([])
    const [isMoreConfigOpen, setIsMoreConfigOpen] = useState(false)
    const [tokenBudget, setTokenBudget] = useState("450");
    const [chunkOverlap, setChunkOverlap] = useState("10");

    const [llmType, setLlmType] = useState('PublicLLM')
    const [separator, setSeparator] = useState(null)
    const [currentSelectedChunkingMethod, setCurrentSelectedChunkingMethod] = useState(null);
    const [embeddingModel, setEmbeddingModel] = useState(null);

    const [currentSelectedChunkingMethodValue, setCurrentSelectedChunkingMethodValue] = useState('Token Chunker');
    const [currentSelectedSeparatorMethodValue, setCurrentSelectedSeparatorMethodValue] = useState('Newline');
    const [embeddingModelValue, setEmbeddingModelValue] = useState(null);
    const [confirmCancel, setConfirmCancel] = useState(false)

    const fieldValidationsCreateLibrary = useMemo(() => [
        { field: libraryName, message: "Please provide a name for the library" },
        { field: libraryDescription, message: "Please provide a description for the library" },
        { field: embeddingModel, message: "Please select an embedding model" },
        { field: currentSelectedChunkingMethod, message: "Please select a chunking method" }
    ], [libraryName, libraryDescription, embeddingModel, currentSelectedChunkingMethod])

    const fieldValidationsEditLibrary = useMemo(() => [
        { field: libraryName, message: "Please provide a name for the library" },
        { field: libraryDescription, message: "Please provide a description for the library" },
    ], [libraryName, libraryDescription])

    const handleCloseSideBar = () => {
        setLibraryName("");
        setLibraryDescription("")
        setTagsListToRender([])
        listOfTags = []
        setIsLibraryDeleted(false)
        setIsSidebarVisible(false)
    };

    const handleDeleteTag = (tagToDelete) => {
        setTagsListToRender((tags) => tags.filter((tag) => tag.key !== tagToDelete.key));
    };

    const handleCreateTagBtn = (tagToAdd) => {
        if (tagToAdd.length > 0) {
            listOfTags = listOfTags.concat({ key: listOfTags.length + 1, label: tagToAdd });
            setNewTag("");
            setTagsListToRender(listOfTags);
        }
    };

    const handleCreateTagBtnOnEnter = (e, tagToAdd) => {
        if (e.key === 'Enter') {
            handleCreateTagBtn(tagToAdd);
        }
    }

    const handleCreateLibrary = () => {
        try {
            for (let validation of fieldValidationsCreateLibrary) {
                if (validation?.field?.length === 0) {
                    showToastMessage({ type: 'error', message: validation.message });
                    return;
                }
            }
            // list_of_chunking_list_models
            console.log("list_of_chunking_list_models", list_of_chunking_list_models)
            createLibrary({
                name: libraryName,
                token_budget: tokenBudget,
                chunk_overlap: chunkOverlap,
                chunking_method: currentSelectedChunkingMethod !== null ? currentSelectedChunkingMethod?.id : list_of_chunking_list_models[0]?.id,
                separators: [separator?.value == null ? "" : separator?.value],
                embedding_model: embeddingModel?.id,
                description: libraryDescription?.length > 0 ? libraryDescription : "default",
                tags: tagsListToRender?.length > 0 ? tagsListToRender?.filter(tag => tag.label).map(tag => tag.label) : []
            });
            setIsSidebarVisible(false);
        } catch (error) {
            console.log(error)
            showToastMessage({ message: "An error occurred while creating the library", type: "error" });
        }
    };

    const handleUpdateLibrary = () => {
        try {
            for (let validation of fieldValidationsEditLibrary) {
                if (validation.field.length === 0) {
                    showToastMessage({ type: 'error', message: validation.message });
                    return;
                }
            }
            updateLibrary({
                id: currentSelectedLibrary?.value,
                payload: {
                    name: libraryName,
                    description: libraryDescription.length > 0 ? libraryDescription : "default",
                    tags: tagsListToRender.length > 0 ? tagsListToRender.filter(tag => tag.label).map(tag => tag.label) : [],
                    token_budget: tokenBudget,
                    chunk_overlap: chunkOverlap,
                    chunking_method: currentSelectedChunkingMethod?.id,
                    separators: [separator === null ? "" : separator?.value],
                    embedding_model: embeddingModel?.id,
                }
            });
            setIsSidebarVisible(false);
        } catch (error) {
            showToastMessage({ message: "An error occurred while updating the library", type: "error" });
        }
    };

    const handleModelDropdownClickForEmbeddingModel = (item_selected, llm_type) => {
        setEmbeddingModel(item_selected)
        setEmbeddingModelValue(item_selected?.value)
        setLlmType(llm_type)
    }

    const handleModelDropdownClickForChunkingModel = (selected_chunking_model) => {
        setCurrentSelectedChunkingMethod(selected_chunking_model)
        setCurrentSelectedChunkingMethodValue(selected_chunking_model?.value)
    }

    const handleModelDropdownClickForSeparators = (selected_separator) => {
        setSeparator(selected_separator)
    }

    useEffect(() => {
        if (currentSelectedLibrary != null) {
            setLibraryName(currentSelectedLibrary?.label);
            setLibraryDescription(currentSelectedLibrary?.description);
            setTokenBudget(currentSelectedLibrary?.token_budget)
            setChunkOverlap(currentSelectedLibrary?.chunk_overlap)
            let tag_list = []
            if (currentSelectedLibrary?.tags?.length > 0)
                tag_list = currentSelectedLibrary?.tags?.map((tag, key) => ({ label: tag, key }));
            if (tag_list.length > 0)
                setTagsListToRender(tag_list)
            let type_selected_for_embedding_model = currentSelectedLibrary?.embedding_model?.llm_type
            console.log("type_selected_for_embedding_model", currentSelectedLibrary?.embedding_model)
            setLlmType(type_selected_for_embedding_model)
            setEmbeddingModel(currentSelectedLibrary?.embedding_model)
            setEmbeddingModelValue(currentSelectedLibrary?.embedding_model?.value)
            setCurrentSelectedChunkingMethodValue(currentSelectedLibrary?.chunking_method?.value)
            setCurrentSelectedChunkingMethod(currentSelectedLibrary?.chunking_method)
            setIsEditingLibrary(true)
            if (currentSelectedLibrary?.chunking_method?.value === 'Recursive Character Chunker') {
                setCurrentSelectedSeparatorMethodValue(currentSelectedLibrary?.separators[0])
            }
        }
    }, [currentSelectedLibrary])

    useEffect(() => {
        if (is_library_created) {
            handleCloseSideBar()
            setIsLibraryCreated(false)
        }
    }, [is_library_created])

    const handleConfirmCancel = () => {
        setConfirmCancel(true)
    }

    return <StyledSideBarContainer className={styles.side_bar_opened}>
        <div className={styles.header}>
            <CustomHeading text='Create library' font_size='26'></CustomHeading>
            <div onClick={handleCloseSideBar}>
                <Ic_Close_Shaded />
            </div>
        </div>
        <div className={styles.field_container}>
            <CustomLabel title='Library name' font_size='18' className={styles.create_tag_label}></CustomLabel>
            <CustomTextField className={styles.library_name} placeholder='Enter the name of the library' value={libraryName} onChange={(e) => { setLibraryName(e.target.value) }}></CustomTextField>
            <CustomTextField className={styles.library_desc} placeholder='Enter a description for the library' value={libraryDescription} onChange={(e) => { setLibraryDescription(e.target.value) }} multiline='true' minRows={7}></CustomTextField>
            <CustomLabel title='Create a tag' font_size='18' className={styles.create_tag_label}></CustomLabel>
            <div className={styles.label_field_container}>
                <CustomTextField className={styles.field} placeholder='Enter a tag' value={newTag} onChange={(e) => { setNewTag(e.target.value) }} onKeyPress={(e) => { handleCreateTagBtnOnEnter(e, newTag) }} ></CustomTextField>
                <div className={styles.create_btn} onClick={() => { handleCreateTagBtn(newTag) }}>
                    <Ic_Add_Shaded />
                </div>
            </div>
            <div className={styles.tags_list}>
                {tagsListToRender.map((data) => {
                    return <StyledTagItem className={styles.tag}>
                        <div className={styles.tag_title}>
                            <StyledTagTitle>{data.label}</StyledTagTitle>
                        </div>
                        <div className={styles.tag_icon} onClick={() => {
                            handleDeleteTag(data)
                        }}>
                            <Ic_Remove_Tag_Shaded />
                        </div>
                    </StyledTagItem>
                })}
            </div>
            <div className={styles.model_container}>
                <div className={styles.header_container}>
                    <CustomLabel title='Embedding model' font_size='18'></CustomLabel>
                </div>
                <CustomModelDropDown type={TYPE_1} handleOnClick={handleModelDropdownClickForEmbeddingModel} defaultValue={embeddingModelValue} defaultLLM={llmType} />
            </div>
            {isMoreConfigOpen === false && (
                <>
                    <div className={styles.advanced_settings_container} onClick={() => { setIsMoreConfigOpen(true) }}>
                        <div className={styles.advanced_settings_icon}>
                            <Ic_Settings_Gear_Shaded />
                        </div>
                        <CustomHeading text='Advanced Settings' color='secondary' font_size='14' onClick={setIsMoreConfigOpen}></CustomHeading>
                    </div>
                    <div className={styles.bottom_container}>
                        <CustomButton onClick={isEditingLibrary ? handleUpdateLibrary : handleCreateLibrary} currentButtonTpe={TYPE_1_button} title={
                            isEditingLibrary ? (
                                is_loading_create_library ? "Updating..." : "Update"
                            ) : (
                                is_loading_create_library ? "Creating..." : "Save"
                            )
                        }></CustomButton>
                        <CustomButton title='Cancel' currentButtonTpe={TYPE_1_button} onClick={handleConfirmCancel}></CustomButton>
                    </div>
                </>
            )}
        </div>
        {
            isMoreConfigOpen == true ? <div className={styles.more_config_container}>
                <StyledDivider></StyledDivider>
                <div className={styles.header_container} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <CustomHeading text='Advanced Settings' font_size='16'></CustomHeading>
                    <div onClick={() => setIsMoreConfigOpen(false)}>
                        <Ic_Close_Shaded />
                    </div>
                </div>
                <div className={styles.row_1}>
                    <div className={styles.field_container}>
                        <div className={styles.label_container}>
                            <CustomLabel title='Token budget'></CustomLabel>
                            <Tooltip title="An integer between 100 and 2000. The default is 500." placement="top">
                                <img src={ic_question_mark} className={styles.more_info_icon} />
                            </Tooltip>
                        </div>
                        <CustomTextField value={tokenBudget} onChange={(e) => setTokenBudget(e.target.value)}></CustomTextField>
                    </div>
                    <div className={styles.field_container}>
                        <div className={styles.label_container}>
                            <CustomLabel title='Chunk overlap'></CustomLabel>
                            <Tooltip title="A number percentage between 0 and 80%. The default is 10%." placement="top">
                                <img src={ic_question_mark} className={styles.more_info_icon} />
                            </Tooltip>
                        </div>
                        <CustomTextField icon={<img src={ic_percentage} className={styles.more_info_icon} />} onChange={(e) => setChunkOverlap(e.target.value)} value={chunkOverlap} ></CustomTextField>
                    </div>
                </div>
                <div className={styles.label_container}>
                    <CustomLabel title='Chunking method'></CustomLabel>
                    <Tooltip title="A method for dividing text into smaller chunks." placement="top">
                        <img src={ic_question_mark} className={styles.more_info_icon} />
                    </Tooltip>
                </div>
                <CustomModelDropDown type={TYPE_2} handleOnClick={handleModelDropdownClickForChunkingModel} defaultValue={currentSelectedChunkingMethodValue} />
                {currentSelectedChunkingMethod?.value === 'Recursive Character Chunker' &&
                    <div>
                        <CustomLabel title='Separators'></CustomLabel>
                        <CustomModelDropDown type={TYPE_3} handleOnClick={handleModelDropdownClickForSeparators} defaultValue={currentSelectedSeparatorMethodValue} />
                    </div>
                }
                <div className={styles.bottom_container}>
                    <CustomButton title={
                        isEditingLibrary ? (
                            is_loading_create_library ? "Updating..." : "Update"
                        ) : (
                            is_loading_create_library ? "Creating..." : "Save"
                        )
                    } currentButtonTpe={TYPE_1_button} onClick={isEditingLibrary ? handleUpdateLibrary : handleCreateLibrary}>
                    </CustomButton>
                    <CustomButton title='Cancel' currentButtonTpe={TYPE_1_button} background_color='secondary' onClick={handleConfirmCancel}></CustomButton >
                </div>

            </div> : ""
        }

        <DeleteConfirmationComponent
            isVisible={confirmCancel}
            onConfirm={() => { setIsSidebarVisible(false); setConfirmCancel(false)  }}
            onCancel={() => { setConfirmCancel(false) }}
            message='You have unsaved changes.'
            type='warning'
        />
    </StyledSideBarContainer>
}

const mapState = (state) => ({
    is_loading_create_library: getIsCreatingLibraryLoading(state),
    current_theme: getCurrentTheme(state),
    is_library_deleted: getIsLibraryDeleted(state),
    is_library_created: getIsLibraryCreated(state),
    list_of_chunking_list_models: getListOfChunkingListModels(state),
})

const mapDispatchToProps = (dispatch) => ({
    createLibrary: (data) => dispatch(createLibrary(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    updateLibrary: (data) => dispatch(updateLibrary(data)),
    deleteLibrary: (data) => dispatch(deleteLibrary(data)),
    setIsLibraryDeleted: (data) => dispatch(setIsLibraryDeleted(data)),
    setIsLibraryCreated: (data) => dispatch(setIsLibraryCreated(data)),
});

export default connect(mapState, mapDispatchToProps)(Sidebar)