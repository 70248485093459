import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Notification_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg xmlns="http://www.w3.org/2000/svg" width="21" height="24" viewBox="0 0 21 24" fill="none">
    <path d="M9.70312 0.75C9.70312 0.375 10.0781 0 10.4531 0C10.875 0 11.25 0.375 11.25 0.75V1.54688C15 1.92188 18 5.10938 18 9V10.4062C18 12.4219 18.7969 14.3906 20.25 15.8438L20.3906 15.9844C20.7656 16.3594 21 16.875 21 17.4375C21 18.6094 20.0625 19.5 18.8906 19.5H2.0625C0.890625 19.5 0 18.6094 0 17.4375C0 16.875 0.1875 16.3594 0.5625 15.9844L0.703125 15.8438C2.15625 14.3906 3 12.4219 3 10.4062V9C3 5.10938 5.95312 1.92188 9.75 1.54688L9.70312 0.75ZM10.4531 3C7.17188 3 4.45312 5.71875 4.45312 9V10.4062C4.45312 12.8438 3.51562 15.1875 1.78125 16.9219L1.64062 17.0156C1.54688 17.1562 1.45312 17.2969 1.45312 17.4375C1.45312 17.7656 1.73438 18 2.0625 18H18.8906C19.2188 18 19.5 17.7656 19.5 17.4375C19.5 17.2969 19.4062 17.1562 19.3125 17.0156L19.1719 16.9219C17.4375 15.1875 16.5 12.8438 16.5 10.4062V9C16.5 5.71875 13.7812 3 10.4531 3ZM10.4531 22.5C11.1094 22.5 11.6719 22.125 11.9062 21.5156C12.0469 21.1406 12.4688 20.9062 12.8438 21.0469C13.2188 21.1875 13.4531 21.6094 13.3125 22.0312C12.8906 23.2031 11.7656 24 10.4531 24C9.1875 24 8.0625 23.2031 7.64062 22.0312C7.5 21.6094 7.73438 21.1875 8.10938 21.0469C8.48438 20.9062 8.90625 21.1406 9.04688 21.5156C9.28125 22.125 9.84375 22.5 10.4531 22.5Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Notification_Un_Shaded)