import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';
import { MESSAGES } from '../global/messages';

const tenant = window.location.hostname.split('.')[0]; // Example: Extract tenant from subdomain

// const BASE_URL = process.env.REACT_APP_BASE_URL || `http://localhost:8000`;
// const BASE_URL = "https://backend.staging.clairo.ai"
console.log("process.env.REACT_APP_ENV : ", process.env.REACT_APP_ENV);

let BASE_URL = "http://localhost:8000"
let RAG_BASE_URL = "http://localhost:8001"

if (process.env.REACT_APP_ENV === "dev") {
    BASE_URL = `http://localhost:8000`
    RAG_BASE_URL = "http://localhost:8001"
}
else if (process.env.REACT_APP_ENV === "staging") {
    BASE_URL = `https://backend.staging.clairo.ai`
    RAG_BASE_URL = "https://ml.staging.clairo.ai:8001"
}
else if (process.env.REACT_APP_ENV === "prod") {
    BASE_URL = `https://backend.api.clairo.ai`
    RAG_BASE_URL = "https://ml.api.clairo.ai"
}

const AUTH_REFRESH_URL = '/api/token/refresh';

let authToken = localStorage.getItem('access') || null;

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${authToken}`,
        // withCredentials: true,
        // TENANT: `api-${window.location.hostname.replace("8000", "")}`
        TENANT: process.env.REACT_APP_ENV == "dev" ? `api-${tenant}.localhost` : process.env.REACT_APP_ENV == "staging" ? `api-${tenant}.staging.clairo.ai` : `${tenant}.api.clairo.ai`
    },

});


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject({ canceled: 'Previous Request Canceled' });
        } else if (error.response) {
            if (error.response.status === 0) {
                return Promise.reject({ error: MESSAGES['500'] });
            } else if (_.inRange(error.response.status, 500, 600)) {
                return Promise.reject({ error: MESSAGES['500'], status: error.response.status });
            } else if (error.response.status === 404) {
                return Promise.reject({ error: 'Not Found', status: error.response.status });
            } else if (error.response.data.message) {
                return Promise.reject({ error: error.response.data.message });
            } else if (error.response.status === 403 && error.response.data.detail) {
                return Promise.reject({ error: error.response.data.detail, status: error.response.status });
            }
            return Promise.reject(error);
        }
        // eslint-disable-next-line consistent-return
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(async (req) => {
    if (!authToken) {
        authToken = localStorage.getItem('access') || '';
        req.headers.Authorization = `Bearer ${authToken}`;
    }
    if (!authToken) {
        return req;
    }
    const user = jwtDecode(authToken || '');
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;
    console.log("going to refresh the token")
    const refreshToken = localStorage.getItem('refresh') || null;
    const userRefresh = jwtDecode(refreshToken || '');
    const isExpiredRefresh = dayjs.unix(userRefresh.exp).diff(dayjs()) < 1;
    if (isExpiredRefresh) {
        localStorage.clear();
        window.location.href = '/auth';
    }

    // TODO: uncomment when refresh token api is implemented

    const response = await axios.post(`${BASE_URL}${AUTH_REFRESH_URL}`, {
        refresh: refreshToken,
    });

    localStorage.setItem('access', response.data.access);
    localStorage.setItem('refresh', response.data.refresh);
    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
});

const WEBSOCKET_BASE_URL = `${process.env.REACT_APP_SECURE_WEBSOCKET_CONNECTION ? 'wss' : 'ws'}://${process.env.REACT_APP_WEBSOCKET_BASE_URL || 'localhost:8000'
    }`;

const headers = {
    Accept: 'application/json,multipart/form-data',
    // "Access-Control-Allow-Origin": "http://localhost:3000",
    // TENANT: `api-${window.location.hostname.replace("8000", "")}`
    TENANT: process.env.REACT_APP_ENV == "dev" ? `api-${tenant}.localhost` : process.env.REACT_APP_ENV == "staging" ? `api-${tenant}.staging.clairo.ai` : `${tenant}.api.clairo.ai`
};

const httpGet = (url, params, config) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the GET request with the merged headers
    return axiosInstance.get(url, {
        headers: headersWithAuthorization,
        params: params,
        ...config,
    });
};

// const httpGet = (url, params, config) =>
//     axiosInstance.get(url, {
//         headers,
//         params: params,
//         ...config,
//     });

// const httpPost = (url, body, config) =>
//     axiosInstance.post(url, body, {
//         headers,
//         ...config,
//     });

// const httpPut = (url, body, config) =>
//     axiosInstance.put(url, body, {
//         headers,
//         ...config,
//     });

// const httpPatch = (url, body) =>
//     axiosInstance.patch(url, body, {
//         headers,
//     });

// const httpDelete = (url) =>
//     axiosInstance.delete(url, {
//         headers,
//     });me


const httpPost = (url, body, config) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the POST request with the merged headers
    return axiosInstance.post(url, body, {
        headers: headersWithAuthorization,
        ...config,
    });
};

const httpPostWithParameters = (url, body, config = {}) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
    };

    // Make the POST request with the merged headers
    return axiosInstance.post(url, body, {
        headers: headersWithAuthorization
    });
};


const httpPut = (url, body, config) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the PUT request with the merged headers
    return axiosInstance.put(url, body, {
        headers: headersWithAuthorization,
        ...config,
    });
};

const httpPatch = (url, body) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the PATCH request with the merged headers
    return axiosInstance.patch(url, body, {
        headers: headersWithAuthorization,
    });
};

const httpDelete = (url) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the DELETE request with the merged headers
    return axiosInstance.delete(url, {
        headers: headersWithAuthorization,
    });
};

const httpDeleteWithBody = (url, data) => {
    // Merge the default headers with the Authorization header
    const headersWithAuthorization = {
        ...headers,
        Authorization: `Bearer ${authToken}`
    };

    // Make the DELETE request with the merged headers and request body
    return axiosInstance.delete(url, {
        headers: headersWithAuthorization,
        data: data // Include the request body
    });
};

export {
    BASE_URL,
    RAG_BASE_URL,
    AUTH_REFRESH_URL,
    WEBSOCKET_BASE_URL,
    httpGet,
    httpPost,
    httpPatch,
    httpDelete,
    httpDeleteWithBody,
    httpPut,
    axios,
    axiosInstance,
    httpPostWithParameters
};
