export const listOfColumns = [{
    title: "First Name",
    sub_title: "A-Z",
    field_name: "first_name",
    type: "text_with_sorting_option",
    sort_option: [
        'A-Z',
        'Z-A'],
   
    width: "25%"
},
{
    title: "Last Name",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "last_name",
    sort_option: [
        'A-Z',
        'Z-A'],
    width: "25%"
},
{
    title: "Email",
    type: "text_with_sorting_option",
    field_name: "email",
    width: "25%"
},
{
    title: "Role",
    sub_title: "All",
    type: "text_with_sorting_option",
    field_name: "role",
    sort_option: [
        'All',
        'super_user',
        'agent_user',
        'test_user'
    ],
    width: "25%"
},
{
    title: "Actions",
    field_name: "actions_list",
    type: "dropdown_actions",
    width: "5%"
}
]