import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Files_Attachment_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" rx="22" fill="#FF5A00"/>
    <path d="M33.0625 20.6406C33.625 20.875 33.9531 21.4375 34 22C34 22.6094 33.625 23.1719 33.0625 23.4062L12.0625 32.4062C11.875 32.5 11.6875 32.5469 11.5 32.5469C11.0781 32.5469 10.6562 32.3594 10.375 32.0312C9.95312 31.6094 9.85938 30.9062 10.1406 30.3438L14.5 22L10.1406 13.7031C9.85938 13.1406 9.95312 12.4375 10.375 11.9688C10.6562 11.6406 11.0781 11.5 11.5 11.5C11.6875 11.5 11.875 11.5469 12.0625 11.6406L33.0625 20.6406ZM11.4531 13.0469L15.7656 21.25H30.7188L11.4531 13.0469ZM11.4531 31.0469L30.6719 22.75H15.7656L11.4531 31.0469Z" fill="#1E1D1E"/>
    </svg>
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Files_Attachment_Shaded)