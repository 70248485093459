// lists
export const getNewMessageResponse = (state) => state.chatscreen?.new_message_response
export const getIsMessageSending = (state) => state.chatscreen?.is_sending_msg
export const getIsLoadingMessageHistory = (state) => state.chatscreen?.is_loading_messageHistory
export const getIsAttachingDocuments = (state) => state.chatscreen?.is_attaching_documents
export const getListOfAttachedDocumentsId = (state) => state.chatscreen?.list_of_attached_documents_id

export const getCurrentConversationId = (state) => state.chatscreen?.current_conversation_id
export const getCurrentCRoomName = (state) => state.chatscreen?.current_room_name
export const getCurrentSelectedTopic = (state) => state.chatscreen?.current_selected_topic
export const getCurrentServerResponse = (state) => state.chatscreen?.current_server_response
export const getShallCreateNewChat = (state) => state.chatscreen?.shall_create_new_chat
export const getCurrentUserMessage = (state) => state.chatscreen?.current_user_message
export const getCurrentSelectedChatbot = (state) => state.chatscreen?.current_selected_chatbot
export const getListOfConversations = (state) => state.chatscreen?.list_of_conversations
export const getIsLoadingListOfConversations = (state) => state.chatscreen?.is_loading_list_of_conversations
export const getListOfMessages = (state) => state.chatscreen?.list_of_messages
export const getNumberOfMessagesSent = (state) => state.chatscreen?.number_of_messages_sent
export const getIsChatbotAdvancedSettingsOpen = (state) => state.chatscreen?.is_chatbot_advanced_settings_open
export const getIsQAbotAdvancedSettingsOpen = (state) => state.chatscreen?.is_qa_bot_advanced_settings_open
export const getCurrentChatScreenType = (state) => state.chatscreen?.current_chat_screen_type
export const getListOfAppsInvisibleOrVisible = (state) => state.chatscreen?.make_list_of_apps_invisible_or_visible
export const getIsAttachDocumentsSideBarVisible = (state) => state.chatscreen?.is_attach_documents_side_bar_visible
export const getIsAttachmentsUploaded = (state) => state.chatscreen?.is_attachments_uploaded
export const getNewMessageResponseFromChatAPI = (state) => state.chatscreen?.message_response_from_chat_api
export const getHasSSESentResponseSinceLastMessage = (state) => state.chatscreen?.has_sse_sent_response_since_last_message
export const getHasChatAPIFailed = (state) => state.chatscreen?.has_chat_api_failed
