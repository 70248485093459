
import { BASE_URL, httpDelete, httpDeleteWithBody, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance"
const USERS_BASE_URL = `${BASE_URL}/`
const createLibrary = (data) => httpPost(`${USERS_BASE_URL}api/library/create/`, data);
const updateLibrary = (data) => httpPut(`${USERS_BASE_URL}api/library/${data.id}`, data.payload);
const deleteLibrary = (data) => httpDelete(`${USERS_BASE_URL}api/library/${data}`);

const uploadDocument = (data) => httpPost(`${USERS_BASE_URL}api/document/create/`, data);
const deleteDocument = (data) => httpDelete(`${USERS_BASE_URL}api/document/${data}`);
const deleteDocuments = (data) => httpDeleteWithBody (`${USERS_BASE_URL}api/document/delete_documents/`,data);


const updateDocument = (data) => httpPut(`${USERS_BASE_URL}api/document/${data.id}`, data.payload);

const getAllLibraries = (data) => httpGet(`${USERS_BASE_URL}api/library/`, data);
const getAllDocuments = (data) => httpGet(`${USERS_BASE_URL}api/document/?limit=1000`, data);

export {
    createLibrary,
    getAllLibraries,
    uploadDocument,
    getAllDocuments,
    deleteDocument,
    deleteDocuments,
    updateDocument,
    updateLibrary,
    deleteLibrary
}