
import { StyledSwitch, StyledTextField } from './styled';
import styles from './styles.module.scss'


// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const CustomSwitch = ({
    defaultColor = "default",
    checkedColor = "white",
    checkedHoverColor = "white",
    checkedTrackColor = "checked_switch_color",
    disabledColor = "light",
    checked = false,
    onChange = (e) => { console.error("Please provide onchange method in switch") },
    ...props }) => {
    return <div className={styles.container}>
        <StyledSwitch
            defaultColor={defaultColor}
            checkedColor={checkedColor}
            checkedHoverColor={checkedHoverColor}
            checkedTrackColor={checkedTrackColor}
            disabledColor={disabledColor}
            onChange={onChange}
            checked={checked}
            {...props}
        />
    </div>
};

export { CustomSwitch }