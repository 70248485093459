import { connect } from "react-redux";
import { loginUser, registerUser, sendThumbsUp } from "../logic/actions";
import { useEffect, useRef, useState } from "react";
import { MyStyledButton_type1, MyStyledButton_type2, MyStyledButton_type3, MyStyledButton_type4, MyStyledButton_type5, StyledABottomBotControllerContainer, StyledAppItemContainer, StyledAppsListContainer, StyledBotMsgContainer, StyledCurrentSelectedBotContainer, StyledDivider, StyledFileItemContainer, StyledHDropZoneContainer, StyledHeading1, StyledHeading10, StyledHeading11, StyledHeading12, StyledHeading13, StyledHeading14, StyledHeading15, StyledHeading16, StyledHeading18, StyledHeading19, StyledHeading2, StyledHeading20, StyledHeading21, StyledHeading22, StyledHeading23, StyledHeading24, StyledHeading3, StyledHeading4, StyledHeading5, StyledHeading7, StyledHeading8, StyledHeading9, StyledHistoryContainer, StyledLabel, StyledListOfBotsAsDropDown, StyledListOfBotsAsDropDownItemContainer, StyledMainContainer, StyledNavBarContainer, StyledNewChatButtonContainer, StyledPagination, StyledSideBarContainer, StyledSwitch, StyledTextareaForMessage, StyledTextField, StyledUserHistoryItemContainer, StyledUserMsgContainer, StyledUserMsgFileContainer } from "./styled";
import styles from './styles.module.scss'
import { getCurrentTheme, getCurrentThemeClone, getRoute, isUserDetailsUpdated } from "../../app/logic/selectors";
import Ic_Notification_Un_Shaded from "../../../shared/ui/editable_icons/Ic_Notification_Un_Shaded";
import Ic_Settings_Un_Shaded from "../../../shared/ui/editable_icons/Ic_Settings_Un_Shaded";
import { default_theme, getUserDetails, showToastMessage, updateTheme } from "../../app/logic/actions";
import Ic_Profile_Un_Shaded from "../../../shared/ui/editable_icons/Ic_Profile_Un_Shaded";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Ic_Logout_Shaded from "../../../shared/ui/editable_icons/Ic_Logout_Shaded";
import { getIsAttachingDocuments, getIsMessageSending, getListOfAttachedDocumentsId, getNewMessageResponse } from "../../dashboard/logic/chat/selectors";
import { attachDocumentsToChat, createConversationId, fetchMessagesHistory, sendChatMessage, sendQuestionMessage, setResponseMessage } from "../../dashboard/logic/chat/actions";
import { getCurrentSelectedTopic } from "../../dashboard/logic/users/selectors";
import Clairo_Logo from '../../../../src/assets/images/Clairo_Master_icon_RGB.png'
import { getIsListOfChunkingMethodsLoading, getListOfChunkingMethods, getListOfDocuments, getListOfSeparators } from "../../dashboard/logic/library/selectors";
import { _listOfEmbeddingModels } from "../../dashboard/ui/sub_screens/Agents/data";
import Chatscreen from "../../../shared/ui/chatscreen";
import { setCurrentChatScreenType } from "../../../shared/ui/chatscreen/logic/slice";
import File_uploader from "../../../shared/ui/file_uploader";
import { getIsAttachDocumentsSideBarVisible } from "../../../shared/ui/chatscreen/logic/selectors";
import { CustomHeading } from "../../../shared/ui/headings";
import { CustomButton } from "../../../shared/ui/buttons";
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from "../../../shared/ui/buttons/button_types";

const ProductionDashboardPage = ({
    current_theme_clone,
    saveNewTheme,
    list_of_documents,
    is_attaching_documents,
    attachDocumentsToChat,
    list_of_attached_documents,
    current_theme,
    setListOfAttachedDocumentsIds,
    getUserDetails,
    isUserDetailsUpdated,
    is_attach_documents_side_bar_visible,
    showToastMessage,
    sendPositiveReaction,
    sendThumbsUp,
    setCurrentChatScreenType,
    ...props }) => {

    const [currentUser, setCurrentUser] = useState("Default_User")
    const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        setCurrentChatScreenType("co-pilot")
        let local_user = localStorage.getItem('user')
        if (local_user != undefined && local_user != null) {
            setCurrentUser(JSON.parse(local_user))
        }
    }, [])

    useEffect(() => {
        if (isDarkModeEnabled) {
            saveNewTheme(current_theme_clone)
        } else {
            saveNewTheme(default_theme)
        }
    }, [isDarkModeEnabled])

    const handleLogout = () => {
        localStorage.clear();
        setTimeout(() => {
            navigate("/auth")
            window.location.reload();
        }, 1000);

    }



    return <StyledMainContainer className={styles.container}>
        <StyledNavBarContainer className={styles.navbar_container}>
            <div className={styles.left_container}>
                <div className={styles.logo_container}>
                    <div className={styles.image}>
                        <img src={Clairo_Logo} width="16%" />
                        <CustomHeading text="Clairo Copilot" font_size='16' className={styles.text}> </CustomHeading>
                    </div>
                </div>
            </div>
            <div className={styles.right_container}>
                <div className={styles.option_container}>
                    {
                        currentUser?.role === "super_user" && 
                        <CustomButton currentButtonTpe={TYPE_1_button} title='Dashboard' onClick={() => {navigate("/dashboard/home")}} className={styles.button}></CustomButton>
                    }

                </div>
                <div className={styles.option_container}>
                    <div className={styles.image}><Ic_Notification_Un_Shaded /></div>
                </div>
                <div className={styles.option_container}>

                    <div className={styles.image}><Ic_Settings_Un_Shaded /></div>
                </div>
                <div className={styles.option_container}>
                    <div className={styles.image} onClick={() => { }}><Ic_Profile_Un_Shaded /></div>
                </div>
                <div className={styles.username_container}>
                    <div className={styles.heading}>
                        <StyledHeading9>{currentUser?.first_name && currentUser.first_name.charAt(0).toUpperCase() + currentUser.first_name.slice(1)} {currentUser?.last_name && currentUser.last_name.charAt(0).toUpperCase() + currentUser.last_name.slice(1)}</StyledHeading9>
                    </div>
                </div>
                <div className={styles.option_container} onClick={handleLogout}>
                    <div className={styles.image}><Ic_Logout_Shaded /></div>
                </div>
            </div>
        </StyledNavBarContainer>
        <div className={styles.chat_container} >
            <Chatscreen />
        </div>
        {
            is_attach_documents_side_bar_visible && <StyledSideBarContainer className={styles.side_bar_opened}>
                <div className={styles.file_uploader}>
                    <File_uploader type='vertical' />
                </div>
            </StyledSideBarContainer>
        }

    </StyledMainContainer>
};

const mapState = (state) => ({
    current_theme_clone: getCurrentThemeClone(state),
    isUserDetailsUpdated: isUserDetailsUpdated(state),
    current_selected_topic: getCurrentSelectedTopic(state),
    list_of_documents: getListOfDocuments(state),
    current_theme: getCurrentTheme(state),
    is_loading_list_of_chunking_methods: getIsListOfChunkingMethodsLoading(state),
    is_attaching_documents: getIsAttachingDocuments(state),
    list_of_attached_documents: getListOfAttachedDocumentsId(state),
    is_attach_documents_side_bar_visible: getIsAttachDocumentsSideBarVisible(state),
})

const mapDispatchToProps = (dispatch) => ({
    saveNewTheme: (data) => dispatch(updateTheme(data)),
    getUserDetails: (data) => dispatch(getUserDetails(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    attachDocumentsToChat: (data, con_id) => dispatch(attachDocumentsToChat(data, con_id)),
    sendThumbsUp: (data) => dispatch(sendThumbsUp(data)),
    setCurrentChatScreenType: (data) => dispatch(setCurrentChatScreenType(data)),
});

export default connect(mapState, mapDispatchToProps)(ProductionDashboardPage)