import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    analytics: {},
    numberOfUsers: null,
    numberOfDocuments: null,
    numberOfConfigurations: null,
    numberOfConversationsAndMessages: null,
    is_loading_analytics: false,
    is_loading_numberOfUsers: false,
    is_loading_numberOfDocuments: false,
    is_loading_numberOfConfigurations: false,
    is_loading_numberOfConversationsAndMessages: false,
    error_numberOfUsers: null,
    error_numberOfDocuments: null,
    error_numberOfConfigurations: null,
    error_numberOfConversationsAndMessages: null,
}; 

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setAnalytics: (state, action) => {
            state.analytics = action.payload;
        },
        setIsLoadingAnalytics: (state, action) => {
            state.is_loading_analytics = action.payload;
        },
        setNumberOfUsers: (state, action) => {
            state.numberOfUsers = action.payload;
        },
        setIsLoadingNumberOfUsers: (state, action) => {
            state.is_loading_numberOfUsers = action.payload;
        },
        setErrorNumberOfUsers: (state, action) => {
            state.error_numberOfUsers = action.payload;
        },
        setNumberOfDocuments: (state, action) => {
            state.numberOfDocuments = action.payload;
        },
        setIsLoadingNumberOfDocuments: (state, action) => {
            state.is_loading_numberOfDocuments = action.payload;
        },
        setErrorNumberOfDocuments: (state, action) => {
            state.error_numberOfDocuments = action.payload;
        },
        setNumberOfConfigurations: (state, action) => {
            state.numberOfConfigurations = action.payload;
        },
        setIsLoadingNumberOfConfigurations: (state, action) => {
            state.is_loading_numberOfConfigurations = action.payload;
        },
        setErrorNumberOfConfigurations: (state, action) => {
            state.error_numberOfConfigurations = action.payload;
        },
        setNumberOfConversationsAndMessages: (state, action) => {
            state.numberOfConversationsAndMessages = action.payload;
        },
        setIsLoadingNumberOfConversationsAndMessages: (state, action) => {
            state.is_loading_numberOfConversationsAndMessages = action.payload;
        },
        setErrorNumberOfConversationsAndMessages: (state, action) => {
            state.error_numberOfConversationsAndMessages = action.payload;
        },
    },
});

export const {
    setAnalytics,
    setIsLoadingAnalytics,
    setNumberOfUsers,
    setIsLoadingNumberOfUsers,
    setErrorNumberOfUsers,
    setNumberOfDocuments,
    setIsLoadingNumberOfDocuments,
    setErrorNumberOfDocuments,
    setNumberOfConfigurations,
    setIsLoadingNumberOfConfigurations,
    setErrorNumberOfConfigurations,
    setNumberOfConversationsAndMessages,
    setIsLoadingNumberOfConversationsAndMessages,
    setErrorNumberOfConversationsAndMessages,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
