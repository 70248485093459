import React, { useEffect, useState, useRef } from 'react';
import { StyledProfileMainContainer, TextBox } from './styled';
import styles from './styles.module.scss';
import { getCurrentTheme, getCurrentThemeClone, selectIsSavingTheme } from '../../../../app/logic/selectors';
import { saveTheme, showToastMessage, updateTheme } from '../../../../app/logic/actions';
import { connect } from "react-redux";
import { getUserDetails } from '../../../../api/UserAPI';
import ic_visible_password from '../../../../../assets/images/icons8-visible-50.png'
import ic_invisible_password from '../../../../../assets/images/icons8-invisible-30.png'
import { updateUserAvatar, updateSingleUserPassword, addUser } from '../../../logic/profile/actions';
import { updateUserData } from '../../../logic/users/actions';
import add_btn from '../../../../../assets/images/icons8-add-48.png'
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomButton } from '../../../../../shared/ui/buttons'
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import { CustomLabel } from '../../../../../shared/ui/label'
import CustomTextField from '../../../../../shared/ui/text_field';
import SidebarToAddUser from './sidebar_to_add_user'
import { getCurrentLoggedInUsername } from '../../../logic/users/selectors';

const Profile = ({
    updateSingleUserPassword,
    updateUserData,
    current_logged_in_user,
    showToastMessage,
    current_theme }) => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
    const [user_password, setNewPassword] = useState("")
    const [user_confirm_password, setUserConfirmPassword] = useState("")
    const [user_current_password, setUserCurrentPassword] = useState("")
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [defaultAvatar, setDefaultAvatar] = useState('')
    const [openCreateUserSideBar, setOpenCreateUserSideBar] = useState(false);
    const handleOpenUserSideBar = () => setOpenCreateUserSideBar(true);
    const [currentUser, setCurrentUser] = useState(null);
    const updateValidations = [
        { field: firstName, message: "Cannot leave first name field empty" },
        { field: lastName, message: "Cannot leave last name field empty" },
    ];
    const passwordValidations = [
        { field: user_current_password, message: "Cannot leave password field empty" },
        { field: user_password, message: "Cannot leave password field empty" },
        { field: user_confirm_password, message: "Cannot leave password field empty" },
    ];

    const validateFields = (fields) => {
        for (let validation of fields) {
            if (validation.field.length === 0) {
                showToastMessage({ type: 'error', message: validation.message });
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        setTheme(current_theme)
    }, [current_theme])

    const user = JSON.parse(sessionStorage.getItem('user'));
    const [theme, setTheme] = useState()

    useEffect(()=>{
        if(current_logged_in_user){
            setCurrentUser(current_logged_in_user)
        }
    },[current_logged_in_user])
    const toggleOldPasswordVisibility = () => {
        setShowOldPassword(!showOldPassword);

    };
    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleConfirmedPasswordVisibility = () => {
        setShowConfirmedPassword(!showConfirmedPassword);
    };

    const handleUpdateUser = () => {
        if (!validateFields(updateValidations)) {
            return;
        }
        updateUserData({
            user_id: currentUser?.id,
            data: {
                first_name: firstName,
                last_name: lastName
            }
        })
        setFirstName('')
        setLastName('')
    }

    const handleUpdatePassword = () => {
        if (!validateFields(passwordValidations)) {
            return;
        }
        if (user_confirm_password !== user_password) {
            showToastMessage({ type: 'error', message: "Passwords do not match" });
            return;
        }
        try {
            const result = updateSingleUserPassword({
                current_password: user_current_password,
                password: user_password,
                confirm_password: user_confirm_password,
            });
            showToastMessage({ type: 'success', message: "Password updated successfully" });
            setUserCurrentPassword('');
            setNewPassword('');
            setUserConfirmPassword('');
        } catch (error) {
            showToastMessage({ type: 'error', message: "Failed to update password. Please try again later." });
        }
    };

    const formattedRole = currentUser?.role?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

    const handleGenerateAvatar = () => {
        const name = `${currentUser?.first_name}+${currentUser?.last_name}`
        const url = `https://ui-avatars.com/api/?name=${name}&size=200&background=343a40&color=ffffff&rounded=true`
        setDefaultAvatar(url)
    }

    useEffect(() => {
        handleGenerateAvatar()
    }, [currentUser])

    return (
        <div className={styles.container}>
            <div className={styles.main_container}>
                <div className={styles.header_container}>
                    <div className={styles.left_container}>
                        <CustomHeading font_weight='bold' font_size='20' text='Your Profile'></CustomHeading>
                        <CustomHeading font_size='15' text='Manage your Clairo AI profile'></CustomHeading>
                    </div>
                    {currentUser?.role == 'super_user' &&
                        <div className={styles.right_container}>
                            <CustomButton icon={<img src={add_btn} className={styles.icon} />} currentButtonTpe={TYPE_3_button} title='Add User' onClick={() => { handleOpenUserSideBar(true) }}></CustomButton>
                        </div>
                    }
                </div>
                <div className={styles.profile_container}>
                    <div className={styles.upload_photo_container}>
                        <div className={styles.upload_photo_button_container}>
                            <div className={styles.photo_upload}>
                                {defaultAvatar && (
                                    <div>
                                        <img src={defaultAvatar}></img>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className={styles.personal_details_container}>
                        <div className={styles.header}>
                            <CustomHeading font_size='20' text='Personal Details'></CustomHeading>
                        </div>
                        <StyledProfileMainContainer className={styles.personal_details_body} autoComplete='off'>
                            <CustomLabel title='First Name'></CustomLabel>
                            <CustomTextField placeholder='Enter first name' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                            <CustomLabel title='Last Name'></CustomLabel>
                            <CustomTextField placeholder='Enter last name' value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                            <TextBox>
                                <CustomLabel title="Email"></CustomLabel>
                                <CustomLabel title={user.email}></CustomLabel>
                            </TextBox>
                            <TextBox>
                                <CustomLabel title="Role"></CustomLabel>
                                <CustomLabel title={formattedRole}></CustomLabel>
                            </TextBox>

                            <div className={styles.personal_details_button_container}>
                                <CustomButton
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleUpdateUser}
                                    title="Save Changes"
                                >
                                </CustomButton>
                            </div>
                        </StyledProfileMainContainer>
                    </div>
                    <div className={styles.account_settings_container}>
                        <div className={styles.header}>
                            <CustomHeading font_size='20' text='Change password'></CustomHeading>
                        </div>
                        <StyledProfileMainContainer className={styles.password_change_body}>
                            <CustomLabel title="Old Password"></CustomLabel>
                            <div className={styles.password_field_container}>
                                <CustomTextField placeholder='Enter Old Password' type={showOldPassword ? "text" : "password"} className={styles.input_field} value={user_current_password} onChange={(e) => { setUserCurrentPassword(e.target.value) }} />
                                <img src={showOldPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={toggleOldPasswordVisibility} />
                            </div>
                            <CustomLabel title="New Password"></CustomLabel>
                            <div className={styles.password_field_container}>
                                <CustomTextField placeholder='Enter New Password' type={showNewPassword ? "text" : "password"} className={styles.input_field} value={user_password} onChange={(e) => { setNewPassword(e.target.value) }} />
                                <img src={showNewPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={toggleNewPasswordVisibility} />
                            </div>
                            <CustomLabel title="Confirm Password"></CustomLabel>
                            <div className={styles.password_field_container}>
                                <CustomTextField placeholder='Confirm New Password' type={showConfirmedPassword ? "text" : "password"} className={styles.input_field} value={user_confirm_password} onChange={(e) => { setUserConfirmPassword(e.target.value) }} />
                                <img src={showConfirmedPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={toggleConfirmedPasswordVisibility} />
                            </div>
                            <div className={styles.password_button_container}>
                                <div className={styles.password_button}>
                                    <CustomButton
                                        currentButtonTpe={TYPE_1_button}
                                        onClick={handleUpdatePassword}
                                        title="Change Password"
                                    >
                                    </CustomButton>
                                </div>
                            </div>
                        </StyledProfileMainContainer>
                    </div>
                </div>
            </div>
            {
                openCreateUserSideBar && <SidebarToAddUser setOpenCreateUserSideBar={setOpenCreateUserSideBar} handleOpenUserSideBar={handleOpenUserSideBar} />
            }
        </div>
    );
};


const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
    current_theme_clone: getCurrentThemeClone(state),
    is_saving_theme: selectIsSavingTheme(state),
    current_logged_in_user: getCurrentLoggedInUsername(state),
})

const mapDispatchToProps = (dispatch) => ({
    saveNewTheme: (data) => dispatch(updateTheme(data)),
    saveNewThemeOnDb: (data) => dispatch(saveTheme(data)),
    getUserDetails: (data) => dispatch(getUserDetails(data)),
    updateUserAvatar: (data) => dispatch(updateUserAvatar(data)),
    updateUserData: (data) => dispatch(updateUserData(data)),
    updateSingleUserPassword: (data) => dispatch(updateSingleUserPassword(data)),
    addUser: (data) => dispatch(addUser(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
});

export default connect(mapState, mapDispatchToProps)(Profile)