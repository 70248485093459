import { Button, Pagination, TextareaAutosize } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

// Containers

const StyledMainContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
`;

const StyledNavBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;


const StyledHistoryContainer = styled.div`
  border-radius:0px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledFileItemContainer = styled.div`
  border-radius:8px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

const StyledHDropZoneContainer = styled.div`
  border-radius:20px;
  border: 3px dashed ${({ theme }) => theme.palette.borderColors.secondary};
`;
const StyledAppsListContainer = styled.div`
  // border-radius:8px;
  // background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

const StyledABottomBotControllerContainer = styled.div`
  border-radius:8px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.transparent};
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

const StyledSwitch = styled(Switch)`
  && {

    &.MuiSwitch-root {
      color: ${({ theme }) => theme.palette.backgroundColors.default};
    }

    & .MuiSwitch-switchBase.Mui-checked {
      color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      &:hover {
        
        background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      }
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      
      background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
    }

    & .MuiSwitch-track.Mui-disabled {
      
      background-color: ${({ theme }) => theme.palette.backgroundColors.light}; /* Change the color when the switch is disabled and checked */
    }
  }
`;


const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
    word-break: break-word;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 24px;
    
  }
`;

const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 0.6rem;
    
  }
`;

const StyledHeading3 = styled(Typography)`
  && {
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 16px;
    
  }
`;

const StyledTextareaForMessage = styled(TextareaAutosize)`
  && {
    display: block;
    max-width: 98%;
    overflow: auto;
    min-width: 98%;
    // max-height: 106px;
    min-height: 26px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    color:  ${({ theme }) => theme.palette.textColors.quaternary}; 
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  
    &::placeholder {
      color: ${({ theme }) => theme.palette.textColors.quaternary};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }


    /* Custom scrollbar styles for WebKit browsers */
    &::-webkit-scrollbar {
      width: 12px; /* Adjust the width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.inputFieldBackground.secondary}; /* Background of the scrollbar track */
      border-radius: 8px; /* Make the track corners rounded */
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.borderColors.secondary}; /* Background color of the scrollbar thumb */
      border-radius: 8px; /* Make the thumb corners rounded */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.palette.borderColors.tertiary}; /* Background color of the scrollbar thumb on hover */
    }

    /* Custom scrollbar styles for Firefox */
    scrollbar-width: thin; /* Make the scrollbar thin */
    scrollbar-color: ${({ theme }) => theme.palette.borderColors.secondary} ${({ theme }) => theme.palette.inputFieldBackground.secondary}; /* Thumb and track colors */
  }
`;

const StyledHeading4 = styled(Typography)`
  && {
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 2.5rem;
    
  }
`;
const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 0px 0; /* Adjust the margin as needed */
`;

const StyledUserHistoryItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.transparent};
  max-width: 85%;
  min-width: 85%;
  cursor:pointer;
  padding:0%;
  display:flex;
  flex-direction:column;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;

const StyledAppItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary};
  max-width: 100%;
  min-width: 70%;

  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;

const StyledCurrentSelectedBotContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary};
  width:25% !important;
  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;


const StyledListOfBotsAsDropDown = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  width:97% !important;
  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;


const StyledListOfBotsAsDropDownItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  width:97% !important;
  border-radius: 7px;
  cursor:pointer;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  
`;


const StyledTextField = styled(TextField)`
  && {
    display: flex;
    width:80%;
    
    input {
      height: 10px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    border: 1px solid  ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
  
    
    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;

const MyStyledButton_type3 = styled(Button)`
  && {
    background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    color: ${({ color, bg_color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
    &:hover {
      background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;

    border: 1px solid  ${({ color, bg_color, theme }) => theme.palette.borderColors[bg_color] || theme.palette.borderColors.tertiary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    // margin-left: 10px;
    
    width: fit-content; /* Adjust width to content */
   
  }
`;



const StyledHeading8 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.quaternary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 12px;
  }
`;


const StyledUserMsgContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.darkest};
  max-width: 70%;
  min-width: fit-content;
  border-radius:25px;
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 10px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
`;
const StyledUserMsgFileContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.file_msg_container_color};
  max-width: 70%;
  min-width: 30%;
  border: 6px solid  ${({ theme }) => theme.palette.borderColors.darkest};
   
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 15px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
`;

const StyledHeading5 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 18px;
  }
`;


const StyledHeading7 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 12px;
  }
`;


const StyledHeading9 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 16px;
  }
`;

const StyledHeading10 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 32px;
  }
`;

const StyledHeading11 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 14px;
  }
`;

const StyledHeading12 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 14px;
    
    font-size: 14px;
  }
`;

const StyledHeading13 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
    
    font-size: 24px;
  }
`;

const StyledHeading14 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
    
    font-size: 12px;
  }
`;

const StyledHeading15 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    
    font-size: 20px;
  }
`;

const StyledHeading16 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    font-size: 14px;
  }
`;

const StyledHeading18 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  
    font-size: 17px;
  }
`;

const StyledHeading19 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-style: normal;
    font-weight: 500;
    line-height: normal;
   
    font-size: 13px;
  }
`;
const MyStyledButton_type2 = styled(Button)`
  && {
    background-color: ${({ color, bg_color, theme }) => theme.palette.backgroundColors[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    color: ${({ color, bg_color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
    &:hover {
      background-color: ${({ color, bg_color, theme }) => theme.palette.backgroundColors[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;

    border: 1px solid  ${({ color, bg_color, theme }) => theme.palette.borderColors[bg_color] || theme.palette.borderColors.darkest};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    // margin-left: 10px;
    
    width: auto; /* Adjust width to content */
    height: auto; /* Adjust height to content */
  }
`;
const StyledBotMsgContainer = styled.div`
  // background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  max-width: 70%;
  min-width: fit-content;
  border-radius:25px;
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 10px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
`;


const StyledNewChatButtonContainer = styled.div`
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--sm_8, 8px);
  border: var(--Nul, 1px) solid var(--Clairo-orange, #FF5A00);
  background: var(--Clairo-orange, #FF5A00);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor:pointer;
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
  `;

const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ theme }) =>
    theme.palette.buttonBackgrounds.secondary};
    color: ${({ theme }) =>
    theme.palette.textColors.default}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) =>
    theme.palette.buttonBackgrounds.primary};
      border: 1px solid
        ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;

    display: flex;
    align-items: flex-start;
    // align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.secondary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top: 10px;
  }
`;

const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    border-radius: 0;
    &:not(.Mui-selected) {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }
  }

  .Mui-selected {
    color: ${({ theme }) => theme.palette.textColors.secondary};
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    &:hover {
      background-color: ${({ theme }) =>
    theme.palette.buttonBackgrounds.primary};
    }
  }
`;


const MyStyledButton_type4 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.transparent};
    color: ${({ theme }) => theme.palette.textColors.primary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      color: ${({ theme }) => theme.palette.textColors.default}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    gap: 0px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.white};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;


const StyledHeading20 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 15px;
  }
`;


const StyledHeading21 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 100;
    line-height: normal;
    font-size: 10px;
  }
`;

const StyledHeading22 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.secondary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 100;
    line-height: normal;
    font-size: 17px;
  }
`;


const StyledHeading23 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 100;
    line-height: normal;
    font-size: 17px;
  }
`;

const StyledHeading24 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 100;
    line-height: normal;
    font-size: 14px;
  }
`;

const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};

    font-feature-settings: "clig" off, "liga" off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
  }
`;

const MyStyledButton_type5 = styled(Button)`
  && {
    background-color: ${({ backgroundColor, theme }) => backgroundColor ? theme.palette.buttonBackgrounds[backgroundColor] : theme.palette.buttonBackgrounds.primary};
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
      color: ${({ theme }) => theme.palette.textColors.primary}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;
    margin-left:5px

  }

  }
`;

export {
  StyledMainContainer,
  StyledNavBarContainer,
  StyledSwitch,
  StyledHistoryContainer,
  StyledHeading1,
  StyledDivider,
  StyledHeading2,
  StyledUserHistoryItemContainer,
  StyledHeading3,
  StyledAppsListContainer,
  StyledAppItemContainer,
  StyledABottomBotControllerContainer,
  StyledTextField,
  StyledHeading4,
  MyStyledButton_type3,
  StyledHeading8,
  StyledUserMsgContainer,
  StyledHeading5,
  StyledHeading7,
  MyStyledButton_type2,
  StyledBotMsgContainer,
  StyledTextareaForMessage,
  StyledHeading9,
  StyledHeading10,
  StyledHeading11,
  StyledHeading12,
  StyledHeading13,
  StyledHeading14,
  StyledHeading15,
  StyledHeading16,
  StyledHeading18,
  StyledHeading19,
  StyledNewChatButtonContainer,
  StyledCurrentSelectedBotContainer,
  StyledListOfBotsAsDropDown,
  StyledListOfBotsAsDropDownItemContainer,
  StyledSideBarContainer,
  MyStyledButton_type1,
  StyledPagination,
  StyledHDropZoneContainer,
  MyStyledButton_type4,
  StyledHeading20,
  StyledHeading21,
  StyledFileItemContainer,
  StyledLabel,
  StyledHeading22,
  StyledUserMsgFileContainer,
  StyledHeading23,
  StyledHeading24,
  MyStyledButton_type5
}
