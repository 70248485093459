
import { TYPE_1, TYPE_3 } from './button_types';
import { StyledButton_Type1, StyledTextField } from './styled';
import styles from './styles.module.scss'


// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const CustomButton = ({
    font_family = "primary",
    font_size = "14px",
    text_color = "primary",
    background_color = "secondary",
    border_color = "primary",
    hoverBorderColor="primary",
    hoverBackgroundColor="primary",
    hoverTextColor="primary",
    mobileFontSize = "12px",
    tabletFontSize = "13px",
    laptopFontSize = "14px",
    macbook13FontSize = "15px",
    pick_passed_font_size = false,
    width = "100%",
    input_height = "5px",
    unit = "px",
    border_radius = "8px",
    title,
    onClick,
    icon,
    padding="12px 16px",
    currentButtonTpe = "default_type",
    ...props }) => {

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    // Determine font sizes to use based on `pick_passed_font_size`
    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return <div className={styles.container}>
        {currentButtonTpe === TYPE_1 && (
            <StyledButton_Type1
                backgroundColor={background_color}
                hoverBackgroundColor={hoverBackgroundColor}
                hoverBorderColor={hoverBorderColor}
                hoverTextColor={hoverTextColor}
                textColor={text_color}
                fontSize={font_size}
                fontWeight="bold"
                borderRadius={border_radius}
                padding={padding}
                marginTop="20px"
                borderColor={border_color}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                onClick={onClick}
                style={{ width: '100%' }}
                {...props}
            >
                {title}
            </StyledButton_Type1>
        )}
        {currentButtonTpe === TYPE_3 && (
            <StyledButton_Type1
                backgroundColor={background_color}
                hoverBackgroundColor={hoverBackgroundColor}
                hoverBorderColor={hoverBorderColor}
                hoverTextColor={hoverTextColor}
                textColor={text_color}
                fontSize={font_size}
                fontWeight="bold"
                borderRadius={border_radius}
                padding={padding}
                marginTop="20px"
                borderColor={border_color}
                mobileFontSize={finalMobileFontSize}
                tabletFontSize={finalTabletFontSize}
                laptopFontSize={finalLaptopFontSize}
                macbook13FontSize={finalMacbook13FontSize}
                onClick={onClick}
                style={{ width: '100%' }}
                {...props}
            >
                {icon}
                {title}
            </StyledButton_Type1>
        )}
    </div>
};

export { CustomButton }