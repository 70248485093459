import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Add_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 1.28125V6.0625H13.2812C13.8457 6.0625 14.3438 6.56055 14.3438 7.125C14.3438 7.72266 13.8457 8.1875 13.2812 8.1875H8.5V12.9688C8.5 13.5664 8.00195 14.0312 7.4375 14.0312C6.83984 14.0312 6.375 13.5664 6.375 12.9688V8.1875H1.59375C0.996094 8.1875 0.53125 7.72266 0.53125 7.125C0.53125 6.56055 0.996094 6.0625 1.59375 6.0625H6.375V1.28125C6.375 0.716797 6.83984 0.21875 7.4375 0.21875C8.00195 0.21875 8.5 0.716797 8.5 1.28125Z" fill="white" fill-opacity="0.75"/>
    </svg>
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Add_Shaded)