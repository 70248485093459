import { Checkbox } from '@mui/material';
import styled from 'styled-components';

// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};

const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${({ theme, color }) => theme.palette.inputFieldBackground[color] || theme.palette.inputFieldBackground.primary};
    
    &.Mui-checked {
      color: ${({ theme, checkedColor }) => theme.palette.buttonBackgrounds[checkedColor] || theme.palette.buttonBackgrounds.secondary};
    }
    
    /* Border color for unchecked state */
    &:not(:checked) {
      color: ${({ theme, uncheckedColor }) => theme.palette.buttonBackgrounds[uncheckedColor] || theme.palette.buttonBackgrounds.secondary};
    }
    
    border-color: ${({ theme, borderColor }) => theme.palette.buttonBackgrounds[borderColor] || theme.palette.buttonBackgrounds.secondary}; 

    /* Media queries for responsive design */
    @media only screen and (max-width: ${breakpoints.mobile}) {
      && {
        font-size: ${({ mobileFontSize }) => mobileFontSize || '12px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      && {
        font-size: ${({ tabletFontSize }) => tabletFontSize || '13px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.laptop}) {
      && {
        font-size: ${({ laptopFontSize }) => laptopFontSize || '14px'};
      }
    }

    @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
           (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
           (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
           (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
      && {
        font-size: ${({ macbook13FontSize }) => macbook13FontSize || '15px'};
      }
    }
  }
`;

export { StyledCheckbox };
