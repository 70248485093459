
import { useEffect, useState } from 'react';
import { CustomDropdown } from '../drop_downs';
import { TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7 } from './general_types';
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { TYPE_1 as TYPE_1_dropdown, TYPE_2 as TYPE_2_dropdown, TYPE_3 as TYPE_3_dropdown, TYPE_4 as TYPE_4_dropdown, TYPE_7 as TYPE_7_dropdown } from '../drop_downs/drop_down_types';
import { getIsListOfLibrariesLoading, getListOfLibraries } from '../../../modules/dashboard/logic/library/selectors';
import { fetchListOfLibraries } from '../../../modules/dashboard/logic/library/actions';
import { _listOfActions, _listOfOptions, _listOfTones, _listOfAgentTypes } from '../../../modules/dashboard/ui/sub_screens/Agents/data';
import { _listOfSidebarOptions } from '../../../modules/dashboard/ui/sub_screens/Profile/data';

const GeneralDropdowns = ({
    type = TYPE_1,

    list_of_libraries,
    is_loading_list_of_libraries,
    loadListOfLibraries,

    defaultSelectedOptionsList,

    handleOnClick,
    defaultValue,
    defaultLLM,
    current_theme,

    icon = null,
    ...props
}) => {

    const [listOfLibraries, setListOfLibraries] = useState([]);
    const [currentSelectedLibrary, setCurrentSelectedLibrary] = useState([]);
    const [currentSelectedLibraries, setCurrentSelectedLibraries] = useState([]);
    const [listOfTones, setListOfTones] = useState([]);
    const [currentSelectedTone, setCurrentSelectedTone] = useState([]);
    const [listOfInstructions, setListOfInstructions] = useState([]);
    const [currentSelectedInstructions, setCurrentSelectedInstructions] = useState([]);
    const [listOfQActions, setListOfQAActions] = useState([]);
    const [listOfAgentTypes, setListOfAgentTypes] = useState([])
    const [currentSelectedAgentType, setCurrentSelectedAgentType] = useState([])
    const [currentSelectedQAction, setCurrentSelectedQAction] = useState([]);
    const [listOfSidebarOptions, setListOfSidebarOptions] = useState([])
    const [currentSelectedSidebarOption, setCurrentSelectedSidebarOption] = useState([])

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElActions, setAnchorElActions] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    useEffect(() => {
        loadListOfLibraries();
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchorElActions && !anchorElActions.contains(event.target)) {
                setIsDropdownOpen(false);
                setAnchorElActions(null);
            }
        };
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen, anchorElActions]);


    useEffect(() => {
        if (list_of_libraries?.length > 0) {
            setListOfLibraries(list_of_libraries)
            if (defaultValue === undefined || defaultValue === null) {
                setCurrentSelectedLibrary(list_of_libraries[0])
            }
        }
    }, [list_of_libraries])

    useEffect(() => {
        if (list_of_libraries?.length > 0) {
            setListOfLibraries(list_of_libraries)
        }
        if (_listOfTones?.length > 0) {
            setListOfTones(_listOfTones)
            if (defaultValue === undefined || defaultValue === null) {
                setCurrentSelectedTone(_listOfTones[0])
            }
        }
        if (_listOfOptions?.length > 0) {
            setListOfInstructions(_listOfOptions)
        }
        if (_listOfActions?.length > 0) {
            setListOfQAActions(_listOfActions)
            if (defaultValue === undefined || defaultValue === null) {
                setCurrentSelectedQAction({ ..._listOfActions[0] })
            }
        }
        if (_listOfSidebarOptions?.length > 0) {
            setListOfSidebarOptions(_listOfSidebarOptions.map((item) => item?.label))
            if (defaultValue === undefined || defaultValue === null) {
                setCurrentSelectedSidebarOption({ ..._listOfSidebarOptions[0] })
            }
        }
        if (_listOfAgentTypes?.length > 0) {
            setListOfAgentTypes(_listOfAgentTypes)
            if (defaultValue === undefined || defaultValue === null) {
                setCurrentSelectedAgentType({ ..._listOfActions[0] })
            }
        }
    }, [
        list_of_libraries, _listOfTones, _listOfOptions, _listOfActions, _listOfSidebarOptions, _listOfAgentTypes
    ])

    useEffect(() => {
        if (defaultValue != undefined && defaultValue != null) {
            setCurrentSelectedLibrary({ value: defaultValue })
            setCurrentSelectedTone({ value: defaultValue })
            setCurrentSelectedAgentType({value: defaultValue})
        } else {
            setCurrentSelectedLibrary(listOfLibraries[0]?.value)
            setCurrentSelectedTone(listOfTones[0]?.value)
            setCurrentSelectedAgentType(listOfAgentTypes[0]?.value)
        }

        if (defaultSelectedOptionsList?.length === 0) {
            setCurrentSelectedInstructions([{ value: listOfInstructions[0]?.id, id: listOfInstructions[0]?.id }])
            setCurrentSelectedLibraries([{ value: listOfLibraries[0]?.id, id: listOfLibraries[0]?.id }])
        }
    }, [defaultValue, listOfLibraries, listOfTones, listOfInstructions, listOfAgentTypes])

    const handleOpenActions = (event) => {
        setAnchorElActions(event.currentTarget);
        setIsDropdownOpen(true);
    };

    const handleCloseActions = (option) => {
        handleOnClick(option)
        setAnchorElActions(null);
        setIsDropdownOpen(false);
    };

    return (
        <div className={styles.container}>
            {
                type === TYPE_1 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfLibraries} defaultSelectedLibraryValue={currentSelectedLibrary?.value} theme={current_theme} is_loading={is_loading_list_of_libraries} list_type='listOfLibraryForSingleSelection' />
            }
            {
                type === TYPE_2 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfTones} defaultToneValue={currentSelectedTone?.value} theme={current_theme} is_loading={false} list_type='listOfTones' />
            }
            {
                type === TYPE_3 && <CustomDropdown dropdown_type={TYPE_3_dropdown} onClick={handleOnClick} options_list={listOfLibraries} defaultSelectedLibrariesValue={defaultSelectedOptionsList?.length > 0 ? defaultSelectedOptionsList : currentSelectedLibraries} theme={current_theme} is_loading={is_loading_list_of_libraries} selectedOptionsList={defaultSelectedOptionsList?.length > 0 ? defaultSelectedOptionsList : currentSelectedLibraries} list_type='listOfLibraryForMultiSelection' />
            }
            {
                type === TYPE_4 && <CustomDropdown dropdown_type={TYPE_3_dropdown} onClick={handleOnClick} options_list={listOfInstructions} defaultInstructionsValue={defaultSelectedOptionsList?.length > 0 ? defaultSelectedOptionsList : currentSelectedInstructions} theme={current_theme} is_loading={false} selectedOptionsList={defaultSelectedOptionsList?.length > 0 ? defaultSelectedOptionsList : currentSelectedInstructions} list_type='listOfInstructions' />
            }
            {
                type === TYPE_5 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfQActions} defaultQAActionsValue={currentSelectedQAction?.value} theme={current_theme} is_loading={false} list_type='listOfQActions' />
            }
            {
                type === TYPE_6 && <CustomDropdown
                    dropdown_type={TYPE_4_dropdown}
                    options_list={listOfSidebarOptions}
                    is_open={Boolean(anchorElActions)}
                    onClick={handleOpenActions}
                    anchorEl={anchorElActions}
                    onClose={handleCloseActions}
                    icon={icon}
                />
            }
            {
                type === TYPE_7 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfAgentTypes} defaultAgentTypeValue={currentSelectedAgentType?.value} theme={current_theme} is_loading={false} list_type='listOfAgentTypes' />
            }
        </div>
    );
};




const mapState = (state) => ({
    list_of_libraries: getListOfLibraries(state),
    is_loading_list_of_libraries: getIsListOfLibrariesLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadListOfLibraries: (data) => dispatch(fetchListOfLibraries(data)),
});

export default connect(mapState, mapDispatchToProps)(GeneralDropdowns)