import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { StyledMainContainer, StyledNavBarContainer, StyledSideBarContainer } from './styled';
import Home from './sub_screens/Home';
import Library from './sub_screens/Library';
import Chat from './sub_screens/Chat';
import Agents from './sub_screens/Agents';
import Profile from './sub_screens/Profile';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Documents from './sub_screens/Documents';
import Ic_Home_Shaded from '../../../shared/ui/editable_icons/Ic_Home_Shaded';
import Ic_Home_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Home_Un_Shaded';
import Ic_Folder_Shaded from '../../../shared/ui/editable_icons/Ic_Folder_Shaded';
import Ic_Folder_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Folder_Un_Shaded';
import Ic_Agents_Shaded from '../../../shared/ui/editable_icons/Ic_Agents_Shaded';
import Ic_Agents_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Agents_Un_Shaded';
import Ic_Chat_Shaded from '../../../shared/ui/editable_icons/Ic_Chat_Shaded';
import Ic_Chat_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Chat_Un_Shaded';
import Ic_Users_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Users_Un_Shaded';
import Ic_Users_Shaded from '../../../shared/ui/editable_icons/Ic_Users_Shaded'
import Ic_Expand_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Expand_Un_Shaded';
import Ic_Compress_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Compress_Un_Shaded';
import Ic_Logout_Shaded from '../../../shared/ui/editable_icons/Ic_Logout_Shaded';
import Ic_Profile_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Profile_Un_Shaded';
import Ic_Profile_Shaded from '../../../shared/ui/editable_icons/Ic_Profile_Shaded';
import Ic_Settings_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Settings_Un_Shaded';
import Ic_Notification_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Notification_Un_Shaded';
import Ic_Documents_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Documents_Un_Shaded';
import Ic_Documents_Shaded from '../../../shared/ui/editable_icons/Ic_Documents_Shaded';
import Ic_Response_Ticket_Un_Shaded from '../../../shared/ui/editable_icons/Ic_Response_Ticket_Un_Shaded';
import Users from './sub_screens/Users';
import { updateTheme } from '../../app/logic/actions';
import { getCurrentThemeClone } from '../../app/logic/selectors';
import ChatBotAgent from './sub_screens/Agents/ChatBotAgent';
import QAChatBotAgent from './sub_screens/Agents/QAChatBotAgent';
import Clairo_Logo from '../../../../src/assets/images/Clairo_Master_icon_RGB.png'
import { setCurrentChatScreenType, setIsChatbotAdvancedSettingsOpen, setIsQAbotAdvancedSettingsOpen } from '../../../shared/ui/chatscreen/logic/slice';
import General_dropdowns from '../../../shared/ui/general_dropdowns';
import { TYPE_6 } from '../../../shared/ui/general_dropdowns/general_types';
import { CustomButton } from '../../../shared/ui/buttons';
import { TYPE_1 as TYPE_1_button } from '../../../../src/shared/ui/buttons/button_types'
import { CustomHeading } from '../../../shared/ui/headings';
import { getCurrentTheme } from '../../app/logic/selectors';
import { TYPE_4 } from '../../../shared/ui/drop_downs/drop_down_types';
import { setCurrentLoggedInUserName } from '../logic/users/slice';
import { getCurrentLoggedInUsername } from '../logic/users/selectors';

function DashboardPage({
    current_theme_clone,
    saveNewTheme,
    setCurrentChatScreenType,
    setIsChatbotAdvancedSettingsOpen,
    setIsQAbotAdvancedSettingsOpen,
    setCurrentLoggedInUserName,
    logged_in_user,
    current_theme,
    ...props }) {
    const navigate = useNavigate();
    const [currentSelectedScreen, setCurrentSelectedScreen] = useState(<Home />)
    const [currentSelectedScreenTitle, setCurrentSelectedScreenTitle] = useState("home");
    const [isSidebarExtended, setIsSidebarExtended] = useState(false)
    const location = useLocation();
    const [dropdown, setDropdown] = useState(false)
    const { sub_screen, target_id, chatbot_type } = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        console.log("logged_in_user",logged_in_user)
        if (logged_in_user != undefined && logged_in_user != null) {
            setFirstName(logged_in_user?.first_name)
            setLastName(logged_in_user?.last_name)
        } else {
            setCurrentLoggedInUserName(user)
        }

    }, [logged_in_user])

    useEffect(() => {
        if (target_id != undefined && chatbot_type != undefined) {
            handleScreenChange(`agents_${chatbot_type}_edit`)
        } else {
            handleScreenChange(sub_screen);
        }
    }, [location.pathname, sub_screen, target_id, chatbot_type]);

    const handleLogout = () => {
        localStorage.clear();
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    const handleOpenSidebarDropdown = (event) => {
        setDropdown(!dropdown);
    };

    const handleScreenChange = (selectedOption) => {
        let screenComponent;
        let screenTitle = selectedOption;

        switch (selectedOption) {
            case "home":
                screenComponent = <Home />;
                break;
            case "library":
                screenComponent = <Library />;
                break;
            case "documents":
                screenComponent = <Documents />;
                break;
            case "chat":
                setIsChatbotAdvancedSettingsOpen(false);
                setCurrentChatScreenType("chat_screen");
                screenComponent = <Chat />;
                break;
            case "agents":
                setCurrentChatScreenType("-");
                setIsChatbotAdvancedSettingsOpen(false);
                screenComponent = <Agents />;
                break;
            case "agents_chatbot_edit":
                screenComponent = <ChatBotAgent />;
                screenTitle = "agents";
                break;
            case "agents_qa_chatbot_edit":
                screenComponent = <QAChatBotAgent />;
                screenTitle = "agents";
                break;
            case "profile":
                screenComponent = <Profile />;
                break;
            case "users":
                screenComponent = <Users />;
                break;
            default:
                screenComponent = <Home />;
                screenTitle = "home";
                break;
        }
        setCurrentSelectedScreen(screenComponent);
        setCurrentSelectedScreenTitle(screenTitle);
        navigate(`/dashboard/${selectedOption.replace('_edit', '')}`);
    };

    const toggleSidebar = () => {
        setIsSidebarExtended(!isSidebarExtended)
    }

    return <StyledMainContainer className={styles.container}>
        <StyledNavBarContainer className={styles.navbar_container}>
            <div className={styles.left_container}>
                <div className={styles.logo_container}>
                    <img src={Clairo_Logo} width="25%" className={styles.image} />
                </div>
            </div>
            <div className={styles.right_container}>
                <div className={styles.button_container}>
                    <CustomButton
                        currentButtonTpe={TYPE_1_button}
                        onClick={() => window.open("/user/dashboard", "_blank")}
                        background_color='primary'
                        title="Copilot"
                    />
                </div>
                <div className={styles.option_container}>
                    <div className={styles.image}><Ic_Notification_Un_Shaded /></div>
                </div>
                <div className={styles.option_container}>
                    <div className={styles.image}><Ic_Settings_Un_Shaded /></div>
                </div>
                <div className={styles.profile_option_container} onClick={() => { handleScreenChange("profile") }}>
                    <div className={styles.image} >
                        {currentSelectedScreenTitle == "profile" ? <Ic_Profile_Shaded /> : <Ic_Profile_Un_Shaded />}
                    </div>
                    <div className={styles.name}>
                        <CustomHeading text={`${firstName} ${lastName}`}></CustomHeading>
                    </div>

                </div>
                <div className={styles.option_container} onClick={handleLogout}>
                    <div className={styles.image}><Ic_Logout_Shaded /></div>
                </div>
            </div>
        </StyledNavBarContainer>
        <div className={styles.body_container}>
            <div className={`${styles.left_container} ${isSidebarExtended ? styles.expanded : ''}`} onDoubleClick={toggleSidebar}>
                <StyledSideBarContainer className={styles.sidebar_container}>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("home") }}>
                        <div className={styles.image}>
                            {currentSelectedScreenTitle == "home" ? <Ic_Home_Shaded className={styles.image} /> : <Ic_Home_Un_Shaded className={styles.image} />}
                        </div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "home" ? "secondary" : "primary"} text='Home' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}
                    </div>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("library") }}>
                        <div className={styles.image}>
                            {currentSelectedScreenTitle == "library" ? <Ic_Folder_Shaded /> : <Ic_Folder_Un_Shaded />}
                        </div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "library" ? "secondary" : "primary"} text='Library' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}
                    </div>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("documents") }}>
                        <div className={styles.image}>
                            {currentSelectedScreenTitle == "documents" ? <Ic_Documents_Shaded /> : <Ic_Documents_Un_Shaded />}
                        </div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "documents" ? "secondary" : "primary"} text='Documents' font_size='16' font_weight='bold' className={styles.heading}>Documents</CustomHeading>}
                    </div>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("agents") }}>
                        <div className={styles.image}>
                            {currentSelectedScreenTitle == "agents" ? <Ic_Agents_Shaded /> : <Ic_Agents_Un_Shaded />}
                        </div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "agents" ? "secondary" : "primary"} text='Agents' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}
                    </div>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("chat") }} >
                        <div className={styles.image}>{currentSelectedScreenTitle == "chat" ? <Ic_Chat_Shaded /> : <Ic_Chat_Un_Shaded />}</div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "chat" ? "secondary" : "primary"} text='Chatbot' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}
                    </div>
                    <div className={styles.option_container} onClick={() => { handleScreenChange("users") }}>
                        <div className={styles.image}>{currentSelectedScreenTitle == "users" ? <Ic_Users_Shaded /> : <Ic_Users_Un_Shaded />}</div>
                        {isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "users" ? "secondary" : "primary"} text='Users' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}
                    </div>
                    <div className={styles.bottom_option_container} >
                        <div className={styles.icon_2} onClick={handleOpenSidebarDropdown}>
                            <General_dropdowns
                                type={TYPE_6}
                                current_theme={current_theme}
                                icon={<Ic_Response_Ticket_Un_Shaded />}
                                handleOnClick={(selected_option) => {
                                    console.log(selected_option)
                                }}
                            />
                            <div className={styles.support_header}>{isSidebarExtended && <CustomHeading color={currentSelectedScreenTitle == "Support" ? "secondary" : "primary"} text='Support' font_size='16' font_weight='bold' className={styles.heading}></CustomHeading>}</div>
                        </div>
                        <div className={styles.icon_1} onClick={toggleSidebar}>
                            {isSidebarExtended ? <Ic_Compress_Un_Shaded /> : <Ic_Expand_Un_Shaded />}
                        </div>
                    </div>
                </StyledSideBarContainer>
            </div>
            <div className={styles.right_container}>
                <div className={styles.sub_screen_container}>
                    {currentSelectedScreen}
                </div>
            </div>
        </div>
    </StyledMainContainer>
}

const mapState = (state) => ({
    logged_in_user: getCurrentLoggedInUsername(state),
    current_theme_clone: getCurrentThemeClone(state),
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentLoggedInUserName: (data) => dispatch(setCurrentLoggedInUserName(data)),
    saveNewTheme: (data) => dispatch(updateTheme(data)),
    setCurrentChatScreenType: (data) => dispatch(setCurrentChatScreenType(data)),
    setIsChatbotAdvancedSettingsOpen: (data) => dispatch(setIsChatbotAdvancedSettingsOpen(data)),
    setIsQAbotAdvancedSettingsOpen: (data) => dispatch(setIsQAbotAdvancedSettingsOpen(data)),
});

export default connect(mapState, mapDispatchToProps)(DashboardPage)
