import { FormControlLabel, TextField } from '@mui/material';
import styled from 'styled-components';

// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};

const StyledDropdown = styled(TextField)`
  && {
    width: ${({ width }) => width || '100%'};
    font-family: ${({ fontFamily, theme }) => theme.palette.fontFamilies[fontFamily] || theme.palette.fontFamilies.secondary};
    font-size: ${({ fontSize }) => fontSize || '16px'};
    font-style: ${({ fontStyle }) => fontStyle || 'normal'};
    font-weight: ${({ fontWeight }) => fontWeight || '400'};
    line-height: ${({ lineHeight }) => lineHeight || '24px'};
    border-radius: ${({ borderRadius }) => borderRadius || '15px'};
    border: 1px solid ${({ borderColor, theme }) => theme.palette.borderColors[borderColor] || theme.palette.borderColors.primary};
    background: ${({ backgroundColor, theme }) => theme.palette.inputFieldBackground[backgroundColor] || theme.palette.inputFieldBackground.primary};
    color: ${({ textColor, theme }) => theme.palette.textColors[textColor] || theme.palette.textColors.primary};

    .MuiInputLabel-root {
      display: ${({ hideLabel }) => hideLabel ? 'none' : 'block'};
    }

    .MuiSelect-root {
      color: ${({ selectTextColor, theme }) => theme.palette.textColors[selectTextColor] || theme.palette.textColors.primary};
    }

    .MuiSelect-icon {
      color: ${({ iconColor, theme }) => theme.palette.textColors[iconColor] || theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ focusedBorderColor, theme }) => theme.palette.borderColors[focusedBorderColor] || theme.palette.borderColors.primary};
      border-radius: ${({ borderRadius }) => borderRadius || '15px'};
    }

    & .MuiOutlinedInput-root.Mui-focused {
      border-radius: ${({ borderRadius }) => borderRadius || '15px'};
      color: ${({ focusedTextColor, theme }) => theme.palette.textColors[focusedTextColor] || theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root:not(.Mui-focused) {
      border-radius: ${({ borderRadius }) => borderRadius || '15px'};
      color: ${({ textColor, theme }) => theme.palette.textColors[textColor] || theme.palette.textColors.primary};
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
      && {
        font-size: ${({ mobileFontSize }) => mobileFontSize || '14px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      && {
        font-size: ${({ tabletFontSize }) => tabletFontSize || '15px'};
      }
    }

    @media only screen and (min-width: ${breakpoints.laptop}) {
      && {
        font-size: ${({ laptopFontSize }) => laptopFontSize || '16px'};
      }
    }

    @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
           (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
           (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
           (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
      && {
        font-size: ${({ macbook13FontSize }) => macbook13FontSize || '17px'};
      }
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
color:white
`

export { StyledDropdown,StyledFormControlLabel };
