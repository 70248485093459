import { Switch } from '@mui/material';
import styled from 'styled-components';


const StyledSwitch = styled(Switch)`
  && {

    &.MuiSwitch-root {
      color: ${({ defaultColor, theme }) => theme.palette.backgroundColors[defaultColor] || theme.palette.backgroundColors.default};
    }

    & .MuiSwitch-switchBase.Mui-checked {
      color: ${({ checkedColor, theme }) =>theme.palette.backgroundColors[checkedColor] || theme.palette.backgroundColors.checked_switch_color};
      &:hover {
        background-color: ${({ checkedHoverColor, theme }) =>theme.palette.backgroundColors[checkedHoverColor] || theme.palette.backgroundColors.checked_switch_color};
      }
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: ${({ checkedTrackColor, theme }) =>theme.palette.backgroundColors[checkedTrackColor] || theme.palette.backgroundColors.checked_switch_color};
    }

    & .MuiSwitch-track.Mui-disabled {
      background-color: ${({ disabledColor, theme }) => theme.palette.backgroundColors[disabledColor] || theme.palette.backgroundColors.light};
    }
  }
`;

export { StyledSwitch };