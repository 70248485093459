
import { ChatAPI, UsersAPI, UserAPI } from "../../../../modules/api";
import { setPositiveReaction, setNegativeReaction, setMessageResponseFromChatAPI, setHasChatAPIFailed, setHasSSESentResponseSinceLastMessage } from './slice'
import { showToastMessage } from "../../../../modules/app/logic/actions";
import { setIsDocumentsUploaded } from "../../../../modules/dashboard/logic/library/slice";
import { store } from "../../../../store/store";
import { setIsMessageSending, setNewMessageResponse, setIsLoadingMessageHistory, setIsAttachingDocuments, setListOfAttachedDocumentsIds, setIsLoadingListOfConversations, setIsFailedListOfConversations, setListOfConversations, setCurrentConversationId, setCurrentRoomName, setCurrentServerResponse, setCurrentSelectedChatbot, setListOfMessages, addNewMessage, setIsAttachFilesSideBarVisible } from "./slice";
import moment from 'moment';



const attachDocumentsToChat = (data, conversation_id) => (dispatch) => {
    dispatch(setIsAttachingDocuments(true));
    dispatch(setIsDocumentsUploaded(false))
    ChatAPI.attachDocuments(data, conversation_id).then((response) => {
        dispatch(setIsAttachingDocuments(false));
        if (response.status == 201) {
            dispatch(setListOfAttachedDocumentsIds(response?.data))
            dispatch(setIsAttachFilesSideBarVisible(false))
            dispatch(setIsDocumentsUploaded(true))
        }
    }).catch((error) => {
        dispatch(setIsAttachingDocuments(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}
const sendQuestionMessage = (data) => (dispatch) => {
    dispatch(setIsMessageSending(true));
    ChatAPI.sendQuestionMessageToServer(data).then((response) => {
        dispatch(setIsMessageSending(false));
        if (response.status == 200) {
            dispatch(setNewMessageResponse(response.data.response.reply.prompt))
        }
    }).catch((error) => {
        dispatch(setIsMessageSending(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}

const setResponseMessage = (data) => (dispatch) => {
    dispatch(setNewMessageResponse(null));
}







// Methods after refactoring  


const formatConversationTime = (timestamp) => {
    return moment(timestamp).format('HH:mm on DD MMM YYYY');
};

const fetchListOfConversations = (data) => async (dispatch) => {
    dispatch(setIsLoadingListOfConversations(true));

    try {
        const response = await UsersAPI.fetchListOfConversations(data);

        if (response.data) {
            const mappedData = response.data
                .filter(rec => rec.config_id !== null && rec.config_id !== undefined)
                .map((rec) => ({
                    id: rec.id,
                    title: rec.conversation_summary_short
                        ? rec.conversation_summary_short.replace(/"/g, '')
                        : rec.updated_at
                            ? `Conversation at ${formatConversationTime(rec.updated_at)}`
                            : "Conversation",
                    updated_at: rec.updated_at,
                    config_id: rec.config_id
                }));

            const historyData = {
                today: [],
                yesterday: [],
                lastWeek: [],
                allTime: []
            };

            const now = moment();
            const today = now.startOf('day');
            const yesterday = moment().subtract(1, 'days').startOf('day');
            const lastWeek = moment().subtract(1, 'weeks').startOf('isoWeek');

            mappedData.forEach(item => {
                const updatedAt = moment(item.updated_at);

                if (updatedAt.isSame(today, 'day')) {
                    historyData.today.push(item);
                } else if (updatedAt.isSame(yesterday, 'day')) {
                    historyData.yesterday.push(item);
                } else if (updatedAt.isAfter(lastWeek)) {
                    historyData.lastWeek.push(item);
                } else {
                    historyData.allTime.push(item);
                }
            });

            dispatch(setListOfConversations(historyData));
            dispatch(setIsLoadingListOfConversations(false));
            dispatch(setIsFailedListOfConversations(false));
        } else {
            dispatch(showToastMessage({ type: 'error', message: 'Unable to load conversations' }))
        }
    } catch (error) {
        console.log(error);
        dispatch(setIsLoadingListOfConversations(false));
        dispatch(setIsFailedListOfConversations(true));
        dispatch(showToastMessage({ type: 'error', message: error.message }));
    }
};



const generateNewConversationId = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (data?.config_id != undefined && data?.config_id !== null) {
            ChatAPI.createNewConversation(data).then((response) => {
                dispatch(setCurrentConversationId(response?.data?.id))
                dispatch(setCurrentRoomName(response?.data?.room_name))
                resolve(response.data);
            }).catch((error) => {
                console.log(error);
                dispatch(showToastMessage({ type: 'error', message: error }));
                reject(error); // Reject with the error received from the API
            });
        } else {
            // dispatch(showToastMessage({ type: 'error', message: "Config id is undefined" }));
        }
    });
};

const sendChatMessage = (data) => (dispatch) => {
    dispatch(setHasChatAPIFailed(false))
    dispatch(setHasSSESentResponseSinceLastMessage(false))
    ChatAPI.sendChatMessageToServer(data).then((response) => {
        try {
            dispatch(setMessageResponseFromChatAPI({ content: response?.reply, message_id: response?.id }))
        } catch (error) {
            dispatch(setHasChatAPIFailed(true))
            dispatch(setMessageResponseFromChatAPI({ content: `Oops! Something didn't go as planned. Please try again later` }))
        }
    }).catch((error) => {
        console.log('sendChatMessage', error)
        dispatch(setHasChatAPIFailed(true))
        dispatch(setIsMessageSending(false));
    })
}


const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
const generateRandomId = () => {
    return Math.random().toString(36).substring(7);
}

const fetchMessagesHistory = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        // dispatch(setIsMessageSending(true));
        dispatch(setIsLoadingMessageHistory(true))
        ChatAPI.getConversationInfo(data).then((response) => {
            let list_of_apps = store.getState().agents.list_of_agents;
            let current_selected_chatbot = list_of_apps.find((agent) => agent?.id === response?.data.config_id)
            let storedUser = localStorage.getItem("user");
            if (storedUser != null && storedUser != undefined) {
                storedUser = JSON.parse(storedUser)?.first_name

            } else {
                storedUser = 'No name Found'
            }
            
            dispatch(setListOfMessages([]))
            let list_of_messages = response.data.conversation_history.slice(1).map((item) => {
                return item?.role === 'assistant' || item?.role === 'system' ? {
                    id: generateRandomId(),
                    message: item?.content,
                    message_sender: 'bot',
                    message_created_at: new Date().toLocaleString('en-US', options),
                    message_sender_user_name: current_selected_chatbot?.name,
                    message_type: 'text',
                    is_loading: false
                } : {
                    id: generateRandomId(),
                    message: item?.content,
                    message_sender: 'user',
                    message_created_at: new Date().toLocaleString('en-US', options),
                    message_sender_user_name: storedUser,
                    message_type: 'text',
                    is_loading: false
                }
            });

            dispatch(setListOfMessages(list_of_messages))
            dispatch(setCurrentConversationId(response.data?.id))
            dispatch(setCurrentRoomName(response.data?.room_name))
            dispatch(setCurrentSelectedChatbot(current_selected_chatbot))

            resolve(response.data.conversation_history);
        }).catch((error) => {
            // dispatch(setIsMessageSending(false));
            dispatch(setIsLoadingMessageHistory(false))
            dispatch(showToastMessage({ type: 'error', message: error }));
            reject(error); // Reject with the error received from the API
        });
    });
};

const sendThumbsUp = (data) => async (dispatch) => {
    try {
        const response = await UserAPI.sendReaction(data);
        dispatch(setPositiveReaction(true));
        dispatch(showToastMessage({ type: 'success', message: "You liked this message!" }));

    } catch (err) {
        dispatch(showToastMessage({ type: 'error', message: "Unable to send feedback" }));

    }
};

const sendThumbsDown = (data) => async (dispatch) => {
    try {
        const response = await UserAPI.sendReaction(data);
        dispatch(setNegativeReaction(true));
        dispatch(showToastMessage({ type: 'success', message: "You disliked this message" }));
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: "Unable to send feedback" }));
    }
};


export {
    sendChatMessage,
    setResponseMessage,
    sendQuestionMessage,

    fetchMessagesHistory,
    attachDocumentsToChat,


    fetchListOfConversations,
    generateNewConversationId,

    sendThumbsUp,
    sendThumbsDown
}