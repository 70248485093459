import { BrowserRouter } from "react-router-dom"
import { Route, Routes as ReactRoutes } from 'react-router'
import { AuthenticationPage } from "../auth"
import { RouteAuthChecker } from "./RouteAuthChecker"
import { DashboardPage } from "../dashboard"

import { AccountSetupPage } from "../account_setup"
import { ProductionDashboardPage } from "../production_dashboard"
import ChatBotAgent from "../dashboard/ui/sub_screens/Agents/ChatBotAgent"
export const Routes = () => {


    return <BrowserRouter>
        <ReactRoutes>
            <Route path="/auth" element={<RouteAuthChecker authenticationPath="/auth" path="/auth" component={<AuthenticationPage />} />} />
            <Route path="/dashboard/:sub_screen" element={<RouteAuthChecker authenticationPath="/auth" path="/dashboard" component={<DashboardPage />} />} />
            <Route path="/dashboard/:sub_screen/:chatbot_type/:target_id?" element={<RouteAuthChecker authenticationPath="/auth" path="/dashboard" component={<DashboardPage />} />} />
           
            <Route path="/accountSetup" element={<RouteAuthChecker authenticationPath="/auth" path="/accountSetup" component={<AccountSetupPage />} />} />
            <Route path="/user/dashboard" element={<ProductionDashboardPage/>} />
            <Route path="*"  element={<RouteAuthChecker authenticationPath="/auth" path="/auth" component={<AuthenticationPage />} />} />
        </ReactRoutes>
    </BrowserRouter>
}