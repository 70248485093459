import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Home_Un_Shaded = ({ current_theme, ...props }) => {
    return <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.2188 12.4375C27.5469 12.7188 27.5469 13.1875 27.2656 13.5156C27.0312 13.8438 26.5625 13.8438 26.2344 13.5625L24.5 12.0156V21.25C24.5 23.3594 22.8125 25 20.75 25H7.25C5.14062 25 3.5 23.3594 3.5 21.25V12.0156L1.71875 13.5625C1.39062 13.8438 0.921875 13.8438 0.6875 13.5156C0.40625 13.1875 0.40625 12.7188 0.734375 12.4375L13.4844 1.23438C13.7656 0.953125 14.1875 0.953125 14.4688 1.23438L27.2188 12.4375ZM7.25 23.5H10.25V16C10.25 15.2031 10.9062 14.5 11.75 14.5H16.25C17.0469 14.5 17.75 15.2031 17.75 16V23.5H20.75C21.9688 23.5 23 22.5156 23 21.25V10.7031L14 2.78125L5 10.7031V21.25C5 22.5156 5.98438 23.5 7.25 23.5ZM11.75 23.5H16.25V16H11.75V23.5Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"} />
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Home_Un_Shaded)