import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Folder_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.0312 8.9375C27.4531 9.5 27.5938 10.25 27.3125 10.9531L24.5 19.9531C24.2188 20.8906 23.375 21.5 22.3906 21.5H3.5C1.8125 21.5 0.5 20.1875 0.5 18.5V3.5C0.5 1.85938 1.8125 0.5 3.5 0.5H8.98438C9.78125 0.5 10.5312 0.828125 11.0938 1.39062L13.25 3.5H21.5C23.1406 3.5 24.5 4.85938 24.5 6.5H23C23 5.70312 22.2969 5 21.5 5H12.5938L10.0625 2.46875C9.78125 2.1875 9.40625 2 8.98438 2H3.5C2.65625 2 2 2.70312 2 3.5V16.625L4.67188 9.5C5 8.60938 5.84375 8 6.78125 8H25.2031C25.9531 8 26.6094 8.375 27.0312 8.9375ZM25.9531 10.4844C26 10.25 25.9531 10.0156 25.8125 9.82812C25.6719 9.64062 25.4844 9.5 25.25 9.5H6.78125C6.45312 9.5 6.17188 9.73438 6.07812 10.0156L2.70312 19.0156C2.60938 19.25 2.65625 19.4844 2.79688 19.7188C2.84375 19.8125 2.9375 19.8594 3.03125 19.9531C3.17188 20 3.3125 20 3.5 20H22.3906C22.7656 20 23.0469 19.8125 23.1406 19.4844L25.9531 10.4844Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"} />
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Folder_Un_Shaded)