const TYPE_1 = "library_models"
const TYPE_2 = "list_of_actions"
const TYPE_3 = "list_of_multi_select_library"
const TYPE_4 = "list_of_multi_select_instructions"
const TYPE_5 = "list_of_qa_bot_action_types"
const TYPE_6 = "sidebar_dropdown"
const TYPE_7 = "model_type_dropdown"

export {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7
}


