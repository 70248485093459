import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Agents_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 19.5C0 18.7031 0.65625 18 1.5 18H4.03125C4.64062 16.6875 5.95312 15.75 7.5 15.75C9 15.75 10.3594 16.6875 10.9219 18H22.5C23.2969 18 24 18.7031 24 19.5C24 20.3438 23.2969 21 22.5 21H10.9219C10.3594 22.3594 9 23.25 7.5 23.25C5.95312 23.25 4.64062 22.3594 4.03125 21H1.5C0.65625 21 0 20.3438 0 19.5ZM9 19.5C9 18.7031 8.29688 18 7.5 18C6.65625 18 6 18.7031 6 19.5C6 20.3438 6.65625 21 7.5 21C8.29688 21 9 20.3438 9 19.5ZM18 12C18 11.2031 17.2969 10.5 16.5 10.5C15.6562 10.5 15 11.2031 15 12C15 12.8438 15.6562 13.5 16.5 13.5C17.2969 13.5 18 12.8438 18 12ZM16.5 8.25C18 8.25 19.3594 9.1875 19.9219 10.5H22.5C23.2969 10.5 24 11.2031 24 12C24 12.8438 23.2969 13.5 22.5 13.5H19.9219C19.3594 14.8594 18 15.75 16.5 15.75C14.9531 15.75 13.6406 14.8594 13.0312 13.5H1.5C0.65625 13.5 0 12.8438 0 12C0 11.2031 0.65625 10.5 1.5 10.5H13.0312C13.6406 9.1875 14.9531 8.25 16.5 8.25ZM9 3C8.15625 3 7.5 3.70312 7.5 4.5C7.5 5.34375 8.15625 6 9 6C9.79688 6 10.5 5.34375 10.5 4.5C10.5 3.70312 9.79688 3 9 3ZM12.4219 3H22.5C23.2969 3 24 3.70312 24 4.5C24 5.34375 23.2969 6 22.5 6H12.4219C11.8594 7.35938 10.5 8.25 9 8.25C7.45312 8.25 6.14062 7.35938 5.53125 6H1.5C0.65625 6 0 5.34375 0 4.5C0 3.70312 0.65625 3 1.5 3H5.53125C6.14062 1.6875 7.45312 0.75 9 0.75C10.5 0.75 11.8594 1.6875 12.4219 3Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}/>
    </svg>
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Agents_Shaded)