import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const CustomTextField = ({
  id = 'outlined-multiline-static',
  label,
  variant = 'standard',
  multiline = false,
  maxRows = 6,
  minRows = 2,
  value,
  onChange,
  width = '100%',
  fontSize = '14px',
  fontFamily,
  textColor = '#FFF',
  backgroundColor = "#191819",
  placeholder_text = "Enter a value",
  border = '1px solid #444446',
  borderRadius = '4px',
  placeholder = '',
  ...props
}) => {
  const styles = {
    width,
    '& .MuiInputBase-root': {
      fontSize,
      fontFamily,
      color: textColor,
      backgroundColor,
      border: '1px solid #444446',
      borderRadius,
      padding: '8px',
      '&:before': {
        content: 'none',
      },
      '&:after': {
        content: 'none', 
      }
    },
    '& .MuiInputBase-input': {
      fontSize,
      fontFamily,
      color: textColor,
      backgroundColor,
      padding: '8px', 
      boxSizing: 'border-box',
    },
    '& .MuiInputBase-input::placeholder': {
      color: textColor, 
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: border, 
      },
      '&:hover fieldset': {
        borderColor: border,
      },
      '&.Mui-focused fieldset': {
        borderColor: border, 
      },
    },
    '& .MuiInputLabel-root': {
      color: textColor, 
    },
    '& .MuiInputLabel-shrink': {
      color: textColor,
    },
  };

  return (
    <TextField
      id={id}
      label={label}
      variant={variant}
      multiline={multiline}
      maxRows={maxRows}
      minRows={minRows}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      sx={styles}
      {...props}
    />
  );
};

CustomTextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  value: PropTypes.any,
  onChange: PropTypes.func,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderRadius: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CustomTextField;
