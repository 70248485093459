import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_PDF_FIle_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 0H10.5V6C10.5 6.84375 11.1562 7.5 12 7.5H18V21C18 22.6875 16.6406 24 15 24H3C1.3125 24 0 22.6875 0 21V3C0 1.35938 1.3125 0 3 0ZM12 0L18 6H12V0ZM3 10.5C2.57812 10.5 2.25 10.875 2.25 11.25V15V17.25C2.25 17.6719 2.57812 18 3 18C3.375 18 3.75 17.6719 3.75 17.25V15.75H4.125C5.53125 15.75 6.75 14.5781 6.75 13.125C6.75 11.7188 5.53125 10.5 4.125 10.5H3ZM4.125 14.25H3.75V12H4.125C4.73438 12 5.25 12.5156 5.25 13.125C5.25 13.7812 4.73438 14.25 4.125 14.25ZM7.5 11.25V17.25C7.5 17.6719 7.82812 18 8.25 18H9.375C10.5938 18 11.625 17.0156 11.625 15.75V12.75C11.625 11.5312 10.5938 10.5 9.375 10.5H8.25C7.82812 10.5 7.5 10.875 7.5 11.25ZM9 16.5V12H9.375C9.75 12 10.125 12.375 10.125 12.75V15.75C10.125 16.1719 9.75 16.5 9.375 16.5H9ZM13.5 10.5C13.0781 10.5 12.75 10.875 12.75 11.25V14.25V17.25C12.75 17.6719 13.0781 18 13.5 18C13.875 18 14.25 17.6719 14.25 17.25V15H15.75C16.125 15 16.5 14.6719 16.5 14.25C16.5 13.875 16.125 13.5 15.75 13.5H14.25V12H15.75C16.125 12 16.5 11.6719 16.5 11.25C16.5 10.875 16.125 10.5 15.75 10.5H13.5Z" fill="#FF5A00"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_PDF_FIle_Shaded)