import { LLmsAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import { setIsLoadingListOfChunkingMethods, setListOfChunkingMethods } from "../library/slice";
import { setListOfChatModels, setListOfChunkingListModels, setListOfEmbeddingModels, setListOfSearchQueryModels, setListOfSummarizationModels, setListOfSQLGenerationModels, setLoadingListOfChatModels, setLoadingListOfEmbeddingModels, setLoadingListOfSearchQueryModels, setLoadingListOfSummarizationModels, setLoadingListOfSQLGenerationModels } from "./slice";

// Existing fetchAnalytics function...

const fetchLLms = () => (dispatch) => {
    dispatch(setLoadingListOfEmbeddingModels(true));
    dispatch(setLoadingListOfChatModels(true));
    dispatch(setLoadingListOfSearchQueryModels(true));
    dispatch(setLoadingListOfSummarizationModels(true));

    LLmsAPI.getLLMs().then((response) => {
        if (response.status == 200) {
            const embeddingModels = response.data["Embedding Model"];
            const chatModels = response.data["Chat Model"];
            const summarisationModels = response.data["Summarisation Model"];
            const searchQueryModels = response.data["Search Query Model"];
               
            dispatch(setListOfEmbeddingModels(embeddingModels));
            dispatch(setListOfChatModels(chatModels));
            dispatch(setListOfSummarizationModels(summarisationModels));
            dispatch(setListOfSearchQueryModels(searchQueryModels));

            dispatch(setLoadingListOfEmbeddingModels(false));
            dispatch(setLoadingListOfChatModels(false));
            dispatch(setLoadingListOfSearchQueryModels(false));
            dispatch(setLoadingListOfSummarizationModels(false));
        }
    }).catch((error) => {
        dispatch(showToastMessage({ type: 'error', message: "Failed to fetch chunking methods" }));
        dispatch(setLoadingListOfEmbeddingModels(false));
        dispatch(setLoadingListOfChatModels(false));
        dispatch(setLoadingListOfSearchQueryModels(false));
        dispatch(setLoadingListOfSummarizationModels(false));
    });
};

const fetchChunkingMethods = () => (dispatch) => {
    dispatch(setIsLoadingListOfChunkingMethods(true));
    LLmsAPI.getChunking().then((response) => {
        if(response.status==200){
            dispatch(setListOfChunkingListModels(response?.data));
            dispatch(setIsLoadingListOfChunkingMethods(false));
        }
     }).catch((error) => {
        console.error(error);
        dispatch(showToastMessage({ type: 'error', message: "Failed to fetch chunking methods" }));
        dispatch(setIsLoadingListOfChunkingMethods(false));
    });
};

export {
    fetchLLms, fetchChunkingMethods,
};
