
import { useState } from 'react';
import Ic_Left_Arrow_Shaded from '../../../editable_icons/Ic_Left_Arrow_Shaded';
import Ic_Right_Arrow_Shaded from '../../../editable_icons/Ic_Right_Arrow_Shaded';
import { CustomHeading } from '../../../headings';
import ImageLoading from '../../../image_loading';
import { StyledAppItemContainer } from './styled';
import styles from './styles.module.scss'
import ItemsCarousel from 'react-items-carousel';
import { setCurrentChatScreenType, setCurrentSelectedChatbot, setCurrentSelectedTopic, setListOfAppsVisibleOrInvisible, setListOfMessages } from '../../logic/slice';
import { connect } from "react-redux";
import { generateNewConversationId } from '../../logic/actions';
import { getCurrentSelectedChatbot } from '../../logic/selectors';
import { getIsLoadingListOfAgents } from '../../../../../modules/dashboard/logic/agents/selectors';
import ic_clairo_logo_gif from '../../../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif';

const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, 
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, 
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`,
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`
    };
};

const chevronWidth = 50;
const CarouselTopics = ({
    items,
    type = null,
    current_selected_chatbot,
    is_loading_list_of_chatbot_agents,
    setCurrentSelectedTopic,
    setListOfMessages,
    generateNewConversationId,
    setCurrentChatScreenType,
    setCurrentSelectedChatbot,
    setListOfAppsVisibleOrInvisible,
    ...props }) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [error, setError] = useState('')

    const formatName = (name) => {
        let formattedName = name?.replace(/_/g, ' ');
        formattedName = formattedName?.replace(/\b\w/g, (char) => char.toUpperCase());
        return formattedName;
    };

    const handleClick = (value) => {
        if (type === 'apps') {
            setCurrentChatScreenType("co-pilot")
            setListOfAppsVisibleOrInvisible(true)
            setCurrentSelectedChatbot(value)
        } else {
            if (current_selected_chatbot != null) {
                generateNewConversationId({ config_id: current_selected_chatbot?.id }).then((data) => {
                    setListOfMessages([])
                    setCurrentSelectedTopic(value)
                }).catch((error) => {
                    setError(error)
                })
            }

        }
    }

    return type != null && <div className={styles.container}>
        {is_loading_list_of_chatbot_agents ? (
            <div className={styles.loading}>
                <img width={100} height={100} src={ic_clairo_logo_gif} />
                <CustomHeading text="Loading agents..." />
            </div>
        ) : (
            <div className={styles.list_of_apps}>
                {items?.length > 0 ? (
                    <div className={styles.apps_carousel} style={{ padding: `0 ${chevronWidth}px`, height: "100%" }}>
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={3}
                            gutter={0}
                            leftChevron={<Ic_Left_Arrow_Shaded />}
                            rightChevron={<Ic_Right_Arrow_Shaded />}
                            outsideChevron
                            chevronWidth={chevronWidth}
                            style={{ height: "30px" }}
                        >
                            {items.map((app, index) => (
                                <StyledAppItemContainer className={styles.app_item} key={index}>
                                    {type === 'apps' ? (
                                        <div className={styles.app_container} onClick={() => handleClick(app)}>
                                            <div className={styles.icon}>
                                                <ImageLoading width="100%" />
                                            </div>
                                            <div className={styles.heading}>
                                                <CustomHeading text={app?.agent_type} />
                                            </div>
                                            <div className={styles.sub_heading}>
                                                <CustomHeading text={app?.name} />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={styles.app_container} onClick={() => handleClick(app)}>
                                            <div className={styles.heading}>
                                                <CustomHeading font_size="15" text={`Topic ${index + 1}`} />
                                            </div>
                                            <div className={styles.sub_heading}>
                                                <CustomHeading font_size="18" text={app} />
                                            </div>
                                        </div>
                                    )}
                                </StyledAppItemContainer>
                            ))}
                        </ItemsCarousel>
                    </div>
                ) : (
                    <div className={styles.no_agent_available_msg}>
                        <CustomHeading text="No agent is available at the moment !!" />
                    </div>
                )}
            </div>
        )}
    </div>

};


const mapState = (state) => ({
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    is_loading_list_of_chatbot_agents: getIsLoadingListOfAgents(state)
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentSelectedTopic: (data) => dispatch(setCurrentSelectedTopic(data)),
    generateNewConversationId: (data) => dispatch(generateNewConversationId(data)),
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setCurrentSelectedChatbot:(data) => dispatch(setCurrentSelectedChatbot(data)),
    setListOfAppsVisibleOrInvisible:(data) => dispatch(setListOfAppsVisibleOrInvisible(data)),
    setCurrentChatScreenType:(data) => dispatch(setCurrentChatScreenType(data)),
});

export default connect(mapState, mapDispatchToProps)(CarouselTopics)