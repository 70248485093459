import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { CustomButton } from '../buttons';
import { TYPE_1 } from '../buttons/button_types';
import { StyledContainer, StyledDocumentListItem } from './styled';
import { CustomHeading } from '../headings';
import ic_remove_doc from '../../../assets/images/remove_doc_icon.svg'
import ic_drop_files from '../../../assets/images/icons/icons8-drop-down-80.png'
import { formatFileSize } from '../../utils';
import { CustomPagination } from '../pagination'; // Import your CustomPagination component
import Ic_Close_Shaded from '../editable_icons/Ic_Close_Shaded';
import { getListOfDocumentsToUpload } from '../../../modules/dashboard/logic/library/selectors';
import { setListOfDocumentsToUpload } from '../../../modules/dashboard/logic/library/slice';
import { updateListOfDocumentsToUpload } from '../../../modules/dashboard/logic/library/actions';
import { Tooltip } from '@mui/material';
import { CustomLabel } from '../label';
import CustomTextField from '../text_field';
import { StyledDivider } from '../chatscreen/sub-compoents/history/styled';
import Ic_Cross_Shaded from '../editable_icons/Ic_Cross_Shaded';
import ic_question_mark from '../../../assets/images/ic_question_mark.png'
import ic_percentage from '../../../assets/images/percentage.png'
import CustomModelDropDown from '../models_dropdowns'
import { TYPE_2, TYPE_3 } from '../models_dropdowns/models_types';
import { getCurrentChatScreenType, getCurrentConversationId, getCurrentSelectedChatbot, getIsAttachingDocuments } from '../chatscreen/logic/selectors';
import ic_loading_gif from '../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif'
import Ic_Settings_Gear_Shaded from '../editable_icons/Ic_Settings_Gear_Shaded';
import { attachDocumentsToChat, generateNewConversationId } from '../chatscreen/logic/actions';
import { showToastMessage } from '../../../modules/app/logic/actions';
import { setIsAttachFilesSideBarVisible } from '../chatscreen/logic/slice';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const FileUploader = ({
    type = 'horizontal',
    setClose = () => { console.error('provide setClose method') },
    show_header = true,
    list_of_documents_to_upload,
    current_conversation_id,
    updateListOfDocumentsToUpload,
    showToastMessage,
    is_attaching_documents,
    setIsAttachFilesSideBarVisible,
    attachDocumentsToChat,
    current_select_chat_bot,
    generateNewConversationId,
    current_chat_screen_type,
    ...props
}) => {

    const documentsPerPage = 3; // Number of documents per page
    const [currentPage, setCurrentPage] = useState(1);
    const [listOfDocumentsToRender, setListOfDocumentsToRender] = useState([]);
    const fileInputRef = useRef(null);
    const [isMoreConfigOpen, setIsMoreConfigOpen] = useState(false)
    const [tokenBudget, setTokenBudget] = useState(450);
    const [chunkOverlap, setChunkOverlap] = useState(10);
    const [currentSelectedChunkingMethod, setCurrentSelectedChunkingMethod] = useState([]);
    const [currentSelectedChunkingMethodValue, setCurrentSelectedChunkingMethodValue] = useState(null);
    const [currentSelectedSeparator, setCurrentSelectedSeparator] = useState([]);
    const [currentSelectedSeparatorValue, setCurrentSelectedSeparatorValue] = useState([]);

    const handleFileChange = (event) => {
        const files = event.target.files;
        const documents = Array.from(files).map(file => ({
            id: Math.random().toString(36).substr(2, 9), // unique id for key prop
            filename: file.name,
            file: file,
            file_size: file.size,
            file_type: file.type,
        }));

        const updatedDocuments = [...list_of_documents_to_upload, ...documents];
        updateListOfDocumentsToUpload(updatedDocuments);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        const documents = Array.from(files).map((file) => ({
            id: Math.random().toString(36).substr(2, 9),
            filename: file.name,
            file: file,
            file_size: file.size,
            file_type: file.type,
        }));


        updateListOfDocumentsToUpload([...list_of_documents_to_upload, ...documents])
    };

    const removeDocument = (id) => {
        updateListOfDocumentsToUpload(list_of_documents_to_upload?.filter(doc => doc.id !== id))
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (list_of_documents_to_upload != null) {
            const startIndex = (currentPage - 1) * documentsPerPage;
            const endIndex = startIndex + documentsPerPage;
            const documentsForCurrentPage = list_of_documents_to_upload?.slice(startIndex, endIndex);
            setListOfDocumentsToRender(documentsForCurrentPage);
        }
    }, [list_of_documents_to_upload, currentPage, documentsPerPage]);



    const handleUploadDocument = (e) => {
        e.preventDefault();
        if (listOfDocumentsToRender.length == 0) {
            showToastMessage({ message: "Please at least one document", type: "error" })
            return
        }
        if (listOfDocumentsToRender.length > 5) {
            showToastMessage({ message: "You can only upload 5 documents at a time", type: "error" })
            return
        }

        const formData = new FormData();

        // Append each document file to the FormData object
        listOfDocumentsToRender.forEach(document => {
            formData.append('uploaded_files', document.file);
        });

        const jsonPayload = {
            title: current_select_chat_bot?.title,
            tags: "", //TODO: still have to fill this value
            token_budget: tokenBudget,
            chunk_overlap: chunkOverlap,
            chunking_method: currentSelectedChunkingMethod?.id,
            separators: [
                currentSelectedSeparator?.value == null ? "" : currentSelectedSeparator?.value
            ],
            // embedding_model: embeddingModel?.id,
            description: current_select_chat_bot?.description
        };
        console.log("jsonPayload:", JSON.stringify(jsonPayload))
        // // Append the JSON payload as a string
        formData.append('data', JSON.stringify(jsonPayload));

        console.log("current_conversation_id", current_conversation_id)
        if (current_conversation_id === null || current_conversation_id == undefined) {
            generateNewConversationId({ config_id: current_select_chat_bot?.id }).then((data) => {
                attachDocumentsToChat(formData, data?.id)
            }).catch((error) => {
                console.error(error)
            })
        } else {
            attachDocumentsToChat(formData, current_conversation_id)
        }
    }

    const handleModelDropdownClickForChunkingModel = (selected_chunking_method) => {
        setCurrentSelectedChunkingMethod(selected_chunking_method)
    }
    const handleModelDropdownClickForSeparators = (selected_separator) => {
        setCurrentSelectedSeparator(selected_separator)
    }

    return (
        <div className={styles.container}>
            {type === 'horizontal' && (
                <div className={styles.horizontal_container}>
                    <StyledContainer className={styles.header_container}>
                        <div className={styles.left_side}>
                            <CustomHeading text='Upload files' font_size='18' font_weight='bold' />
                            <CustomHeading text='Upload images or files to your chat' />
                        </div>
                        <div className={styles.right_side} onClick={() => {
                            setClose()
                        }}>
                            <Ic_Close_Shaded />
                        </div>
                    </StyledContainer>
                    {
                        isMoreConfigOpen ? <StyledContainer className={styles.more_config_container}>
                            <StyledDivider />
                            <div className={styles.header}>
                                <div className={styles.text}>
                                    <CustomHeading text='Advanced Settings'></CustomHeading>
                                </div>
                                <div className={styles.icon} onClick={() => { setIsMoreConfigOpen(false) }}>
                                    <CustomHeading text='Close'></CustomHeading>
                                    <Ic_Cross_Shaded />
                                </div>
                            </div>
                            <div className={styles.row_1}>
                                <div className={styles.field_container}>
                                    <div className={styles.label_container}>
                                        <CustomLabel title='Token budget'></CustomLabel>
                                        <Tooltip title="an integer between 100 and 2000. The default is 500." placement="top">
                                            <img src={ic_question_mark} className={styles.more_info_icon} />
                                        </Tooltip>
                                    </div>
                                    <CustomTextField value={tokenBudget} onChange={(e) => setTokenBudget(e.target.value)}></CustomTextField>
                                </div>
                                <div className={styles.field_container}>
                                    <div className={styles.label_container}>
                                        <CustomLabel title='Chunk overlap'></CustomLabel>
                                        <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                                            <img src={ic_question_mark} className={styles.more_info_icon} />
                                        </Tooltip>
                                    </div>
                                    <CustomTextField onChange={(e) => setChunkOverlap(e.target.value)} value={chunkOverlap} icon={<img src={ic_percentage} className={styles.more_info_icon} />}
                                    ></CustomTextField>
                                </div>
                            </div>


                            <div className={styles.label_container}>
                                <CustomLabel>Chunking method</CustomLabel>
                                <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                                    <img src={ic_question_mark} className={styles.more_info_icon} />
                                </Tooltip>
                            </div>
                            <CustomModelDropDown type={TYPE_2} handleOnClick={handleModelDropdownClickForChunkingModel} defaultValue={currentSelectedChunkingMethodValue} />
                            {currentSelectedChunkingMethod?.value === 'Recursive Character Chunker' &&
                                <div>
                                    <CustomLabel title='Separators'></CustomLabel>
                                    <CustomModelDropDown type={TYPE_3} handleOnClick={handleModelDropdownClickForSeparators} defaultValue={currentSelectedSeparatorValue} />
                                </div>
                            }
                        </StyledContainer> : <StyledContainer className={styles.drop_zone} onDrop={handleDrop} onDragOver={handleDragOver}>
                            <img className={styles.drop_zone_image} src={ic_drop_files} alt="Drop files here" />
                            <div className={styles.upload_text}>
                                <p style={{ color: 'white' }}>Drag & drop files here</p>
                                <div className={styles.btn_container}>
                                    <CustomButton currentButtonTpe={TYPE_1} onClick={handleUploadClick} title='Select files' />
                                </div>
                                <input
                                    multiple
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    accept=".docx, .txt, .pdf, .pptx, .ppt, .xlsx, .html, .json"
                                />
                            </div>
                          
                        </StyledContainer>
                    }

                    {/* <StyledDivider /> */}

                    {
                        listOfDocumentsToRender?.length > 0 && <StyledContainer className={styles.documents_list}>
                            {listOfDocumentsToRender.map((doc) => (
                                <StyledDocumentListItem key={doc.id}>
                                    <div className={styles.documents_list_item}>
                                        {/* <div className={styles.checkbox_container}>
                                            <input type="checkbox" />
                                        </div> */}
                                        <div className={styles.file_name_container}>
                                            <CustomHeading text={doc.filename} />
                                            <CustomHeading text={formatFileSize(doc.file_size)} />
                                        </div>
                                        <div className={styles.cross_icon_container} onClick={() => removeDocument(doc.id)}>
                                            <img src={ic_remove_doc} alt="Remove" />
                                        </div>
                                    </div>
                                </StyledDocumentListItem>
                            ))}
                        </StyledContainer>
                    }
                    {
                        listOfDocumentsToRender?.length > 0 && <StyledContainer className={styles.pagination_container}>
                            <CustomPagination
                                count={Math.ceil(list_of_documents_to_upload?.length / documentsPerPage)} // Calculate total pages
                                variant="outlined"
                                shape="rounded"
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </StyledContainer>
                    }



                    {
                        current_chat_screen_type === 'chat_screen' && <StyledContainer className={styles.bottom_container}>
                            <CustomButton
                                currentButtonTpe={TYPE_1}
                                onClick={(e) => {
                                    handleUploadDocument(e)
                                }}
                                title={
                                    is_attaching_documents
                                        ? (
                                            <>
                                                <img src={ic_loading_gif} width={"20px"} />
                                                {" Uploading..."}
                                            </>
                                        )
                                        : "Upload"
                                }
                            />
                            <CustomButton currentButtonTpe={TYPE_1} onClick={() => { }} title='Cancel'></CustomButton>
                            <div className={styles.advance_settings_container} onClick={() => { setIsMoreConfigOpen(true) }}>
                                <Ic_Settings_Gear_Shaded />
                                <CustomHeading text='Advanced Settings'></CustomHeading>
                            </div>
                        </StyledContainer>

                    }

                </div>
            )}
            {type === 'vertical' && (
                <div className={styles.vertical_container}>
                    {
                        show_header && <StyledContainer className={styles.header_container}>
                            <div className={styles.left_side}>
                                <CustomHeading text='Upload files' font_size='18' font_weight='bold' />
                                <CustomHeading text='Upload images or files to your chat' />
                            </div>
                            <div className={styles.right_side} onClick={() => {
                                setClose()
                                setIsAttachFilesSideBarVisible(false)

                            }}>
                                <Ic_Close_Shaded />
                            </div>
                        </StyledContainer>
                    }

                    <StyledContainer className={styles.drop_zone} onDrop={handleDrop} onDragOver={handleDragOver}>
                        <img className={styles.drop_zone_image} src={ic_drop_files} alt="Drop files here" />
                        <div className={styles.upload_text}>
                            <p style={{ color: 'white' }}>Drag & drop files here</p>
                            <div className={styles.btn_container}>
                                <CustomButton currentButtonTpe={TYPE_1} onClick={handleUploadClick} title='Select files' />
                            </div>
                            <input
                                multiple
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                accept=".docx, .txt, .pdf, .pptx, .ppt, .xlsx, .html, .json"
                            />
                        </div>
                    </StyledContainer>
                    {
                        listOfDocumentsToRender?.length > 0 && <StyledContainer className={styles.documents_list}>
                            {listOfDocumentsToRender.map((doc) => (
                                <StyledDocumentListItem key={doc.id}>
                                    <div className={styles.documents_list_item}>
                                        {/* <div className={styles.checkbox_container}>
                                            <input type="checkbox" />
                                        </div> */}
                                        <div className={styles.file_name_container}>
                                            <CustomHeading text={doc.filename} />
                                            <CustomHeading text={formatFileSize(doc.file_size)} />
                                        </div>
                                        <div className={styles.cross_icon_container} onClick={() => removeDocument(doc.id)}>
                                            <img src={ic_remove_doc} alt="Remove" />
                                        </div>
                                    </div>
                                </StyledDocumentListItem>
                            ))}
                        </StyledContainer>
                    }

                    {
                        listOfDocumentsToRender?.length > 0 && <StyledContainer className={styles.pagination_container}>
                            <CustomPagination
                                count={Math.ceil(list_of_documents_to_upload?.length / documentsPerPage)} // Calculate total pages
                                variant="outlined"
                                shape="rounded"
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </StyledContainer>
                    }

                    {
                        isMoreConfigOpen && <div className={styles.more_config_container}>
                            <StyledDivider />
                            <div className={styles.header}>
                                <div className={styles.text}>
                                    <CustomHeading text='Advanced Settings'></CustomHeading>
                                </div>
                                <div className={styles.icon} onClick={() => { setIsMoreConfigOpen(false) }}>
                                    <CustomHeading text='Close'></CustomHeading>
                                    <Ic_Cross_Shaded />
                                </div>
                            </div>
                            <div className={styles.row_1}>
                                <div className={styles.field_container}>
                                    <div className={styles.label_container}>
                                        <CustomLabel title='Token budget'></CustomLabel>
                                        <Tooltip title="an integer between 100 and 2000. The default is 500." placement="top">
                                            <img src={ic_question_mark} className={styles.more_info_icon} />
                                        </Tooltip>
                                    </div>
                                    <CustomTextField value={tokenBudget} onChange={(e) => setTokenBudget(e.target.value)}></CustomTextField>
                                </div>
                                <div className={styles.field_container}>
                                    <div className={styles.label_container}>
                                        <CustomLabel title='Chunk overlap'></CustomLabel>
                                        <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                                            <img src={ic_question_mark} className={styles.more_info_icon} />
                                        </Tooltip>
                                    </div>
                                    <CustomTextField onChange={(e) => setChunkOverlap(e.target.value)} value={chunkOverlap} icon={<img src={ic_percentage} className={styles.more_info_icon} />}
                                    ></CustomTextField>
                                </div>
                            </div>


                            <div className={styles.label_container}>
                                <CustomLabel>Chunking method</CustomLabel>
                                <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                                    <img src={ic_question_mark} className={styles.more_info_icon} />
                                </Tooltip>
                            </div>
                            <CustomModelDropDown type={TYPE_2} handleOnClick={handleModelDropdownClickForChunkingModel} defaultValue={currentSelectedChunkingMethodValue} />
                            {currentSelectedChunkingMethod?.value === 'Recursive Character Chunker' &&
                                <div>
                                    <CustomLabel title='Separators'></CustomLabel>
                                    <CustomModelDropDown type={TYPE_3} handleOnClick={handleModelDropdownClickForSeparators} defaultValue={currentSelectedSeparatorValue} />
                                </div>
                            }
                        </div>
                    }
                    {
                        current_chat_screen_type === 'co-pilot' && <div className={styles.bottom_container}>
                            <CustomButton
                            font_size='12' padding="8px 18px"
                                currentButtonTpe={TYPE_1}
                                onClick={(e) => {
                                    handleUploadDocument(e)
                                }}
                                title={
                                    is_attaching_documents
                                        ? (
                                            <>
                                                <img src={ic_loading_gif} width={"20px"} />
                                                {" Uploading..."}
                                            </>
                                        )
                                        : "Upload"
                                }
                            />
                            <CustomButton  font_size='12' padding="8px 18px" background_color='transparent' currentButtonTpe={TYPE_1} onClick={() => { }} title='Cancel'></CustomButton>
                            <div className={styles.advance_settings_container} onClick={() => { setIsMoreConfigOpen(true) }}>
                                <Ic_Settings_Gear_Shaded />
                                <CustomHeading text='Advanced Settings'></CustomHeading>
                            </div>
                        </div>

                    }

                </div>
            )
            }
        </div >
    );
};

const mapState = (state) => ({
    // list_of_apps: getListOfAgents(state),
    list_of_documents_to_upload: getListOfDocumentsToUpload(state),
    is_attaching_documents: getIsAttachingDocuments(state),
    current_select_chat_bot: getCurrentSelectedChatbot(state),
    current_conversation_id: getCurrentConversationId(state),
    current_chat_screen_type: getCurrentChatScreenType(state)
});

const mapDispatchToProps = (dispatch) => ({
    generateNewConversationId: (data) => dispatch(generateNewConversationId(data)),
    updateListOfDocumentsToUpload: (data) => dispatch(updateListOfDocumentsToUpload(data)),
    attachDocumentsToChat: (data, con_id) => dispatch(attachDocumentsToChat(data, con_id)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    setIsAttachFilesSideBarVisible: (data) => dispatch(setIsAttachFilesSideBarVisible(data)),
});

export default connect(mapState, mapDispatchToProps)(FileUploader);
