import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';

const StyledSourcesField = styled(TextField)`
  .MuiInputBase-root {
    color: #AEAEB2;
    padding: 0.5rem !important;
    border-radius: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 12px;
  }
  .MuiInputBase-input {
    padding: 0.5rem !important;
    margin: 0 !important;
    line-height: 1.5;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-weight: 300 !important;
    font-size: 12px !important;
  }
  .MuiInputLabel-root {
    color: #AEAEB2;
    transform: translate(14px, 10px) scale(1); 
  }
  .MuiInputLabel-shrink {
    transform: translate(0, -1.5px) scale(0.75);
  }
  .MuiOutlinedInput-root {
    padding: 0 !important;
    margin: 0;
    width: 190px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid #AEAEB2 !important;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid #AEAEB2 !important;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 0.5px solid #AEAEB2 !important;
  }
  .MuiInputAdornment-root {
    margin-right: 0;
    margin-left: 6px; 
  }
`;

export { StyledSourcesField };
