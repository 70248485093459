import { CustomHeading } from '../../../headings';
import { StyledBotMsgContainer, StyledUserMsgContainer, StyledUserMsgFileContainer } from './styled';
import styles from './styles.module.scss';
import ReactMarkdown from 'react-markdown';
import ic_user from '../../../../../assets/images/user_icon.png';
import ic_clairo_logo_gif from '../../../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif';

import gif_loading from '../../../../../assets/images/output-onlinegiftools.gif';
import Ic_PDF_FIle_Shaded from '../../../editable_icons/Ic_PDF_FIle_Shaded';
import ImageLoading from '../../../image_loading';
import ic_thumbs_up from '../../../../../assets/images/icons8-thumbs-up-50 (2).png'
import ic_thumbs_down from '../../../../../assets/images/icons8-thumbs-down-50.png'
import ic_clipboard from '../../../../../assets/images/icons8-clipboard-64.png'
import Sources from '../sources';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const MessageBubble = ({
    id,
    message_type = "text",
    message_sender = "user",
    message = "Provide message content",
    message_sender_user_name = "Provide user name",
    message_created_at = "Provide created at time stamp",
    list_of_file_attachments = [],
    is_loading = false,
    has_failed = false,
    handleReactions,
    copyToClipboard,
    ...props
}) => {
    return (
        <div className={styles.container}>
            {
                message_type === "text" ? (
                    message_sender === "user" ? (
                        <div className={styles.text_msg_container}>
                            <div className={styles.user_msg_container}>
                                <div className={styles.user_info_container}>
                                    {is_loading ? <img src={gif_loading} className={styles.loader_gif} /> : <CustomHeading className={styles.date_and_time} text={message_created_at}></CustomHeading>}
                                    <CustomHeading className={styles.name} text={message_sender_user_name} />
                                    <img src={ic_user} className={styles.icon} />
                                </div>
                                <StyledUserMsgContainer className={styles.user_msg_content_container}>
                                    <CustomHeading className={styles.content} text={<ReactMarkdown>{message}</ReactMarkdown>} />
                                </StyledUserMsgContainer>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.text_msg_container}>
                            <div className={styles.bot_msg_container}>
                                <div className={styles.bot_info_container}>
                                    <div className={styles.icon}>
                                        <ImageLoading width="90%" />
                                    </div>
                                    <CustomHeading className={styles.name} text={message_sender_user_name} />
                                    <CustomHeading className={styles.date_and_time} text={message_created_at} />
                                </div>
                                <StyledBotMsgContainer className={styles.bot_msg_content_container}>
                                    {is_loading ? (
                                        <div className={styles.response_loader_container}>
                                            <img src={ic_clairo_logo_gif} width={60} height={50} />
                                        </div>
                                    ) : has_failed ? <CustomHeading  className={styles.content} text={<ReactMarkdown>{message.startsWith('-') ? message.substring(1) : message}</ReactMarkdown>}></CustomHeading> : (
                                        <CustomHeading className={styles.content} text={<ReactMarkdown>{message.startsWith('-') ? message.substring(1) : message}</ReactMarkdown>}></CustomHeading>
                                    )}
                                </StyledBotMsgContainer>
                                <div className={styles.bottom_container}>
                                    
                                    {
                                        !is_loading &&
                                        <>
                                         < div className={styles.icons_container}>
                                            <img src={ic_thumbs_up} className={styles.icon} onClick={() => handleReactions(id, 'like')}></img>
                                            <img src={ic_thumbs_down} className={styles.icon} onClick={() => handleReactions(id, 'dislike')}></img>
                                            <img src={ic_clipboard} className={styles.icon} onClick={() => copyToClipboard(message)}></img>
                                        </div>
                                        <div className={styles.sources}>
                                            <Sources/>
                                        </div>
                                        </>
                                    }
                                    
                                    
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    message_type === "file" && (
                        <div className={styles.file_msg_container}>
                            <div className={styles.user_msg_file_container}>
                                <div className={styles.user_info_container}>
                                    {is_loading ? <img src={gif_loading} className={styles.loader_gif} /> : <CustomHeading className={styles.date_and_time} text={message_created_at} />}
                                    <CustomHeading className={styles.name} text={message_sender_user_name} />
                                    <img src={ic_user} className={styles.icon} />
                                </div>
                                <StyledUserMsgFileContainer className={styles.user_msg_content_container}>
                                    <div className={styles.list_of_documents}>
                                        {
                                            list_of_file_attachments?.length > 0 && list_of_file_attachments?.map((doc) => (
                                                <div className={styles.list_item} key={doc.fileName}>
                                                    <div className={styles.icon}>
                                                        <Ic_PDF_FIle_Shaded />
                                                    </div>
                                                    <div className={styles.content}>
                                                        <CustomHeading text={doc.filename} />
                                                        <CustomHeading text={doc.file_size} kb />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <CustomHeading text={message} />
                                </StyledUserMsgFileContainer>
                            </div>
                        </div>
                    )
                )
            }
        </div >
    );
};

export { MessageBubble };
