// lists
export const getListOfDocuments = (state) => state.library?.list_of_documents
export const getListOfLibraries = (state) => state.library?.list_of_libraries
export const getListOfChunkingMethods = (state) => state.library?.list_of_chunking_methods
export const getListOfSeparators = (state) => state.library?.list_of_separators

// is loading
export const getIsListOfDocumentsLoading = (state) => state.library?.is_loading_list_of_documents
export const getIsListOfLibrariesLoading = (state) => state.library?.is_loading_list_of_libraries
export const getIsListOfChunkingMethodsLoading = (state) => state.library?.is_loading_list_of_chunking_methods
export const getIsCreatingLibraryLoading = (state) => state.library?.is_creating_library_loading
export const getIsUploadingDocument = (state) => state.library?.is_uploading_document
export const getIsDocumentUploaded = (state) => state.library?.is_documents_uploaded
export const getIsDocumentDeleting = (state) => state.library?.is_deleting_document
export const getIsLibraryDeleted = (state) => state.library?.is_library_deleted
export const getIsListOfSeparatorsLoading = (state) => state.library?.is_loading_list_of_separators;
export const getCurrentSelectedLibrary = (state) => state.library?.current_selected_library;
export const getIsListOfDocumentsVisible = (state) => state.library?.is_documents_list_visible;

// new
export const getListOfDocumentsToUpload = (state) => state.library?.list_of_documents_to_upload;
export const getIsLibraryCreated = (state) => state.library?.is_library_created;
