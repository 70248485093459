import { useEffect, useState } from 'react';
import { CustomDropdown } from '../drop_downs';
import { TYPE_4, TYPE_5 } from '../drop_downs/drop_down_types';
import { CustomHeading } from '../headings';
import { StyledDivider, StyledHeaderColumnContainer_Type_1, StyledTableCellContainer_Type_1, StyledTableContainer } from './styled';
import styles from './styles.module.scss';
import { listOfColumnsDummy, listOfRowsDummy } from './dummury_data';
import { CustomPagination } from '../pagination';
import Ic_Left_Arrow_Rounded_Shaded from '../editable_icons/Ic_Left_Arrow_Rounded_Shaded';
import { CustomCheckbox } from '../checkbox';
import { CustomTagChip } from '../chips_tags';

const CustomTable = ({
  listOfColumns = [],
  listOfRows = [],
  show_selection_checkbox = false,
  setListOfSelectedRows = (items) => { console.error("method to store list of selected items is not provided") },
  handleTableActions = () => { console.error('handleTableActions method is not provided') },
  theme,
  defaultSortField = null,
  defaultSortDirection = null,
  ...props
}) => {

  const [listOfFilteredRows, setListOfFilteredRows] = useState([]);
  const [sortState, setSortState] = useState({
    columnIndex: null,
    direction: null,
  });
  const [areSomeItemsSelected, setAreSomeItemsSelected] = useState(false);
  const [areAllItemsSelected, setAreAllItemsSelected] = useState(false);
  const [documentsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [listOfItemsSelected, setListOfItemSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(Array(listOfColumns.length).fill(null));
  const [anchorElActions, setAnchorElActions] = useState(Array(listOfRows.length).fill(null));
  const [isDropdownActionsOpen, setIsDropdownActionsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownActionsOpen && !anchorElActions.some(el => el && el.contains(event.target))) {
        setIsDropdownActionsOpen(false);
        setAnchorElActions(Array(listOfRows.length).fill(null));
      }
    };
    if (isDropdownActionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownActionsOpen, anchorElActions]);

  const handleOpenSort = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event?.currentTarget;
    setAnchorEl(newAnchorEl);
    console.log(newAnchorEl, event)
  };

  const handleCloseSort = (index, option) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
    handleSort(index, option)
  };

  const handleOpenActions = (index, event) => {
    const newAnchorEl = [...anchorElActions];
    newAnchorEl[index] = event?.currentTarget;
    setAnchorElActions(newAnchorEl);
    setIsDropdownActionsOpen(true);
  };

  const handleCloseActions = (index, option) => {
    const newAnchorEl = [...anchorElActions];
    newAnchorEl[index] = null;
    setAnchorElActions(newAnchorEl);
    handleTableActions(option, listOfFilteredRows[index])
    setIsDropdownActionsOpen(false)
  };

  const handleSort = (columnIndex, direction) => {
    const column = listOfColumns[columnIndex];

    const sortedRows = [...listOfRows].sort((a, b) => {
      if (direction === 'A-Z' || direction === 'Largest to smallest') {
        return a[column.field_name] > b[column.field_name] ? 1 : -1;
      } else if (direction === 'Z-A' || direction === 'Smallest to largest') {
        return a[column.field_name] < b[column.field_name] ? 1 : -1;
      }
      else if (direction === 'Newest') {
        sortedRows.sort((a, b) => (new Date(b[column.field_name]) - new Date(a[column.field_name])));
      } else if (direction === 'Oldest') {
        sortedRows.sort((a, b) => (new Date(a[column.field_name]) - new Date(b[column.field_name])));
      }
    });

    const validDirections = [
      'active', 'inactive', 'Published', 'Draft',
      'Question', 'Chatbot', 'Analytics', 'False', 'false',
      'True', 'true', 'super_user', 'agent_user', 'test_user',"PublicLLM","PrivateLMM"
    ];
    const filteredRows = sortedRows.filter(row => {
      if (validDirections.includes(direction)) {
        return row[column.field_name] === direction;
      }
      return row;
    });
    setSortState({ columnIndex, direction });
    setListOfFilteredRows(filteredRows);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  }

  const getCheckboxStatus = (listItem) => {
    let stored_item = listOfItemsSelected.filter((item) => {
      return item?.listItem?.id === listItem?.id;
    });
    if (stored_item.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleSelectedDocuments = (event, listItem) => {
    let stored_item_index = listOfItemsSelected.findIndex((item) => item?.listItem?.id === listItem?.id);
    if (event.target.checked) {
      if (stored_item_index === -1) {
        setListOfItemSelected((prev) => {
          return [...prev, { is_checked: true, listItem }];
        });
      }
    } else {
      setListOfItemSelected((prev) => {
        return prev.filter((item) => item?.listItem?.id !== listItem?.id);
      });
    }
  }

  useEffect(() => {
    setListOfFilteredRows(listOfRows)
  }, [listOfRows])


  useEffect(() => {
    if (defaultSortField && defaultSortDirection) {
      const columnIndex = listOfColumns.findIndex(col => col.field_name === defaultSortField);
      if (columnIndex !== -1) {
        handleSort(columnIndex, defaultSortDirection);
      }
    }
  }, [defaultSortField, defaultSortDirection, listOfColumns, listOfRows]);

  useEffect(() => {
    if (listOfItemsSelected.length > 0) {
      setAreSomeItemsSelected(true);
    } else if (listOfFilteredRows.length === listOfItemsSelected.length) {
      setAreAllItemsSelected(false);
    } else {
      setAreAllItemsSelected(false);
      setAreSomeItemsSelected(false);
    }
  }, [listOfItemsSelected])

  useEffect(() => {
    if (areAllItemsSelected) {
      setAreSomeItemsSelected(false);
      setListOfItemSelected(listOfFilteredRows.map(listItem => ({ is_checked: true, listItem })));
    } else {
      setAreSomeItemsSelected(true);
      setListOfItemSelected([]);
    }
  }, [areAllItemsSelected])

  useEffect(() => {
    setListOfSelectedRows(listOfItemsSelected);
  }, [listOfItemsSelected])

  const indexOfLastRow = currentPage * documentsPerPage;
  const indexOfFirstRow = indexOfLastRow - documentsPerPage;
  const currentRows = listOfFilteredRows.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div className={styles.container}>
      <StyledTableContainer className={styles.table_container}>
        <div className={styles.table_header_container}>
          {listOfColumns?.map((column, index) => (
            <StyledHeaderColumnContainer_Type_1 width={column.width} key={index}>
              {show_selection_checkbox && index === 0 && (
                <CustomCheckbox
                  checked={areAllItemsSelected}
                  indeterminate={areSomeItemsSelected}
                  onClick={(e) => {
                    setAreAllItemsSelected(e.target.checked);
                  }}
                />
              )}
              <div>
                <CustomHeading text={column.title} font_size="15" font_weight="bold" />
                {(column?.type === "number_with_sorting_option" || column?.type === "text_with_sorting_option") &&
                  column?.sub_title && (
                    <CustomHeading text={column.sub_title} />
                  )
                }
              </div>
              <div>
                {(column?.type === "number_with_sorting_option" || column?.type === "text_with_sorting_option") &&
                  column?.sort_option && (
                    <CustomDropdown
                      theme={theme}
                      is_open={Boolean(anchorEl[index])}
                      options_list={column?.sort_option}
                      onClick={(e) => handleOpenSort(index, e)}
                      anchorEl={anchorEl[index]}
                      onClose={(option) => handleCloseSort(index, option)}
                      dropdown_type={TYPE_5}
                      anchors={anchorEl}
                    />
                  )
                }
              </div>
            </StyledHeaderColumnContainer_Type_1>
          ))}
        </div>
        <StyledDivider />
        <div className={styles.table_body_container}>
          {currentRows?.map((row, rowIndex) => (
            <div key={rowIndex} className={styles.table_row}>
              {show_selection_checkbox && (
                <CustomCheckbox
                  checked={getCheckboxStatus(row)}
                  onClick={(e) => {
                    handleSelectedDocuments(e, row);
                  }}
                />
              )}
              {listOfColumns.map((column, colIndex) => (
                <StyledTableCellContainer_Type_1 key={colIndex} width={column.width}>
                  {column.type === 'dropdown_actions' ? (
                    <CustomDropdown
                      dropdown_type={TYPE_4}
                      options_list={row.actions_list}
                      is_open={Boolean(anchorElActions[rowIndex])}
                      onClick={(e) => handleOpenActions(rowIndex, e)}
                      anchorEl={anchorElActions[rowIndex]}
                      onClose={(option) => handleCloseActions(rowIndex, option)}
                      anchors={anchorElActions}
                    />
                  ) : column.type === 'button_action' ? (
                    <span onClick={() => {
                      handleTableActions(column.action, listOfFilteredRows[rowIndex]);
                    }}>{row?.button_action_icon}</span>
                  ) : (
                    column?.is_this_chip ? (
                      <CustomTagChip
                        width="fit-content"
                        text_color={row.status === 'active' ? 'white' : 'white'}
                        background_color={row.status === 'active' ? 'green' : '#FF5A00'}
                        title={row[column.field_name]}
                      />
                    ) : (
                      <CustomHeading text={row[column.field_name]} />
                    )
                  )}
                </StyledTableCellContainer_Type_1>
              ))}
            </div>
          ))}
        </div>
        <StyledDivider />
        <div className={styles.table_pagination_container}>
          <CustomPagination
            count={Math.ceil(listOfFilteredRows.length / documentsPerPage)}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            boundaryCount={1}
            siblingCount={0}
          />
        </div>
      </StyledTableContainer>
    </div>
  );
};

export { CustomTable };