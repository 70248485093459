import { Tabs, Tab, Box } from '@mui/material';
import styled from 'styled-components';

const StyledTabs = styled(Tabs)`
  && {
    width: 100%;
    border: 1px solid #EC950A;
    border-radius: 8px;
    margin-bottom: 20px;
    min-height: 36px;
    max-height: 36px;

    .MuiTabs-indicator {
      display: none;
    }
  }
`;

const StyledTab = styled(Tab)`
  && {
    flex: 1;
    max-width: none;
    min-height: 36px;
    max-height: 36px;
    color: #EC950A;
    border: 1px solid #EC950A;
    background-color: rgba(236, 149, 10, 0.2);
    text-transform: none;
    font-weight: bold;

    &.Mui-selected {
      color: black;
      background-color: #EC950A;
      border-bottom: none;
    }

    &:not(.Mui-selected) {
      &:hover {
        background-color: rgba(236, 149, 10, 0.4)
    }
  }
`;

const StyledTabPanel = styled(({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`custom-tabpanel-${index}`}
        aria-labelledby={`custom-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Box sx={{ p: 1 }}>
                {children}
            </Box>
        )}
    </div>
))`
  && {
    
  }
`;

export { StyledTabs, StyledTab, StyledTabPanel };
