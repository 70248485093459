import { ChatAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import { setIsMessageSending, setNewMessageResponse, setIsLoadingMessageHistory, setIsAttachingDocuments, setListOfAttachedDocumentsIds } from "./slice";

const sendChatMessage = (data) => (dispatch) => {
    dispatch(setIsMessageSending(true));
    ChatAPI.sendChatMessageToServer(data).then((response) => {
        console.log("sendChatMessage",response)
        dispatch(setIsMessageSending(false));
        if (response.status == 200) {
            console.log({reply:response.data.response.reply,message_id:response.data.id})
            dispatch(setNewMessageResponse({reply:response.data.response.reply,message_id:response.data.id}))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsMessageSending(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}

const attachDocumentsToChat = (data, conversation_id) => (dispatch) => {
    console.log("attachDocumentsToChat", conversation_id)
    dispatch(setIsAttachingDocuments(true));
    ChatAPI.attachDocuments(data, conversation_id).then((response) => {
        console.log('attachDocumentsToChat : ', response)
        dispatch(setIsAttachingDocuments(false));
        if (response.status == 201) {
            console.log(response.data.document_ids)
            dispatch(setListOfAttachedDocumentsIds(response?.data))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsAttachingDocuments(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}
const sendQuestionMessage = (data) => (dispatch) => {
    dispatch(setIsMessageSending(true));
    console.log("sendQuestionMessageToServer", data)
    ChatAPI.sendQuestionMessageToServer(data).then((response) => {
        console.log("sendQuestionMessageToServer", response)
        dispatch(setIsMessageSending(false));
        if (response.status == 200) {
            console.log(response.data.response.reply.prompt)
            dispatch(setNewMessageResponse(response.data.response.reply.prompt))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsMessageSending(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}

const setResponseMessage = (data) => (dispatch) => {
    dispatch(setNewMessageResponse(null));
}


const createConversationId = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        // dispatch(setIsMessageSending(true));
        // ChatAPI.createNewConversation(data).then((response) => {
        //     console.log(response);
        //     // dispatch(setIsMessageSending(false));

        //     console.log(response.data);
        //     // Dispatch any actions if needed
        //     // dispatch(setNewMessageResponse(response.data.response.reply));
        //     resolve(response.data.id); // Resolve with the conversation id

        // }).catch((error) => {
        //     console.log(error);
        //     // dispatch(setIsMessageSending(false));
        //     dispatch(showToastMessage({ type: 'error', message: error }));
        //     reject(error); // Reject with the error received from the API
        // });
    });
};


const fetchMessagesHistory = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(setIsLoadingMessageHistory(true))
        ChatAPI.getConversationInfo(data).then((response) => {
            dispatch(setIsLoadingMessageHistory(false))
            resolve(response.data.conversation_history); // Resolve with the conversation id
        }).catch((error) => {
            console.log(error);
            // dispatch(setIsMessageSending(false));
            dispatch(setIsLoadingMessageHistory(false))
            dispatch(showToastMessage({ type: 'error', message: error }));
            reject(error); // Reject with the error received from the API
        });
    });
};

export {
    sendChatMessage,
    setResponseMessage,
    sendQuestionMessage,
    createConversationId,
    fetchMessagesHistory,
    attachDocumentsToChat
}