import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Users_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="30" height="24" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9531 15C12.2812 15 10.0781 12.8438 10.125 10.125C10.125 7.45312 12.2812 5.25 14.9531 5.25C17.625 5.25 19.8281 7.45312 19.8281 10.125C19.8281 12.8438 17.6719 15 14.9531 15ZM14.9531 6.75C13.125 6.75 11.5781 8.29688 11.5781 10.125C11.5781 12 13.125 13.5 14.9531 13.5C16.8281 13.5 18.3281 12 18.3281 10.125C18.3281 8.29688 16.8281 6.75 14.9531 6.75ZM17.2969 16.5C21 16.5 24 19.3125 24 22.7812C24 23.4844 23.3906 24 22.6406 24H7.3125C6.5625 24 6 23.4844 6 22.7812C6 19.3125 8.95312 16.5 12.6562 16.5H17.2969ZM7.5 22.5H22.4531C22.3125 20.0156 20.0625 18 17.2969 18H12.6094C9.89062 18 7.64062 20.0156 7.5 22.5ZM24 7.5C21.8906 7.5 20.25 5.85938 20.25 3.75C20.25 1.6875 21.8906 0 24 0C26.0625 0 27.75 1.6875 27.75 3.75C27.75 5.85938 26.0625 7.5 24 7.5ZM24 1.5C22.7344 1.5 21.75 2.53125 21.75 3.75C21.75 5.01562 22.7344 6 24 6C25.2188 6 26.25 5.01562 26.25 3.75C26.25 2.53125 25.2188 1.5 24 1.5ZM6 7.5C3.89062 7.5 2.25 5.85938 2.25 3.75C2.25 1.6875 3.89062 0 6 0C8.0625 0 9.75 1.6875 9.75 3.75C9.75 5.85938 8.0625 7.5 6 7.5ZM6 1.5C4.73438 1.5 3.75 2.53125 3.75 3.75C3.75 5.01562 4.73438 6 6 6C7.21875 6 8.25 5.01562 8.25 3.75C8.25 2.53125 7.21875 1.5 6 1.5ZM26.2969 9C28.3125 9 30 10.7812 30 12.8906V13.5C30 13.9219 29.625 14.25 29.25 14.25C28.8281 14.25 28.5 13.9219 28.5 13.5V12.8906C28.5 11.5781 27.4688 10.5 26.2969 10.5H23.25C22.9219 10.5 22.6406 10.5938 22.3594 10.7344C21.9844 10.9219 21.5156 10.7344 21.3281 10.3594C21.1875 9.98438 21.3281 9.5625 21.7031 9.375C22.1719 9.14062 22.6875 9 23.25 9H26.2969ZM7.59375 10.7344C7.3125 10.5938 7.03125 10.5 6.75 10.5H3.65625C2.48438 10.5 1.5 11.5781 1.5 12.8906V13.5C1.5 13.9219 1.125 14.25 0.75 14.25C0.328125 14.25 0 13.9219 0 13.5V12.8906C0 10.7812 1.64062 9 3.65625 9H6.75C7.26562 9 7.78125 9.14062 8.25 9.375C8.625 9.5625 8.76562 9.98438 8.625 10.3594C8.4375 10.7344 7.96875 10.9219 7.59375 10.7344Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}/>
    </svg>  
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Users_Shaded)
