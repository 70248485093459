import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Cross_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.83594 7.33594C7.1875 6.98438 7.77344 6.98438 8.125 7.33594L9.96094 9.21094L11.8359 7.33594C12.1875 6.98438 12.7734 6.98438 13.125 7.33594C13.5156 7.72656 13.5156 8.3125 13.125 8.66406L11.2891 10.5L13.125 12.3359C13.5156 12.7266 13.5156 13.3125 13.125 13.6641C12.7734 14.0547 12.1875 14.0547 11.8359 13.6641L9.96094 11.8281L8.125 13.6641C7.77344 14.0547 7.1875 14.0547 6.83594 13.6641C6.44531 13.3125 6.44531 12.7266 6.83594 12.3359L8.67188 10.5L6.83594 8.66406C6.44531 8.3125 6.44531 7.72656 6.83594 7.33594ZM20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5C0 4.99219 4.45312 0.5 10 0.5C15.5078 0.5 20 4.99219 20 10.5ZM10 2.375C5.50781 2.375 1.875 6.04688 1.875 10.5C1.875 14.9922 5.50781 18.625 10 18.625C14.4531 18.625 18.125 14.9922 18.125 10.5C18.125 6.04688 14.4531 2.375 10 2.375Z" fill="#FF5A00"/>
    </svg>
    
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Cross_Shaded)