import { Navigate } from 'react-router';
export const RouteAuthChecker = ({ path, authenticationPath, component }) => {
    if (path === '/auth') {
        let role = JSON.parse(localStorage.getItem('user'))?.role
        console.log("role : ", role)
        if (role != undefined) {
            if (role === "agent_user") {
                if (!localStorage.getItem('access')) {
                    return component;
                } else {
                    return <Navigate to={{ pathname: '/user/dashboard' }} />;
                }
            } else {
                if (!localStorage.getItem('access')) {
                    return component;
                } else {
                    return <Navigate to={{ pathname: '/dashboard/home' }} />;
                }
            }
        }else{
            return component;
        }

    } else {
        // if (true) {
        if (localStorage.getItem('access')) {
            return component;
        } else {
            return <Navigate to={{ pathname: authenticationPath }} />;
        }
    }
}