
import { useEffect, useState } from 'react';
import { StyledDivider, StyledHistoryContainer, StyledNewChatButtonContainer, StyledNewChatContainer, StyledTextareaForMessage, StyledUserHistoryItemContainer } from './styled';
import styles from './styles.module.scss'
import Ic_Filter_Shaded from '../../../editable_icons/Ic_Filter_Shaded';
import Ic_Search_Shaded from '../../../editable_icons/Ic_Search_Shaded';
import CustomTextField from '../../../text_field';
import { CustomHeading } from '../../../headings';
import Ic_Arrow_Right_Shaded from '../../../editable_icons/Ic_Arrow_Right_Shaded';
import Ic_Add_Shaded from '../../../editable_icons/Ic_Add_Shaded';
import { connect } from "react-redux";
import { getCurrentChatScreenType, getCurrentSelectedChatbot, getIsChatbotAdvancedSettingsOpen, getIsLoadingListOfConversations, getIsLoadingMessageHistory, getIsQAbotAdvancedSettingsOpen, getListOfConversations, getNumberOfMessagesSent } from '../../logic/selectors';
import { setCurrentSelectedChatbot, setIsQAbotAdvancedSettingsOpen, setListOfMessages, setShallCreateNewChat } from '../../logic/slice';
import { getListOfAgents } from '../../../../../modules/dashboard/logic/agents/selectors';
import { fetchListOfConversations, fetchMessagesHistory } from '../../logic/actions';
import ic_clairo_logo_gif from '../../../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif';
import ChatBotAgent from '../../../../../modules/dashboard/ui/sub_screens/Agents/ChatBotAgent';
import QAChatBotAgent from '../../../../../modules/dashboard/ui/sub_screens/Agents/QAChatBotAgent';
import Ic_Arrow_Upward_Shaded from '../../../editable_icons/Ic_Arrow_Upward_Shaded';

const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`,
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`,
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`,
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`,
    };
};

const HistoryList = ({
    lis_of_conversations,
    list_of_apps,
    number_of_messages_sent,
    is_loading_list_of_conversations,
    is_chatbot_advanced_settings_open,
    current_selected_chatbot,
    is_qa_advanced_settings_open,
    current_chat_screen_type,
    fetchListOfConversations,
    setShallCreateNewChat,
    fetchMessagesHistory,
    setListOfMessages,
    setIsQAbotAdvancedSettingsOpen,
    ...props }) => {
    const [isAdvancedSettingsVisible, setIsAdvancedSettingsVisible] = useState(false)
    const [listOfConversationsToRender, setListOfConversationsToRender] = useState([])
    const maxWords = 12;

    useEffect(() => {
        if (current_chat_screen_type !== 'play_ground')
            setIsQAbotAdvancedSettingsOpen(false)
    }, [])

    useEffect(() => {
        setIsAdvancedSettingsVisible(is_chatbot_advanced_settings_open)
    }, [is_chatbot_advanced_settings_open])

    useEffect(() => {
        fetchListOfConversations()
    }, [])

    useEffect(() => {
        if (number_of_messages_sent > 1 && number_of_messages_sent < 2) {
            fetchListOfConversations()
        }
    }, [number_of_messages_sent])

    useEffect(() => {
        const sortedConversations = Object.entries(lis_of_conversations).reduce((acc, [period, items]) => {
            acc[period] = [...items].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            return acc;
        }, {});
        setListOfConversationsToRender(sortedConversations);
    }, [lis_of_conversations]);
    

    const getAgentType = (agent_id) => {
        if (list_of_apps?.length > 0) {
            let agent_found = list_of_apps?.filter((agent) => agent?.id === agent_id)
            return formatName(agent_found[0]?.name)
        } else {
            return "Clairo-ai"
        }
    }

    function truncateText(text, maxWords) {
        const words = text.split(' ');
        const truncatedText = words.slice(0, maxWords).join(' ');
        const displayText = words.length > maxWords ? truncatedText + '...' : truncatedText;
        return displayText;
    }

    const formatName = (name) => {
        let formattedName = name?.replace(/_/g, ' ');
        formattedName = formattedName?.replace(/\b\w/g, (char) => char.toUpperCase());
        return formattedName;
    };

    return <div className={styles.container}>
        {
            !isAdvancedSettingsVisible && !is_qa_advanced_settings_open && <StyledHistoryContainer className={styles.history_opened_container}>
                <div className={styles.header_container}>

                    <div className={styles.heading}>
                        <div className={styles.heading_container}>
                            <CustomHeading font_size='15' font_weight='bold' text='Conversation History'></CustomHeading>
                        </div>
                        {
                            current_chat_screen_type === "co-pilot" && <div className={styles.new_chat_button}>
                                <StyledNewChatButtonContainer onClick={() => {
                                    setShallCreateNewChat(true)
                                }}>
                                    <Ic_Add_Shaded />
                                </StyledNewChatButtonContainer>
                            </div>
                        }

                    </div>
                    <div className={styles.sub_heading_container}>
                        <div className={styles.field}>
                            <CustomTextField placeholder_text='Search' />
                            <Ic_Search_Shaded></Ic_Search_Shaded>
                        </div>
                        <div className={styles.filter}>
                            <Ic_Filter_Shaded />
                        </div>
                    </div>
                </div>
                <div className={styles.history_list_container}>
                    {is_loading_list_of_conversations && <div className={styles.loading_container}>
                        <img width={100} height={100} src={ic_clairo_logo_gif} />
                        <CustomHeading text='Loading...' />
                    </div>}
                    {!is_loading_list_of_conversations && Object.entries(listOfConversationsToRender).map(([period, items]) => (
                        <div className={styles.list} key={period}>
                            <div className={styles.yesterday_title}>
                                <CustomHeading text={period.charAt(0).toUpperCase() + period.slice(1)} />
                            </div>
                            <div className={styles.divider}>
                                <StyledDivider />
                            </div>
                            {items.map((item, index) => (
                                <StyledUserHistoryItemContainer
                                    className={styles.list_item}
                                    onClick={(e) => {
                                        fetchMessagesHistory({ id: item?.id })
                                    }}
                                    key={index}
                                >
                                    <div className={styles.left_container}>
                                        <CustomHeading font_size='12' font_weight='bold' text={truncateText(item.title, 4)} />
                                        <CustomHeading font_size='10' text={getAgentType(item.config_id)} />
                                    </div>
                                    <div className={styles.right_container}>
                                        <Ic_Arrow_Right_Shaded />
                                    </div>
                                </StyledUserHistoryItemContainer>
                            ))}
                        </div>
                    ))}
                    {
                        current_chat_screen_type !== "co-pilot" && <StyledNewChatContainer className={styles.new_chat_button_container}>
                            <StyledDivider />
                            <div className={styles.bottom_container}>
                                <CustomHeading font_size='15' font_weight='bold' text='New Chat'></CustomHeading >
                                <StyledNewChatButtonContainer onClick={() => {
                                    setShallCreateNewChat(true)
                                }}>
                                    <Ic_Add_Shaded />
                                </StyledNewChatButtonContainer>
                            </div>
                        </StyledNewChatContainer>
                    }

                </div>
            </StyledHistoryContainer>
        }

        {
            isAdvancedSettingsVisible && !is_qa_advanced_settings_open && <StyledHistoryContainer className={styles.history_opened_container}>

                <div className={styles.history_list_container}>
                    <div>
                        <ChatBotAgent agent_config_to_edit={current_selected_chatbot} setCurrentAgentToEdit={() => { }} setOpenSideBar={() => { }}></ChatBotAgent>
                    </div>

                    <StyledNewChatContainer className={styles.new_chat_button_container}>
                        {
                            isAdvancedSettingsVisible && <div className={styles.closed_conversation} onClick={()=>{
                                setIsAdvancedSettingsVisible(false)
                                setCurrentSelectedChatbot(current_selected_chatbot)
                            }}>
                                <StyledDivider />
                                <div className={styles.title_container}>
                                    <CustomHeading font_size='15' font_weight='bold' text='Conversation History'></CustomHeading >
                                    <Ic_Arrow_Upward_Shaded />
                                </div>

                            </div>
                        }
                         <StyledDivider />
                        <div className={styles.bottom_container}>
                            <CustomHeading font_size='15' font_weight='bold' text='Neww Chat'></CustomHeading >
                            <StyledNewChatButtonContainer onClick={() => { }}>
                                <Ic_Add_Shaded />
                            </StyledNewChatButtonContainer>
                        </div>
                    </StyledNewChatContainer>
                </div>
            </StyledHistoryContainer>
        }
        {
            is_qa_advanced_settings_open && <StyledHistoryContainer className={styles.history_opened_container}>

                <div className={styles.history_list_container}>
                    <div>
                        <QAChatBotAgent agent_config_to_edit={current_selected_chatbot} setCurrentAgentToEditForQA={() => { }} setOpenSideBar={() => { }}></QAChatBotAgent>
                    </div>
                    <StyledNewChatContainer className={styles.new_chat_button_container}>
                        <StyledDivider />
                        <div className={styles.bottom_container}>
                            <CustomHeading font_size='15' font_weight='bold' text='New Chat'></CustomHeading >
                            <StyledNewChatButtonContainer onClick={() => { }}>
                                <Ic_Add_Shaded />
                            </StyledNewChatButtonContainer>
                        </div>
                    </StyledNewChatContainer>
                </div>
            </StyledHistoryContainer>
        }
    </div>
};




const mapState = (state) => ({
    list_of_apps: getListOfAgents(state),
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    lis_of_conversations: getListOfConversations(state),
    number_of_messages_sent: getNumberOfMessagesSent(state),
    is_loading_list_of_conversations: getIsLoadingListOfConversations(state),
    is_chatbot_advanced_settings_open: getIsChatbotAdvancedSettingsOpen(state),
    is_qa_advanced_settings_open: getIsQAbotAdvancedSettingsOpen(state),
    current_chat_screen_type: getCurrentChatScreenType(state),
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentSelectedChatbot: (data) => dispatch(setCurrentSelectedChatbot(data)),
    fetchListOfConversations: (data) => dispatch(fetchListOfConversations(data)),
    setShallCreateNewChat: (data) => dispatch(setShallCreateNewChat(data)),
    fetchMessagesHistory: (data) => dispatch(fetchMessagesHistory(data)),
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setIsQAbotAdvancedSettingsOpen: (data) => dispatch(setIsQAbotAdvancedSettingsOpen(data)),
});

export default connect(mapState, mapDispatchToProps)(HistoryList)