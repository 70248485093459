export const getListOfEmbeddingModels = (state) => state.llms?.list_of_embedding_models;
export const getIsLoadingListOfEmbeddingModels = (state) => state.llms?.is_loading_list_of_embedding_models;

export const getListOfChunkingListModels = (state) => state.llms?.list_of_chunking_list_models;
export const getIsLoadingListOfChunkingListModels = (state) => state.llms?.is_loading_list_of_chunking_list_models;

export const getListOfSummarizationModels = (state) => state.llms?.list_of_summarization_models;
export const getIsLoadingListOfSummarizationModels = (state) => state.llms?.is_loading_list_of_summarization_models;

export const getListOfChatModels = (state) => state.llms?.list_of_chat_models;
export const getIsLoadingListOfChatModels = (state) => state.llms?.is_loading_list_of_chat_models;

export const getListOfSearchQueryModels = (state) => state.llms?.list_of_search_query_models;
export const getIsLoadingListOfSearchQueryModels = (state) => state.llms?.is_loading_list_of_search_query_models;