import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
    name: 'user',
    initialState: {
        is_loading_user:false,
    },
    reducers: {
        setIsLoadingUser: (state, action) => {
            state.is_loading_list_of_users = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
})

export const {
    setIsLoadingUser,
    setUser
} = profileSlice.actions;