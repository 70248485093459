import React from 'react'
import styles from './styles.module.scss'
import { useEffect, useState, useMemo } from 'react';
import { StyledAgentMainContainer, StyledSlider } from './styled';
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomLabel } from '../../../../../shared/ui/label';
import CustomTextField from '../../../../../shared/ui/text_field';
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { TYPE_4, TYPE_5 } from '../../../../../shared/ui/models_dropdowns/models_types';
import { TYPE_2, TYPE_4 as TYPE_4_multi_select_dropdown } from '../../../../../shared/ui/general_dropdowns/general_types';
import { CustomSwitch } from '../../../../../shared/ui/switch';
import General_dropdowns from '../../../../../shared/ui/general_dropdowns';
import { getCurrentTheme } from '../../../../app/logic/selectors';
import { showToastMessage } from '../../../../app/logic/actions';
import { connect } from 'react-redux';
import { CustomButton } from '../../../../../shared/ui/buttons';
import { TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types';
import { handleCreateAnalystAgentConfig, updateAnalystAgentConfig } from '../../../logic/agents/actions';
import { Box } from '@mui/material';
import { CustomTabs } from '../../../../../shared/ui/tabs';
import { TextField } from '@mui/material';

const AnalyticsAgent = ({
    current_theme,
    setOpenAnalyticsSideBar,
    setOpenSideBar,
    handleCreateAnalystAgentConfig,
    setCurrentAnalyticsAgentToEdit,
    agent_config_to_edit,
    is_agent_created_or_updated,
    setIsConfigCreated,
    openCreateSideBar,
    updateAnalystAgentConfig,
    showToastMessage,
    ...props
}) => {
    const initialFormState = {
        id: null,
        convoEnabled: false,
        genSearchEnabled: false,
        agentName: '',
        agentDescription: '',
        prompt: '',
        systemPrompt: '',
        tone: null,
        creativity: 0.8,
        llmTypeForChatbotModel: 'PublicLLM',
        agentConfigToEdit: null,
        isToneEnabled: false,
        currentSelectedChatbotModel: [],
        isTesting: false,
        currentSelectedChatbotModelValue: null,
        agentResponse: '500',
        conversationSummaryLength: '500',
        currentSelectedSummarizationModel: null,
        llmTypeForSummarizationModel: 'PublicLLM',
        currentSelectedSummarizationModelValue: null,
        isConversationSummaryEnabled: false,
        databaseURL: '',
        databaseName: '',
        tokenLimit: '1000'
    };
    
    const [formState, setFormState] = useState(initialFormState);
    const [agentConfigToEdit, setAgentConfigToEdit] = useState(null);
    const [isTesting, setIsTesting] = useState(false);
    const [mode, setMode] = useState('create');
    const [isPromptSettingsActive, setPromptSettingsActive] = useState(true);
    const [isAdvancedSettingsActive, setAdvancedSettingsActive] = useState(false);
    const tabs = [
        { label: 'Prompt Settings' },
        { label: 'Advanced Settings' }
    ];
    const fieldValidations = useMemo(() => [
        { field: formState.agentName, message: "Please provide a name for this agent" },
        { field: formState.agentDescription, message: "Please provide a description for this agent" },
        { field: formState.prompt, message: "Please provide a prompt for this agent" },
        { field: formState.isToneEnabled, message: "Please provide a tone for this agent" },
        { field: formState.currentSelectedChatbotModel, message: "Please select a chatbot model" },
        { field: formState.databaseName, message: "Please provide a name for this agent's database" },
    ], [formState.agentName, formState.agentDescription, formState.prompt, formState.isToneEnabled, formState.currentSelectedChatbotModel, formState.databaseName]);

    const handlePromptSettings = () => {
        setPromptSettingsActive(true);
        setAdvancedSettingsActive(false);
    };

    const handleAdvancedSettings = () => {
        setPromptSettingsActive(false);
        setAdvancedSettingsActive(true);
    };

    const onTabChange = [handlePromptSettings, handleAdvancedSettings];

    useEffect(() => {
        if (is_agent_created_or_updated) {
            setCurrentAnalyticsAgentToEdit(null);
            setOpenAnalyticsSideBar(false);
            setIsConfigCreated(false);
            resetFormState();
        }
    }, [is_agent_created_or_updated]);

    useEffect(() => {
        if (agent_config_to_edit != null) {
            setAgentConfigToEdit(agent_config_to_edit);
            setMode('update');
        } else {
            setMode('create');
        }
    }, [agent_config_to_edit]);

    useEffect(() => {
        if (agent_config_to_edit !== null && agent_config_to_edit !== undefined) {

            setFormState(prevState => {
                const updatedFormState = {
                    ...prevState,
                    agentName: agent_config_to_edit.name || '',
                    agentDescription: agent_config_to_edit.description || '',
                    currentSelectedChatbotModel: agent_config_to_edit.chat_model || [],
                    prompt: agent_config_to_edit.prompt || '',
                    tone: agent_config_to_edit.tone || null,
                    isToneEnabled: agent_config_to_edit.is_tone_active || false,
                    creativity: agent_config_to_edit.creativity || 0.8,
                    databaseName: agent_config_to_edit.db_name || '',
                    agentResponse: agent_config_to_edit.chat_completion || '500',
                    conversationSummaryLength: agent_config_to_edit.summary_completion || '500',
                    currentSelectedSummarizationModel: agent_config_to_edit.summarization_model || null,
                    isConversationSummaryEnabled: agent_config_to_edit.conversation_summarization || false,
                    isTesting: agent_config_to_edit.is_test || false,
                    tokenLimit: agent_config_to_edit.token_limit || '500'
                };
                return updatedFormState
            })
        } else {
            resetFormState()
        }
    }, [mode, agent_config_to_edit]);

    const resetFormState = () => {
        setFormState(initialFormState);
        setAdvancedSettingsActive(false);
    };

    const handleInputChange = (field, value) => {
        setFormState(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSwitchChange = (field, value) => {
        handleInputChange(field, value);
    };

    const validateFields = () => {
        for (let validation of fieldValidations) {
            if (!validation.field || validation.field.length === 0) {
                showToastMessage({ type: 'error', message: validation.message });
                return false;
            }
        }
        return true;
    };
    
    const buildAgentConfig = () => ({
        agent_name: formState.agentName,
        description: formState.agentDescription,
        chat_model: formState.currentSelectedChatbotModel?.id,
        prompt: formState.prompt,
        db_name: formState.databaseName,
        chat_completion: parseInt(formState.agentResponse, 10),
        token_limit: parseInt(formState.tokenLimit, 10),
        is_tone_active: formState.isToneEnabled,
        tone: formState.tone?.value,
        creativity: formState.creativity,
        system_prompt: formState.systemPrompt,
        summary_completion: parseInt(formState.conversationSummaryLength, 10),
        summarization_model: formState.currentSelectedSummarizationModel?.id,
        conversation_summarization: formState.isConversationSummaryEnabled,
        is_test: false,
    });
    
    const handleCreateAgent = () => {
        try {
            if (!validateFields()) return;
    
            const config = buildAgentConfig();
            handleCreateAnalystAgentConfig(config);
            closeSideBar();
        } catch (error) {
            showToastMessage({ type: 'error', message: "Unable to create agent. Please try again." });
        }
    };
    
    const buildUpdatePayload = () => {
        const modelSelectedForSummarization = agent_config_to_edit?.summarizationModel;
    
        return {
            ...(agent_config_to_edit && { id: agent_config_to_edit.id }),
            prompt: formState.prompt,
            is_tone_active: formState.isToneEnabled,
            tone: formState.tone?.value,
            creativity: formState.creativity,
            agent_name: formState.agentName,
            description: formState.description,
            chat_model: formState.currentSelectedChatbotModel?.id,
            summarization_model: formState.currentSelectedSummarizationModel?.id || modelSelectedForSummarization?.id,
            conversation_summarization: formState.isConversationSummaryEnabled,
            chat_completion: formState.agentResponse,
            search_query_completion: formState.searchQueryLength,
            summary_completion: formState.conversationSummaryLength,
            is_test: formState.is_test,
            db_name: formState.databaseName,
        };
    };
    
    const handleUpdateAgent = () => {
        try {
            if (!validateFields()) return;
    
            const payload = buildUpdatePayload();
            updateAnalystAgentConfig(payload);
        } catch (error) {
            showToastMessage({ type: 'error', message: "Unable to update agent. Please try again." });
        }
    };
    

    const closeSideBar = () => {
        setOpenSideBar(false)
        resetFormState()
        setCurrentAnalyticsAgentToEdit(null);
        setAdvancedSettingsActive(false)
    } 

    return (
        <div className={styles.container}>
            <StyledAgentMainContainer className={styles.agent_config_container}>
                    <div className={styles.main_container}>
                        <CustomTabs tabs={tabs} onTabChange={onTabChange}></CustomTabs>
                        {isPromptSettingsActive && 
                        <>
                        <div className={styles.heading_container}>
                            <CustomHeading text="Agent's Personality and Instructions" font_size='16' font_weight='bold'></CustomHeading>
                        </div>
                        <div className={styles.input_fields_container}>
                            <CustomTextField
                                InputProps={{ disableUnderline: true }}
                                placeholder="Enter agent name"
                                value={formState.agentName}
                                onChange={(e) => handleInputChange('agentName', e.target.value)}
                            />
                        </div>
                        <div className={styles.input_fields_container}>
                            <CustomTextField
                                InputProps={{ disableUnderline: true }}
                                value={formState.agentDescription}
                                onChange={(e) => handleInputChange('agentDescription', e.target.value)}
                                placeholder="Agent Description"
                                multiline='true'
                                minRows={7}
                            />
                        </div>
                        <div className={styles.input_fields_container}>
                            <CustomTextField
                                InputProps={{ disableUnderline: true }}
                                placeholder="System Prompt"
                                value={formState.systemPrompt}
                                onChange={(e) => handleInputChange('systemPrompt', e.target.value)}
                                multiline='true'
                                minRows={7}
                            />
                        </div>
                        <div className={styles.input_fields_container}>
                            <CustomTextField
                                InputProps={{ disableUnderline: true }}
                                placeholder='Analytics Prompt'
                                value={formState.prompt}
                                onChange={(e) => handleInputChange('prompt', e.target.value)}
                                multiline='true'
                                minRows={7}
                            />
                        </div>
                        <div className={styles.radio_container}>
                            <div className={styles.additional_toggle}>
                                <div>
                                    <CustomLabel title="Chatbot model" />
                                    <CustomModelDropDown
                                        type={TYPE_4}
                                        handleOnClick={(model, llmType) => {
                                            handleInputChange('currentSelectedChatbotModel', model);
                                            handleInputChange('llmTypeForChatbotModel', llmType);
                                        }}
                                        defaultValue={formState.currentSelectedChatbotModelValue}
                                        defaultLLM={formState.llmTypeForChatbotModel}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.input_fields_container_with_label}>
                            <CustomHeading text='Your Database' font_size='14' />
                            <CustomTextField
                                InputProps={{ disableUnderline: true }}
                                placeholder='Enter your database name'
                                value={formState.databaseName}
                                onChange={(e) => handleInputChange('databaseName', e.target.value)}
                                multiline='true'
                                minRows={7}
                            />
                        </div>
                        <div className={styles.switch_button}>
                            <div className={styles.left_container}>
                                <CustomSwitch
                                    checked={formState.isToneEnabled}
                                    onChange={(e) => handleSwitchChange('isToneEnabled', e.target.checked)}
                                />
                                <CustomLabel title='Tone' />
                            </div>
                            <div className={styles.right_container}>
                                <General_dropdowns
                                    type={TYPE_2}
                                    current_theme={current_theme}
                                    handleOnClick={(selectedTone) => handleInputChange('tone', selectedTone)}
                                />
                            </div>
                        </div>
                        <div className={styles.slider_container}>
                            <div className={styles.header_container}>
                                <div className={styles.title}>
                                    <CustomLabel title='Creativity' />
                                </div>
                                <div className={styles.value}>
                                    <CustomLabel title={formState.creativity} />
                                </div>
                            </div>
                            <Box className={styles.slider}>
                                <StyledSlider
                                    value={parseFloat(formState.creativity)}
                                    onChange={(e, newValue) => handleInputChange('creativity', newValue)}
                                    step={0.1}
                                    marks
                                    max={1}
                                />
                            </Box>
                        </div>
                        <div className={styles.button_container}>
                            {mode === 'update' && !isTesting && (
                                <CustomButton
                                    font_size='12'
                                    padding="8px 18px"
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleUpdateAgent}
                                    title="Update"
                                />
                            )}
                            {mode === 'create' && !isTesting && (
                                <CustomButton
                                    font_size='12'
                                    padding="8px 18px"
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleCreateAgent}
                                    title="Create"
                                />
                            )}
                                <CustomButton
                                    currentButtonTpe={TYPE_1_button}
                                    border_color='white'
                                    background_color='transparent'
                                    font_size='12'
                                    padding="8px 18px"
                                    title='Cancel'
                                    onClick={closeSideBar}
                                />
                        </div>
                        </>
                        }
                    </div>
                
                {isAdvancedSettingsActive && (
                    <div className={styles.advanced_config_container}>
                        <div className={styles.advanced_row_0}>
                            <div className={styles.left_container}>
                                <CustomSwitch
                                    checked={formState.convoEnabled}
                                    onChange={(e) => handleSwitchChange('convoEnabled', e.target.checked)}
                                />
                                <CustomLabel title='Conversation Summary' />
                            </div>
                            <div className={styles.additional_toggle}>
                                {formState.convoEnabled && (
                                    <div>
                                        <CustomModelDropDown
                                            type={TYPE_5}
                                            handleOnClick={(model, llmType) => {
                                                handleInputChange('currentSelectedSummarizationModel', model);
                                                handleInputChange('llmTypeForSummarizationModel', llmType);
                                            }}
                                            defaultValue={formState.currentSelectedSummarizationModelValue}
                                            defaultLLM={formState.llmTypeForSummarizationModel}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.advanced_row_1}>
                            <div className={styles.left_container}>
                                <CustomHeading text='Prompt Token Limit'></CustomHeading>
                                <CustomTextField placeholder={formState.tokenLimit} value={formState.tokenLimit} onChange={(e) => handleInputChange('tokenLimit', e.target.value)}></CustomTextField>
                            </div>
                            <div className={styles.right_container}>
                            <CustomLabel title='Agent Response' />
                            <CustomTextField
                                placeholder={formState.agentResponse}
                                value={formState.agentResponse}
                                onChange={(e) => handleInputChange('agentResponse', e.target.value)}
                            />
                        </div>
                        </div>
                    
                        <div className={styles.advanced_row_2}>
                            <CustomLabel title='Conversation Summary Length' />
                            <CustomTextField
                                placeholder={formState.conversationSummaryLength}
                                value={formState.conversationSummaryLength}
                                onChange={(e) => handleInputChange('conversationSummaryLength', e.target.value)}
                            />
                        </div>
                        <div className={styles.button_container}>
                            {mode === 'update' && !isTesting && (
                                <CustomButton
                                    font_size='12'
                                    padding="8px 18px"
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleUpdateAgent}
                                    title="Update"
                                />
                            )}
                            {mode === 'create' && !isTesting && (
                                <CustomButton
                                    font_size='12'
                                    padding="8px 18px"
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleCreateAgent}
                                    title="Create"
                                />
                            )}
                            <CustomButton
                                currentButtonTpe={TYPE_1_button}
                                border_color='white'
                                background_color='transparent'
                                font_size='12'
                                padding="8px 18px"
                                title='Cancel'
                                onClick={() => { setOpenSideBar(false); resetFormState(); }}
                            />
                        </div>
                    </div>
                )}
            </StyledAgentMainContainer>
        </div>
    );
};


const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    handleCreateAnalystAgentConfig: (data) => dispatch(handleCreateAnalystAgentConfig(data)),
    updateAnalystAgentConfig: (data) => dispatch(updateAnalystAgentConfig(data))
});
export default connect(mapState, mapDispatchToProps) (AnalyticsAgent)