import Ic_Left_Arrow_Rounded_Shaded from "../../../../shared/ui/editable_icons/Ic_Left_Arrow_Rounded_Shaded";
import { LibraryAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import {
    setIsDocumentsDeleting,
    setIsDocumentsUploaded,
    setIsLibraryCreated,
    setIsLibraryDeleted,
    setIsLoadingCreatingLibrary,
    setIsLoadingListOfDocuments,
    setIsLoadingListOfLibraries,
    setIsUploadingDocument,
    setListOfChunkingMethods,
    setListOfDocuments,
    setListOfDocumentsToUpload,
    setListOfLibraries,
    setListOfSeparators
} from "./slice"

const fetchListOfDocuments = (data) => (dispatch) => {
    dispatch(setIsLoadingListOfDocuments(true))
    LibraryAPI.getAllDocuments(data).then((response) => {
        console.log("fetchListOfDocuments", response.data.items)
        console.log('response.data.items.length', response.data.items.length)
        const formattedDocuments = response.data.items.map((item) => ({
            fileName: item.title.split('/').pop(), // Extracts the file name from the URL
            id: item.id.toString(),
            status: item.status,
            library: item.library, // Assuming you want to concatenate all library names
            library_name: item?.library?.name,
            created: item.created_at.substring(0, 10),
            chunking_method: item.chunking_method,
            chunk_overlap: item.chunk_overlap,
            token_budget: item.token_budget,
            is_active: item.is_active,
            num_tokens: item.num_tokens === null ? 0 : item.num_tokens,
            description: item.description,
            processing_status: item.processing_status.toLowerCase().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            actions_list: ['View', 'Pause', 'Resume', 'Delete',],
        }));
        console.log("formattedDocuments", formattedDocuments)
        dispatch(setListOfDocuments(formattedDocuments))
        setTimeout(() => {
            dispatch(setIsLoadingListOfDocuments(false))
        }, 3000);

        dispatch(setIsDocumentsUploaded(false))
    }).catch((error) => {
        console.log(error)
        dispatch(setIsLoadingListOfDocuments(false))
        dispatch(showToastMessage("Something went wrong while loading list of documents"))
    })
}

const fetchListOfLibraries = (data) => (dispatch) => {
    dispatch(setIsLoadingListOfLibraries(true))
    LibraryAPI.getAllLibraries({}).then((response) => {
        console.log("fetchListOfLibraries-response", response)
        if (response.data) {

            const libraries = response.data.items.map(library => {

                return {
                    id: `${library.id}`,
                    value: `${library.id}`, // Constructing a unique value from the ID
                    label: library.name,
                    name: library.name,
                    description: library.description,
                    documents: library.documents,
                    number_of_documents: library?.documents?.length > 0 ? library?.documents?.length : 0,
                    tags: library.tags, // Directly using the tags array from the response
                    chunking_method: library?.chunking_method,
                    embedding_model: library?.embedding_model,
                    chunk_overlap: library?.chunk_overlap,
                    separators: library?.separators,
                    token_budget: library?.token_budget,
                    actions_list: ['Edit', 'Delete'],
                    button_action_icon: <Ic_Left_Arrow_Rounded_Shaded />
                }
            });
            console.log('libraries', libraries)
            dispatch(setListOfLibraries(libraries));
            dispatch(setIsLoadingListOfLibraries(false))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsLoadingListOfLibraries(false))
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while loading list of libraries" }))
    })
}

const fetchListOfChunkingMethods = (data) => (dispatch) => {

    dispatch(setListOfChunkingMethods([
        { value: 'character text chunking', label: 'Character Text Chunking' },
        { value: 'recursive-character-text-chunking', label: 'Recursive Character Text Chunking' },
        { value: 'semantic chunking', label: 'Semantic Chunking' },
        { value: 'token text chunking', label: 'Token Text Chunking' },
    ]));
}


const fetchListOfSeparators = (data) => (dispatch) => {
    // dispatch(setIsLoadingListOfSeparators(true))
    // setTimeout(() => {
    dispatch(setListOfSeparators([
        { id: '\n\n', value: '\n\n', label: 'Double newline'},
        { id: '\n', value: '\n', label: 'Newline' },
        { id: ' ', value: ' ', label: 'Space' },
        { id: '.', value: '.', label: 'Full stop' },
        { id: ',', value: ',', label: 'Comma' },
        { id: '', value: '', label: 'Zero-length string'},
        { id: "\u200B", value: "\u200B", label: "Zero-width space" },
        { id: '\uff0c',  value: '\uff0c', label: 'Full-width comma' },
        { id: '\u3001', value: '\u3001', label: 'Ideographic comma' },
        { id: '\uff0e', value: '\uff0e', label: 'Full-width full stop' },
        { id: '\u3002', value: '\u3002', label: 'Ideographic full stop' }
    ]));
    //     dispatch(setIsLoadingListOfSeparators(false))
    // }, 0)
}

const createLibrary = (data) => (dispatch) => {
    dispatch(setIsLoadingCreatingLibrary(true))
    LibraryAPI.createLibrary(data).then((response) => {
        if (response.data) {
            dispatch(setIsLoadingCreatingLibrary(false))
            dispatch(fetchListOfLibraries({}))
            dispatch(setIsLibraryCreated(true))
            dispatch(showToastMessage({ type: 'success', message: "Successfully created library" }))
        } else {
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong!" }))
        }
    }).catch((error) => {
        dispatch(showToastMessage({ type: 'error', message: "Could not create library" }))
        dispatch(setIsLoadingCreatingLibrary(false))
    })
}

const updateLibrary = (data) => (dispatch) => {
    dispatch(setIsLoadingCreatingLibrary(true))
    console.log("updateLibrary-data", data)
    LibraryAPI.updateLibrary(data).then((response) => {
        console.log(response);
        if (response) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated library" }))
            dispatch(setIsLoadingCreatingLibrary(false))
            dispatch(fetchListOfLibraries({}))
            dispatch(fetchListOfDocuments({}))
        } else {
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while updating library" }))
        }

    }).catch((error) => {
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}

const uploadDocument = (data) => (dispatch) => {
    dispatch(setIsUploadingDocument(true))

    LibraryAPI.uploadDocument(data).then((response) => {
        console.log(response);
        if (response.data.documents) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully uploaded documents" }))
            dispatch(setIsDocumentsUploaded(true))
            dispatch(setIsLoadingListOfDocuments(true))
            dispatch(fetchListOfDocuments({}))
            dispatch(setListOfDocumentsToUpload([]))
        } else {
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while uploading documents" }))
        }
        dispatch(setIsUploadingDocument(false))
    }).catch((error) => {
        console.log(error)
        dispatch(setIsUploadingDocument(false))
    })
}

const deleteDocument = (data) => (dispatch) => {
    dispatch(setIsDocumentsDeleting(true))

    LibraryAPI.deleteDocument(data).then((response) => {
        console.log(response);
        if (response) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully deleted document" }))
            dispatch(setIsDocumentsDeleting(false))
            dispatch(setIsLoadingListOfDocuments(true))
            dispatch(fetchListOfDocuments({}))
        } else {
            dispatch(setIsDocumentsDeleting(false))
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while deleting document" }))
        }

    }).catch((error) => {
        console.log(error)
        dispatch(setIsDocumentsDeleting(false))
    })
}

const deleteDocuments = (data) => (dispatch) => {
    dispatch(setIsDocumentsDeleting(true))

    LibraryAPI.deleteDocuments(data).then((response) => {
        console.log("deleteDocuments", response);
        if (response) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully deleted documents" }))
            dispatch(setIsDocumentsDeleting(false))
            dispatch(setIsLoadingListOfDocuments(true))
            dispatch(fetchListOfDocuments({}))
        } else {
            dispatch(setIsDocumentsDeleting(false))
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while deleting document" }))
        }

    }).catch((error) => {
        console.log(error)
        dispatch(setIsDocumentsDeleting(false))
    })
}
const deleteLibrary = (data) => (dispatch) => {
    dispatch(setIsLoadingCreatingLibrary(true))
    dispatch(setIsLibraryDeleted(false))
    console.log("deleteLibrary-data", data)

    LibraryAPI.deleteLibrary(data).then((response) => {
        console.log(response);
        if (response) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully deleted library and associated documents" }))
            dispatch(setIsLoadingCreatingLibrary(false))
            dispatch(fetchListOfDocuments({}))
            dispatch(fetchListOfLibraries({}))
            dispatch(setIsLibraryDeleted(true))
        } else {
            dispatch(setIsDocumentsDeleting(false))
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while deleting library" }))
        }

    }).catch((error) => {
        console.log(error)
        dispatch(setIsDocumentsDeleting(false))
    })
}
const updateDocument = (data) => (dispatch) => {
    LibraryAPI.updateDocument(data).then((response) => {
        console.log(response);
        if (response) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated document" }))
            dispatch(setIsLoadingListOfDocuments(true))
            dispatch(fetchListOfDocuments({}))
        } else {
            dispatch(showToastMessage({ type: 'warning', message: "Something went wrong while updating document" }))
        }

    }).catch((error) => {
        console.log(error)
    })
}

const updateListOfDocumentsToUpload = (data) => (dispatch) => {
    dispatch(setListOfDocumentsToUpload(data))
}

export {
    fetchListOfDocuments,
    fetchListOfLibraries,
    fetchListOfChunkingMethods,
    fetchListOfSeparators,
    createLibrary,
    uploadDocument,
    deleteDocument,
    deleteDocuments,
    updateDocument,
    updateLibrary,
    deleteLibrary,
    updateListOfDocumentsToUpload
}