
import { StyledChip } from './styled';
import styles from './styles.module.scss'


// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const CustomTagChip = ({
    font_family = "primary",
    font_size = "14px",
    text_color = "white",
    background_color = "rgba(0, 255, 0, 0.1)",
    border_color = "primary",
    hoverBorderColor="primary",
    hoverBackgroundColor="primary",
    hoverTextColor="primary",
    mobileFontSize = "12px",
    tabletFontSize = "13px",
    laptopFontSize = "14px",
    macbook13FontSize = "15px",
    pick_passed_font_size = false,
    width = "100%",
    unit = "px",
    border_radius = "8px",
    title,
    ...props }) => {

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    // Determine font sizes to use based on `pick_passed_font_size`
    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return <div className={styles.container}>
        <StyledChip
        width={width}
        fontFamily={font_family}
        fontSize={font_size}
        backgroundColor={background_color}
        color={text_color}
        mobileFontSize={finalMobileFontSize}
        tabletFontSize={finalTabletFontSize}
        laptopFontSize={finalLaptopFontSize}
        macbook13FontSize={finalMacbook13FontSize}
        {...props}
      >
        {title}
      </StyledChip>
    </div>
};

export { CustomTagChip }