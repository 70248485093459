import styled from 'styled-components';
// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};

const StyledTableContainer = styled.div`
background-color: ${({ theme, backgroundColor }) => backgroundColor ? theme.palette.backgroundColors[backgroundColor] : theme.palette.backgroundColors.secondary};
border: 1px solid ${({ theme, borderColor }) => borderColor ? theme.palette.borderColors[borderColor] : theme.palette.borderColors.primary};
padding: ${({ padding }) => padding || '1rem'};
border-radius: ${({ borderRadius }) => borderRadius || '8px'};
`;

const StyledHeaderColumnContainer_Type_1 = styled.div`
width: ${({ theme, width }) => width || '20%'};
display:flex;
justify-content:space-between;
`;

// StyledTableCellContainer

const StyledTableCellContainer_Type_1 = styled.div`
width: ${({ theme, width }) => width || '20%'};
display:flex;
justify-content:space-between;
align-items:center;
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;

export { StyledTableContainer, StyledHeaderColumnContainer_Type_1,StyledTableCellContainer_Type_1,StyledDivider };
