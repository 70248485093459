import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Profile_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0ZM12 22.5C14.2031 22.5 16.2656 21.8438 17.9531 20.6719C17.7656 18.3281 15.8438 16.5 13.5 16.5H10.5C8.10938 16.5 6.1875 18.3281 6 20.6719C7.6875 21.8438 9.75 22.5 12 22.5ZM19.2656 19.5469C21.2344 17.625 22.5 14.9531 22.5 12C22.5 6.23438 17.7656 1.5 12 1.5C6.1875 1.5 1.5 6.23438 1.5 12C1.5 14.9531 2.71875 17.625 4.6875 19.5469C5.34375 16.9688 7.6875 15 10.5 15H13.5C16.2656 15 18.6094 16.9688 19.2656 19.5469ZM12 6C14.0625 6 15.75 7.6875 15.75 9.75C15.75 11.8594 14.0625 13.5 12 13.5C9.89062 13.5 8.25 11.8594 8.25 9.75C8.25 7.6875 9.89062 6 12 6ZM12 12C13.2188 12 14.25 11.0156 14.25 9.75C14.25 8.53125 13.2188 7.5 12 7.5C10.7344 7.5 9.75 8.53125 9.75 9.75C9.75 11.0156 10.7344 12 12 12Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Profile_Un_Shaded)