import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Agents_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 19.5C0 19.125 0.328125 18.75 0.75 18.75H3.79688C4.17188 17.0625 5.67188 15.75 7.5 15.75C9.28125 15.75 10.7812 17.0625 11.1562 18.75H23.25C23.625 18.75 24 19.125 24 19.5C24 19.9219 23.625 20.25 23.25 20.25H11.1562C10.7812 21.9844 9.28125 23.25 7.5 23.25C5.67188 23.25 4.17188 21.9844 3.79688 20.25H0.75C0.328125 20.25 0 19.9219 0 19.5ZM9.75 19.5C9.75 18.2812 8.71875 17.25 7.5 17.25C6.23438 17.25 5.25 18.2812 5.25 19.5C5.25 20.7656 6.23438 21.75 7.5 21.75C8.71875 21.75 9.75 20.7656 9.75 19.5ZM16.5 8.25C18.2812 8.25 19.7812 9.5625 20.1562 11.25H23.25C23.625 11.25 24 11.625 24 12C24 12.4219 23.625 12.75 23.25 12.75H20.1562C19.7812 14.4844 18.2812 15.75 16.5 15.75C14.6719 15.75 13.1719 14.4844 12.7969 12.75H0.75C0.328125 12.75 0 12.4219 0 12C0 11.625 0.328125 11.25 0.75 11.25H12.7969C13.1719 9.5625 14.6719 8.25 16.5 8.25ZM18.75 12C18.75 10.7812 17.7188 9.75 16.5 9.75C15.2344 9.75 14.25 10.7812 14.25 12C14.25 13.2656 15.2344 14.25 16.5 14.25C17.7188 14.25 18.75 13.2656 18.75 12ZM23.25 3.75C23.625 3.75 24 4.125 24 4.5C24 4.92188 23.625 5.25 23.25 5.25H12.6562C12.2812 6.98438 10.7812 8.25 9 8.25C7.17188 8.25 5.67188 6.98438 5.29688 5.25H0.75C0.328125 5.25 0 4.92188 0 4.5C0 4.125 0.328125 3.75 0.75 3.75H5.29688C5.67188 2.0625 7.17188 0.75 9 0.75C10.7812 0.75 12.2812 2.0625 12.6562 3.75H23.25ZM6.75 4.5C6.75 5.76562 7.73438 6.75 9 6.75C10.2188 6.75 11.25 5.76562 11.25 4.5C11.25 3.28125 10.2188 2.25 9 2.25C7.73438 2.25 6.75 3.28125 6.75 4.5Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"} />
    </svg>

}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Agents_Un_Shaded)