
import { BASE_URL, httpDelete, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance"
const USERS_BASE_URL = `${BASE_URL}/`

const fetchListOfUsers = (data) => httpGet(`${USERS_BASE_URL}api/user/list-user/`, data);
const fetchListOfConversations = (data) => httpGet(`${USERS_BASE_URL}api/conversation/list`, data);

const inviteUser = (data) => httpPost(`${USERS_BASE_URL}api/user/add-user/`, data);
const updateUser = (data) => httpPost(`${USERS_BASE_URL}api/user/update/${data.user_id}/`, data.data);
const updateUserPassword = (data) => httpPost(`${USERS_BASE_URL}api/user/admin_update_user_password/${data.user_id}/`, data.data);
const deleteUser = (data) => httpDelete(`${USERS_BASE_URL}api/user/delete/${data.user_id}/`);

export {
    inviteUser,
    fetchListOfUsers,
    updateUser,
    updateUserPassword,
    deleteUser,
    fetchListOfConversations
}