import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { connect } from "react-redux";
import add_btn from '../../../../../assets/images/icons8-add-48.png'
import { deleteDocument, deleteDocuments, fetchListOfDocuments, updateDocument } from '../../../logic/library/actions'
import { getIsCreatingLibraryLoading, getIsDocumentDeleting, getIsListOfDocumentsLoading, getListOfDocuments } from '../../../logic/library/selectors'
import { showToastMessage } from '../../../../app/logic/actions'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import { setIsListOfDocumentsVisible, setListOfDocuments } from '../../../logic/library/slice'
import Ic_Back_Shaded from '../../../../../shared/ui/editable_icons/Ic_Back_Shaded'
import Ic_Search_Shaded from '../../../../../shared/ui/editable_icons/Ic_Search_Shaded'
import { CustomTable } from '../../../../../shared/ui/table'
import { listOfColumns } from './table_columns'
import Sidebar_to_upload_documents from './sidebar_to_upload_documents'
import { CustomButton } from '../../../../../shared/ui/buttons'
import Sidebar_to_view_document from './sidebar_to_view_document'
import { CustomHeading } from '../../../../../shared/ui/headings';
import CustomTextField from '../../../../../shared/ui/text_field';
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const Documents = ({
    is_loading_documents_list,
    loadDocumentsList,
    list_of_documents,
    showToastMessage,
    deleteDocument,
    updateDocument,
    library_id_to_load = null,
    current_theme,
    deleteDocuments,
    is_deleting_document,
    setIsListOfDocumentsVisible,
    ...props }) => {


    const [isUploadDocumentSideBarOpen, setIsUploadDocumentSideBarOpen] = useState(false)
    const [isViewDocumentSideBarOpen, setIsViewDocumentSideBarOpen] = useState(false)
    const [currentSelectDocument, setCurrentSelectDocument] = useState(null);
    const [searchParam, setSearchParam] = useState(null);
    const [listOfDocumentsSelected, setListOfDocumentsSelected] = useState([])
    const [filteredDocuments, setFilteredDocuments] = useState(list_of_documents);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirmDeleteDocs, setConfirmDeleteDocs] = useState(false)
    const [docToDelete, setDocToDelete] = useState(null);
    const [docsToDelete, setDocsToDelete] = useState([])

    useEffect(() => {
        loadDocumentsList();
    }, [])


    useEffect(() => {
        if (!is_deleting_document) {
            setListOfDocumentsSelected([])
        }
    }, [is_deleting_document])

    const handleDeleteSelectedDocuments = () => {
        if (listOfDocumentsSelected.length > 0) {
          const documentNames = listOfDocumentsSelected.map(doc => doc.listItem.fileName); // Assuming listItem has a 'name' field
          setDocsToDelete(documentNames);
          setConfirmDeleteDocs(true);
          console.log('listofdocumentsselected', listOfDocumentsSelected);
        }
      };
    
      const confirmDeleteDocuments = () => {
        if (listOfDocumentsSelected.length > 0) {
          const docIds = listOfDocumentsSelected.map(doc => doc.listItem.id);
          deleteDocuments(docIds);
          setConfirmDeleteDocs(false);
          setDocsToDelete([]);
          console.log('docIds', docIds);
        }
      };
    
    useEffect(() => {
        if (searchParam?.length > 0) {
            const lowercaseSearchParam = searchParam.toLowerCase();
            const tempListOfDoc = list_of_documents.filter((doc) => {
                const fileName = doc?.fileName?.toLowerCase();
                return fileName.includes(lowercaseSearchParam);
            });

            setListOfDocuments(tempListOfDoc);
            setFilteredDocuments(tempListOfDoc);

        } else if (library_id_to_load !== undefined && library_id_to_load !== null) {
            let tempListOfDoc = list_of_documents.map((doc) => {
                let lib_id = doc?.library?.id
                return lib_id == library_id_to_load?.value && doc
            }).filter(doc => doc !== false);
            setListOfDocuments(tempListOfDoc)
            setFilteredDocuments(tempListOfDoc)
        } else {
            setListOfDocuments(list_of_documents)
            setFilteredDocuments(list_of_documents)
        }
    }, [searchParam, library_id_to_load,list_of_documents])

    const formatName = (name) => {
        let formattedName = name?.replace(/_/g, ' ');
        formattedName = formattedName?.replace(/\b\w/g, (char) => char.toUpperCase());
        return formattedName;
    };

    const handleTableActions = (option, document) => {
        switch (option) {
            case 'View':
                setCurrentSelectDocument(document)
                setIsViewDocumentSideBarOpen(true);
                break;
            case 'Pause':
                updateDocument({ payload: { status: "inactive" }, id: document?.id })
                break;
            case 'Resume':
                updateDocument({ payload: { status: "active" }, id: document?.id })
                break;
            case 'Delete':
                setConfirmDelete(true)
                setDocToDelete(document?.id)
                break;
            case 'Confirm Delete':
                deleteDocument(docToDelete)
                setConfirmDelete(false);
                setDocToDelete(null);
                break;
            default:
                break;
        }
    }

    return <div className={styles.container}>
        <div className={styles.list_of_document_container}>
            <div className={styles.header_container}>
                <div className={styles.left_container}>
                    {
                        library_id_to_load != null && <div className={styles.icon_container} onClick={(e) => {
                            setIsListOfDocumentsVisible(false)
                        }}>
                            <Ic_Back_Shaded />
                        </div>
                    }
                    <div className={styles.text_container}>
                        <CustomHeading font_size='20' font_weight='bold' text={library_id_to_load === null ? `Document Library` : formatName(library_id_to_load?.label)} ></CustomHeading>
                        <CustomHeading color='tertiary' font_size='12' text={library_id_to_load === null ? `Manage the knowledge of your Agents` : library_id_to_load?.description}> </CustomHeading>
                    </div>
                </div>
                <div className={`${styles.right_container} ${listOfDocumentsSelected.length > 0 ? styles.with_delete_btn : ''}`}>

                    <div className={styles.add_btn_container}>

                        <CustomButton
                            padding="8px 15px"
                            text_color='default'
                            icon={<img src={add_btn} className={styles.icon} />}
                            currentButtonTpe={TYPE_3_button}
                            title='Upload document'
                            font_size='12'
                            onClick={() => setIsUploadDocumentSideBarOpen(true)}
                        ></CustomButton>

                    </div>
                    {
                        listOfDocumentsSelected.length > 0 && <div className={styles.delete_btn}>
                            <CustomButton
                                padding="8px 15px"
                                text_color='default'
                                font_size='12'
                                title={is_deleting_document ? 'Deleting ' + listOfDocumentsSelected.length : 'Delete ' + listOfDocumentsSelected.length} 
                                currentButtonTpe={TYPE_1_button} 
                                onClick={handleDeleteSelectedDocuments}>  
                            </CustomButton>
                            <DeleteConfirmationComponent
                                isVisible={confirmDeleteDocs}
                                onConfirm={confirmDeleteDocuments}
                                onCancel={() => setConfirmDeleteDocs(false)}
                                message='Are you sure you want to delete these documents?'
                                documents={docsToDelete}
                            />
                        </div>
                    }

                </div>
            </div>
            {
                <div className={styles.search_bar_container}>
                    <CustomTextField icon={<Ic_Search_Shaded />} placeholder='Search' onChange={(e) => setSearchParam(e.target.value)} />
                </div>
            }
            <div className={styles.body_container}>
                <CustomTable defaultSortDirection='A-Z' defaultSortField='fileName' show_selection_checkbox={true} handleTableActions={handleTableActions} listOfColumns={listOfColumns} listOfRows={filteredDocuments} setListOfSelectedRows={setListOfDocumentsSelected} />
                <DeleteConfirmationComponent
                    isVisible={confirmDelete}
                    onConfirm={() => handleTableActions('Confirm Delete')}
                    onCancel={() => { setConfirmDelete(false) }}
                    message='Are you sure you want to delete this document?'
                />
            </div>
        </div>
        {
            isUploadDocumentSideBarOpen && <Sidebar_to_upload_documents library_id_to_load={library_id_to_load} setIsUploadDocumentSideBarOpen={setIsUploadDocumentSideBarOpen} />
        }
        {
            isViewDocumentSideBarOpen && <Sidebar_to_view_document currentSelectDocument={currentSelectDocument} setIsViewDocumentSideBarOpen={setIsViewDocumentSideBarOpen} />
        }
    </div >
}
const mapState = (state) => ({
    is_loading_documents_list: getIsListOfDocumentsLoading(state),
    list_of_documents: getListOfDocuments(state),
    is_loading_create_library: getIsCreatingLibraryLoading(state),
    current_theme: getCurrentTheme(state),
    is_deleting_document: getIsDocumentDeleting(state),
})

const mapDispatchToProps = (dispatch) => ({
    loadDocumentsList: (data) => dispatch(fetchListOfDocuments(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    deleteDocument: (data) => dispatch(deleteDocument(data)),
    deleteDocuments: (data) => dispatch(deleteDocuments(data)),
    updateDocument: (data) => dispatch(updateDocument(data)),
    setIsListOfDocumentsVisible: (data) => dispatch(setIsListOfDocumentsVisible(data)),
});

export default connect(mapState, mapDispatchToProps)(Documents)