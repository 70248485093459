import React, { useEffect, useRef, useState } from 'react';

import { connect } from "react-redux";
import styles from './styles.module.scss'
import { MyStyledButton_type1, MyStyledButton_type2, MyStyledButton_type3, StyledBotContainer, StyledBotMsgContainer, StyledHeading1, StyledHeading2, StyledHeading3, StyledHeading4, StyledHeading5, StyledHeading6, StyledHeading7, StyledHeading8, StyledMainContainer, StyledTabBar, StyledTextField } from './styled';
import ic_chat_bot from '../../../assets/images/ic_clairo_logo.svg'
import gif_loading from '../../../assets/images/loading_gif.gif'
import ic_user from '../../../assets/images/user_icon.png'
import ic_search_icon from '../../../assets/images/search_icon.svg';

function AccountSetupPage(props) {

    const [listOfQuestions, setListOfQuestions] = useState([
        {

            sender: "Cario.ai",
            timeStamp: "9 Jun 2024",
            content: "What is the name of your company?",
            messageType: "text",
            isBot: true,
            isLoading: true,
        },

        {


            sender: "Cario.ai",
            timeStamp: "9 Jun 2024",
            content: "What industry does your business operate in?",
            messageType: "choice",
            isLoading: true,
            isResponseSubmitted: false,
            options: [{
                id: "1",
                title: "Option 1",
                isSelected: false
            },
            {
                id: "2",
                title: "Option 2",
                isSelected: false
            },
            {
                id: "3",
                title: "Option 3",
                isSelected: false
            }], // List of options
            isBot: true,
        },

        {
            sender: "Cario.ai",
            timeStamp: "9 Jun 2024",
            content: "Describe the most manual tasks your business does.",
            messageType: "text",
            isBot: true,
            isLoading: true,
        },

        {
            sender: "Cario.ai",
            timeStamp: "9 Jun 2024",
            content: "Describe the opportunities for GenAI in your company.",
            messageType: "text",
            isBot: true,
            isLoading: true,
        },
    ])

    const [listOfMessages, setListOfMessages] = useState([]);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [currentMessageId, setCurrentMessageId] = useState(0);

    const messageContainerRef = useRef(null);
    const handleTypedAnswer = (event) => {
        if (event.key === 'Enter') {
            const inputValue = event.target.value;
            let messageId = generateRandomId()
            let answerMessage = {
                id: messageId,
                sender: "Zeeshan",
                timeStamp: "9 Jun 2024",
                content: inputValue,
                messageType: "text",
                isBot: false,
                isLoading: true,
            }
            let copyOfListOfMessage = listOfMessages;
            copyOfListOfMessage.push(answerMessage)
            setListOfMessages(copyOfListOfMessage)
            setCurrentMessageId(messageId);
            addMessageAndScroll()
        }
    }


    const handleOptionAnswer = (questionId, answerId, isResponseSubmitted) => {
        if (!isResponseSubmitted) {
            // Find the question in listOfMessages
            const questionIndex = listOfMessages.findIndex((q) => q.id === questionId);

            if (questionIndex !== -1) {
                const question = listOfMessages[questionIndex];

                // Find the selected option in the question's options
                const selectedOptionIndex = question.options.findIndex((opt) => opt.id === answerId);

                if (selectedOptionIndex !== -1) {
                    // Toggle the isSelected property
                    const updatedOptions = [...question.options];
                    updatedOptions[selectedOptionIndex] = {
                        ...updatedOptions[selectedOptionIndex],
                        isSelected: !updatedOptions[selectedOptionIndex].isSelected,
                    };

                    // Update the question with the updated options
                    const updatedQuestion = {
                        ...question,
                        options: updatedOptions,
                    };

                    // Update the listOfMessages with the updated question
                    const updatedListOfMessages = [...listOfMessages];
                    updatedListOfMessages[questionIndex] = updatedQuestion;

                    // Update the state with the updated listOfMessages
                    setListOfMessages(updatedListOfMessages);
                }
            }
        }
    };


    useEffect(() => {
        let messageToRender = listOfQuestions[currentQuestionIndex];
        messageToRender.id = generateRandomId();
        setListOfMessages([listOfQuestions[currentQuestionIndex], ...listOfMessages]);
        setCurrentMessageId(messageToRender.id);

    }, [])

    useEffect(() => {
        if (currentMessageId) {
            handleMessageLoading(currentMessageId)
        }
    }, [currentMessageId])

    useEffect(() => {
        if (currentQuestionIndex < listOfQuestions.length) {
            let messageToRender = listOfQuestions[currentQuestionIndex];
            messageToRender.id = generateRandomId();
            listOfMessages.push(messageToRender);
            setListOfMessages(listOfMessages);
            setCurrentMessageId(messageToRender.id);
            addMessageAndScroll()
        }
    }, [currentQuestionIndex])

    const handleMessageLoading = (messageId) => {
        // we will change this a little after actual api is consumed
        setTimeout(() => {
            let messageWhoseLoadingShouldBeSetFalse = listOfMessages.filter((message) => {

                if (message.id === messageId) return message
            })

            let newListOfMessage = listOfMessages.map((message) => {
                if (message.id === messageId) {
                    messageWhoseLoadingShouldBeSetFalse[0].isLoading = false
                    return messageWhoseLoadingShouldBeSetFalse[0]
                } else {
                    return message;
                }
            });
            setListOfMessages(newListOfMessage);
            // change question index.. if it was user response. 
            if (!messageWhoseLoadingShouldBeSetFalse[0].isBot) {
                let newIndex = currentQuestionIndex + 1;
                setCurrentQuestionIndex(newIndex);
            }
        }, 3000);
    }

    const generateRandomId = () => {
        return Math.random().toString(36).substring(7); // Generates a random alphanumeric ID
    }

    const handleNextQuestionClick = () => {
        // Check if the current message is a bot type 2 message
        let currentMessageId = listOfQuestions[currentQuestionIndex].id;

        const questionIndexInListOfMessages = listOfMessages.findIndex(
            (message) => message.id === currentMessageId
        );

        const currentMessage = listOfMessages[questionIndexInListOfMessages];
        if (currentMessage.messageType === "choice") {
            // Set isResponseSubmitted to true for the current message
            const updatedMessage = {
                ...currentMessage,
                isResponseSubmitted: true,
            };

            // Update the listOfMessages with the updated message
            const updatedListOfMessages = [...listOfMessages];
            updatedListOfMessages[questionIndexInListOfMessages] = updatedMessage;
            setListOfMessages(updatedListOfMessages);
        }

        // Move to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
    };

    const handleSubmitData = () => {
        // Create an empty object to store the questions and answers
        const dataObject = {};
        listOfMessages.forEach((message, index) => {
            if (message.isBot) {
                if (message.messageType === "text") {
                    dataObject[index] = {
                        question: message.content,
                        answer: ""
                    }
                } else {
                    dataObject[index] = {
                        question: message.content,
                        answer: getSelectedOptions(message.options)
                    }
                }
            } else {
                dataObject[index - 1].answer = message.content
            }
        })
        // Log or send the dataObject as needed
        // console.log("answer object", dataObject);
        alert(JSON.stringify(dataObject))
        // You can also perform any further processing or actions with the dataObject here
    };

    // Helper function to get selected options for choice type questions



    // Helper function to get selected options for choice type questions
    const getSelectedOptions = (options) => {
        const selectedOptions = options.filter((opt) => opt.isSelected);
        return selectedOptions.map((opt) => opt.title);
    };

    const addMessageAndScroll = () => {

    };
    return (
        <StyledMainContainer className={styles.container}>
            <div className={styles.header}>
                <StyledHeading4 className={styles.heading_1}>Account Setup</StyledHeading4>
            </div>
            <div className={styles.body}>
                <StyledBotContainer className={styles.bot_container}>

                    <div className={styles.list_of_msgz} ref={messageContainerRef}>
                        {
                            listOfMessages.map((message, index) => {
                                return message.isBot == true ? message.messageType === "text" ? <div className={styles.bot_msg_container_typ1}>

                                    <div className={styles.bot_info_container}>
                                        <img src={ic_chat_bot} className={styles.icon} />
                                        <StyledHeading5 className={styles.name}>{message.sender}</StyledHeading5>
                                        <StyledHeading6 className={styles.date_and_time}>{message.timeStamp}</StyledHeading6>
                                    </div>
                                    <StyledBotMsgContainer className={styles.bot_msg_content_container}>
                                        {
                                            message.isLoading ? <div className={styles.response_loader_container}>
                                                <img src={gif_loading} className={styles.loader_gif} />
                                                <StyledHeading6>Generating response</StyledHeading6>
                                            </div> : <StyledHeading7 className={styles.content}>{message.content}</StyledHeading7>
                                        }
                                    </StyledBotMsgContainer>
                                </div> : <div className={styles.bot_msg_container_typ2}>

                                    <div className={styles.bot_info_container}>
                                        <img src={ic_chat_bot} className={styles.icon} />
                                        <StyledHeading5 className={styles.name}>{message.sender}</StyledHeading5>
                                        <StyledHeading6 className={styles.date_and_time}>{message.timeStamp}</StyledHeading6>
                                    </div>
                                    <StyledBotMsgContainer className={styles.bot_msg_content_container}>
                                        {
                                            message.isLoading ? <div className={styles.response_loader_container}>
                                                <img src={gif_loading} className={styles.loader_gif} />
                                                <StyledHeading6>Generating response</StyledHeading6>
                                            </div> : <StyledHeading7 className={styles.content}>{message.content}</StyledHeading7>
                                        }
                                    </StyledBotMsgContainer>

                                    {/* list of options to select */}
                                    {
                                        !message.isLoading && <div className={styles.list_of_options_to_select}>
                                            {
                                                message.options.map((opt, index) => {
                                                    return opt.isSelected == true ? <div key={index}><MyStyledButton_type2 onClick={() => handleOptionAnswer(message.id, opt.id, message.isResponseSubmitted)}>{opt.title}</MyStyledButton_type2></div> :
                                                        <div key={index}><MyStyledButton_type2 onClick={() => handleOptionAnswer(message.id, opt.id, message.isResponseSubmitted)} bg_color='darkest' color='primary' >{opt.title}</MyStyledButton_type2></div>
                                                })
                                            }

                                        </div>
                                    }
                                    {message.options.some((opt) => opt.isSelected) && !message.isResponseSubmitted && (
                                        <div className={styles.next_btn_container}>
                                            <MyStyledButton_type3 onClick={handleNextQuestionClick} className={styles.btn}>Next question</MyStyledButton_type3>
                                        </div>
                                    )}




                                </div> : <div className={styles.user_msg_container}>
                                    <div className={styles.user_info_container}>
                                        <StyledHeading5 className={styles.name}>{message.sender}</StyledHeading5>
                                        {message.isLoading ? <img src={gif_loading} className={styles.loader_gif} /> : <StyledHeading6 className={styles.date_and_time}>{message.timeStamp}</StyledHeading6>}
                                        <img src={ic_user} className={styles.icon} />
                                    </div>
                                    <StyledBotMsgContainer className={styles.user_msg_content_container}>
                                        <StyledHeading7 className={styles.content}>{message.content}</StyledHeading7>
                                    </StyledBotMsgContainer>

                                </div>
                            })
                        }



                    </div>
                    {
                        currentQuestionIndex === (listOfQuestions.length) && <div className={styles.next_btn_container}>
                            <MyStyledButton_type3 onClick={handleSubmitData} className={styles.btn}>Submit</MyStyledButton_type3>
                        </div>
                    }
                    <div className={styles.text_field_container}>
                        <img src={ic_search_icon} className={styles.icon} />
                        <StyledTextField
                            variant="outlined"
                            placeholder='Message your Agent'
                            onKeyPress={handleTypedAnswer}
                        />
                    </div>
                    <StyledHeading8 className={styles.word_count}>Character count (300)</StyledHeading8>

                </StyledBotContainer>

            </div>

        </StyledMainContainer >
    );
}

const mapState = (state) => ({
    // loading: getLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(AccountSetupPage)
