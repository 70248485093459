import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Home_Shaded = ({ current_theme, ...props }) => {
    return <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.9531 12C26.9531 12.8438 26.25 13.5 25.4531 13.5H23.9531L24 21C24 21.1406 24 21.2812 24 21.375V22.125C24 23.2031 23.1562 24 22.125 24H21.375C21.2812 24 21.2344 24 21.1875 24C21.1406 24 21.0469 24 21 24H19.5H18.375C17.2969 24 16.5 23.2031 16.5 22.125V21V18C16.5 17.2031 15.7969 16.5 15 16.5H12C11.1562 16.5 10.5 17.2031 10.5 18V21V22.125C10.5 23.2031 9.65625 24 8.625 24H7.5H6C5.90625 24 5.85938 24 5.76562 24C5.71875 24 5.67188 24 5.625 24H4.875C3.79688 24 3 23.2031 3 22.125V16.875C3 16.875 3 16.8281 3 16.7812V13.5H1.5C0.65625 13.5 0 12.8438 0 12C0 11.5781 0.140625 11.2031 0.46875 10.875L12.4688 0.375C12.7969 0.046875 13.1719 0 13.5 0C13.8281 0 14.2031 0.09375 14.4844 0.328125L26.4375 10.875C26.8125 11.2031 27 11.5781 26.9531 12Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"} />
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Home_Shaded)