import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: 'app',
    initialState: { isSavingTheme: false, isUserDetailsUpdate: false, currentTheme: {}, currentThemeClone: {}, isThisValidTanent: false, route: null },
    reducers: {
        setCurrentTheme: (state, action) => {
            state.currentTheme = action.payload;
        },
        setCurrentThemeClone: (state, action) => {
            state.currentThemeClone = action.payload;
        },
        setIsThisValidTenant: (state, action) => {
            state.isThisValidTanent = action.payload;
        },
        setRoute: (state, action) => {
            state.route = action.payload;
        },
        setIsUserDetailsUpdated: (state, action) => {
            state.isUserDetailsUpdate = !state.payload;
        },
        setIsSavingTheme: (state, action) => {
            state.isSavingTheme = state.payload;
        },
    },
});

export const {
    setIsSavingTheme,
    setCurrentTheme,
    setIsThisValidTenant,
    setCurrentThemeClone,
    setRoute,
    setIsUserDetailsUpdated
} = appSlice.actions;
export default appSlice.reducer;
