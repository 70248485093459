import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';

const StyledCardContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 30px;
  }
`;

const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 20px;
  }
`;

const StyledHeading4 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 13px;
  }
`;
const StyledHeading3 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 30px;
  }
`;

const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.card_buttons};
    color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.card_buttons};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 13px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.darkest};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;
export {
    StyledCardContainer,
    StyledHeading1,
    StyledHeading2,
    StyledHeading3,
    StyledHeading4,
    MyStyledButton_type1,
    StyledDivider
}