import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        new_message_response: null,
        is_sending_msg: false,
        is_loading_messageHistory: false,
        is_attaching_documents:false,
        list_of_attached_documents_id:[]
    },
    reducers: {
        setNewMessageResponse: (state, action) => {
            state.new_message_response = action.payload;
        },
        setIsAttachingDocuments: (state, action) => { 
            state.is_attaching_documents = action.payload;
        },
        setListOfAttachedDocumentsIds: (state, action) => { 
            state.list_of_attached_documents_id = action.payload;
        },
        setIsMessageSending: (state, action) => {
            console.log(action, state)
            state.is_sending_msg = action.payload;
        },
        setIsLoadingMessageHistory: (state, action) => {
            console.log(action, state)
            state.is_loading_messageHistory = action.payload;
        },
    },
});

export const {
    setNewMessageResponse,
    setIsMessageSending,
    setIsLoadingMessageHistory,
    setIsAttachingDocuments,
    setListOfAttachedDocumentsIds
} = chatSlice.actions;
export default chatSlice.reducer;
