import ic_icon_1 from '../../../assets/images/icons/Icon 1 RGB.png'
import ic_icon_2 from '../../../assets/images/icons/Icon 2 RGB.png'
import ic_icon_3 from '../../../assets/images/icons/Icon 3 RGB.png'
import ic_icon_4 from '../../../assets/images/icons/Icon 4 RGB.png'
import ic_icon_5 from '../../../assets/images/icons/Icon 5 RGB.png'
import ic_icon_6 from '../../../assets/images/icons/Icon 6 RGB.png'
import ic_icon_7 from '../../../assets/images/icons/Icon 7 RGB.png'
import ic_icon_8 from '../../../assets/images/icons/Icon 8 RGB.png'
import ic_gif from '../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif'
import { useEffect, useState } from 'react'


const ImageLoading = ({ width }) => {
    const [randomImage, setRandomImage] = useState(null);

    // Define the array of image URLs
    const images = [ic_icon_1, ic_icon_2, ic_icon_3, ic_icon_4, ic_icon_5, ic_icon_6, ic_icon_7, ic_icon_8];

    useEffect(() => {
        // Pick a random image URL once when the component mounts
        const randomIndex = Math.floor(Math.random() * images.length);
        const selectedImage = images[randomIndex];
        setRandomImage(selectedImage);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    // Render the selected image
    return randomImage ? <img src={randomImage} style={{ width: width }} alt="Random Icon" /> : null;
};


export default ImageLoading;