import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'chatscreen',
    initialState: {
        new_message_response: null,
        is_sending_msg: false,
        is_loading_messageHistory: false,
        is_attaching_documents: false,
        list_of_attached_documents_id: [],

        // new values after refactoring

        current_conversation_id: null,
        current_room_name: null,
        current_selected_chatbot: null,
        current_selected_topic: null,
        current_user_message: null,
        current_server_response: null,
        shall_create_new_chat: false,
        number_of_messages_sent: 0,

        list_of_conversations: [],
        is_loading_list_of_conversations: false,
        is_failed_list_of_conversations: false,

        list_of_messages: [],
        is_loading_list_of_messages: false,
        is_failed_list_of_messages: false,

        is_chatbot_advanced_settings_open:false,
        is_qa_bot_advanced_settings_open:false,
        
        current_chat_screen_type:'-',
        make_list_of_apps_invisible_or_visible:false,

        is_attach_documents_side_bar_visible:false,
        is_attachments_uploaded:false,

        new_positive_reaction: null,
        new_negative_reaction: null,

        message_response_from_chat_api:null,
        has_sse_sent_response_since_last_message:false,

        has_chat_api_failed:false,
    },
    reducers: {

        setNewMessageResponse: (state, action) => {
            state.new_message_response = action.payload;
        },
        setMessageResponseFromChatAPI: (state, action) => {
            state.message_response_from_chat_api = action.payload;
        },
        setHasSSESentResponseSinceLastMessage: (state, action) => {
            state.has_sse_sent_response_since_last_message = action.payload;
        },
        setHasChatAPIFailed: (state, action) => {
            state.has_chat_api_failed = action.payload;
        },
        setIsAttachingDocuments: (state, action) => {
            state.is_attaching_documents = action.payload;
        },

        setListOfAttachedDocumentsIds: (state, action) => {
            state.list_of_attached_documents_id = action.payload;
        },

        setIsMessageSending: (state, action) => {
            console.log(action, state)
            state.is_sending_msg = action.payload;
        },


        setIsLoadingMessageHistory: (state, action) => {
            console.log(action, state)
            state.is_loading_messageHistory = action.payload;
        },

        // new reducers 

        setCurrentConversationId: (state, action) => {
            state.current_conversation_id = action.payload;
        },

        setCurrentRoomName: (state, action) => {
            state.current_room_name = action.payload;
        },

        setNumberOfMessagesSent: (state, action) => {
            state.number_of_messages_sent = action.payload;
        },

        setCurrentUserMessage: (state, action) => {
            state.current_user_message = action.payload;
        },
        setShallCreateNewChat: (state, action) => {
            state.shall_create_new_chat = action.payload;
        },
        setCurrentServerResponse: (state, action) => {
            state.current_server_response = action.payload;
        },

        setCurrentSelectedTopic: (state, action) => {
            state.current_selected_topic = action.payload;
        },

        setCurrentSelectedChatbot: (state, action) => {
            state.current_selected_chatbot = action.payload;
        },

        setListOfConversations: (state, action) => {
            state.list_of_conversations = action.payload;
        },

        setIsLoadingListOfConversations: (state, action) => {
            state.is_loading_list_of_conversations = action.payload;
        },

        setIsFailedListOfConversations: (state, action) => {
            state.is_failed_list_of_conversations = action.payload;
        },


        setListOfMessages: (state, action) => {
            state.list_of_messages = action.payload;
        },

        setIsLoadingListOfMessages: (state, action) => {
            state.is_loading_list_of_messages = action.payload;
        },

        setIsFailedListOfMessages: (state, action) => {
            state.is_failed_list_of_messages = action.payload;
        },

        addNewMessage: (state, action) => {
            state.list_of_messages.push(action.payload);
        },

        setIsChatbotAdvancedSettingsOpen: (state, action) => {
            state.is_chatbot_advanced_settings_open = action.payload;
        },

        setIsQAbotAdvancedSettingsOpen: (state, action) => {
            state.is_qa_bot_advanced_settings_open = action.payload;
        },

        setCurrentChatScreenType: (state, action) => {
            state.current_chat_screen_type = action.payload;
        },

        setListOfAppsVisibleOrInvisible: (state, action) => {
            state.make_list_of_apps_invisible_or_visible = action.payload;
        },
        setIsAttachFilesSideBarVisible: (state, action) => {
            state.is_attach_documents_side_bar_visible = action.payload;
        },
        setIsAttachmentsUploaded: (state, action) => {
            state.is_attachments_uploaded = action.payload;
        },
        setPositiveReaction: (state, action) =>{
            state.new_positive_reaction = action.payload
        },
        setNegativeReaction: (state, action) =>{
            state.new_negative_reaction = action.payload
        }
        
    },
});

export const {
    setNewMessageResponse,
    setIsMessageSending,
    setIsLoadingMessageHistory,
    setIsAttachingDocuments,
    setListOfAttachedDocumentsIds,

    // methods after refactoring 
    setCurrentConversationId,
    setCurrentRoomName,
    setCurrentSelectedChatbot,
    setCurrentSelectedTopic,
    setCurrentUserMessage,
    setCurrentServerResponse,
    setShallCreateNewChat,
    setNumberOfMessagesSent,
    setListOfConversations,
    setIsLoadingListOfConversations,
    setIsFailedListOfConversations,
    setListOfMessages,
    setIsLoadingListOfMessages,
    setIsFailedListOfMessages,
    addNewMessage,
    setIsChatbotAdvancedSettingsOpen,
    setIsQAbotAdvancedSettingsOpen,
    setCurrentChatScreenType,
    setListOfAppsVisibleOrInvisible,
    setIsAttachFilesSideBarVisible,
    setIsAttachmentsUploaded,
    setPositiveReaction,
    setNegativeReaction,
    setHasSSESentResponseSinceLastMessage,
    setMessageResponseFromChatAPI,
    setHasChatAPIFailed
} = chatSlice.actions;
export default chatSlice.reducer;
