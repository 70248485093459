
import { useEffect, useState } from 'react';
import Ic_Image_Attachment_Shaded from '../../../editable_icons/Ic_Image_Attachment_Shaded';
import { StyledContainer, StyledDocumentListItem, StyledTextareaForMessage } from './styled';
import styles from './styles.module.scss'
import Ic_Mic_Attachment_Shaded from '../../../editable_icons/Ic_Mic_Attachment_Shaded';
import Ic_Files_Attachment_Shaded from '../../../editable_icons/Ic_Files_Attachment_Shaded';
import { getCurrentChatScreenType, getCurrentConversationId, getCurrentCRoomName, getCurrentSelectedChatbot, getCurrentSelectedTopic, getListOfAttachedDocumentsId, getNumberOfMessagesSent } from '../../logic/selectors';
import { connect } from "react-redux";
import { addNewMessage, setCurrentSelectedTopic, setCurrentUserMessage, setIsAttachFilesSideBarVisible, setListOfAttachedDocumentsIds, setNumberOfMessagesSent } from '../../logic/slice';
import { getUserDetails, showToastMessage } from '../../../../../modules/app/logic/actions';
import { generateNewConversationId, sendChatMessage, sendQuestionMessage } from '../../logic/actions';
import File_uploader from '../../../file_uploader';
import { getIsDocumentUploaded, getListOfDocumentsToUpload } from '../../../../../modules/dashboard/logic/library/selectors';
import { CustomPagination } from '../../../pagination';
import { CustomHeading } from '../../../headings';
import { formatFileSize } from '../../../../utils';
import { setListOfDocumentsToUpload } from '../../../../../modules/dashboard/logic/library/slice';

// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
const generateRandomId = () => {
    return Math.random().toString(36).substring(7); // Generates a random alphanumeric ID
}
const BottomControls = ({
    // Selectors
    current_selected_topic,
    current_conversation_id,
    current_room_name,
    current_selected_chatbot,
    number_of_messages_sent,
    current_chat_screen_type,
    list_of_uploaded_document_ids,
    is_documents_uploaded,
    list_of_documents_to_upload,
    // Reducers
    addNewMessage,
    getUserDetails,
    generateNewConversationId,

    setCurrentUserMessage,
    sendChatMessage,
    setCurrentSelectedTopic,
    setNumberOfMessagesSent,
    sendQuestionMessage,
    setIsAttachFilesSideBarVisible,
    setListOfDocumentsToUpload,
    setListOfAttachedDocumentsIds,
    ...props }) => {

    const [messageToSend, setMessageToSend] = useState(null)
    const [openAttachDocuments, setOpenAttachDocuments] = useState(false)
    const [currentUser, setCurrentUser] = useState("Default_User")
    const [isUploadDocumentsOpen, setIsUploadDocumentsOpen] = useState(false)
    const documentsPerPage = 9; // Number of documents per page
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState('')



    useEffect(() => {
        let storedUser = localStorage.getItem("user");
        if (storedUser != undefined) {
            setCurrentUser(JSON.parse(storedUser))
        } else {
            getUserDetails({})
        }
    }, [])

    useEffect(() => {
        if (list_of_uploaded_document_ids.length > 0) {
            setIsUploadDocumentsOpen(false)
        }
    }, [list_of_uploaded_document_ids, list_of_documents_to_upload])

    useEffect(() => {
        if (current_selected_topic != null) {
            if ((current_conversation_id != null && current_room_name != null)) {
                sendMessage(current_selected_topic, current_conversation_id)
                setCurrentSelectedTopic(null)
            } else {
                generateNewConversationId({ config_id: current_selected_chatbot?.id }).then((data) => {
                    sendMessage(current_selected_topic, data?.id)
                }).catch((error) => {
                    setError(error)
                    showToastMessage({type:'error', message:'Unable to load conversation'})
                })
            }
        }
    }, [current_selected_topic])

    const handleTypedAnswer = (event) => {
        if (event.shiftKey && event.key === 'Enter') {

        }
        else if (event.key === 'Enter') {
            const inputValue = event.target.value;
            setMessageToSend('')
            if (current_conversation_id != null && current_room_name != null) {
                sendMessage(inputValue, current_conversation_id)
            } else {
                generateNewConversationId({ config_id: current_selected_chatbot?.id }).then((data) => {
                    sendMessage(inputValue, data?.id)
                }).catch((error) => {
                    setError(error)
                })
            }
        }
    }

    const sendMessage = (inputValue, conv_id = null) => {
        setNumberOfMessagesSent(number_of_messages_sent + 1)
        if (list_of_uploaded_document_ids?.length === 0) {
            addNewMessage({
                id: generateRandomId(),
                message: inputValue,
                message_sender: 'user',
                message_created_at: new Date().toLocaleString('en-US', options),
                message_sender_user_name: currentUser?.first_name,
                message_type: 'text'
            })
        } else {
            addNewMessage({
                id: generateRandomId(),
                message: inputValue,
                message_sender: 'user',
                message_created_at: new Date().toLocaleString('en-US', options),
                message_sender_user_name: currentUser?.first_name,
                message_type: 'file',
                list_of_file_attachments: list_of_documents_to_upload,
            })
        }
        if (current_chat_screen_type === "chat_screen" || current_chat_screen_type === "co-pilot") {
            sendChatMessage({
                conversation_id: current_conversation_id === null ? conv_id + "" : current_conversation_id + "",
                message: inputValue,
                config_id: current_selected_chatbot?.id,
                agent_type: current_selected_chatbot?.agent_type,
                prompt_intro: "",
                document_ids: list_of_uploaded_document_ids?.length > 0 ? list_of_uploaded_document_ids.map((doc) => doc?.document_id) : []
            })
        } else if (current_chat_screen_type === "play_ground") {
            sendQuestionMessage({
                conversation_id: current_conversation_id === null ? conv_id + "" : current_conversation_id + "",
                prompt_intro: "",
                message: inputValue,
                agent_type: current_selected_chatbot?.agent_type,
                config_id: current_selected_chatbot?.id,
                document_ids: list_of_uploaded_document_ids?.length > 0 ? list_of_uploaded_document_ids.map((doc) => doc?.document_id) : []
            })
        }
        console.log("setCurrentUserMessage(inputValue)",inputValue)
        setCurrentUserMessage(inputValue)
        setListOfDocumentsToUpload([])
        setListOfAttachedDocumentsIds([])
    }

    const handleKeyDown = (e) => {
        if (e.target.scrollWidth > e.target.offsetWidth) {
            // If the width exceeds, insert a newline character ('\n') at the cursor position
            const cursorPos = e.target.selectionStart;
            const newValue = e.target.value.substring(0, cursorPos) + '\n' + e.target.value.substring(cursorPos);
            // Update the value of the input field
            e.target.value = newValue;
        }
    }

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    return <div className={styles.container}>
        {
            isUploadDocumentsOpen && <div className={styles.files_uploader_container}>
                <File_uploader setClose={() => {
                    setIsUploadDocumentsOpen(false)
                }} />
            </div>
        }
        {
            is_documents_uploaded && list_of_uploaded_document_ids?.length > 0 && <div>
                {
                    list_of_documents_to_upload?.length > 0 && <StyledContainer className={styles.documents_list}>
                        {list_of_documents_to_upload.map((doc) => (
                            <StyledDocumentListItem key={doc.id}>
                                <div className={styles.documents_list_item}>
                                    {/* <div className={styles.checkbox_container}>
                                        <input type="checkbox" />
                                    </div> */}
                                    <div className={styles.file_name_container}>
                                        <CustomHeading text={doc.filename} />
                                        <CustomHeading text={formatFileSize(doc.file_size)} />
                                    </div>
                                    {/* <div className={styles.cross_icon_container} onClick={() => removeDocument(doc.id)}>
                                        <img src={ic_remove_doc} alt="Remove" />
                                    </div> */}
                                </div>
                            </StyledDocumentListItem>
                        ))}
                    </StyledContainer>
                }
                {
                    list_of_documents_to_upload?.length > 0 && <StyledContainer className={styles.pagination_container}>
                        <CustomPagination
                            count={Math.ceil(list_of_documents_to_upload?.length / documentsPerPage)} // Calculate total pages
                            variant="outlined"
                            shape="rounded"
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </StyledContainer>
                }
            </div>
        }

        <div className={styles.controls_container}>
            <div className={styles.message_field_container}>
                <StyledTextareaForMessage
                    value={messageToSend}
                    onChange={(e) => setMessageToSend(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    maxRows={6}
                    variant="outlined"
                    placeholder='Message your Agent'
                    onKeyPress={handleTypedAnswer}
                    onKeyDown={handleKeyDown}
                />
            </div>
            {
                current_chat_screen_type !== 'play_ground' && <div className={styles.image_field_container} onClick={() => {

                    if (current_chat_screen_type !== 'co-pilot') {
                        setIsUploadDocumentsOpen(true)
                    } else if (current_chat_screen_type === 'co-pilot') {
                        setIsAttachFilesSideBarVisible(true)
                    }
                }}>
                    <Ic_Image_Attachment_Shaded />
                </div>
            }

            <div className={styles.voice_field_container}>
                <Ic_Mic_Attachment_Shaded />
            </div>
            <div className={styles.files_field_container} onClick={() => {
                if (current_conversation_id != null && current_room_name != null) {
                    setMessageToSend('')
                    sendMessage(messageToSend)
                } else {

                }}}>
                <div className={styles.icon}>
                    <Ic_Files_Attachment_Shaded />
                </div>
            </div>
        </div>
    </div>
};


const mapState = (state) => ({
    current_conversation_id: getCurrentConversationId(state),
    current_room_name: getCurrentCRoomName(state),
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    current_selected_topic: getCurrentSelectedTopic(state),
    number_of_messages_sent: getNumberOfMessagesSent(state),
    current_chat_screen_type: getCurrentChatScreenType(state),
    list_of_uploaded_document_ids: getListOfAttachedDocumentsId(state),
    is_documents_uploaded: getIsDocumentUploaded(state),
    list_of_documents_to_upload: getListOfDocumentsToUpload(state),
})

const mapDispatchToProps = (dispatch) => ({
    addNewMessage: (data) => dispatch(addNewMessage(data)),
    getUserDetails: (data) => dispatch(getUserDetails(data)),
    generateNewConversationId: (data) => dispatch(generateNewConversationId(data)),
    setCurrentUserMessage: (data) => dispatch(setCurrentUserMessage(data)),
    sendChatMessage: (data) => dispatch(sendChatMessage(data)),
    setCurrentSelectedTopic: (data) => dispatch(setCurrentSelectedTopic(data)),
    setNumberOfMessagesSent: (data) => dispatch(setNumberOfMessagesSent(data)),
    sendQuestionMessage: (data) => dispatch(sendQuestionMessage(data)),
    setIsAttachFilesSideBarVisible: (data) => dispatch(setIsAttachFilesSideBarVisible(data)),
    setListOfDocumentsToUpload: (data) => dispatch(setListOfDocumentsToUpload(data)),
    setListOfAttachedDocumentsIds: (data) => dispatch(setListOfAttachedDocumentsIds(data)),
});

export default connect(mapState, mapDispatchToProps)(BottomControls)