import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Remove_Tag_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.15234 6.40234C6.46875 6.08594 6.99609 6.08594 7.3125 6.40234L8.96484 8.08984L10.6523 6.40234C10.9688 6.08594 11.4961 6.08594 11.8125 6.40234C12.1641 6.75391 12.1641 7.28125 11.8125 7.59766L10.1602 9.25L11.8125 10.9023C12.1641 11.2539 12.1641 11.7812 11.8125 12.0977C11.4961 12.4492 10.9688 12.4492 10.6523 12.0977L8.96484 10.4453L7.3125 12.0977C6.99609 12.4492 6.46875 12.4492 6.15234 12.0977C5.80078 11.7812 5.80078 11.2539 6.15234 10.9023L7.80469 9.25L6.15234 7.59766C5.80078 7.28125 5.80078 6.75391 6.15234 6.40234ZM18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25C13.957 0.25 18 4.29297 18 9.25ZM9 1.9375C4.95703 1.9375 1.6875 5.24219 1.6875 9.25C1.6875 13.293 4.95703 16.5625 9 16.5625C13.0078 16.5625 16.3125 13.293 16.3125 9.25C16.3125 5.24219 13.0078 1.9375 9 1.9375Z" fill="#EC950A"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Remove_Tag_Shaded)