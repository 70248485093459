import { createSlice, current } from '@reduxjs/toolkit';

const librarySlice = createSlice({
    name: 'library',
    initialState: {
        list_of_documents: [],
        is_loading_list_of_documents: false,
        list_of_libraries: [],
        is_loading_list_of_libraries:false,
        list_of_chunking_methods: [],
        is_loading_list_of_chunking_methods:false,
        is_creating_library_loading:false,
        is_uploading_document:false,
        is_documents_uploaded:false,
        is_deleting_document:false,
        is_library_deleted:false,
        is_sorted_list_of_documents:false,
        is_sorted_list_of_libraries:false,
        is_loading_list_of_separators:false,
        list_of_separators: [],
        is_documents_list_visible:false,
        current_selected_library:null,

        // new hooks 
        list_of_documents_to_upload:[],
        is_library_created:false,
    },
    reducers: {
        setListOfDocuments: (state, action) => {
            state.list_of_documents = action.payload;
        },
        setIsListOfDocumentsVisible: (state, action) => {
            state.is_documents_list_visible = action.payload;
        },
        setCurrentSelectedLibrary: (state, action) => {
            state.current_selected_library = action.payload;
        },
        setListOfLibraries: (state, action) => {
            state.list_of_libraries = action.payload;
        },
        setIsLibraryDeleted: (state, action) => {
            state.is_library_deleted = action.payload;
        },
        setListOfChunkingMethods: (state, action) => {
            state.list_of_chunking_methods = action.payload;
        },
        setIsLoadingListOfDocuments: (state, action) => {
            state.is_loading_list_of_documents = action.payload;
        },
        setIsLoadingListOfLibraries: (state, action) => {
            state.is_loading_list_of_libraries = action.payload;
        },
        setIsLoadingListOfChunkingMethods: (state, action) => {
            state.is_loading_list_of_chunking_methods = action.payload;
        },
        setIsLoadingCreatingLibrary: (state, action) => {
            state.is_creating_library_loading = action.payload;
        },
        setIsUploadingDocument: (state, action) => {
            state.is_uploading_document = action.payload;
        },
        setIsDocumentsUploaded: (state, action) => {
            state.is_documents_uploaded = action.payload;
        },
        setIsDocumentsDeleting: (state, action) => {
            state.is_deleting_document = action.payload;
        },
        setIsDocumentsSorted: (state, action) => {
            state.is_sorted_list_of_documents = action.payload;
        },
        setIsLibrariesSorted: (state, action) => {
            state.is_sorted_list_of_libraries = action.payload;
        },
        setIsLoadingListOfSeparators: (state, action) => {
            state.is_loading_list_of_separators = action.payload;
        },
        setListOfSeparators: (state, action) => {
            state.list_of_separators = action.payload
        },


        // new
        setListOfDocumentsToUpload: (state, action) => {
            console.log("setListOfDocumentsToUpload",action.payload)
            state.list_of_documents_to_upload = action.payload;
        },

        setIsLibraryCreated: (state, action) => {
            state.is_library_created = action.payload;
        },
    },
});

export const {
    setListOfDocuments,
    setIsLoadingListOfDocuments,
    setListOfLibraries,
    setIsLoadingListOfLibraries,
    setListOfChunkingMethods,
    setIsLoadingListOfChunkingMethods,
    setIsLoadingCreatingLibrary,
    setIsUploadingDocument,
    setIsDocumentsUploaded,
    setIsDocumentsDeleting,
    setIsLibraryDeleted,
    setIsLoadingListOfSeparators,
    setListOfSeparators,
    setIsListOfDocumentsVisible,
    setCurrentSelectedLibrary,

    // new 
    setListOfDocumentsToUpload,
    setIsLibraryCreated,
} = librarySlice.actions;
export default librarySlice.reducer;
