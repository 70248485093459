
import styles from './styles.module.scss'
import { StyledAgentMainContainer, StyledSlider, StyledTextarea } from './styled';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { connect } from "react-redux";
import { _listOfModels, _listOfEmbeddingModels, _listOfGenAIModels, _listOfOptions, _listOfSummarizationModels, _listOfTones } from './data';
import { getUserDetails, showToastMessage } from '../../../../app/logic/actions';
import { createChatbotAgentConfig, fetchChatbotAgent, updateAgentConfig, updateAgentConfigForTest } from '../../../logic/agents/actions';
import { getCurrentTheme, isUserDetailsUpdated } from '../../../../app/logic/selectors';
import Ic_X_Un_Shaded from '../../../../../shared/ui/editable_icons/Ic_X_Un_Shaded';
import ic_delete_file from '../../../../../assets/images/ic_delete_file.svg'
import ic_red_gear_icon from '../../../../../assets/images/red_settings_gear_icon.svg'
import { setIsConfigCreated } from '../../../logic/agents/slice';
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomLabel } from '../../../../../shared/ui/label';
import CustomTextField from '../../../../../shared/ui/text_field';
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { TYPE_4, TYPE_5, TYPE_6 } from '../../../../../shared/ui/models_dropdowns/models_types';
import General_dropdowns from '../../../../../shared/ui/general_dropdowns';
import { TYPE_2, TYPE_3, TYPE_4 as TYPE_4_multi_select_dropdown } from '../../../../../shared/ui/general_dropdowns/general_types';
import { CustomButton } from '../../../../../shared/ui/buttons';
import { TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types';
import { CustomSwitch } from '../../../../../shared/ui/switch';
import { getCurrentChatScreenType } from '../../../../../shared/ui/chatscreen/logic/selectors';
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const ChatBotAgent = ({
    setIsAgentConfigScreenOpen,
    showToastMessage,
    saveAgentConfig,
    agent_config_to_edit,
    setCurrentAgentToEdit,
    updateAgentConfig,
    agent_config_to_test,

    current_theme,
    getUserDetails,
    isUserDetailsUpdated,
    fetchChatbotAgent,

    setIsConfigCreated,
    updateAgentConfigForTest,
    setAgentConfigToTest,
    setOpenSideBar,
    current_chat_screen_type,
    is_agent_created_or_updated,

    ...props }) => {


    const [isTesting, setIsTesting] = useState(false);
    const [currentCreativity, setCurrentCreativity] = useState(0.7)

    const [listOfTones, setListOfTones] = useState(_listOfTones)
    const [listOfOptions, setListOfOptions] = useState(_listOfOptions)

    const [prompt, setPrompt] = useState(
        `- Answer the users question, if the user is greeting you, greet them back. 
- You are an customer service chatbot for a company called Clairo. 
- Use only the information below, but act as if you are a friendly chatbot. If the answer cannot be found in the documents, explicitly tell the user you cannot answer the question. 
        `
    );

    const [description, setDescription] = useState('')
    const [agentName, setAgentName] = useState('');

    const [tone, setTone] = useState(_listOfTones[0]);
    const [toneValue, setToneValue] = useState('funny');

    const [instruction, setInstruction] = useState([{ id: 'instruction', value: 'instruction' }]);
    const [instructionValue, setInstructionValue] = useState([{ id: 'instruction', value: 'instruction' }])

    const [currentSelectedLibrary, setCurrentSelectedLibrary] = useState([]);
    const [currentSelectedLibraryValues, setCurrentSelectedLibraryValues] = useState([]);

    const [isToneEnabled, setIsToneEnabled] = useState(false);
    const [isInstructionEnabled, setIsInstructionEnabled] = useState(false);
    const [isConversationSummaryEnabled, setIsConversationSummaryEnabled] = useState(false)
    const [isDocumentRetrievalEnabled, setIsDocumentRetrievalEnabled] = useState(false)
    const [isGenerateSearchQueryEnabled, setIsGenerateSearchQueryEnabled] = useState(false)

    const [tokenLimit, setTokenLimit] = useState('2000');
    const [agentResponse, setAgentResponse] = useState('800');
    const [searchQueryLength, setSearchQueryLength] = useState('100');
    const [conversationSummaryLength, setConversationSummaryLength] = useState('500');
    const [maxDocumentRetrieved, setMaxDocumentRetrieved] = useState('30');
    const [docRetrievalRelevance, setDocRetrievalRelevance] = useState('0.7');

    const [genAICompletion, setGenAICompletion] = useState('500');
    const [dynamicPromptCompletion, setDynamicPromptCompletion] = useState('200');
    const [summaryCompletion, setSummaryCompletion] = useState('200');

    const [documentLimit, setDocumentLimit] = useState('30');
    const [similarityThreshold, setSimilarityThreshold] = useState('0.7');
    const [listOfTopicsToInput, setListOfTopicsToInput] = useState([
        { id: 1, topic: '', placeholder: 'Enter topic 1' }
    ])

    const [openAdvancedConfig, setOpenAdvancedConfig] = useState(false)
    const [convoEnabled, setConvoEnabled] = useState(false)

    const [currentSelectedSummarizationModel, setCurrentSelectedSummarizationModel] = useState(null);
    const [currentSelectedSearchQueryModel, setCurrentSelectedSearchQueryModel] = useState(null);
    const [currentSelectedChatbotModel, setCurrentSelectedChatbotModel] = useState(null);

    const [currentSelectedSummarizationModelValue, setCurrentSelectedSummarizationModelValue] = useState(null);
    const [currentSelectedSearchQueryModelValue, setCurrentSelectedSearchQueryModelValue] = useState(null);
    const [currentSelectedChatbotModelValue, setCurrentSelectedChatbotModelValue] = useState(null);
    const [currentSelectedChatbotModelLocalValue, setCurrentSelectedChatbotModelLocalValue] = useState(null);

    const [llmTypeForSummarizationModel, setLLMTypeForSummarizationModel] = useState('PublicLLM');
    const [llmTypeForChatbotModel, setLLMTypeForChatbotModel] = useState('PublicLLM');
    const [llmTypeForSearchQueryModel, setLLMTypeForSearchQueryModel] = useState('PublicLLM');

    const [confirmCancel, setConfirmCancel] = useState(false)

    useEffect(() => {
        if (is_agent_created_or_updated) {
            setCurrentAgentToEdit(null)
            setOpenSideBar(false);
            setIsConfigCreated(false)
        }
    }, [is_agent_created_or_updated])

    const handleSwitchChange = (event) => {
        setIsConversationSummaryEnabled(event.target.checked)
    }

    const handleGenSearchSwitchChange = (event) => {
        setIsGenerateSearchQueryEnabled(event.target.checked)
    }

    useEffect(()=>{
        console.log('isGenerateSearchQueryEnabled',isGenerateSearchQueryEnabled)
    },[isGenerateSearchQueryEnabled])

    const resetValues = () => {
        setIsTesting(false);
        setCurrentCreativity(0.7);
        setListOfTones([]);
    //     setPrompt(`- Answer the users question, if the user is greeting you, greet them back. 
    // - You are an customer service chatbot for a company called Clairo. 
    // - Use only the information below, but act as if you are a friendly chatbot. If the answer cannot be found in the documents, explicitly tell the user you cannot answer the question. 
    //     `);
    //     setDescription('');
    //     setAgentName('');
        // setTone([]);
        setToneValue('funny');
        setInstruction([{ id: 'instruction', value: 'instruction' }]);
        setInstructionValue([{ id: 'instruction', value: 'instruction' }]);
        setCurrentSelectedLibrary([]);
        setCurrentSelectedLibraryValues([]);
        setTokenLimit('2000');
        setAgentResponse('800');
        setSearchQueryLength('100');
        setConversationSummaryLength('500');
        setMaxDocumentRetrieved('30');
        setDocRetrievalRelevance('0.7');
        setGenAICompletion('500');
        setDynamicPromptCompletion('200');
        setSummaryCompletion('200');
        setDocumentLimit('30');
        setSimilarityThreshold('0.7');
        setListOfTopicsToInput([{ id: 1, topic: '', placeholder: 'Enter topic 1' }]);
      
        setConvoEnabled(false);
    };


    useEffect(()=>{
        if(currentSelectedChatbotModelLocalValue){
            setCurrentSelectedChatbotModelValue(currentSelectedChatbotModelLocalValue)
        }
    },[currentSelectedChatbotModelLocalValue])

    useEffect(() => {
        if (agent_config_to_edit !== null && agent_config_to_edit !== undefined) {
            const creativityValue = parseFloat(agent_config_to_edit.creativity);
            console.log("agent_config_to_edit-for chat-bot ", agent_config_to_edit)
            if (!isNaN(creativityValue)) {
                setCurrentCreativity(creativityValue);
            } else {
                setCurrentCreativity(0.8);
            }

            const librariesForDropdown = agent_config_to_edit?.library?.map(lib => ({
                value: lib.id.toString(),
                label: lib.name
            }));

            let instructionsForDropdown = agent_config_to_edit?.actions?.map(instr => {
                let keyObject = listOfOptions.find((opt) => opt.value === instr);
                if (keyObject != undefined) {
                    return {
                        id: keyObject.value,
                        value: keyObject.value,
                        label: keyObject.label
                    }

                }
            }).filter((val) => val != undefined)

            let listOfTopicsToInputRender = agent_config_to_edit?.topics?.map((q, index) => ({
                id: index,
                topic: q.replace(/'/g, ''),
                placeholder: 'Enter topic '
            }));

            let type_selected_for_summarization_model = agent_config_to_edit?.summarizationModel?.llm_type
            setLLMTypeForSummarizationModel((type_selected_for_summarization_model === undefined || type_selected_for_summarization_model === null) ? 'PublicLLM' : type_selected_for_summarization_model)
            let model_selected_for_summarization = agent_config_to_edit?.summarizationModel?.value
            setCurrentSelectedSummarizationModelValue(model_selected_for_summarization);
            setCurrentSelectedSummarizationModel(agent_config_to_edit?.summarizationModel)

            let type_selected_for_search_query_model = agent_config_to_edit?.search_query_model?.llm_type
            setLLMTypeForSearchQueryModel((type_selected_for_search_query_model === undefined || type_selected_for_search_query_model === null) ? 'PublicLLM' : type_selected_for_search_query_model)
            let model_selected_for_search_query = agent_config_to_edit?.search_query_model?.value
            setCurrentSelectedSearchQueryModelValue(model_selected_for_search_query);
            setCurrentSelectedSearchQueryModel(agent_config_to_edit?.search_query_model)
            let type_selected_for_chat_bot_model = agent_config_to_edit?.chat_model?.llm_type

            setLLMTypeForChatbotModel(type_selected_for_chat_bot_model)

            let model_selected_for_chat_bot = agent_config_to_edit?.chat_model?.value

            setCurrentSelectedChatbotModelLocalValue(model_selected_for_chat_bot);

            setIsTesting(agent_config_to_edit.is_test)
            setPrompt(agent_config_to_edit.prompt || '');
            setAgentName(agent_config_to_edit.name || '');
            setDescription(agent_config_to_edit.description || 'Default');
            setCurrentSelectedLibrary(librariesForDropdown);
            setCurrentSelectedLibraryValues(librariesForDropdown)
            setToneValue(agent_config_to_edit?.tone);
            setInstructionValue(instructionsForDropdown)
            setInstruction(instructionsForDropdown)
            setIsToneEnabled(agent_config_to_edit.isToneActive || false);
            setIsInstructionEnabled(agent_config_to_edit.isActionActive || false);
            setIsDocumentRetrievalEnabled(agent_config_to_edit.documentRetrieval || false);
            
            setIsConversationSummaryEnabled(agent_config_to_edit?.conversationSummarization);
            setIsGenerateSearchQueryEnabled(agent_config_to_edit?.generativeSearchQuery);

            setTokenLimit(agent_config_to_edit.tokenLimit?.toString() || '2000');
            setAgentResponse(agent_config_to_edit.genAICompletion?.toString() || '500');
            setSearchQueryLength(agent_config_to_edit.dynamicPromptCompletion?.toString() || '256');
            setConversationSummaryLength(agent_config_to_edit.summaryCompletion?.toString() || '500');
            setMaxDocumentRetrieved(agent_config_to_edit.documentLimit?.toString() || '280');
            setDocRetrievalRelevance(agent_config_to_edit.similarityThreshold?.toString() || '0.7');
            setListOfTopicsToInput(listOfTopicsToInputRender)
        } else {
            resetValues()
        }
    }, [agent_config_to_edit,openAdvancedConfig]);

    const handleCreateAgentConfig = (is_test) => {
        const fields = [
            { name: 'prompt', value: prompt, message: "Cannot leave prompt field empty" },
            { name: 'instruction', value: instruction, message: "Cannot leave instruction field empty" },
            { name: 'agentName', value: agentName, message: "Cannot leave agent name field empty" },
            { name: 'description', value: description, message: "Cannot leave description field empty" },
            { name: 'tokenLimit', value: tokenLimit, message: "Cannot leave token limit field empty" },
            { name: 'genAICompletion', value: genAICompletion, message: "Cannot leave generative AI completion field empty" },
            { name: 'dynamicPromptCompletion', value: dynamicPromptCompletion, message: "Cannot leave dynamic prompt completion field empty" },
            { name: 'summaryCompletion', value: summaryCompletion, message: "Cannot leave summary completion field empty" },
            { name: 'documentLimit', value: documentLimit, message: "Cannot leave document field empty" },
            { name: 'similarityThreshold', value: similarityThreshold, message: "Cannot leave similarity field empty" }
        ];
        for (const field of fields) {
            if (field.value == null || field.value.length === 0) {
                showToastMessage({ type: 'error', message: field.message });
                return;
            }
        }
        if (/\s/.test(agentName)) {
            showToastMessage({ type: 'error', message: "Agent name cannot have spaces between words" });
            return;
        }

        let config = {
            ...(agent_config_to_edit != null && { id: agent_config_to_edit.id }),
            prompt: prompt,
            is_tone_active: isToneEnabled,
            tone: toneValue,
            description: description,
            is_action_active: isInstructionEnabled,
            actions: instruction.map((i) => i.value),
            topics: listOfTopicsToInput?.map((t) => t.topic),
            creativity: currentCreativity + "",
            agent_name: agentName,
            chat_model: currentSelectedChatbotModel?.id,
            summarization_model: currentSelectedSummarizationModel?.id,
            search_query_model: currentSelectedSearchQueryModel?.id,
            document_retrieval: isDocumentRetrievalEnabled,
            conversation_summarization: isConversationSummaryEnabled,
            generative_search_query: isGenerateSearchQueryEnabled,
            token_limit: parseInt(tokenLimit, 10),
            chat_completion: parseInt(agentResponse, 10),
            search_query_completion: parseInt(searchQueryLength, 10),
            summary_completion: parseInt(conversationSummaryLength, 10),
            document_limit: parseInt(maxDocumentRetrieved, 10) + "",
            similarity_threshold: parseFloat(docRetrievalRelevance) + "",
            library: currentSelectedLibrary.map((lib) => parseInt(lib.value)),
            is_test: is_test,
        };
        saveAgentConfig(config);
    };


    const handleUpdateConfiguration = (is_testing) => {

        let model_selected_for_summarization = agent_config_to_edit?.summarizationModel
        let model_selected_for_search_query = agent_config_to_edit?.search_query_model

        let payload = {
            ...(agent_config_to_edit != null && { id: agent_config_to_edit.id }),
            pro_id: agent_config_to_edit?.id,
            prompt: prompt!==null ? prompt : agent_config_to_edit?.prompt,
            is_tone_active: isToneEnabled,
            tone: toneValue,
            is_action_active: isInstructionEnabled,
            actions: instruction.map((i) => i.value),
            topics: agent_config_to_edit?.topics,
            creativity: agent_config_to_edit?.creativity,
            agent_name: agentName!==null ? agentName : agent_config_to_edit?.name,
            description: agent_config_to_edit?.description,
            chat_model: currentSelectedChatbotModel?.id,
            summarization_model: currentSelectedSummarizationModel !== null ? currentSelectedSummarizationModel?.id : model_selected_for_summarization?.id,
            search_query_model: currentSelectedSearchQueryModel !== null ? currentSelectedSearchQueryModel?.id : model_selected_for_search_query?.id,
            document_retrieval: isDocumentRetrievalEnabled,
            conversation_summarization: isConversationSummaryEnabled,
            generative_search_query: isGenerateSearchQueryEnabled,
            token_limit:tokenLimit,
            chat_completion: agentResponse,
            search_query_completion: searchQueryLength,
            summary_completion: conversationSummaryLength,
            document_limit: maxDocumentRetrieved,
            similarity_threshold: docRetrievalRelevance,
            is_test: agent_config_to_edit?.is_test,
            library: currentSelectedLibrary.map((lib) => parseInt(lib.value)),
        }
        if (!is_testing)
            updateAgentConfig(payload)
        else
            updateAgentConfigForTest(payload)
    }

    const handleAddNewTopic = () => {
        const newTopicId = listOfTopicsToInput?.length + 1;
        setListOfTopicsToInput(prevTopics => [
            ...prevTopics,
            { id: newTopicId, topic: '', placeholder: `Enter topic ${newTopicId}` }
        ]);
    };

    const handleChangeTopic = (id, value) => {
        setListOfTopicsToInput(prevTopics => {
            const updatedTopics = prevTopics.map(topic => {
                if (topic.id === id) {
                    return { ...topic, topic: value };
                }
                return topic;
            });
            return updatedTopics;
        });
    };

    const handleRemoveTopic = (id) => {
        setListOfTopicsToInput(prevTopics => prevTopics.filter(topic => topic.id !== id));
    };

    const handleModelDropdownClickForChatbotModel = (selected_chat_bot_model, llm_type) => {
        setCurrentSelectedChatbotModel(selected_chat_bot_model)
        console.log('handleModelDropdownClickForChatbotModel',selected_chat_bot_model?.value)
        setCurrentSelectedChatbotModelValue(selected_chat_bot_model?.value)
        setLLMTypeForChatbotModel(llm_type)
    }

    const handleModelDropdownClickForSummarizationModel = (selected_summarization_bot_model, llm_type) => {
        setCurrentSelectedSummarizationModel(selected_summarization_bot_model)
        setCurrentSelectedSummarizationModelValue(selected_summarization_bot_model?.value)
        setLLMTypeForSummarizationModel(llm_type)
    }

    const handleModelDropdownClickForSearchQueryModel = (selected_search_query_bot_model, llm_type) => {
        setCurrentSelectedSearchQueryModel(selected_search_query_bot_model)
        setCurrentSelectedSearchQueryModelValue(selected_search_query_bot_model?.value)
        setLLMTypeForSearchQueryModel(llm_type)
    }

    const handleGeneralMultiSelectDropdownForLibraries = (selected_library_values) => {
        setCurrentSelectedLibraryValues(selected_library_values)
        setCurrentSelectedLibrary(selected_library_values)
    }

    const handleGeneralDropdownForTone = (selected_tone_value) => {
        setTone(selected_tone_value)
        setToneValue(selected_tone_value?.value)
    }

    const handleGeneralMultiSelectDropdownForInstructions = (selected_instructions_values) => {
        setInstruction(selected_instructions_values)
        setInstructionValue(selected_instructions_values)
    }

    const handleConfirmCancel = () => {
        setConfirmCancel(true)
    }

    return <div className={styles.container}>
        <div className={styles.agent_config_container}>
            <div className={styles.chat_bot_main_container}>
                <StyledAgentMainContainer className={styles.body_container}>
                    <div className={styles.left_container}>
                        {openAdvancedConfig === false && current_chat_screen_type !== "chat_screen" &&
                            <div className={styles.header_container}>
                                <div className={styles.left_container}>
                                    <CustomHeading text='Agent’s Personality and Instructions'></CustomHeading>
                                </div>
                            </div>
                        }

                        {openAdvancedConfig === false &&

                            <div className={styles.body_container}>
                                {
                                    current_chat_screen_type !== "chat_screen" && <div className={styles.row_0}>
                                        <CustomLabel font_size='11' title='Agent Name'></CustomLabel>
                                        <CustomTextField
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            value={agentName} onChange={(e) => setAgentName(e.target.value)} placeholder='Enter agent name' />
                                    </div>
                                }
                                {
                                    current_chat_screen_type !== "chat_screen" && <div className={styles.row_1}>
                                        <CustomTextField value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Describe your agent' multiline='true' minRows={7} />
                                    </div>
                                }

                                <div className={styles.row_2}>
                                    <CustomTextField value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder='Agent Instructions' multiline='true' minRows={7} />
                                </div>
                                <div className={styles.row_7}>
                                    <div className={styles.additional_toggle}>
                                        <div>
                                            <p style={{ color: 'white' }}>Chatbot model</p>
                                            <CustomModelDropDown type={TYPE_4} handleOnClick={handleModelDropdownClickForChatbotModel} defaultValue={currentSelectedChatbotModelValue} defaultLLM={llmTypeForChatbotModel} />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.row_3}>
                                    <div className={styles.left_container}>
                                        <CustomSwitch checked={isDocumentRetrievalEnabled} onChange={(e) => setIsDocumentRetrievalEnabled(e.target.checked)} />
                                        <CustomLabel title='Document Retrieval'></CustomLabel>
                                    </div>
                                    <div className={styles.right_container}>
                                        <General_dropdowns type={TYPE_3} handleOnClick={handleGeneralMultiSelectDropdownForLibraries} current_theme={current_theme} defaultSelectedOptionsList={currentSelectedLibraryValues} />
                                    </div>
                                </div>
                                <div className={styles.row_4}>
                                    <div className={styles.left_container}>
                                        <CustomSwitch checked={isToneEnabled} onChange={(e) => setIsToneEnabled(e.target.checked)} />
                                        <CustomLabel title='Tone'></CustomLabel>
                                    </div>
                                    <div className={styles.right_container}>
                                        <General_dropdowns type={TYPE_2} handleOnClick={handleGeneralDropdownForTone} defaultValue={toneValue} />
                                    </div>
                                </div>
                                <div className={styles.row_5}>
                                    <div className={styles.left_container}>
                                        <CustomSwitch checked={isInstructionEnabled} onChange={(e) => setIsInstructionEnabled(e.target.checked)} />
                                        <CustomLabel title='Action'></CustomLabel>
                                    </div>
                                    <div className={styles.right_container}>
                                        <General_dropdowns type={TYPE_4_multi_select_dropdown} handleOnClick={handleGeneralMultiSelectDropdownForInstructions} current_theme={current_theme} defaultSelectedOptionsList={instructionValue} />
                                    </div>
                                </div>
                                {

                                    current_chat_screen_type !== "chat_screen" &&
                                    <div className={styles.row_6}>
                                        <div className={styles.header_container}>
                                            <div className={styles.title}>
                                                <CustomLabel title='Creativity'></CustomLabel>
                                            </div>
                                            <div className={styles.value}>
                                                <CustomLabel title={currentCreativity}></CustomLabel>
                                            </div>
                                        </div>
                                        <Box className={styles.slider_container}>
                                            <StyledSlider
                                                value={parseFloat(currentCreativity)} // Control the component with the state
                                                onChange={(e, newValue) => setCurrentCreativity(newValue)} // Inline handler
                                                step={0.1}
                                                marks
                                                max={1}
                                            />
                                        </Box>
                                    </div>
                                }
                                {

                                    current_chat_screen_type !== "chat_screen" && <div className={styles.row_10}>
                                        <CustomLabel title='Topics'></CustomLabel>
                                    </div>
                                }

                                <div className={styles.list_of_topics}>
                                    {
                                        current_chat_screen_type !== "chat_screen" && listOfTopicsToInput?.map((topic, index) => (
                                            <div key={topic.id} className={styles.list_item_container}>
                                                <div className={styles.topic_container}>
                                                    <CustomTextField
                                                        value={topic.topic}
                                                        onChange={(e) => handleChangeTopic(topic.id, e.target.value)}
                                                        placeholder={topic.placeholder}
                                                        icon={index > 0 && <img src={ic_delete_file} onClick={() => handleRemoveTopic(topic.id)} alt="Delete topic" />}
                                                    />

                                                </div>
                                            </div>
                                        ))}
                                    {

                                        current_chat_screen_type !== "chat_screen" &&
                                        <div className={styles.list_item_container}>
                                            <div className={styles.add_topic_btn_container}>
                                                <CustomButton font_size='10' currentButtonTpe={TYPE_1_button} text_color='secondary' background_color='quaternary' title='Add New Topic' onClick={handleAddNewTopic} />
                                            </div>

                                        </div>
                                    }
                                    {

                                        current_chat_screen_type !== "chat_screen" &&
                                        <div className={styles.action_button_container}>
                                            <div className={styles.btns_container}>
                                                {agent_config_to_edit != null && isTesting == false && <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleUpdateConfiguration(false) }} title={agent_config_to_edit != null ? "Update" : "Create"}></CustomButton>}
                                                {agent_config_to_edit == null && isTesting == false && <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleCreateAgentConfig(isTesting) }} title={agent_config_to_edit != null ? "Update" : "Create"}></CustomButton>}
                                                {/* {((agent_config_to_test != null && isTesting) || (agent_config_to_edit != null && isTesting)) &&
                                                    <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleCreateAgentConfig(false) }} title='Publish'></CustomButton>
                                                } */}
                                                <CustomButton
                                                    currentButtonTpe={TYPE_1_button}
                                                    onClick={handleConfirmCancel}
                                                    border_color='white' background_color='transparent' font_size='12' padding="8px 18px" title='Cancel'
                                                >
                                                </CustomButton>
                                            </div>
                                            {openAdvancedConfig === false &&
                                                <div className={styles.config_btn} onClick={() => setOpenAdvancedConfig(true)}>
                                                    <img className={styles.more_config_icon} src={ic_red_gear_icon} />
                                                    <CustomHeading text='Advanced Settings'></CustomHeading>
                                                </div>
                                            }

                                        </div>
                                    }
                                    {
                                        current_chat_screen_type === "chat_screen" &&
                                        <div className={styles.actions_container}>
                                            <div className={styles.left_container}>
                                                <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={(e) => { handleUpdateConfiguration(true) }} title='Apply Changes'></CustomButton>
                                            </div>
                                            <div className={styles.right_container}>
                                                <CustomButton onClick={handleConfirmCancel} font_size='12' padding="8px 18px" background_color='transparent' currentButtonTpe={TYPE_1_button} title='Cancel'></CustomButton>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {openAdvancedConfig === true ? <div className={styles.more_config_container}>
                            <div className={styles.advanced_config_header}>
                                <CustomHeading text='Advanced Settings'></CustomHeading >
                                <div onClick={() => setOpenAdvancedConfig(false)}>
                                    <Ic_X_Un_Shaded />
                                </div>
                            </div>

                            <div className={styles.row_0}>
                                <div className={styles.left_container}>
                                    <CustomSwitch checked={isConversationSummaryEnabled} onChange={handleSwitchChange} />
                                    <CustomLabel title='Conversation Summary'></CustomLabel>
                                </div>
                                <div className={styles.additional_toggle}>
                                    {isConversationSummaryEnabled && (
                                        <div>
                                            <p style={{ color: 'white' }}>Conversation Summary model settings</p>
                                            <CustomModelDropDown type={TYPE_5} handleOnClick={handleModelDropdownClickForSummarizationModel} defaultValue={currentSelectedSummarizationModelValue} defaultLLM={llmTypeForSummarizationModel} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.row_1}>
                                <div className={styles.left_container}>
                                    <CustomSwitch checked={isGenerateSearchQueryEnabled} onChange={handleGenSearchSwitchChange} />
                                    <CustomLabel title='Generative Search Query'></CustomLabel>
                                </div>
                                <div className={styles.additional_toggle}>
                                    {isGenerateSearchQueryEnabled && (
                                        <div>
                                            <p style={{ color: 'white' }}>Search query model settings</p>
                                            <CustomModelDropDown type={TYPE_6} handleOnClick={handleModelDropdownClickForSearchQueryModel} defaultValue={currentSelectedSearchQueryModelValue} defaultLLM={llmTypeForSearchQueryModel} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>
                                <CustomHeading text='Model Parameters'></CustomHeading>
                                <div className={styles.row_2}>
                                    <div className={styles.left_container}>
                                        <CustomHeading text='Prompt Token Limit'></CustomHeading>
                                        <CustomTextField placeholder={tokenLimit} value={tokenLimit} onChange={(e) => setTokenLimit(e.target.value)}></CustomTextField>
                                    </div>
                                    <div className={styles.right_container}>
                                        <CustomHeading text='Agent Response'></CustomHeading>
                                        <CustomTextField placeholder={agentResponse} value={agentResponse} onChange={(e) => setAgentResponse(e.target.value)}></CustomTextField>
                                    </div>
                                </div>
                            </div>
                            <div class={styles.row_3}>
                                <CustomHeading text='Search Query Length'></CustomHeading>
                                <CustomTextField placeholder={searchQueryLength} value={searchQueryLength} onChange={(e) => setSearchQueryLength(e.target.value)}></CustomTextField>
                            </div>
                            <div class={styles.row_4}>
                                <CustomHeading text='Conversation Summary Length'></CustomHeading>
                                <CustomTextField placeholder={conversationSummaryLength} value={conversationSummaryLength} onChange={(e) => setConversationSummaryLength(e.target.value)}></CustomTextField>
                            </div>
                            <div className={styles.row_5}>
                                <div className={styles.left_container}>
                                    <CustomHeading text='Max Documents Retrieved'></CustomHeading>
                                    <CustomTextField placeholder={maxDocumentRetrieved} value={maxDocumentRetrieved} onChange={(e) => setMaxDocumentRetrieved(e.target.value)}></CustomTextField>
                                </div>
                                <div className={styles.right_container}>
                                    <CustomHeading text='Doc. Retrieval Relevance'></CustomHeading>
                                    <CustomTextField placeholder={docRetrievalRelevance} value={docRetrievalRelevance} onChange={(e) => setDocRetrievalRelevance(e.target.value)}></CustomTextField>
                                </div>
                            </div>
                            <div className={styles.action_button_container}>
                                <div className={styles.btns_container}>
                                    {agent_config_to_edit != null && isTesting == false && <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleUpdateConfiguration(false) }} title={agent_config_to_edit != null ? "Update" : "Create"}></CustomButton>}
                                    {agent_config_to_edit == null && isTesting == false && <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleCreateAgentConfig(isTesting) }} title={agent_config_to_edit != null ? "Update" : "Create"}></CustomButton>}
                                    {((agent_config_to_test != null && isTesting) || (agent_config_to_edit != null && isTesting)) &&
                                        <CustomButton font_size='12' padding="8px 18px" currentButtonTpe={TYPE_1_button} onClick={() => { handleCreateAgentConfig(false) }}>Publish</CustomButton>
                                    }
                                    <CustomButton
                                        currentButtonTpe={TYPE_1_button}
                                        onClick={handleConfirmCancel}
                                        border_color='white' background_color='transparent' font_size='12' padding="8px 18px" title='Cancel'
                                    >
                                    </CustomButton>
                                </div>
                                {openAdvancedConfig === false &&
                                    <div className={styles.config_btn} onClick={() => setOpenAdvancedConfig(true)}>
                                        <img className={styles.more_config_icon} src={ic_red_gear_icon} />
                                        <CustomHeading text='Advanced Settings'></CustomHeading >
                                    </div>
                                }
                            </div>
                        </div> : ""}
                    </div>
                    <DeleteConfirmationComponent
                        isVisible={confirmCancel}
                        onConfirm={() => { setOpenSideBar(false); setConfirmCancel(false)  }}
                        onCancel={() => { setConfirmCancel(false) }}
                        message='You have unsaved changes.'
                        type='warning'
                    />
                </StyledAgentMainContainer>
            </div>
        </div>

    </div>
}

const mapState = (state) => ({
    isUserDetailsUpdated: isUserDetailsUpdated(state),
    current_theme: getCurrentTheme(state),
    current_chat_screen_type: getCurrentChatScreenType(state),
})

const mapDispatchToProps = (dispatch) => ({
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    saveAgentConfig: (data) => dispatch(createChatbotAgentConfig(data)),
    updateAgentConfig: (data) => dispatch(updateAgentConfig(data)),
    getUserDetails: (data) => dispatch(getUserDetails(data)),
    fetchChatbotAgent: (data) => dispatch(fetchChatbotAgent(data)),
    setIsConfigCreated: (data) => dispatch(setIsConfigCreated(data)),
    updateAgentConfigForTest: (data) => dispatch(updateAgentConfigForTest(data)),
});

export default connect(mapState, mapDispatchToProps)(ChatBotAgent)