import { BASE_URL, httpDelete, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance";
const ANALYTICS_BASE_URL = `${BASE_URL}/api/analytics/`;

// API to get the total number of users
const getNumberOfUsers = () => httpGet(`${ANALYTICS_BASE_URL}number_of_users/`);

// API to get the number of documents grouped by active and inactive status
const getNumberOfDocuments = () => httpGet(`${ANALYTICS_BASE_URL}number_of_documents/`);

// API to get the number of configurations, both draft and published
const getNumberOfConfigurations = () => httpGet(`${ANALYTICS_BASE_URL}number_of_configurations/`);

// API to get the number of conversations and messages
const getNumberOfConversationsAndMessages = () => httpGet(`${ANALYTICS_BASE_URL}number_of_conversations_and_messages/`);

export {
    getNumberOfUsers,
    getNumberOfDocuments,
    getNumberOfConfigurations,
    getNumberOfConversationsAndMessages
};
