import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Left_Arrow_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.6875 8.3125C19.0625 8.6875 19.0625 9.375 18.6875 9.75L12.375 16L18.6875 22.3125C19.0625 22.6875 19.0625 23.375 18.6875 23.75C18.3125 24.125 17.625 24.125 17.25 23.75L10.25 16.75C10.0625 16.5625 10 16.3125 10 16C10 15.75 10.0625 15.5 10.25 15.3125L17.25 8.3125C17.625 7.9375 18.3125 7.9375 18.6875 8.3125ZM16 0C24.8125 0 32 7.1875 32 16C32 24.875 24.8125 32 16 32C7.125 32 0 24.875 0 16C0 7.1875 7.125 0 16 0ZM16 30C23.6875 30 30 23.75 30 16C30 8.3125 23.6875 2 16 2C8.25 2 2 8.3125 2 16C2 23.75 8.25 30 16 30Z" fill="#AEAEB2"/>
    </svg>
    
    
    
    
    
    
} 

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Left_Arrow_Shaded)