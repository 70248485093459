import { TextareaAutosize, Typography } from '@mui/material';
import styled from 'styled-components';


const StyledTextareaForMessage = styled(TextareaAutosize)`
  && {
    display: block;
    max-width: 98%;

    min-width: 98%;
    min-height: 26px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-weight: 400;
    border-radius: 15px;
    color:  ${({ theme }) => theme.palette.textColors.quaternary}; 
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  
    &::placeholder {
      color: ${({ theme }) => theme.palette.textColors.quaternary};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }
  }
`;




const StyledDocumentListItem = styled(Typography)`
  && {
    display: block;
    max-width: 90%;
    min-width: 90%;
    min-height: 50px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 14px;
    font-weight: 400;
    border-radius: 15px;
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
    align-items: center;
    align-content: center;
    justify-content: center;
`;


const StyledContainer = styled.div`
  border-radius:8px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

export { StyledTextareaForMessage, StyledContainer, StyledDocumentListItem };