import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Chat_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5 8.25C20.5 12.8438 16.0938 16.5 10.75 16.5C9.76562 16.5 8.875 16.4062 7.98438 16.1719C6.67188 17.0625 4.60938 18 2.07812 18C1.65625 18 1.23438 17.7656 1.04688 17.3438C0.90625 16.9219 0.953125 16.4531 1.28125 16.125C1.28125 16.125 2.40625 14.9062 3.10938 13.4062C1.79688 12 1 10.2188 1 8.25C1 3.70312 5.35938 0 10.75 0C16.0938 0 20.5 3.70312 20.5 8.25ZM10.75 15C15.2969 15 19 12 19 8.25C19 4.54688 15.2969 1.5 10.75 1.5C6.15625 1.5 2.5 4.54688 2.5 8.25C2.5 9.75 3.0625 11.2031 4.1875 12.375L4.89062 13.125L4.46875 14.0625C4 15.0469 3.39062 15.8906 2.96875 16.5C4.75 16.3125 6.20312 15.5625 7.1875 14.9531L7.70312 14.5781L8.35938 14.7188C9.10938 14.9062 9.95312 15 10.75 15ZM29.4062 20.4375C30.0156 21.4219 30.6719 22.125 30.6719 22.1719C30.9531 22.4531 31.0469 22.9219 30.9062 23.3438C30.7188 23.7656 30.2969 24 29.875 24C28.3281 24 27.0156 23.6719 25.8906 23.2031C24.6719 23.7188 23.3594 24 22 24C17.7344 24 14.2188 21.375 13.2344 17.7656C13.75 17.6719 14.2656 17.5312 14.7344 17.3906C15.5312 20.3438 18.4375 22.5 22 22.5C23.125 22.5 24.2031 22.2656 25.2812 21.8438L25.8438 21.5625L26.4531 21.7969C27.2969 22.1719 28.1406 22.4062 28.9844 22.5C28.75 22.1719 28.4219 21.7031 28.1406 21.2344L27.5781 20.3438L28.1875 19.5469C29.0312 18.4219 29.5 17.1094 29.5 15.75C29.5 12.0469 26.125 9 22 9C21.9531 9 21.9531 9.04688 21.9531 9.04688C21.9531 8.76562 22 8.53125 22 8.25C22 8.01562 21.9531 7.78125 21.9531 7.54688C21.9531 7.54688 21.9531 7.5 22 7.5C26.9688 7.5 31 11.2031 31 15.75C31 17.4844 30.3906 19.125 29.4062 20.4375Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Chat_Un_Shaded)