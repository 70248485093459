import {  StyledMetaDataSideBarContainer, } from './styled'
import styles from './styles.module.scss'
import { connect } from "react-redux";
import Ic_Close_Shaded from '../../../../../shared/ui/editable_icons/Ic_Close_Shaded'
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomButton } from '../../../../../shared/ui/buttons';
import { TYPE_1 } from '../../../../../shared/ui/buttons/button_types';
const SidebarToViewDocumentDetails = ({
    currentSelectDocument,
    setIsViewDocumentSideBarOpen,
    ...props }) => {
    return <StyledMetaDataSideBarContainer className={styles.meta_data_side_bar_opened}>
        <div onClick={() => { setIsViewDocumentSideBarOpen(false) }} className={styles.close_icon}>
            <Ic_Close_Shaded />
        </div>
        <CustomHeading text='Document meta data' className={styles.header}></CustomHeading>
        <div className={styles.table}>
            <table className={styles.table_main}>
                <tbody className={styles.table_body}>
                    <tr className={styles.table_row}>
                        <td className={styles.one_table_row}>
                            <CustomHeading className={styles.one_table_col} text='Number of tokens '></CustomHeading>
                            <CustomHeading text={currentSelectDocument?.num_tokens}> </CustomHeading>
                        </td>
                        <td className={styles.one_table_row}>
                            <CustomHeading text='Status'> </CustomHeading>
                            <CustomHeading text={currentSelectDocument?.status} ></CustomHeading>
                        </td>
                    </tr>
                    <tr className={styles.long_table_row}>
                        <td className={styles.long_row}>
                            <CustomHeading text='Segmentation Method'></CustomHeading>
                            <CustomHeading text={currentSelectDocument?.chunking_method}></CustomHeading>
                        </td>
                    </tr>
                    <tr className={styles.table_row}>
                        <td className={styles.one_table_row}>
                            <CustomHeading text='Library'> </CustomHeading>
                            <CustomHeading text={currentSelectDocument?.library?.name}> </CustomHeading>
                        </td>
                        <td className={styles.one_table_row}>
                            <CustomHeading text='Upload Date '></CustomHeading>
                            <CustomHeading text={currentSelectDocument?.created}></CustomHeading>
                        </td>
                    </tr>
                    <tr className={styles.long_table_row}>
                        <td className={styles.long_row}>
                            <CustomHeading text='Summary'> </CustomHeading>
                            <CustomHeading text={currentSelectDocument?.description}> </CustomHeading>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div style={{ paddingBottom: "10px", position: "absolute", bottom: "0", width: "95%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <div style={{ gap: "5px", width: "25%", display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                <CustomButton currentButtonTpe={TYPE_1} onClick={() => {
                    setIsViewDocumentSideBarOpen(false)
                }}>Close</CustomButton >
            </div>
        </div>
    </StyledMetaDataSideBarContainer>
}

const mapState = (state) => ({
    // is_loading_documents_list: getIsListOfDocumentsLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
    // loadDocumentsList: (data) => dispatch(fetchListOfDocuments(data)),
});

export default connect(mapState, mapDispatchToProps)(SidebarToViewDocumentDetails)