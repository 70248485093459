import { useEffect, useRef, useState } from 'react';
import Ic_Dropdown_Shaded from '../../../editable_icons/Ic_Dropdown_Shaded';
import Ic_Dropup_Shaded from '../../../editable_icons/Ic_Dropup_Shaded';
import { CustomHeading } from '../../../headings';
import ImageLoading from '../../../image_loading';
import { StyledCurrentSelectedBotContainer, StyledListOfBotsAsDropDown, StyledListOfBotsAsDropDownItemContainer } from './styled';
import styles from './styles.module.scss'
import Ic_Settings_Gear_Shaded_Size_Free from '../../../editable_icons/Ic_Settings_Gear_Shaded_Size_Free';
import { getListOfAgents, getListOfAnalystAgents } from '../../../../../modules/dashboard/logic/agents/selectors';
import { fetchListOfChatbotAgents } from '../../../../../modules/dashboard/logic/agents/actions';
import { connect } from "react-redux";
import { setCurrentConversationId, setCurrentRoomName, setCurrentSelectedChatbot, setIsChatbotAdvancedSettingsOpen, setListOfMessages, setNumberOfMessagesSent } from '../../logic/slice';
import { getCurrentChatScreenType, getCurrentSelectedChatbot, getIsChatbotAdvancedSettingsOpen } from '../../logic/selectors';

const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const AppsDropDown = ({
    list_of_apps,
    list_of_analyst_agent,
    current_selected_chatbot,
    setCurrentSelectedChatbot,
    is_chatbot_advanced_settings_open,
    current_chat_screen_type,
    // reducers
    setListOfMessages,
    setNumberOfMessagesSent,
    setIsChatbotAdvancedSettingsOpen,
    setCurrentConversationId,
    setCurrentRoomName,
    ...props }) => {

    const [isConversationHistoryVisible, setIsConversationHistoryVisible] = useState(true)
    const [isBotMenuOpen, setIsBotMenuOpen] = useState(false)
    const [listOfAppsToRender, setListOfAppsToRender] = useState([])
    const [currentSelectedChatbot, setCurrentSelectedBot] = useState(null)
    const menuRef = useRef(null);

    useEffect(() => {
        if (current_selected_chatbot) {
            if (current_selected_chatbot?.name) {
                setCurrentSelectedBot(current_selected_chatbot)
                setIsConversationHistoryVisible(true)
            } else {
                setIsConversationHistoryVisible(false)
            }
        }
    }, [current_selected_chatbot])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsBotMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const [shuffledArray, setShuffledArray] = useState([])

    useEffect(() => {
        if (list_of_apps != null && list_of_analyst_agent != null) {
            const combinedArray = [...list_of_apps, ...list_of_analyst_agent];
            setShuffledArray(shuffleArray(combinedArray))
        }
    }, [list_of_apps, list_of_analyst_agent])


    useEffect(() => {
        if (shuffledArray && current_chat_screen_type !== "play_ground") {
            setListOfAppsToRender(shuffledArray)
        }
    }, [shuffledArray])


    const formatName = (name) => {
        let formattedName = name?.replace(/_/g, ' ');
        formattedName = formattedName?.replace(/\b\w/g, (char) => char.toUpperCase());
        return formattedName;
    };

    return <div className={styles.container}>
        <StyledCurrentSelectedBotContainer className={styles.top_header} ref={menuRef}>
            <div className={styles.icon_container}>
                {isConversationHistoryVisible ? <ImageLoading width="60%" /> : <Ic_Settings_Gear_Shaded_Size_Free height={25} width={25} />}
            </div>
            <div className={styles.title_container}>
                <CustomHeading font_size='11' text={isConversationHistoryVisible ? currentSelectedChatbot?.name : "Advanced chatbot settings"}> </CustomHeading>
                {isConversationHistoryVisible ? <CustomHeading font_size='10' text={currentSelectedChatbot?.agent_type}></CustomHeading> : ''}
            </div>
            <div className={styles.drop_down_container} onClick={(e) => {
                e.preventDefault()
                setIsBotMenuOpen(!isBotMenuOpen)
            }}>
                {isBotMenuOpen ? <Ic_Dropup_Shaded /> : <Ic_Dropdown_Shaded />}
            </div>

            {
                isBotMenuOpen && <StyledListOfBotsAsDropDown className={styles.drop_down}>

                    <div className={styles.list_of_bots}>
                        {
                            listOfAppsToRender && listOfAppsToRender.length > 0 && listOfAppsToRender.map((app, index) => {
                                return <StyledListOfBotsAsDropDownItemContainer className={styles.item_container} onClick={(e) => {
                                    e.preventDefault();
                                    setIsBotMenuOpen(false)
                                    setCurrentSelectedChatbot(app)
                                    setListOfMessages([])
                                    setNumberOfMessagesSent(0)
                                    setIsChatbotAdvancedSettingsOpen(false)
                                    setCurrentConversationId(null)
                                    setCurrentRoomName(null)
                                }}>
                                    <div className={styles.icon_container}>
                                        <ImageLoading width="70%" />
                                    </div>
                                    <div className={styles.title_container}>
                                        <CustomHeading text={app?.name}></CustomHeading >
                                        <CustomHeading text={app?.agent_type}></CustomHeading >
                                    </div>
                                </StyledListOfBotsAsDropDownItemContainer>
                            })
                        }
                        {
                            current_chat_screen_type === "chat_screen" && <StyledListOfBotsAsDropDownItemContainer className={styles.item_container} onClick={(e) => {
                                e.preventDefault();
                                setIsBotMenuOpen(false)
                                setIsConversationHistoryVisible(false)
                                setIsChatbotAdvancedSettingsOpen(true)
                            }}>
                                <div className={styles.icon_container}>
                                    <Ic_Settings_Gear_Shaded_Size_Free height={25} width={25} />
                                </div>
                                <div className={styles.title_container}>
                                    <CustomHeading text='Advanced
                                        chatbot settings'></CustomHeading >
                                </div>
                            </StyledListOfBotsAsDropDownItemContainer>
                        }

                    </div>
                </StyledListOfBotsAsDropDown>
            }


        </StyledCurrentSelectedBotContainer>
    </div>
};


const mapState = (state) => ({
    list_of_apps: getListOfAgents(state),
    list_of_analyst_agent: getListOfAnalystAgents(state),
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    is_chatbot_advanced_settings_open: getIsChatbotAdvancedSettingsOpen(state),
    current_chat_screen_type: getCurrentChatScreenType(state),
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentSelectedChatbot: (data) => dispatch(setCurrentSelectedChatbot(data)),
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setNumberOfMessagesSent: (data) => dispatch(setNumberOfMessagesSent(data)),
    setCurrentConversationId: (data) => dispatch(setCurrentConversationId(data)),
    setCurrentRoomName: (data) => dispatch(setCurrentRoomName(data)),
    setIsChatbotAdvancedSettingsOpen: (data) => dispatch(setIsChatbotAdvancedSettingsOpen(data)),
});

export default connect(mapState, mapDispatchToProps)(AppsDropDown)