import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Right_Arrow_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.6875 18.3125L31.6875 25.3125C31.875 25.5 32 25.75 32 26C32 26.3125 31.875 26.5625 31.6875 26.75L24.6875 33.75C24.3125 34.125 23.625 34.125 23.25 33.75C22.875 33.375 22.875 32.6875 23.25 32.3125L29.5625 26L23.25 19.75C22.875 19.375 22.875 18.6875 23.25 18.3125C23.625 17.9375 24.3125 17.9375 24.6875 18.3125ZM26 10C34.8125 10 42 17.1875 42 26C42 34.875 34.8125 42 26 42C17.125 42 10 34.875 10 26C10 17.1875 17.125 10 26 10ZM26 40C33.6875 40 40 33.75 40 26C40 18.3125 33.6875 12 26 12C18.25 12 12 18.3125 12 26C12 33.75 18.25 40 26 40Z" fill="#AEAEB2"/>
    </svg>
    
    
    
    
    
    
} 

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Right_Arrow_Shaded)