import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Compress_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.25 16.875C2.25 17.5312 1.73438 18 1.125 18C0.46875 18 0 17.5312 0 16.875V1.125C0 0.515625 0.46875 0 1.125 0C1.73438 0 2.25 0.515625 2.25 1.125V16.875ZM12.75 14.2031C13.2188 14.625 13.2188 15.3281 12.7969 15.8438C12.375 16.2656 11.6719 16.3125 11.2031 15.8906L4.82812 9.89062C4.35938 9.46875 4.35938 8.67188 4.82812 8.25L11.2031 2.25C11.6719 1.82812 12.375 1.82812 12.7969 2.29688C12.9844 2.53125 13.0781 2.76562 13.0781 3.04688C13.0781 3.375 12.9844 3.65625 12.75 3.89062L8.4375 7.92188H19.8281C20.4844 7.92188 21 8.39062 21 9C21 9.65625 20.4844 10.125 19.8281 10.125H8.4375L12.75 14.2031Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Compress_Un_Shaded)