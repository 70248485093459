import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Chat_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.75 16.5C8.92188 16.5 7.28125 16.125 5.82812 15.4219C5.45312 15.6094 5.07812 15.75 4.65625 15.9375C3.76562 16.2656 2.73438 16.5 1.75 16.5C1.42188 16.5 1.14062 16.3125 1 15.9844C0.90625 15.7031 1.04688 15.375 1.28125 15.1875V15.1406H1.32812C1.375 15.0938 1.46875 15 1.5625 14.9531C1.70312 14.8125 1.9375 14.5781 2.17188 14.2969C2.40625 13.9688 2.6875 13.5469 2.82812 13.125C1.65625 11.7656 1 10.0781 1 8.25C1 3.70312 5.35938 0 10.75 0C16.0938 0 20.5 3.70312 20.5 8.25C20.5 12.8438 16.0938 16.5 10.75 16.5ZM22 8.25H21.9531C21.9531 8.01562 21.9531 7.78125 21.9531 7.54688C27.0156 7.82812 30.9531 11.4375 30.9531 15.75C30.9531 17.5781 30.2969 19.2656 29.125 20.625C29.2656 21.0469 29.5469 21.4219 29.7812 21.75C30.0156 22.0781 30.25 22.2656 30.3906 22.4531C30.4844 22.5 30.5781 22.5938 30.625 22.5938C30.625 22.6406 30.6719 22.6406 30.6719 22.6406C30.9531 22.875 31.0469 23.2031 30.9531 23.4844C30.8594 23.8125 30.5312 24 30.25 24C29.2188 24 28.1875 23.7656 27.2969 23.4375C26.875 23.25 26.5 23.1094 26.125 22.9219C24.6719 23.625 23.0312 24 21.25 24C16.75 24 12.9531 21.4688 11.8281 17.9531C17.3125 17.4844 22 13.5469 22 8.25Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}/>
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Chat_Shaded)