
import styles from './styles.module.scss'
import { connect } from "react-redux";
import ChatScreen from '../../../../../shared/ui/chatscreen'

const Chat = ({...props }) => {
    // here we need to set flags which we need to set-up things for chatbot testing
    return <div className={styles.container}>
        <ChatScreen />
    </div>
}

const mapState = (state) => ({})

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapState, mapDispatchToProps)(Chat)