import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel  from '@mui/material/FormControlLabel';

const StyledProfileMainContainer = styled.div`
border-radius:20px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;

const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 30px;
  }
`;
const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
   
    font-size: 20px;
  }
`;
const StyledHeading3 = styled(Typography)(({ theme, primary }) => ({
  color: primary ? theme.palette.textColors.primary : theme.palette.textColors.secondary,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: theme.palette.fontFamilies.primary,
  fontStyle: 'bold',
  fontWeight: 900,
  lineHeight: 'normal',
  fontSize: '15px',
  cursor: 'pointer'
}));


const StyledLabel = styled(Typography)(({ theme, primary }) => ({
  color: primary ? theme.palette.textColors.primary : 'grey',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: theme.palette.fontFamilies.primary,
  fontStyle: 'normal',
  fontWeight: 300,
  lineHeight: 'normal',
  cursor: 'pointer',
  fontSize: '14px',
}));

const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;

const StyledTextField = styled(TextField)`
  && {
    display: flex;
    width:100%;
    
    input {
      height: 10px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    border: 1px solid  ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
  
    
    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;


const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
    color: ${({ theme }) => theme.palette.textColors.default}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      border: 1px solid ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      color: ${({ theme }) => theme.palette.textColors.primary}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.secondary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;
const MyStyledButton_type2 = styled(Button)`
  && {
    background-color: ${({backgroundColor, theme }) => backgroundColor ? theme.palette.buttonBackgrounds[backgroundColor] : theme.palette.buttonBackgrounds.primary};
    color: ${({color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
      color: ${({ theme }) => theme.palette.textColors.primary}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;

const StyledHeading4 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
   
    cursor:pointer;
    font-size: 12px;
  }
`;

const StyledChip = styled.div`
  background-color:  ${({ theme }) => theme.palette.backgroundColors.light};
  color:${({ color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
  border-radius:25px;
  width:50%;
  text-align:center;
  padding:1rem
 
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
   
    cursor:pointer;
    font-size: 15px;
`;

const TextBox = styled.div`
  padding: 10px;
  margin-top: 12px;
  color: white;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  border-radius: 8px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
color:white
`;

export {
    StyledProfileMainContainer,
    StyledHeading1,
    StyledHeading2,
    StyledHeading3,
    StyledHeading4,
    StyledChip,
    StyledDivider,
    StyledTextField,
    StyledLabel,
    MyStyledButton_type1,
    MyStyledButton_type2,
    TextBox,
    StyledSideBarContainer,
    StyledFormControlLabel
}