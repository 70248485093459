import styled from 'styled-components';

const StyledUserMsgContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.darkest};
  max-width: 70%;
  min-width: fit-content;
  border-radius:25px;
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 10px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;


const StyledBotMsgContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
  max-width: 70%;
  min-width: fit-content;
  border-radius:25px;
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 10px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
`;

const StyledUserMsgFileContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.file_msg_container_color};
  max-width: 70%;
  min-width: 30%;
  border: 6px solid  ${({ theme }) => theme.palette.borderColors.darkest};
   
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 15px;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
`;

export {StyledUserMsgContainer,StyledBotMsgContainer,StyledUserMsgFileContainer };