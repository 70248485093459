import { connect, useDispatch } from "react-redux";
import { handleLoginUser, handleRegisterUser, handleRequestPassword, handleVerifyCode, handleResetUserPassword } from "../logic/actions";
import { getLoading } from "../logic/selectors"
import { useEffect, useState } from "react";
import { StyledMainContainer, StyledLeftContainer, StyledRightContainer, StyledHeading1, StyledDivider } from "./styled";
import ic_visible_password from '../../../assets/images/icons8-visible-50.png'
import ic_invisible_password from '../../../assets/images/icons8-invisible-30.png'
import ic_clairo_logo from '../../../assets/images/Clairo_Master_icon_RGB.png'

import ic_clairo_logo_gif from '../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif'
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss'
import { getRoute } from "../../app/logic/selectors";
import { showToastMessage } from "../../app/logic/actions";
import { CustomHeading } from '../../../../src/shared/ui/headings'
import CustomTextField from "../../../../src/shared/ui/text_field";
import { CustomLabel } from '../../../../src/shared/ui/label'
import { CustomButton } from '../../../../src/shared/ui/buttons'
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../src/shared/ui/buttons/button_types'

const AuthenticationPage = ({ onLogin, routeToChange, loading, onRegister, showToastMessage,}) => {
    const navigate = useNavigate();
    const [logInPage, setLogInPage] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showForgotPasswordScreen, setShowForgotPasswordScreen] = useState(false)
    const [hasCode, setHasCode] = useState(false)
    const [resetPasswordScreen, setResetPasswordScreen] = useState(false)
    const [emailForCode, setEmailForCode] = useState('')
    const [resetCode, setResetCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [token, setToken] = useState('')
    const dispatch = useDispatch()
    const loginValidations = [
        { field: email, message: "Cannot leave email field empty" },
        { field: password, message: "Cannot leave password field empty" }
    ];
    const codeValidations = [
        { field: resetCode, message: "Please enter the code sent to your email address" }
    ];
    const emailValidations = [
        { field: emailForCode, message: "Please enter your email address" }
    ];
    const passwordValidations = [
        { field: newPassword, message: "Please enter your new password" },
        { field: confirmNewPassword, message: "Please confirm your new password" }
    ];

    useEffect(() => {
        setTimeout(() => {
            let user = localStorage.getItem("access")
            if (routeToChange && user != undefined) {
                navigate("/" + routeToChange)
            }
        }, 2000);

    }, [routeToChange])

    const togglePasswordVisibility = (field) => {
        switch (field) {
            case 'password':
                setShowPassword(!showPassword);
                break;
            case 'newPassword':
                setShowNewPassword(!showNewPassword);
                break;
            case 'confirmPassword':
                setShowConfirmPassword(!showConfirmPassword);
                break;
            default:
                break;
        }
    };

    const handleForgotPasswordScreen = () => {
        setShowForgotPasswordScreen(true)
        setLogInPage(false)
    }

    const validateFields = (fields) => {
        for (let validation of fields) {
            if (validation.field.length === 0) {
                showToastMessage({ type: 'error', message: validation.message });
                return false;
            }
        }
        return true;
    };
    
    const loginAccount = () => {
        if (!validateFields(loginValidations)) {
            return;
        }
        onLogin({
            password: password,
            email: email
        })
    }

    const sendCode = () => {
        if (!validateFields(emailValidations)) {
            return;
        }
        dispatch(handleRequestPassword(emailForCode))
        setShowForgotPasswordScreen(false)
        setHasCode(true)
    }

    const verifyCode = async () => {
        if (!validateFields(codeValidations)) {
            return;
        }
        try {
            const receivedToken = await dispatch(handleVerifyCode({code: resetCode, email: emailForCode}))
            setToken(receivedToken)
            setResetPasswordScreen(true)
            setHasCode(false)
        } catch (err) {
            showToastMessage({ type: 'error', message: 'Failed to verify code' });
        }        
    }

    const resetPassword = async () => {
        if (!validateFields(passwordValidations)) {
            return;
        }
        if (newPassword !== confirmNewPassword) {
            showToastMessage({ type: 'error', message: "Passwords do not match" });
            return;
        }
        try {
            const result = await dispatch(handleResetUserPassword({
                token: token,
                password: newPassword,
                confirm_password: confirmNewPassword
            }))
            showToastMessage({ type: 'success', message: "Password updated successfully" });
            setResetPasswordScreen(false)
            setLogInPage(true)
        } catch (err) {
            showToastMessage({ type: 'error', message: "Failed to update password. Please try again later." });
        }
    }

    return <StyledMainContainer className={styles.container}>
            <StyledLeftContainer className={styles.left_container}>
                {logInPage &&
                <>
                    <StyledHeading1 className={styles.heading_1}>Sign in to Clairo</StyledHeading1>
                    <div className={styles.input_fields_container}>
                        <CustomLabel title="Email" ></CustomLabel>
                        <CustomTextField onChange={(e) => { setEmail(e.target.value) }} />
                        <CustomLabel title="Password"></CustomLabel>
                        <div className={styles.password_field_container}>
                            <CustomTextField type={showPassword ? "text" : "password"} className={styles.input_field} onChange={(e) => { setPassword(e.target.value) }} />
                            <img src={showPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={() => togglePasswordVisibility('password')} />
                        </div>
                        <div className={styles.continue_button}>
                            <CustomButton className={styles.button} title={loading ? 'Please wait...' : 'Continue'} currentButtonTpe={TYPE_1_button} onClick={loginAccount}></CustomButton >
                        </div>
                        <StyledDivider />
                        <div className={styles.other_buttons_container}>
                            <CustomButton className={styles.button} title='Sign in with Google' currentButtonTpe={TYPE_1_button} backgroundColor='primary' font_size='12' ></CustomButton >
                            <CustomButton className={styles.button} title='Sign in with Microsoft' currentButtonTpe={TYPE_1_button} backgroundColor='primary' font_size='12'></CustomButton >
                        </div>
                    </div>
                    <div className='forgot_password' >
                        <CustomHeading text="Forgot password?" font_size='14' onClick={handleForgotPasswordScreen} color='secondary'></CustomHeading>
                    </div>
                </>
                }
                {showForgotPasswordScreen &&
                    <div className={styles.forgot_password_container}>
                        <div className={styles.forgot_password_headers}>
                            <CustomHeading text="Forgot your password?" font_size='30'></CustomHeading>
                            <CustomHeading text="Enter the email associated with your Clairo AI account to change your password." font_size='15'></CustomHeading>
                        </div>
                        <div className={styles.second_input_fields_container}>
                            <CustomTextField onChange={(e) => { setEmailForCode(e.target.value)}} value={emailForCode} placeholder="Enter your email" />
                            <div className={styles.continue_button}>
                                <CustomButton className={styles.button} title='Continue' currentButtonTpe={TYPE_1_button} font_size='12' onClick={sendCode} ></CustomButton >
                            </div>
                        </div>
                    </div>
                }
                {hasCode &&
                    <div className={styles.check_emails_container}>
                        <CustomHeading text='Enter the code that was sent to your email' font_size='30'></CustomHeading>
                        <CustomTextField value={resetCode} onChange={(e) => {setResetCode(e.target.value)}} placeholder='Enter code'/>
                        <div className={styles.continue_button}> 
                            <CustomButton className={styles.button} title='Continue' currentButtonTpe={TYPE_1_button} onClick={verifyCode}></CustomButton >
                        </div>
                    </div>
                }
                {resetPasswordScreen &&
                    <div className={styles.reset_password_container}>
                        <CustomHeading text='Create a New Password' font_size='30'></CustomHeading>
                        <div className={styles.password_fields}>
                            <CustomLabel title='New Password'></CustomLabel>
                            <div className={styles.password_field_container}>
                                <CustomTextField type={showNewPassword ? "text" : "password"} className={styles.input_field} value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
                                <img src={showNewPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={() => togglePasswordVisibility('newPassword')} />
                            </div>
                            <CustomLabel title='Confirm New Password'></CustomLabel>
                            <div className={styles.password_field_container}>
                                <CustomTextField type={showConfirmPassword ? "text" : "password"} className={styles.input_field} value={confirmNewPassword} onChange={(e) => { setConfirmNewPassword(e.target.value) }} />
                                <img src={showConfirmPassword ? ic_visible_password : ic_invisible_password} className={styles.eye_icon} onClick={() => togglePasswordVisibility('confirmPassword')} />
                            </div>
                            <div className={styles.button_container}>
                            <CustomButton className={styles.button} title='Continue' currentButtonTpe={TYPE_1_button} onClick={resetPassword}></CustomButton >
                            </div>
                        </div>
                    </div>
                }
            </StyledLeftContainer>
            <StyledRightContainer className={styles.right_container}>
                {
                    loading == false && <img className={styles.logo} src={ic_clairo_logo} />
                }
                {
                    loading == true && <div className={styles.loading_gif}>
                        <img className={styles.logo_gif} src={ic_clairo_logo_gif} />
                    </div>
                }
            </StyledRightContainer>
        </StyledMainContainer>
};



const mapState = (state) => ({
    loading: getLoading(state),
    routeToChange: getRoute(state),
})

const mapDispatchToProps = (dispatch) => ({
    onLogin: (data) => dispatch(handleLoginUser(data)),
    onRegister: (data) => dispatch(handleRegisterUser(data)),
    sendCode: (data) => dispatch(handleRequestPassword(data)),
    verifyCode: (data) => dispatch(handleVerifyCode(data)),
    resetUserPassword: (data) => dispatch(handleResetUserPassword(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
});


export default connect(mapState, mapDispatchToProps)(AuthenticationPage)