import styled from 'styled-components';

export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

export const CenteredBox = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  font-size: 1em;
  width: 30%;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;
