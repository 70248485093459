import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Filter_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.8125C0 1.24805 0.464844 0.75 1.0625 0.75H13.8125C14.377 0.75 14.875 1.24805 14.875 1.8125C14.875 2.41016 14.377 2.875 13.8125 2.875H1.0625C0.464844 2.875 0 2.41016 0 1.8125ZM2.125 7.125C2.125 6.56055 2.58984 6.0625 3.1875 6.0625H11.6875C12.252 6.0625 12.75 6.56055 12.75 7.125C12.75 7.72266 12.252 8.1875 11.6875 8.1875H3.1875C2.58984 8.1875 2.125 7.72266 2.125 7.125ZM9.5625 12.4375C9.5625 13.0352 9.06445 13.5 8.5 13.5H6.375C5.77734 13.5 5.3125 13.0352 5.3125 12.4375C5.3125 11.873 5.77734 11.375 6.375 11.375H8.5C9.06445 11.375 9.5625 11.873 9.5625 12.4375Z" fill="white" fill-opacity="0.75"/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Filter_Shaded)