import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Link_Shaded = ({ current_theme, ...props }) => {
    return <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.73047 4.35938C6.26172 2.82812 8.75 2.82812 10.2812 4.35938C11.7578 5.80859 11.8125 8.16016 10.4727 9.71875L10.3086 9.88281C10.0625 10.1562 9.65234 10.1836 9.37891 9.96484C9.10547 9.71875 9.07812 9.30859 9.32422 9.03516L9.46094 8.87109C10.3906 7.83203 10.3359 6.27344 9.35156 5.28906C8.33984 4.25 6.67188 4.25 5.63281 5.28906L2.54297 8.37891C1.53125 9.41797 1.53125 11.0586 2.54297 12.0977C3.52734 13.0547 5.08594 13.1094 6.125 12.207L6.28906 12.0703C6.5625 11.8242 6.97266 11.8516 7.21875 12.125C7.46484 12.3984 7.4375 12.8086 7.16406 13.0547L7 13.1914C5.44141 14.5586 3.08984 14.4766 1.61328 13.0273C0.0820312 11.4961 0.0820312 8.98047 1.61328 7.44922L4.73047 4.35938ZM12.7695 11.168C11.2109 12.6992 8.72266 12.6992 7.19141 11.168C5.71484 9.69141 5.66016 7.36719 7 5.80859L7.13672 5.64453C7.38281 5.37109 7.79297 5.34375 8.06641 5.58984C8.33984 5.83594 8.36719 6.24609 8.12109 6.51953L8.01172 6.65625C7.08203 7.69531 7.13672 9.25391 8.12109 10.2383C9.13281 11.25 10.8008 11.25 11.8398 10.2383L14.9297 7.14844C15.9414 6.10938 15.9414 4.46875 14.9297 3.42969C13.9453 2.47266 12.3867 2.41797 11.3477 3.32031L11.1836 3.45703C10.9102 3.70312 10.5 3.67578 10.2539 3.40234C10.0078 3.12891 10.0352 2.71875 10.3086 2.47266L10.4727 2.33594C12.0312 0.96875 14.3828 1.05078 15.8594 2.5C17.3906 4.03125 17.3906 6.51953 15.8594 8.07812L12.7695 11.168Z" fill="#AEAEB2"/>
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

export default connect(mapState, null)(Ic_Link_Shaded)