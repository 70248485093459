import { generateNewConversationId } from "../../../../shared/ui/chatscreen/logic/actions";
import { setCurrentSelectedChatbot } from "../../../../shared/ui/chatscreen/logic/slice";
import { AgentsAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import { setAgentConfigToEdit, setAgentConfigToTest, setIsConfigCreated, setIsCreatingAgentConfig, setIsDeletingAgentConfig, setIsLoadingAgentDetails, setIsLoadingListOfAgents, setIsUpdatingAgentFlags, setListOfAgents, setListOfAnalystAgents, setListOfAQAgents } from "./slice"

const formatName = (name) => {
    let formattedName = name?.replace(/_/g, ' ');
    formattedName = formattedName?.replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedName;
};

const fetchListOfChatbotAgents = () => (dispatch) => {
    dispatch(setIsLoadingListOfAgents(true));
    AgentsAPI.getAllChatbotAgentsConfig({})
        .then((response) => {
            if (response.data) {
                const mappedData = response.data.items.map(agent => ({
                    type: "Chatbot",
                    agent_type:agent?.agent_type,
                    id: agent?.id,
                    name: agent?.agent_name,
                    prompt: agent?.prompt,
                    description: agent?.description,
                    isToneActive: agent?.is_tone_active,
                    tone: agent?.tone,
                    isActionActive: agent?.is_action_active,
                    actions: agent?.actions,
                    creativity: agent?.creativity,
                    chat_model: agent?.chat_model,
                    model_name: agent?.chat_model?.name,
                    llmType: agent?.chat_model?.llm_type,
                    generativeModel: agent?.gen_ai_model,
                    summarizationModel: agent?.summarization_model,
                    // embeddingModel: agent?.embedding_model,
                    documentRetrieval: agent?.document_retrieval,
                    conversationSummarization: agent?.conversation_summarization,
                    search_query_model: agent?.search_query_model,
                    generativeSearchQuery: agent?.generative_search_query,
                    tokenLimit: agent?.token_limit,
                    genAICompletion: agent?.chat_completion, // TODO: to change
                    dynamicPromptCompletion: agent?.search_query_completion, // TODO: to change
                    summaryCompletion: agent?.summary_completion,
                    documentLimit: agent?.document_limit,
                    similarityThreshold: agent?.similarity_threshold,
                    library: agent?.library.map(lib => ({
                        id: lib.id,
                        name: lib.name,
                        description: lib.description,
                        tags: lib.tags
                    })),
                    createdAt: agent?.created_at?.substring(0, 10),
                    is_test: agent?.is_test,
                    topics: agent?.topics,
                    status: agent?.is_test ? 'Draft' : 'Published',
                    actions_list: [ 'Edit',  'Delete'],
                    documentRetrieval:agent?.document_retrieval+"",
                }));
                dispatch(setListOfAgents(mappedData));
                dispatch(setIsLoadingListOfAgents(false));
              }
        })
        .catch((error) => {
            dispatch(showToastMessage({ type: 'error', message: "Something went wrong while fetching agent configurations" }));
        })
        .finally(() => {
            setTimeout(() => {
                dispatch(setIsLoadingListOfAgents(false))
            }, 3000);
            ;
        });
};

const fetchListOfAnalystAgents = () => (dispatch) => {
    // dispatch(setIsLoadingListOfAgents(true));
    
    AgentsAPI.getAllAnalystAgentsConfig({})
        .then((response) => {
            if (response.data) {
                const mappedData = response.data.map(agent => ({
                    // type: "Chatbot",
                    agent_type:agent?.agent_type,
                    id: agent?.id,
                    name: agent?.agent_name,
                    prompt: agent?.prompt,
                    system_prompt: agent?.system_prompt, 
                    description: agent?.description,
                    isToneActive: agent?.is_tone_active,
                    tone: agent?.tone,
                    creativity: agent?.creativity,
                    sql_generation_model: agent?.sql_generation_model,
                    chat_model: agent?.chat_model,
                    model_name: agent?.chat_model?.name,
                    llmType: agent?.chat_model?.llm_type,
                    // llmType: agent.sql_generation_model?.llm_type,
                    summarizationModel: agent?.summarization_model,
                    conversationSummarization: agent?.conversation_summarization,
                    summaryCompletion: agent?.summary_completion,
                    createdAt: agent?.created_at?.substring(0, 10),
                    status: 'Published',
                    actions_list: [ 'Edit',  'Delete'],
                    documentRetrieval:"NA",
                    db_name:agent?.db_name,
                    sql_db_uri: agent?.sql_db_uri
                }));
                console.log("mappedData-chatbot",mappedData)
                dispatch(setListOfAnalystAgents(mappedData));
                // dispatch(setIsLoadingListOfAgents(false));
              }
        })
        .catch((error) => {
            console.error(error);
            dispatch(showToastMessage({ type: 'error', message: "Something went wrong while fetching agent configurations" }));
        })
        .finally(() => {
            // setTimeout(() => {
            //     // dispatch(setIsLoadingListOfAgents(false))
            // }, 3000);
            ;
        });
};

const fetchListOfQAChatbotAgents = () => (dispatch) => {
    dispatch(setIsLoadingListOfAgents(true));
    AgentsAPI.getAllQAChatbotAgentsConfig({})
        .then((response) => {

            if (response.data) {
                const mappedData = response.data.items.map(agent => ({
                    type: agent?.action === "check-in" ? "Intent" : "Question",
                    id: agent.id,
                    agent_type:agent?.agent_type,
                    name:formatName(agent.agent_name),
                    model: agent.model,
                    model_name: agent?.model?.name,
                    generativeModel: agent.model,
                    llmType: agent.model?.llm_type,
                    tokenLimit: '-',
                    isToneActive: agent.is_tone_active,
                    tone: agent.tone,
                    isActionActive: agent.is_action_active,
                    action: agent.action,
                    documentRetrieval:"NA",
                    creativity: agent.creativity,
                    questions: agent.questions, // Parse the questions string into an array
                    systemPrompt: agent.system_prompt,
                    disclaimer: agent.disclaimer,
                    status: agent.is_test ? 'Draft' : 'Published',
                    is_test: agent.is_test,
                    qaQuestions: Object.keys(agent.qa_questions).map(key => ({
                        question: agent.qa_questions[key].question_prompt,
                        answer: agent.qa_questions[key].answer,
                        defaultPosition: agent.qa_questions[key].default_position
                    })),
                    createdAt: agent?.created_at?.substring(0, 10),
                    topics: agent?.topics,
                    actions_list: [ 'Edit',  'Delete'],
                }));

                dispatch(setListOfAQAgents(mappedData));
                // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched agent configurations" }));
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(showToastMessage({ type: 'error', message: "Something went wrong while fetching agent configurations" }));
        })
        .finally(() => {
            setTimeout(() => {
                dispatch(setIsLoadingListOfAgents(false))
            }, 3000);
        });
};


const fetchChatbotAgent = (data) => (dispatch) => {
    dispatch(setIsLoadingAgentDetails(true));
    AgentsAPI.getAgentConfig(data)
        .then((response) => {
            if (response.data) {
                const mappedData = {
                    type: "Chatbot",
                    id: response.data.id,
                    name: response.data.agent_name,
                    prompt: response.data.prompt,
                    description: response.data.description,
                    isToneActive: response.data.is_tone_active,
                    tone: response.data.tone,
                    isActionActive: response.data.is_action_active,
                    actions: response.data.actions,
                    creativity: response.data.creativity,
                    generativeModel: response.data.gen_ai_model,
                    summarizationModel: response.data.summerization_model,
                    embeddingModel: response.data.embedding_model,
                    documentRetrieval: response.data.document_retrieval,
                    conversationSummarization: response.data.conversation_summarization,
                    generativeSearchQuery: response.data.generative_search_query,
                    tokenLimit: response.data.token_limit,
                    genAICompletion: response.data.gen_ai_completion,
                    dynamicPromptCompletion: response.data.dynamic_prompt_completion,
                    summaryCompletion: response.data.summary_completion,
                    documentLimit: response.data.document_limit,
                    similarityThreshold: response.data.similarity_threshold,
                    library: response.data.library.map(lib => ({
                        id: lib.id,
                        name: lib.name,
                        description: lib.description,
                        tags: lib.tags.join(', ') // Assuming you want to convert the tags array to a string
                    })),
                    createdAt: response.data.created_at.substring(0, 10),
                    is_test: response.data.is_test,
                    topics: response.data?.topics

                }


                // Dispatch the action to set the list of agents with the mapped data
                dispatch(setIsLoadingAgentDetails(false))
                dispatch(setAgentConfigToEdit(mappedData));
                // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched agent configuration" }));
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingAgentDetails(false))
            dispatch(showToastMessage({ type: 'error', message: "Something went wrong while fetching agent configurations" }));
        })
        .finally(() => {
            setTimeout(() => {
                dispatch(setIsLoadingAgentDetails(false))
            }, 3000);
            ;
        });
};


const fetchQAChatbotAgent = (data) => (dispatch) => {
    dispatch(setIsLoadingAgentDetails(true));
    AgentsAPI.getQAAgentConfig(data)
        .then((response) => {
            if (response.data) {
                const mappedData = {
                    type: response.data?.action === "check-in" ? "Intent" : "Question",
                    id: response.data.id,
                    name: response.data.agent_name,
                    model: response.data.model,
                    generativeModel: response.data.model,
                    tokenLimit: '-',
                    isToneActive: response.data.is_tone_active,
                    tone: response.data.tone,
                    isActionActive: response.data.is_action_active,
                    action: response.data.action,
                    creativity: response.data.creativity,
                    questions: response.data.questions, // Parse the questions string into an array
                    systemPrompt: response.data.system_prompt,
                    disclaimer: response.data.disclaimer,
                    is_test: response.data.is_test,
                    qaQuestions: Object.keys(response.data.qa_questions).map(key => ({
                        question: response.data.qa_questions[key].question_prompt,
                        answer: response.data.qa_questions[key].answer,
                        defaultPosition: response.data.qa_questions[key].default_position
                    })),
                    createdAt: response.data.created_at.substring(0, 10),
                    topics: response.data?.topics,
                    
                }

                // Dispatch the action to set the list of agents with the mapped data
                dispatch(setIsLoadingAgentDetails(false))
                dispatch(setAgentConfigToEdit(mappedData));
                // dispatch(setAgentConfigToEdit([]));
                // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched agent configuration" }));
            }
        })
        .catch((error) => {
            console.error(error);
            dispatch(setIsLoadingAgentDetails(false))
            dispatch(showToastMessage({ type: 'error', message: "Something went wrong while fetching agent configurations" }));
        })
        .finally(() => {
            setTimeout(() => {
                dispatch(setIsLoadingAgentDetails(false))
            }, 3000);
            ;
        });
};


const createChatbotAgentConfig = (data) => (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));

    AgentsAPI.createChatBotConfig(data).then((response) => {

        if (!response.data.error) {
            console.log(response.data)
            dispatch(showToastMessage({ type: 'success', message: "Successfully created agent configuration" }))
            dispatch(setAgentConfigToTest(response?.data))
            dispatch(fetchListOfChatbotAgents())
            if (!data.is_test)
                dispatch(setIsConfigCreated(true));
            else
                dispatch(setAgentConfigToEdit(response?.data))
        } else {
            dispatch(showToastMessage({ type: 'error', message: response.data.error }))

        }
    }).catch((error) => {
        console.log(error)
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while creating agent configuration" }))
    })
}

const createQuestionChatbotAgentConfig = (data) => (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));

    AgentsAPI.createQuestionChatBotConfig(data).then((response) => {

        if (!response.data.error) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully created question agent configuration" }))
            dispatch(setAgentConfigToTest(response?.data))
            dispatch(fetchListOfChatbotAgents())
            dispatch(fetchListOfQAChatbotAgents())

            if (!data.is_test)
                dispatch(setIsConfigCreated(true));
            else
                dispatch(setAgentConfigToEdit(response?.data))
            dispatch(setIsConfigCreated(true))
        } else {
            dispatch(showToastMessage({ type: 'error', message: response.data.error }))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while creating agent configuration" }))
    })
}

const updateAgentConfig = (data) => (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));
    AgentsAPI.updateAgentConfig({ id: data.id, payload: data }).then((response) => {
        if (response.data) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated agent configuration" }))
            if (data.is_test) {
                dispatch(setAgentConfigToTest(response.data))
            } else {
                dispatch(fetchListOfChatbotAgents())
            }

            dispatch(setIsConfigCreated(true));
        }
    }).catch((error) => {
        console.log(error)
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while updating agent configuration" }))
    })
}

const updateAgentConfigForTest = (data) => (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));
    AgentsAPI.updateAgentConfigForTest({ pro_id: data.pro_id, payload: data }).then((response) => {
        if (response.data) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated agent configuration for testing" }))
            if (data.is_test) {
                dispatch(setAgentConfigToTest(response.data))
                dispatch(setCurrentSelectedChatbot(response.data))
                dispatch(generateNewConversationId({ config_id: response?.data?.id }))
            } else {
                dispatch(fetchListOfChatbotAgents())
            }
            dispatch(setIsConfigCreated(true));
        }
    }).catch((error) => {
        console.log(error)
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while updating agent configuration" }))
    })
}


const updateQAAgentConfig = (data) => (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));
    AgentsAPI.updateQAAgentConfig({ id: data.id, payload: data }).then((response) => {
        if (response.data) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated agent configuration" }))
            dispatch(setAgentConfigToTest(response.data))
            dispatch(fetchListOfQAChatbotAgents())
            dispatch(fetchListOfChatbotAgents())
            dispatch(setIsConfigCreated(true));
            dispatch(setAgentConfigToEdit(response?.data))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while updating agent configuration" }))
    })
}

const deleteAgentConfig = (data) => (dispatch) => {
    dispatch(setIsDeletingAgentConfig(true));
    AgentsAPI.deleteAgentConfig(data).then((response) => {
        dispatch(setIsDeletingAgentConfig(false));
        dispatch(fetchListOfChatbotAgents())
        dispatch(showToastMessage({ type: 'success', message: "Successfully deleted agent configuration" }))
         
    }).catch((error) => {
        console.log(error)
        dispatch(setIsDeletingAgentConfig(false));
    })
}

const deleteAQAgentConfig = (data) => (dispatch) => {
    dispatch(setIsDeletingAgentConfig(true));
    AgentsAPI.deleteQAAgentConfig(data).then((response) => {
        dispatch(setIsDeletingAgentConfig(false));
        dispatch(fetchListOfQAChatbotAgents())
        dispatch(showToastMessage({ type: 'success', message: "Successfully deleted QA agent configuration" }))

    }).catch((error) => {
        console.log(error)
        dispatch(setIsDeletingAgentConfig(false));
        dispatch(showToastMessage({ type: 'error', message: "Facing issue while deleting the QA agent configuration" }))

    })
}

const handleCreateAnalystAgentConfig = (data) => async (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));
    try {
        const response = await AgentsAPI.createAnalystConfig(data);
        dispatch(fetchListOfAnalystAgents());
        dispatch(showToastMessage({type: 'success', message: 'Successfully created new agent!'}));
    } catch (error) {
        dispatch(showToastMessage({type: 'error', message: 'Failed to create new analyst agent'}));
    } finally {
        dispatch(setIsCreatingAgentConfig(false));
    }
}

const updateAnalystAgentConfig = (data) => async (dispatch) => {
    dispatch(setIsCreatingAgentConfig(true));
    dispatch(setIsConfigCreated(false));

    try {
        const response = await AgentsAPI.updateAnalystConfig({ id: data.id, payload: data });
        if (response.data) {
            dispatch(showToastMessage({ type: 'success', message: "Successfully updated agent configuration" }));
            dispatch(setAgentConfigToTest(response.data));
            dispatch(fetchListOfAnalystAgents());
            dispatch(setIsConfigCreated(true));
            dispatch(setAgentConfigToEdit(response.data));
        }
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: "Something went wrong while updating agent configuration" }));
    }
};

const deleteAnalystAgentConfig = (data) => async (dispatch) => {
    dispatch(setIsDeletingAgentConfig(true));
    try {
        const response = await AgentsAPI.deleteAnalystConfig(data)
        dispatch(setIsDeletingAgentConfig(false));
        dispatch(fetchListOfAnalystAgents())        
        dispatch(showToastMessage({ type: 'success', message: "Successfully deleted analyst agent" }))
    } catch (error) {
        dispatch(setIsDeletingAgentConfig(false));
        dispatch(showToastMessage({type:'error', message: 'Failed to delete analyst agent'}))
    }
}

export {
    fetchListOfChatbotAgents,
    createChatbotAgentConfig,
    updateAgentConfig,
    deleteAgentConfig,
    createQuestionChatbotAgentConfig,
    fetchListOfQAChatbotAgents,
    updateQAAgentConfig,
    deleteAQAgentConfig,
    fetchChatbotAgent,
    fetchQAChatbotAgent,
    updateAgentConfigForTest,
    fetchListOfAnalystAgents,
    handleCreateAnalystAgentConfig,
    deleteAnalystAgentConfig,
    updateAnalystAgentConfig
}