import styled from 'styled-components';


const StyledAppItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary};
  max-width: 100%;
  min-width: 70%;

  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;


export { StyledAppItemContainer };