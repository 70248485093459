import { createSlice } from '@reduxjs/toolkit';

const productionDashboardSlice = createSlice({
    name: 'production_dashboard',
    initialState: { 
        loading: false,
        new_positive_reaction: null,
        new_negative_reaction: null,
        new_feedback_message: null
    },
    reducers: {
        toggleLoading: (state, action) => {
            state.loading = !state.loading;
        },
        setPositiveReaction: (state, action) =>{
            state.new_positive_reaction = action.payload
        },
        setNegativeReaction: (state, action) =>{
            state.new_negative_reaction = action.payload
        },
        setNewFeedbackMessage: (state, action) =>{
            state.new_feedback_message = action.payload
        }
    },
});

export const { toggleLoading, setPositiveReaction, setNegativeReaction, setNewFeedbackMessage } = productionDashboardSlice.actions;
export default productionDashboardSlice.reducer;
