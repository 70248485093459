export const listOfColumns = [{
    title: "File Name",
    sub_title: "A-Z",
    field_name: "label",
    type: "text_with_sorting_option",
    sort_option: [
        'A-Z',
        'Z-A'],
   
    width: "20%"
},
{
    title: "Description",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "description",
    sort_option: [
        'A-Z',
        'Z-A'],
    width: "55%"
},
{
    title: "No.  of documents",
    sub_title: "Largest to smallest",
    type: "number_with_sorting_option",
    field_name: "number_of_documents",
    sort_option: [
        'Largest to smallest',
        'Smallest to largest'
    ],
   
    width: "15%"
},
{
    title: "Actions",
    field_name: "actions_list",
    type: "dropdown_actions",
    width: "5%"
},
{
    title: "",
    field_name: "actions_title",
    type: "button_action",
    action:"View",
    width: "5%"
}
]