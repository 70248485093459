
import { useEffect, useState } from 'react';
import { CustomDropdown } from '../drop_downs';
import { TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6 } from './models_types';
import { StyledLabel } from './styled';
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { TYPE_1 as TYPE_1_dropdown, TYPE_2 as TYPE_2_dropdown } from '../drop_downs/drop_down_types';

import { getListOfSeparators } from '../../../modules/dashboard/logic/library/selectors';
import { getIsLoadingListOfChatModels, getIsLoadingListOfChunkingListModels, getIsLoadingListOfEmbeddingModels, getIsLoadingListOfSearchQueryModels, getIsLoadingListOfSummarizationModels, getListOfChatModels, getListOfChunkingListModels, getListOfEmbeddingModels, getListOfSearchQueryModels, getListOfSummarizationModels } from '../../../modules/dashboard/logic/llms/selectors';
import { fetchListOfChunkingMethods, fetchListOfSeparators } from '../../../modules/dashboard/logic/library/actions';
import { fetchChunkingMethods, fetchLLms } from '../../../modules/dashboard/logic/llms/actions';

const CustomModelDropDown = ({
    type = TYPE_1,

    list_of_separators,
    loadChunkingList,
    loadSeparatorList,
    list_of_embedding_models,
    list_of_chunking_list_models,
    list_of_summarization_models,
    list_of_chat_models,
    list_of_search_query_models,
    is_loading_list_of_embedding_models,
    is_loading_list_of_chunking_list_models,
    is_loading_list_of_summarization_models,
    is_loading_list_of_chat_models,
    is_loading_list_of_search_query_models,
    fetchLLms,
    fetchChunkingMethods,

    handleOnClick,
    defaultValue,
    setDefaultLLM,
    defaultLLM,
    current_theme,

    ...props
}) => {

    const [listOfEmbeddingModels, setListOfEmbeddingModels] = useState([]);
    const [listOfChatModels, setListOfChatModels] = useState([]);
    const [listOfSummarizationModels, setListOfSummarizationModels] = useState([]);
    const [listOfChunkingModels, setListOfChunkingModels] = useState([]);
    const [listOfSearchQueryModels, setListOfSearchQueryModels] = useState([]);
    const [listOfSeparators, setListOfSeparators] = useState([]);

    const [listOfPublicLLMEmbeddingModels, setListOfPublicLLMEmbeddingModels] = useState([])
    const [listOfPrivateLLMEmbeddingModels, setListOfPrivateLLMEmbeddingModels] = useState([])
    const [listOfPublicLLMSummarizationModels, setListOfPublicLLMSummarizationModels] = useState([]);
    const [listOfPrivateLLMSummarizationModels, setListOfPrivateLLMSummarizationModels] = useState([]);
    const [listOfPublicLLMSearchQueryModels, setListOfPublicLLMSearchQueryModels] = useState([]);
    const [listOfPrivateLLMSearchQueryModels, setListOfPrivateLLMSearchQueryModels] = useState([]);
    const [listOfPublicLLMChatbotModels, setListOfPublicLLMChatbotModels] = useState([]);
    const [listOfPrivateLLMChatbotModels, setListOfPrivateLLMChatbotModels] = useState([]);

    const [currentSelectedEmbeddingModel, setCurrentSelectedEmbeddingModel] = useState(null);
    const [currentSelectedSummarizationModel, setCurrentSelectedSummarizationModel] = useState(null);
    const [currentSelectedSearchQueryModel, setCurrentSelectedSearchQueryModel] = useState(null);
    const [currentSelectedChatbotModel, setCurrentSelectedChatbotModel] = useState(null);
    const [currentSelectedChunkingModel, setCurrentSelectedChunkingModel] = useState([]);
    const [currentSelectedSeparator, setCurrentSelectedSeparator] = useState([]);
    const [currentSelectedRadioButton, setCurrentSelectedRadioButton] = useState('PublicLLM')
   
    const [temporaryEmbeddingModel, setTemporaryEmbeddingModel] = useState(null);
    const [temporaryChatbotModel, setTemporaryChatbotModel] = useState(null);
    const [temporarySummarizationModel, setTemporarySummarizationModel] = useState(null);
    const [temporarySearchQueryModel, setTemporarySearchQueryModel] = useState(null);

    useEffect(() => {
        loadSeparatorList();
        fetchLLms();
        fetchChunkingMethods();
    }, [])

    useEffect(() => {
        if (defaultLLM === null || defaultLLM === undefined) {
            // need to set public llm
        } else {
            if (defaultLLM === 'PublicLLM' || defaultLLM === 'PrivateLLM')
                setCurrentSelectedRadioButton(defaultLLM)
            else {
                // need to set public llm
            }
        }
    }, [defaultLLM])

    useEffect(() => {
        console.log('listOfPublicLLMEmbeddingModels,listOfPrivateLLMEmbeddingModels', listOfPublicLLMEmbeddingModels, listOfPrivateLLMEmbeddingModels, listOfEmbeddingModels, defaultValue)
    }, [listOfPublicLLMEmbeddingModels, listOfPrivateLLMEmbeddingModels, listOfEmbeddingModels, defaultValue])

    useEffect(() => {
        const initializeModels = (list, setPublicList, setPrivateList, setCurrentModel, setTemporaryModel) => {
            if (Array.isArray(list) && list.length > 0) {
                const publicLLM = list.filter(model => model.llm_type === "PublicLLM");
                const privateLLM = list.filter(model => model.llm_type === "PrivateLLM");
                setPublicList(publicLLM);
                setPrivateList(privateLLM);
                // console.log("privateLLM,publicLLM", privateLLM, publicLLM)
                // console.log("currentSelectedRadioButton", currentSelectedRadioButton)
                let defaultModel = currentSelectedRadioButton === 'PublicLLM' ? publicLLM[0] : privateLLM[0];
                if (defaultValue !== null && defaultValue !== undefined) {
                    defaultModel = list.find(model => model.value === defaultValue) || defaultModel;
                }
                // console.log('defaultModel',defaultModel)
                setCurrentModel(defaultModel);
                setTemporaryModel(defaultModel);
            }
        };

        initializeModels(
            listOfEmbeddingModels,
            setListOfPublicLLMEmbeddingModels,
            setListOfPrivateLLMEmbeddingModels,
            setCurrentSelectedEmbeddingModel,
            setTemporaryEmbeddingModel
        );

        initializeModels(
            listOfSummarizationModels,
            setListOfPublicLLMSummarizationModels,
            setListOfPrivateLLMSummarizationModels,
            setCurrentSelectedSummarizationModel,
            setTemporarySummarizationModel
        );

        initializeModels(
            listOfSearchQueryModels,
            setListOfPublicLLMSearchQueryModels,
            setListOfPrivateLLMSearchQueryModels,
            setCurrentSelectedSearchQueryModel,
            setTemporarySearchQueryModel
        );

        initializeModels(
            listOfChatModels,
            setListOfPublicLLMChatbotModels,
            setListOfPrivateLLMChatbotModels,
            setCurrentSelectedChatbotModel,
            setTemporaryChatbotModel
        );
    }, [listOfEmbeddingModels, listOfSummarizationModels, listOfSearchQueryModels, listOfChatModels, currentSelectedRadioButton, defaultValue]);


    useEffect(() => {
        if (temporaryEmbeddingModel) {
            setCurrentSelectedEmbeddingModel(temporaryEmbeddingModel);
        }
        if (temporarySummarizationModel) {
            setCurrentSelectedSummarizationModel(temporarySummarizationModel);
        }
        if (temporarySearchQueryModel) {
            setCurrentSelectedSearchQueryModel(temporarySearchQueryModel);
        }
        if (temporaryChatbotModel) {
            setCurrentSelectedChatbotModel(temporaryChatbotModel);
        }
    }, [temporaryEmbeddingModel, listOfPrivateLLMEmbeddingModels, listOfPublicLLMEmbeddingModels, temporarySummarizationModel, temporarySearchQueryModel, temporaryChatbotModel]);


    useEffect(() => {
        const handleListUpdate = (listType, list) => {
            switch (listType) {
                case 'separators':
                    if (list?.length > 0) {
                        setListOfSeparators(list);
                    }
                    break;
                case 'chunking':
                    if (list?.length > 0) {
                        setListOfChunkingModels(list);
                    }
                    break;
                case 'embedding':
                    if (list?.length > 0) {
                        setListOfEmbeddingModels(list);
                    }
                    break;
                case 'searchQuery':
                    if (list?.length > 0) {
                        setListOfSearchQueryModels(list);
                    }
                    break;
                case 'summarization':
                    if (list?.length > 0) {
                        setListOfSummarizationModels(list);
                    }
                    break;
                case 'chat':
                    if (list?.length > 0) {
                        setListOfChatModels(list);
                    }
                    break;
                default:
                    break;
            }
        };
        handleListUpdate('separators', list_of_separators);
        handleListUpdate('chunking', list_of_chunking_list_models);
        handleListUpdate('embedding', list_of_embedding_models);
        handleListUpdate('searchQuery', list_of_search_query_models);
        handleListUpdate('summarization', list_of_summarization_models);
        handleListUpdate('chat', list_of_chat_models);
    }, [list_of_separators, list_of_chunking_list_models, list_of_embedding_models, list_of_search_query_models, list_of_summarization_models, list_of_chat_models]);

    useEffect(() => {
        if (defaultValue != undefined && defaultValue != null) {
            setCurrentSelectedChunkingModel({ value: defaultValue });
            // setListOfEmbeddingModels([{ value: defaultValue }]);
            setCurrentSelectedSeparator({ value: defaultValue });
        } else {

            const embeddingModels = Array.isArray(listOfEmbeddingModels) ? listOfEmbeddingModels : [];
            const chunkingModels = Array.isArray(listOfChunkingModels) ? listOfChunkingModels : [];
            const separators = Array.isArray(listOfSeparators) ? listOfSeparators : [];

            // setCurrentSelectedChunkingModel(chunkingModels[0]?.value);
            // setListOfEmbeddingModels(embeddingModels[0]?.value);
            // setCurrentSelectedSeparator(separators[0]?.label);
        }
    }, [defaultValue]);

    return (
        <div className={styles.container}>
            {
                type === TYPE_1 && <CustomDropdown dropdown_type={TYPE_2_dropdown} onClick={handleOnClick} public_options={listOfPublicLLMEmbeddingModels} private_options={listOfPrivateLLMEmbeddingModels} defaultEmbeddingModelValue={currentSelectedEmbeddingModel?.value} theme={current_theme} is_loading={is_loading_list_of_embedding_models} defaultLLM={defaultLLM} currentSelectedRadioButton={currentSelectedRadioButton} setCurrentSelectedRadioButton={setCurrentSelectedRadioButton} setCurrentSelectedEmbeddingModel={setCurrentSelectedEmbeddingModel} list_type='listOfEmbeddingModels' />
            }
            {
                type === TYPE_2 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfChunkingModels} defaultChunkingMethodValue={currentSelectedChunkingModel?.value} theme={current_theme} is_loading={is_loading_list_of_chunking_list_models} list_type='listOfChunkingMethods' />
            }
            {
                type === TYPE_3 && <CustomDropdown dropdown_type={TYPE_1_dropdown} onClick={handleOnClick} options_list={listOfSeparators} defaultSeparatorMethodValue={currentSelectedSeparator?.value} theme={current_theme} is_loading={false} list_type='listOfSeparators' />
            }
            {
                type === TYPE_4 && <CustomDropdown dropdown_type={TYPE_2_dropdown} onClick={handleOnClick} public_options={listOfPublicLLMChatbotModels} private_options={listOfPrivateLLMChatbotModels} defaultChatModelValue={currentSelectedChatbotModel?.value} theme={current_theme} is_loading={is_loading_list_of_chat_models} defaultLLM={defaultLLM} currentSelectedRadioButton={currentSelectedRadioButton} setCurrentSelectedRadioButton={setCurrentSelectedRadioButton} setCurrentSelectedChatbotModel={setCurrentSelectedChatbotModel} list_type='listOfChatModels' />
            }
            {
                type === TYPE_5 && <CustomDropdown dropdown_type={TYPE_2_dropdown} onClick={handleOnClick} public_options={listOfPublicLLMSummarizationModels} private_options={listOfPrivateLLMSummarizationModels} defaultSummarizationModelValue={currentSelectedSummarizationModel?.value} theme={current_theme} is_loading={is_loading_list_of_summarization_models} defaultLLM={defaultLLM} currentSelectedRadioButton={currentSelectedRadioButton} setCurrentSelectedRadioButton={setCurrentSelectedRadioButton} setCurrentSelectedSummarizationModel={setCurrentSelectedSummarizationModel} list_type='listOfSummarizationModel' />
            }
            {
                type === TYPE_6 && <CustomDropdown dropdown_type={TYPE_2_dropdown} onClick={handleOnClick} public_options={listOfPublicLLMSearchQueryModels} private_options={listOfPrivateLLMSearchQueryModels} defaultSearchQueryModelModelValue={currentSelectedSearchQueryModel?.value} theme={current_theme} is_loading={is_loading_list_of_search_query_models} defaultLLM={defaultLLM} currentSelectedRadioButton={currentSelectedRadioButton} setCurrentSelectedRadioButton={setCurrentSelectedRadioButton} setCurrentSelectedSearchQueryModel={setCurrentSelectedSearchQueryModel} list_type='listOfSearchQueryModel' />
            }
        </div>
    );
};

const mapState = (state) => ({
    list_of_separators: getListOfSeparators(state),
    list_of_embedding_models: getListOfEmbeddingModels(state),
    list_of_chunking_list_models: getListOfChunkingListModels(state),
    list_of_summarization_models: getListOfSummarizationModels(state),
    list_of_chat_models: getListOfChatModels(state),
    list_of_search_query_models: getListOfSearchQueryModels(state),

    is_loading_list_of_embedding_models: getIsLoadingListOfEmbeddingModels(state),
    is_loading_list_of_chunking_list_models: getIsLoadingListOfChunkingListModels(state),
    is_loading_list_of_summarization_models: getIsLoadingListOfSummarizationModels(state),
    is_loading_list_of_chat_models: getIsLoadingListOfChatModels(state),
    is_loading_list_of_search_query_models: getIsLoadingListOfSearchQueryModels(state),

})

const mapDispatchToProps = (dispatch) => ({
    loadChunkingList: (data) => dispatch(fetchListOfChunkingMethods(data)),
    loadSeparatorList: (data) => dispatch(fetchListOfSeparators(data)),
    fetchLLms: (data) => dispatch(fetchLLms(data)),
    fetchChunkingMethods: (data) => dispatch(fetchChunkingMethods(data)),
});

export default connect(mapState, mapDispatchToProps)(CustomModelDropDown)



