import { useEffect, useState } from 'react'
import { MyStyledButton_type1, StyledCardContainer, StyledDivider, StyledHeading1, StyledHeading2, StyledHeading3, StyledHeading4 } from './styled'
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { CustomDateRangePicker, CustomDropdown_type_1 } from '../../../../../shared/ui/dropdown'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import { getNumberOfConfigurations, getNumberOfConversationsAndMessages, getNumberOfDocuments, getNumberOfUsers, getIsLoadingNumberOfUsers, getIsLoadingNumberOfDocuments, getIsLoadingNumberOfConfigurations, getIsLoadingNumberOfConversationsAndMessages } from '../../../logic/dashboard/selectors';
import dayjs from 'dayjs';
import loadingGif from '../../../../../assets/images/gif clairo.gif'

import {
    fetchNumberOfUsers,
    fetchNumberOfDocuments,
    fetchNumberOfConfigurations,
    fetchNumberOfConversationsAndMessages
} from '../../../logic/dashboard/actions';
import { CustomHeading } from '../../../../../shared/ui/headings';


const Home = ({
    current_theme,
    numberOfUsers,
    is_loading_numberOfUsers,
    numberOfDocuments,
    is_loading_numberOfDocuments,
    numberOfConfigurations,
    is_loading_numberOfConfigurations,
    numberOfConversationsAndMessages,
    is_loading_numberOfConversationsAndMessages,
    fetchNumberOfUsers,
    fetchNumberOfDocuments,
    fetchNumberOfConfigurations,
    fetchNumberOfConversationsAndMessages,
    ...props }) => {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [currentDateRange, setCurrentDateRange] = useState(null)
    const [numberOfBotConfigs, setNumberOfBotConfigs] = useState({
        draft: 0,
        published: 0
    })

    const [numberOfDocumentsLocal, setNumberOfDocuments] = useState({
        active: 0,
        inactive: 0
    })

    const [listOfConversationsAndMessages, setListOfConversationsAndMessages] = useState([])
    const [numberOfConversations, setNumberOfConversations] = useState(0)
    const [numberOfMessages, setNumberOfMessages] = useState(0)

    const [listOfDocumentsGroupedByLibrary, setListOfDocumentsGroupedByLibrary] = useState({});
    const [listOfConfigsGroupedByBotType, setListOfConfigsGroupedByBotType] = useState({});

    const [showConfigOfCurrentSelectedOption, setShowConfigOfCurrentSelectedOption] = useState("chatbot")
    const [libraries, setLibraries] = useState([
        { value: 'default', label: 'All' },
    ])
    const [numberOfUsersLocal, setNumberOfUsers] = useState(0)
    const [botTypes, setBotsType] = useState([
        { value: 'default', label: 'All' },
        { value: 'chat_bot', label: 'Chatbot' },
        { value: 'aq_chat_bot', label: 'QA Chatbot' },
    ])

    const handleOpenSpecificBotConfigScreen = (selectedOption) => {
        switch (selectedOption.value) {
            case "default":
                console.log("listOfConfigsGroupedByBotType", listOfConfigsGroupedByBotType)
                setNumberOfBotConfigs({
                    draft: listOfConfigsGroupedByBotType['chat_bot']?.draft ?? 0,
                    published: listOfConfigsGroupedByBotType['chat_bot']?.published ?? 0
                });
                break;
            case "chat_bot":
                setNumberOfBotConfigs({
                    draft: listOfConfigsGroupedByBotType['chat_bot']?.draft ?? 0,
                    published: listOfConfigsGroupedByBotType['chat_bot']?.published ?? 0
                });
                break;

            case "aq_chat_bot":
                setNumberOfBotConfigs({
                    draft: 0,
                    published: 0
                })
                break;
            default:
                setNumberOfBotConfigs({
                    draft: 0,
                    published: 0
                })
                break;
        }
    }

    const handleSpecificLibrarySelection = (selectedOption) => {
        console.log('handleSpecificLibrarySelection', selectedOption)
        if (selectedOption.value != "default") {
            let currentSelectedLibrary = listOfDocumentsGroupedByLibrary.find((doc) => doc.id === selectedOption.value)
            console.log('currentSelectedLibrary', currentSelectedLibrary)
            setNumberOfDocuments({
                active: currentSelectedLibrary?.active_count,
                inactive: currentSelectedLibrary?.inactive_count
            })
        } else {
            // When the default option is selected, sum up all active and inactive counts
            let sumOfActive = listOfDocumentsGroupedByLibrary.reduce((acc, doc) => acc + (doc.active_count || 0), 0);
            let sumOfInactive = listOfDocumentsGroupedByLibrary.reduce((acc, doc) => acc + (doc.inactive_count || 0), 0);

            setNumberOfDocuments({
                active: sumOfActive,
                inactive: sumOfInactive
            });
        }
    }


    useEffect(() => {
        fetchNumberOfUsers();
        fetchNumberOfDocuments();
        fetchNumberOfConfigurations();
        fetchNumberOfConversationsAndMessages();
    }, [fetchNumberOfUsers, fetchNumberOfDocuments, fetchNumberOfConfigurations, fetchNumberOfConversationsAndMessages]);


    useEffect(() => {
        try {
            console.log("currentDateRange", currentDateRange)
            if (currentDateRange != null) {
                console.log("currentDateRange-1", currentDateRange)
                const startDate = dayjs(currentDateRange[0]['$d']).format('YYYY-MM-DD');
                const endDate = dayjs(currentDateRange[1]['$d']).format('YYYY-MM-DD');
                console.log("currentDateRange-2", currentDateRange)
                console.log("startDate", startDate);
                console.log("endDate", endDate);
                setStartDate("startDate", startDate);
                setStartDate("endDate", endDate);
                console.log("listOfConversationsAndMessages-filter", listOfConversationsAndMessages)
                filterNumberOfConversations(listOfConversationsAndMessages, startDate, endDate)
            }
        } catch (error) {
            console.error(error)
        }
    }, [currentDateRange, listOfConversationsAndMessages])

    useEffect(() => {
        if (numberOfUsers !== undefined) {
            console.log("numberOfUsers", numberOfUsers)
            setNumberOfUsers(numberOfUsers?.non_superuser_count);
        }
    }, [numberOfUsers]);

    useEffect(() => {
        if (numberOfDocuments !== undefined) {
            console.log("numberOfDocuments", numberOfDocuments)
            setNumberOfDocuments(numberOfDocuments);

            setListOfDocumentsGroupedByLibrary(numberOfDocuments)
            let listOfLibrary = numberOfDocuments?.map((lib) => {
                return { "value": lib.id, "label": lib.library_name }
            })
            console.log("listOfLibrary", listOfLibrary)
            if (listOfLibrary !== undefined)
                setLibraries([...libraries, ...listOfLibrary]);

        }
    }, [numberOfDocuments]);

    useEffect(() => {
        if (numberOfConfigurations !== null) {
            console.log("numberOfConfigurations", numberOfConfigurations)
            const { draft, published } = numberOfConfigurations;
            setNumberOfBotConfigs({ draft, published });
            setListOfConfigsGroupedByBotType({
                "chat_bot": numberOfConfigurations
            })

        }
    }, [numberOfConfigurations]);

    useEffect(() => {
        if (numberOfConversationsAndMessages !== undefined && numberOfConversationsAndMessages?.length > 0) {
            // Assuming numberOfConversationsAndMessages is an array of objects with details
            // about each conversation and its messages. You might need to adjust this
            // depending on the actual structure of your data.
            console.log("numberOfConversationsAndMessages", numberOfConversationsAndMessages)
            const totalConversations = numberOfConversationsAndMessages.reduce((acc, conversation) => acc + conversation.conversation_count, 0);
            const totalMessages = numberOfConversationsAndMessages.reduce((acc, conversation) => acc + conversation.total_messages, 0);
            setListOfConversationsAndMessages(numberOfConversationsAndMessages)
            setNumberOfConversations(totalConversations);
            setNumberOfMessages(totalMessages);
        }
    }, [numberOfConversationsAndMessages]);

    // // Example useEffect for filtering conversations by date range
    // useEffect(() => {
    //     if (currentDateRange !== null) {
    //         const [start, end] = currentDateRange; // Assuming currentDateRange is an array [startDate, endDate]
    //         const formattedStartDate = dayjs(start).format('YYYY-MM-DD');
    //         const formattedEndDate = dayjs(end).format('YYYY-MM-DD');

    //         filterNumberOfConversations(listOfConversationsAndMessages, formattedStartDate, formattedEndDate);
    //     }
    // }, [currentDateRange, listOfConversationsAndMessages]);

    // useEffect(() => {

    //     if (analytics != null) {

    //         if (Object.keys(analytics).length > 0) {
    //             console.log(analytics)
    //             setListOfConfigsGroupedByBotType({
    //                 "chat_bot": analytics?.configurations
    //             })
    //             setListOfDocumentsGroupedByLibrary(analytics?.library_documents)
    //             let listOfLibrary = analytics?.library_documents?.map((lib) => {
    //                 return { "value": lib.id, "label": lib.library_name }
    //             })
    //             console.log("listOfLibrary", listOfLibrary)
    //             setLibraries([...libraries, ...listOfLibrary]);
    //             // setNumberOfUsers(analytics?.non_superuser_count)
    //             // setListOfConversationsAndMessages(analytics?.conversations)
    //             // filterNumberOfConversations(analytics?.conversations, startDate, endDate)
    //         }
    //     }
    // }, [analytics])

    useEffect(() => {
        if (libraries.length > 1) {
            handleSpecificLibrarySelection({ value: "default" });
            handleOpenSpecificBotConfigScreen({ value: "default" })
        }
    }, [libraries, listOfConfigsGroupedByBotType])

    const filterNumberOfConversations = (listOfConversations, startDate, endDate) => {
        // Filter conversations based on the specified start and end date
        try {
            console.log("listOfConversations", listOfConversations)
            const filteredConversations = listOfConversations.filter(conversation => {
                const creationDate = new Date(conversation.creation_date);
                const creationDateString = creationDate.toISOString().split('T')[0]; // Extract YYYY-MM-DD from ISO string
                // Convert startDate and endDate to YYYY-MM-DD format
                console.log("startDate['$d']", startDate['$d']);
                console.log(" endDate['$d']", endDate['$d']);
                console.log("startDate", startDate);
                console.log("endDate", endDate);
                let startDateString = null;
                let endDateString = null;

                if (startDate['$d'] !== undefined && endDate['$d'] !== undefined) {
                    startDateString = dayjs(startDate['$d']).format('YYYY-MM-DD')
                    endDateString = dayjs(endDate['$d']).format('YYYY-MM-DD');

                } else {
                    startDateString = startDate
                    endDateString = endDate

                }

                console.log("startDateString", startDateString);
                console.log("endDateString", endDateString);


                return creationDateString >= startDateString && creationDateString <= endDateString;

                // return creationDate >= startDate && creationDate <= endDate;
            });
            console.log("filteredConversations", filteredConversations)
            // Calculate total number of conversations and total number of messages
            const totalNumberOfConversations = filteredConversations.reduce((acc, conversation) => acc + conversation.conversation_count, 0);
            const totalNumberOfMessages = filteredConversations.reduce((acc, conversation) => acc + conversation.total_messages, 0);

            // Update state with the filtered conversations
            // setListOfConversationsAndMessages(filteredConversations);

            // Update state with the total numbers
            setNumberOfConversations(totalNumberOfConversations);
            setNumberOfMessages(totalNumberOfMessages);
        } catch (error) {
            console.error(error)
        }
    }


    return <div className={styles.container}>
        <div className={styles.header_container}>
            <div className={styles.left_container}>
                <CustomHeading text='Dashboard' font_weight='bold' font_size='30' color='primary'/>
            </div>
            <div className={styles.right_container}>

            </div>
        </div>
        <div className={styles.row_1_cards}>
            <div className={styles.card_1_container}>
                <StyledCardContainer>
                    <div className={styles.header_container}>
                        <div className={styles.column_1}>
                            <StyledHeading2>All Users</StyledHeading2>
                        </div>
                    </div>
                    <StyledDivider />
                    <div className={styles.value_type_container}>
                        <div className={styles.column_1}>
                            <StyledHeading3>{is_loading_numberOfUsers ? 
                                <div className={styles.loading_container}>
                                    <img src={loadingGif} alt='loading' className={styles.icon}></img>
                                </div> : <div>{numberOfUsersLocal}</div>
                                }</StyledHeading3>
                            <StyledHeading4>who will be testing bots</StyledHeading4>
                        </div>
                    </div>
                </StyledCardContainer>
            </div>
            <div className={styles.card_2_container}>
                <StyledCardContainer>
                    <div className={styles.header_container}>
                        <div className={styles.column_1}>
                            <StyledHeading2>Agents</StyledHeading2>
                        </div>

                        <div className={styles.column_2}>
                            <CustomDropdown_type_1 options={botTypes} defaultValue="default" onClick={handleOpenSpecificBotConfigScreen} current_theme={current_theme} is_loading={false} />

                            {/* <MyStyledButton_type1 onClick={() => { setShowConfigOfCurrentSelectedOption("chatbot") }}>Chatbot</MyStyledButton_type1>
                            <MyStyledButton_type1 onClick={() => { setShowConfigOfCurrentSelectedOption("qa_chatbot") }}>QA Chatbot</MyStyledButton_type1> */}
                        </div>
                    </div>
                    <StyledDivider />
                    <div className={styles.value_type_container}>
                        <div className={styles.column_1}>
                            <StyledHeading3>{is_loading_numberOfConfigurations ? 
                                <div className={styles.loading_container}>
                                    <img src={loadingGif} alt='loading' className={styles.icon}></img>
                                </div> : <div>{numberOfBotConfigs.published}</div>
                                }</StyledHeading3>
                            <StyledHeading4>Published</StyledHeading4>
                        </div>
                        <div className={styles.column_2}>
                            <StyledHeading3>{numberOfBotConfigs.draft}</StyledHeading3>
                            <StyledHeading4>draft</StyledHeading4>
                        </div>
                    </div>

                </StyledCardContainer>
            </div>
            <div className={styles.card_3_container}>
                <StyledCardContainer>
                    <div className={styles.header_container}>
                        <div className={styles.column_1}>
                            <StyledHeading2>Documents</StyledHeading2>
                        </div>

                        <div className={styles.column_2}>
                            {/* <CustomDateRangePicker current_theme={current_theme}/> */}
                            <CustomDropdown_type_1 options={libraries} defaultValue="default" onClick={handleSpecificLibrarySelection} current_theme={current_theme} is_loading={false} />
                            {/* <MyStyledButton_type1 onClick={() => { setShowConfigOfCurrentSelectedOption("qa_chatbot") }}>QA Chatbot</MyStyledButton_type1> */}
                        </div>
                    </div>
                    <StyledDivider />
                    <div className={styles.value_type_container}>
                        <div className={styles.column_1}>
                            <StyledHeading3>{is_loading_numberOfDocuments ? 
                                <div className={styles.loading_container}>
                                    <img src={loadingGif} alt='loading' className={styles.icon}></img>
                                </div> : <div>{numberOfDocumentsLocal?.active}</div>
                                }</StyledHeading3>
                            <StyledHeading4>Active</StyledHeading4>
                        </div>
                        <div className={styles.column_2}>
                            <StyledHeading3>{numberOfDocumentsLocal?.inactive}</StyledHeading3>
                            <StyledHeading4>Paused</StyledHeading4>
                        </div>
                    </div>

                </StyledCardContainer>
            </div>
        </div>
        <div className={styles.row_2_cards}>

            <div className={styles.card_1_container}>
                <StyledCardContainer>
                    <div className={styles.header_container}>
                        <div className={styles.column_1}>
                            <StyledHeading2>Messages</StyledHeading2>
                        </div>

                        <div className={styles.column_2}>
                            <CustomDateRangePicker current_theme={current_theme} setCurrentDateRange={setCurrentDateRange} />

                            {/* <MyStyledButton_type1 onClick={() => { setShowConfigOfCurrentSelectedOption("chatbot") }}>Chatbot</MyStyledButton_type1>
                            <MyStyledButton_type1 onClick={() => { setShowConfigOfCurrentSelectedOption("qa_chatbot") }}>QA Chatbot</MyStyledButton_type1> */}
                        </div>
                    </div>
                    <StyledDivider />
                    <div className={styles.value_type_container}>
                        <div className={styles.column_1}>
                            <StyledHeading3>{is_loading_numberOfConversationsAndMessages ? 
                                <div className={styles.loading_container}>
                                    <img src={loadingGif} alt='loading' className={styles.icon}></img>
                                </div> : <div>{numberOfConversations}</div>
                                }</StyledHeading3>
                            <StyledHeading4>Conversations</StyledHeading4>
                        </div>
                        <div className={styles.column_2}>
                            <StyledHeading3>{numberOfMessages}</StyledHeading3>
                            <StyledHeading4>Messages</StyledHeading4>
                        </div>
                    </div>

                </StyledCardContainer>
            </div>

        </div>
    </div>
}


const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
    numberOfUsers: getNumberOfUsers(state),
    is_loading_numberOfUsers: getIsLoadingNumberOfUsers(state),
    numberOfDocuments: getNumberOfDocuments(state),
    is_loading_numberOfDocuments: getIsLoadingNumberOfDocuments(state),
    numberOfConfigurations: getNumberOfConfigurations(state),
    is_loading_numberOfConfigurations: getIsLoadingNumberOfConfigurations(state),
    numberOfConversationsAndMessages: getNumberOfConversationsAndMessages(state),
    is_loading_numberOfConversationsAndMessages: getIsLoadingNumberOfConversationsAndMessages(state)
});

const mapDispatchToProps = (dispatch) => ({
    fetchNumberOfUsers: () => dispatch(fetchNumberOfUsers()),
    fetchNumberOfDocuments: () => dispatch(fetchNumberOfDocuments()),
    fetchNumberOfConfigurations: () => dispatch(fetchNumberOfConfigurations()),
    fetchNumberOfConversationsAndMessages: () => dispatch(fetchNumberOfConversationsAndMessages()),
});


export default connect(mapState, mapDispatchToProps)(Home)