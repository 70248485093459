const TYPE_1 = "embedding_models"
const TYPE_2 = "chunking_method"
const TYPE_3 = "separators"
const TYPE_4 = "chatbot_models"
const TYPE_5 = "summarization_models"
const TYPE_6 = "search_query_models"
const TYPE_7 = "sql_generation_models"

export {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7
}


