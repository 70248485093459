import TextField from "@mui/material/TextField";
import styled from "styled-components";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Chip from "@mui/material/Chip";

const StyledMetaDataSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;

const StyledTextarea = styled(TextareaAutosize)`
  && {
    display: block;
    max-width: 98%;

    min-width: 98%;
    min-height: 50px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 14px;
    font-weight: 400;
    border-radius: 15px;
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
    align-items: center;
    align-content: center;
    justify-content: center;

    &::placeholder {
      color: ${({ theme }) => theme.palette.textColors.quaternary};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }
  }
`;



const StyledTagsListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap; /* Items will wrap to new lines */
  justify-content: flex-start;
  overflow: hidden;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledTagItem = styled.span`
  min-width:fit-content;
  gap:5px;
  padding:2px;
  margin-right:10px
  color:${({ theme }) => theme.palette.textColors.primary};
  border-radius:8px;

  background-color: ${({ theme }) => theme.palette.backgroundColors.tag_bg_color};
  `

const StyledTagChip = styled(Chip)`
&& {
  color: ${({ theme }) => theme.palette.textColors.chip_text_color};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background-color: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};
  cursor:pointer;
  font-size: 14px;
}
`;

const StyledChipTextField = styled(TextField)`
  && {
    display: flex;
    width: 65%;
    height: 28px;
    input {
      height: 0px;
      color: ${({ theme }) => theme.palette.textColors.primary};
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    border: 1px solid transparent;
    background: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};

    /* Shadow/xs */

    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: transparent;
      }
    }
  }
`;


const StyledChipCreateButton = styled.div`
  color: ${({ theme }) => theme.palette.textColors.primary};

  font-feature-settings: "clig" off, "liga" off;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  background-color: ${({ theme }) =>
    theme.palette.backgroundColors.tag_chip_bg};
  cursor: pointer;
  font-size: 10px;
  width: 50%;
  height: 60;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export {
  StyledTextarea,
  StyledTagItem,
  StyledTagsListContainer,
  StyledTagChip,
  StyledChipTextField,
  StyledChipCreateButton,
  StyledSideBarContainer,
  StyledMetaDataSideBarContainer,
};
