
import { BASE_URL, httpDelete, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance"
const USERS_BASE_URL = `${BASE_URL}/`

const getLLMs = (data) => httpGet(`${USERS_BASE_URL}api/llm/list/`, data);
const getChunking = (data) => httpGet(`${USERS_BASE_URL}api/llm/chunking/list`, data);

export {
    getLLMs,
    getChunking
}