import { Button, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const StyledTextarea = styled(TextareaAutosize)`
  && {
    display: block;
    width: 96.5%;
    min-height: 100px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    color:  ${({ theme }) => theme.palette.textColors.primary}; 
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  
    // &::placeholder {
    //   color: ${({ theme }) => theme.palette.textColors.primary};
    // }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }
  }
`;

const StyledSlider = styled(Slider)`
  && {
    color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color}; /* Set the color of the slider track and thumb */
    & .MuiSlider-markLabel {
      color: white; /* Change the color of the marks (steps) below the slider */
    }
  }
`;

const StyledAgentMainContainer = styled.div`
border-radius:20px;
  // background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;

const StyledQASideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;

const StyledAnalyticsSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
`;



// ------------------


export {
  StyledAgentMainContainer,
  StyledTextarea,
  StyledSlider,
  StyledSideBarContainer,
  StyledQASideBarContainer,
  StyledAnalyticsSideBarContainer
}