import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Back_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.921875 9.96875L9.92188 0.96875C10.4844 0.359375 11.4688 0.359375 12.0312 0.96875C12.6406 1.53125 12.6406 2.51562 12.0312 3.07812L4.10938 11L12.0312 18.9688C12.6406 19.5312 12.6406 20.5156 12.0312 21.0781C11.4688 21.6875 10.4844 21.6875 9.92188 21.0781L0.921875 12.0781C0.3125 11.5156 0.3125 10.5312 0.921875 9.96875Z" fill="white" fill-opacity="0.75"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Back_Shaded)