import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Documents_Un_Shaded = ({ current_theme, ...props }) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M3.87868 1.87868C4.44129 1.31607 5.20435 1 6 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8V20C21 20.7957 20.6839 21.5587 20.1213 22.1213C19.5587 22.6839 18.7956 23 18 23H6C5.20435 23 4.44129 22.6839 3.87868 22.1213C3.31607 21.5587 3 20.7957 3 20V4C3 3.20435 3.31607 2.44129 3.87868 1.87868ZM6 3C5.73478 3 5.48043 3.10536 5.29289 3.29289C5.10536 3.48043 5 3.73478 5 4V20C5 20.2652 5.10536 20.5196 5.29289 20.7071C5.48043 20.8946 5.73478 21 6 21H18C18.2652 21 18.5196 20.8946 18.7071 20.7071C18.8946 20.5196 19 20.2652 19 20V9H14C13.4478 9 13 8.55229 13 8.00001L13 3H6ZM15 4.41423L17.5858 7H15L15 4.41423ZM7.00003 8.99998C7.00003 8.4477 7.44775 7.99998 8.00003 7.99998H10C10.5523 7.99998 11 8.4477 11 8.99998C11 9.55227 10.5523 9.99998 10 9.99998H8.00003C7.44775 9.99998 7.00003 9.55227 7.00003 8.99998ZM7.00003 13C7.00003 12.4477 7.44775 12 8.00003 12H16C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14H8.00003C7.44775 14 7.00003 13.5523 7.00003 13ZM7.00003 17C7.00003 16.4477 7.44775 16 8.00003 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H8.00003C7.44775 18 7.00003 17.5523 7.00003 17Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Documents_Un_Shaded)