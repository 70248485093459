import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Response_Ticket_Un_Shaded = ({ current_theme, ...props }) => {
    return <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.83643 10.5C8.83643 9.98438 9.21143 9.5625 9.77393 9.5625C10.2896 9.5625 10.7114 9.98438 10.7114 10.5C10.7114 11.0156 10.2896 11.4375 9.77393 11.4375C9.21143 11.4375 8.83643 11.0156 8.83643 10.5ZM8.97705 3.375H10.3833C11.6021 3.375 12.6333 4.40625 12.6333 5.625C12.6333 6.46875 12.1646 7.21875 11.3677 7.59375L10.4771 8.0625V8.25C10.4771 8.67188 10.1489 9 9.72705 9C9.35205 9 8.97705 8.67188 8.97705 8.25V7.59375C8.97705 7.3125 9.16455 7.03125 9.39893 6.9375L10.7114 6.28125C10.9458 6.14062 11.1333 5.90625 11.1333 5.625C11.1333 5.20312 10.8052 4.875 10.3833 4.875H8.97705C8.64893 4.875 8.36768 5.15625 8.36768 5.48438C8.36768 5.90625 8.03955 6.23438 7.61768 6.23438C7.1958 6.23438 6.86768 5.90625 6.86768 5.48438C6.86768 4.3125 7.80518 3.375 8.97705 3.375ZM16.5239 15H10.5239L5.41455 17.9062C5.27393 18 5.18018 18 5.08643 18C4.7583 18 4.52393 17.8125 4.52393 17.4375V15H3.02393C1.33643 15 0.0239258 13.6875 0.0239258 12V3C0.0239258 1.35938 1.33643 0 3.02393 0H16.5239C18.1646 0 19.5239 1.35938 19.5239 3V12C19.5239 13.6875 18.1646 15 16.5239 15ZM16.5239 12.75C16.8989 12.75 17.2739 12.4219 17.2739 12V3C17.2739 2.625 16.8989 2.25 16.5239 2.25H3.02393C2.60205 2.25 2.27393 2.625 2.27393 3V12C2.27393 12.4219 2.60205 12.75 3.02393 12.75H6.77393V14.5781L9.91455 12.75H16.5239ZM14.2739 16.5V18C14.2739 18.4219 14.6021 18.75 15.0239 18.75H20.0864L23.2739 20.5781V18.75H27.0239C27.3989 18.75 27.7739 18.4219 27.7739 18V9C27.7739 8.625 27.3989 8.25 27.0239 8.25H21.0239V6H27.0239C28.6646 6 30.0239 7.35938 30.0239 9V18C30.0239 19.6875 28.6646 21 27.0239 21H25.5239V23.4375C25.5239 23.8125 25.2427 24 24.9614 24C24.8208 24 24.7271 24 24.5864 23.9062L19.5239 21H15.0239C13.3364 21 12.0239 19.6875 12.0239 18V16.5H14.2739Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>

}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Response_Ticket_Un_Shaded)