export const listOfColumns = [{
    title: "Name",
    sub_title: "A-Z",
    field_name: "name",
    type: "text_with_sorting_option",
    sort_option: [
        'A-Z',
        'Z-A'],

    width: "20%"
},
{
    title: "Model",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "model_name",
    sort_option: [
        'A-Z',
        'Z-A'],
    width: "12%"
},
{
    title: "Model Type",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "llmType",
    sort_option: [
        'All',
        'PublicLLM',
        'PrivateLLM'],
    width: "12%"
},

{
    title: "Last Updated",
    sub_title: "Newest",
    type: "text_with_sorting_option",
    field_name: "createdAt",
    sort_option: [
        'Newest',
        'Oldest'],
    width: "12%"
},
{
    title: "Status",
    sub_title: "All",
    type: "text_with_sorting_option",
    is_this_chip: true,
    field_name: "status",
    sort_option: [
        'All',
        'Published',
        'Draft'],
    width: "12%"
},
{
    title: "Type",
    sub_title: "All",
    type: "text_with_sorting_option",
    field_name: "agent_type",
    sort_option: [
        'All',
        'Question',
        'RAG',
        'Analytics'
    ],
    width: "12%"
},
{
    title: "Doc Retrieval",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "documentRetrieval",
    sort_option: [
        'All',
        'false',
        'true'
    ],
    width: "12%"
},
{
    title: "Actions",
    field_name: "actions_list",
    type: "dropdown_actions",
    width: "5%"
}]