
import { StyledHeadingType1 } from './styled';
import styles from './styles.module.scss'


// Function to calculate responsive font sizes
const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};


const CustomHeading = ({
    font_family = "secondary",
    text = "Please provide text",
    color = "primary",
    font_size = "12",
    unit = "px",
    font_weight = "normal",
    pick_passed_font_size = false,
    mobileFontSize = "13",
    tabletFontSize = "13",
    laptopFontSize = "13",
    macbook13FontSize = "13",

    ...props }) => {

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    // Determine font sizes to use based on `pick_passed_font_size`
    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    return <div className={styles.container}>
        <StyledHeadingType1
            fontFamily={font_family}
            fontSize={font_size + unit}
            fontWeight={font_weight}
            color={color}
            mobileFontSize={finalMobileFontSize}
            tabletFontSize={finalTabletFontSize}
            laptopFontSize={finalLaptopFontSize}
            macbook13FontSize={finalMacbook13FontSize}
            {...props}
        >
            {text}
        </StyledHeadingType1>
    </div>
};

export { CustomHeading }