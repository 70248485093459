import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Adjustment_Settings_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.375 0.25C3.83203 0.25 4.21875 0.636719 4.21875 1.09375V3.20312C5.34375 3.55469 6.1875 4.64453 6.1875 5.875C6.1875 7.14062 5.34375 8.23047 4.21875 8.58203V17.4062C4.21875 17.8984 3.83203 18.25 3.375 18.25C2.88281 18.25 2.53125 17.8984 2.53125 17.4062V8.58203C1.37109 8.23047 0.5625 7.14062 0.5625 5.875C0.5625 4.64453 1.37109 3.55469 2.53125 3.20312V1.09375C2.53125 0.636719 2.88281 0.25 3.375 0.25ZM3.375 7C3.97266 7 4.5 6.50781 4.5 5.875C4.5 5.27734 3.97266 4.75 3.375 4.75C2.74219 4.75 2.25 5.27734 2.25 5.875C2.25 6.50781 2.74219 7 3.375 7ZM11.8125 12.625C11.8125 13.8906 10.9688 14.9805 9.84375 15.332V17.4062C9.84375 17.8984 9.45703 18.25 9 18.25C8.50781 18.25 8.15625 17.8984 8.15625 17.4062V15.332C6.99609 14.9805 6.1875 13.8906 6.1875 12.625C6.1875 11.3945 6.99609 10.3047 8.15625 9.95312V1.09375C8.15625 0.636719 8.50781 0.25 9 0.25C9.45703 0.25 9.84375 0.636719 9.84375 1.09375V9.95312C10.9688 10.3047 11.8125 11.3945 11.8125 12.625ZM9 13.75C9.59766 13.75 10.125 13.2578 10.125 12.625C10.125 12.0273 9.59766 11.5 9 11.5C8.36719 11.5 7.875 12.0273 7.875 12.625C7.875 13.2578 8.36719 13.75 9 13.75ZM15.4688 17.4062C15.4688 17.8984 15.082 18.25 14.625 18.25C14.1328 18.25 13.7812 17.8984 13.7812 17.4062V9.70703C12.6211 9.35547 11.8125 8.26562 11.8125 7C11.8125 5.76953 12.6211 4.67969 13.7812 4.32812V1.09375C13.7812 0.636719 14.1328 0.25 14.625 0.25C15.082 0.25 15.4688 0.636719 15.4688 1.09375V4.32812C16.5938 4.67969 17.4375 5.76953 17.4375 7C17.4375 8.26562 16.5938 9.35547 15.4688 9.70703V17.4062ZM14.625 5.875C13.9922 5.875 13.5 6.40234 13.5 7C13.5 7.63281 13.9922 8.125 14.625 8.125C15.2227 8.125 15.75 7.63281 15.75 7C15.75 6.40234 15.2227 5.875 14.625 5.875Z" fill="#FF5A00"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Adjustment_Settings_Shaded)