import { createSlice } from '@reduxjs/toolkit';

const agentsSlice = createSlice({
    name: 'agents',
    initialState: {
        list_of_agents: [],
        list_of_qa_agents: [],
        list_of_analyst_agents: [],
        is_loading_list_of_agents: false,
        is_loading_agent_details: false,
        is_updating_agent_flags: false,
        is_creating_agent_config: false,
        is_deleting_agent_config: false,
        agent_config_to_edit: null,
        agent_config_to_test: null,
        is_config_created:false,
        is_agent_playground_visible:false
    },
    reducers: {
        setIsConfigCreated: (state, action) => {
            state.is_config_created = action.payload;
        },
        setIsAgentPlaygroundVisible: (state, action) => {
            state.is_agent_playground_visible = action.payload;
        },
        setListOfAgents: (state, action) => {
            state.list_of_agents = action.payload;
        },
        setListOfAnalystAgents: (state, action) => {
            state.list_of_analyst_agents = action.payload;
        },
        setListOfAQAgents: (state, action) => {
            state.list_of_qa_agents = action.payload;
        },
        setAgentConfigToEdit: (state, action) => {
            state.agent_config_to_edit = action.payload;
        },
        setIsLoadingAgentDetails: (state, action) => {
            state.is_loading_agent_details = action.payload;
        },
        setAgentConfigToTest: (state, action) => {
            state.agent_config_to_test = action.payload;
        },
        setIsLoadingListOfAgents: (state, action) => {
            state.is_loading_list_of_agents = action.payload;
        },
        setIsCreatingAgentConfig: (state, action) => {
            state.is_creating_agent_config = action.payload;
        },
        setIsUpdatingAgentFlags: (state, action) => {
            state.is_updating_agent_flags = action.payload;
        },
        setIsDeletingAgentConfig: (state, action) => {
            state.is_deleting_agent_config = action.payload;
        },
    },
});

export const {
    setListOfAgents,
    setIsLoadingListOfAgents,
    setIsCreatingAgentConfig,
    setIsUpdatingAgentFlags,
    setIsDeletingAgentConfig,
    setAgentConfigToEdit,
    setAgentConfigToTest,
    setIsConfigCreated,
    setListOfAQAgents,
    setIsLoadingAgentDetails,
    setIsAgentPlaygroundVisible,
    setListOfAnalystAgents
} = agentsSlice.actions;
export default agentsSlice.reducer;
