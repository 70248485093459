import styled from 'styled-components';

const StyledHistoryContainer = styled.div`
  border-radius:0px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledNewChatContainer = styled.div`
  border-radius:0px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;

const StyledUserHistoryItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.transparent};
  max-width: 85%;
  min-width: 85%;
  cursor:pointer;
  padding:0%;
  display:flex;
  flex-direction:column;
  height: fit-content;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;

const StyledNewChatButtonContainer = styled.div`
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--sm_8, 8px);
  border: var(--Nul, 1px) solid var(--Clairo-orange, #FF5A00);
  background: var(--Clairo-orange, #FF5A00);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor:pointer;
`;

export { StyledHistoryContainer,StyledDivider,StyledUserHistoryItemContainer,StyledNewChatContainer,StyledNewChatButtonContainer };