import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';

// Containers

const StyledMainContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
`;

const StyledLeftContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 2px 2px 0px black;
`;

const StyledRightContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.tertiary};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 2px 4px 0px black;
`;

// components


const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;

    font-size: 40px;
  }
`;

const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;

    font-size: 15px;
  }
`;

const StyledHeading3 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.secondary};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    cursor:pointer;
    font-size: 15px;
  }
`;

const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    cursor:pointer;
    font-size: 14px;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    display: flex;
    width:100%;
    
    input {
      height: 10px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    border: 1px solid  ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
  

    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;

const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
    color: ${({ theme }) => theme.palette.textColors.default}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.secondary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;


const MyStyledButton_type2 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
    color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    text-transform: capitalize;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;

    justify-content: center;
    align-items: center;
    flex-direction:flex-row;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    margin-top:10px;

  }
`;
const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;




export {
  MyStyledButton_type1,
  MyStyledButton_type2,
  StyledTextField,
  StyledLabel,
  StyledHeading1,
  StyledMainContainer,
  StyledLeftContainer,
  StyledHeading2,
  StyledHeading3,
  StyledRightContainer,
  StyledDivider
}
