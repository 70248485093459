import { StyledChipCreateButton, StyledChipTextField, StyledTagChip, StyledTagItem, StyledTagsListContainer, StyledTextarea, StyledSideBarContainer, } from './styled'
import styles from './styles.module.scss'
import back_icon from '../../../../../assets/images/back_icon.png'
import Tooltip from '@mui/material/Tooltip';
import ic_question_mark from '../../../../../assets/images/ic_question_mark.png'
import ic_red_gear_icon from '../../../../../assets/images/red_settings_gear_icon.svg'
import ic_loading_gif from '../../../../../assets/images/icons/35501 CLA Icon Animated Gif (1000x1000) Transparent.gif'
import { useEffect, useState } from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import { connect } from "react-redux";
import { updateDocument, uploadDocument } from '../../../logic/library/actions'
import { getCurrentSelectedLibrary, getIsDocumentUploaded, getIsUploadingDocument, getListOfDocumentsToUpload } from '../../../logic/library/selectors'
import { showToastMessage } from '../../../../app/logic/actions'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import { setIsListOfDocumentsVisible } from '../../../logic/library/slice'
import Ic_Close_Shaded from '../../../../../shared/ui/editable_icons/Ic_Close_Shaded'
import { CustomHeading } from '../../../../../shared/ui/headings'
import { CustomLabel } from '../../../../../shared/ui/label'
import { CustomButton } from '../../../../../shared/ui/buttons'
import { TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import CustomTextField from '../../../../../shared/ui/text_field';
import ic_percentage from '../../../../../assets/images/percentage.png'
import General_dropdowns from '../../../../../shared/ui/general_dropdowns'
import { TYPE_1 } from '../../../../../shared/ui/general_dropdowns/general_types'
import File_uploader from '../../../../../shared/ui/file_uploader'
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { TYPE_2, TYPE_3 } from '../../../../../shared/ui/models_dropdowns/models_types';
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const SidebarToUploadDocuments = ({
    showToastMessage,
    updateDocument,
    library_id_to_load = null,
    current_selected_library,
    is_uploading_document,
    setIsUploadDocumentSideBarOpen,
    list_of_documents_to_upload,
    is_document_uploaded,
    uploadDocument,
    ...props }) => {

    const [isMoreConfigOpen, setIsMoreConfigOpen] = useState(false)

    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const [selectedLibraryValue, setSelectedLibraryValue] = useState(null);
    const [currentSelectedChunkingMethod, setCurrentSelectedChunkingMethod] = useState([]);
    const [currentSelectedChunkingMethodValue, setCurrentSelectedChunkingMethodValue] = useState(null);
    const [currentSelectedSeparator, setCurrentSelectedSeparator] = useState([]);
    const [currentSelectedSeparatorValue, setCurrentSelectedSeparatorValue] = useState([]);
    const [listOfDocumentsToUpload, setListOfDocumentsToUpload] = useState([]);
    const [description, setDescription] = useState("");
    const [tokenBudget, setTokenBudget] = useState("450");
    const [chunkOverlap, setChunkOverlap] = useState("10");
    const [tagsList, setTagsList] = useState([{ key: 1, label: 'Create new tag' }]);
    const [newTag, setNewTag] = useState("");
    const [isCreateTagFieldOpen, setIsCreateTagFieldOpen] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)

    useEffect(() => {
        if (is_document_uploaded) {
            handleCloseSideBar();
        }
    }, [is_document_uploaded])

    useEffect(() => {
        if (library_id_to_load !== null) {
            setSelectedLibraryValue(library_id_to_load?.value)
            setSelectedLibrary(library_id_to_load)
        }
    }, [library_id_to_load])

    useEffect(() => {
        if (list_of_documents_to_upload?.length > 0) {
            setListOfDocumentsToUpload(list_of_documents_to_upload)
        }
    }, [list_of_documents_to_upload])

    useEffect(() => {
        console.log("currentSelectedSeparator", currentSelectedSeparator)
    }, [currentSelectedSeparator])
    useEffect(() => {
        if (selectedLibrary) {
            let new_tags = selectedLibrary?.tags?.map((tag, index) => {
                return { key: index + 1, label: tag }
            })
            setTagsList([{ key: 0, label: 'Create new tag' }, ...new_tags])
            setSelectedLibraryValue(selectedLibrary?.value)
            setDescription(selectedLibrary?.description)
            console.log("selectedLibrary---", selectedLibrary)
            setTokenBudget(selectedLibrary?.token_budget);
            setChunkOverlap(selectedLibrary?.chunk_overlap + "");
            setCurrentSelectedChunkingMethodValue(selectedLibrary?.chunking_method?.value)
            setCurrentSelectedChunkingMethod(selectedLibrary?.chunking_method)
            if (selectedLibrary?.chunking_method?.value === 'Recursive Character Chunker') {
                setCurrentSelectedSeparatorValue(selectedLibrary?.separators[0])
                setCurrentSelectedSeparator({ value: selectedLibrary?.separators[0] })
            }
        }
    }, [selectedLibrary])

    const handleCloseSideBar = () => {
        setIsUploadDocumentSideBarOpen(false)
    };

    const handleDeleteTag = (tagToDelete) => () => {
        setTagsList((tags) => tags.filter((tag) => tag.key !== tagToDelete.key));
    };

    const handleCreateTagBtn = (tagToAdd) => {
        if (tagToAdd.length > 0) {
            const newUpdateList = [...tagsList, { key: tagsList.length + 1, label: tagToAdd }]
            setTagsList(newUpdateList)
            setIsCreateTagFieldOpen(false)
        }
    }

    const handleUploadDocument = (e) => {
        e.preventDefault();
        if (listOfDocumentsToUpload.length == 0) {
            showToastMessage({ message: "Please upload at least one document", type: "error" })
            return
        }
        if (chunkOverlap >= tokenBudget) {
            showToastMessage({ message: 'Token Budget must be greater than Chunk Overlap', type: 'error' })
            return
        }

        if (chunkOverlap > 80) {
            showToastMessage({ message: "Chunk overlap must be between 0% and 80%", type: "error" })
            return
        }

        const formData = new FormData();

        listOfDocumentsToUpload.forEach(document => {
            formData.append('uploaded_files', document.file);
        });

        const jsonPayload = {
            title: selectedLibrary.label + "_group",
            tags: tagsList?.length > 0 ? tagsList.map(tag => tag.label).join(', ') : '',
            status: "active",
            token_budget: tokenBudget,
            chunk_overlap: chunkOverlap,
            chunking_method_id: currentSelectedChunkingMethod?.id, //TODO:replace with actual value
            file: "default",
            separators: currentSelectedSeparator?.value == null ? "" : currentSelectedSeparator?.value,
            library_id: parseInt(selectedLibrary.value),
            description: description
        };

        if (chunkOverlap > 80) {
            showToastMessage({ message: "Chunk overlap must be between 0% and 80%", type: "error" })
            return
        }

        if (chunkOverlap >= tokenBudget) {
            showToastMessage({ message: 'Token Budget must be greater than Chunk Overlap', type: 'error' })
            return
        }

        formData.append('payload', JSON.stringify(jsonPayload));
        uploadDocument(formData)
    }



    const handleGeneralDropdownForLibraries = (selected_library) => {
        if (library_id_to_load === null)
            setSelectedLibrary(selected_library)
    }
    const handleModelDropdownClickForChunkingModel = (selected_chunking_method) => {
        setCurrentSelectedChunkingMethod(selected_chunking_method)
    }

    const handleModelDropdownClickForSeparators = (selected_separator) => {
        setCurrentSelectedSeparator(selected_separator)
    }

    const handleConfirmCancel = () => {
        setConfirmCancel(true)
    }
    return <StyledSideBarContainer className={styles.side_bar_opened}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <CustomHeading font_size='20' font_weight='bold' text='Upload new documents'></CustomHeading>
            <div onClick={handleCloseSideBar}>
                <Ic_Close_Shaded />
            </div>
        </div>
        <div className={styles.upload_document_container}>
            {!isMoreConfigOpen &&
                <div className={styles.main_container}>
                    <div className={styles.body_container}>
                        <div className={styles.second_main_container}>
                            <div className={styles.header_container}>
                                <CustomHeading text='Document details'></CustomHeading>
                            </div>
                            <div className={styles.body_container}>
                                <div className={styles.body_header}>
                                    <CustomLabel title='Library name'></CustomLabel>
                                </div>
                                <div className={styles.body_dropdown}>
                                    <General_dropdowns type={TYPE_1} handleOnClick={handleGeneralDropdownForLibraries} defaultValue={selectedLibraryValue} />
                                </div>
                                <div className={styles.body_header}>
                                    <CustomLabel title='Library description'></CustomLabel>
                                </div>
                                <StyledTextarea value={description} placeholder='Enter a description...' onChange={(e) => setDescription(e.target.value)}></StyledTextarea>
                            </div>
                            <div className={styles.tags_list_container}>
                                <StyledTagsListContainer>
                                    {tagsList.map((data) => {
                                        let icon;
                                        if (data.label === 'Create new tag') {
                                            icon = <AddCircleOutline />;
                                        }
                                        return (
                                            <StyledTagItem key={data.key} className={styles.tag_item}>
                                                {
                                                    isCreateTagFieldOpen == true && data.label === 'Create new tag' && <div style={{ width: "fit-content", height: "30px", display: "flex", flexDirection: "row" }}>
                                                        <StyledChipTextField value={newTag} onChange={(e) => { setNewTag(e.target.value) }} placeholder='Enter tag title'></StyledChipTextField>
                                                        <StyledChipCreateButton onClick={() => { handleCreateTagBtn(newTag) }}>Create</StyledChipCreateButton>
                                                    </div>
                                                }
                                                {
                                                    isCreateTagFieldOpen == false && data.label === 'Create new tag' && <StyledTagChip
                                                        icon={icon}
                                                        label={data.label}
                                                        onClick={data.label === 'Create new tag' ? () => { setIsCreateTagFieldOpen(true) } : undefined}
                                                        onDelete={data.label === 'Create new tag' ? undefined : handleDeleteTag(data)}
                                                    />
                                                }
                                                {data.label != 'Create new tag' && <StyledTagChip
                                                    icon={icon}
                                                    label={data.label}
                                                    onClick={data.label === 'Create new tag' ? () => { setIsCreateTagFieldOpen(true) } : undefined}
                                                    onDelete={data.label === 'Create new tag' ? undefined : handleDeleteTag(data)}
                                                />}
                                            </StyledTagItem>

                                        );
                                    })}
                                </StyledTagsListContainer>
                            </div>

                            <div className={styles.file_upload_container}>
                                <File_uploader show_header={false} type='vertical' />
                            </div>

                            <div className={styles.footer_container}>
                                <div className={styles.button_container}>
                                    <div className={styles.upload_button}>
                                        <CustomButton
                                            currentButtonTpe={TYPE_1_button}
                                            onClick={handleUploadDocument}
                                            font_size='12' padding="8px 25px"
                                            title={
                                                is_uploading_document ? (
                                                    <>
                                                        <img src={ic_loading_gif} width={"20px"} />
                                                        Uploading...
                                                    </>
                                                ) : "Upload"
                                            }
                                        >
                                        </CustomButton>
                                    </div>
                                    <div className={styles.cancel_button}>
                                        <CustomButton
                                            currentButtonTpe={TYPE_1_button}
                                            onClick={handleConfirmCancel}
                                            border_color='white' background_color='transparent' font_size='12' padding="8px 18px" title='Cancel'
                                        />
                                    </div>
                                </div>
                                <div className={styles.more_config_container}>
                                    <div className={styles.more_config}>
                                        <img className={styles.more_config_icon} src={ic_red_gear_icon} />
                                        <CustomHeading text='Advanced Settings' color='secondary' onClick={() => { setIsMoreConfigOpen(!isMoreConfigOpen) }}></CustomHeading>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                isMoreConfigOpen == true ? <div className={styles.advanced_settings_container}>
                    <div className={styles.back_icon}>
                        <img className={styles.back} src={back_icon} onClick={() => setIsMoreConfigOpen(false)}></img>
                        <CustomHeading text='Advanced Settings'></CustomHeading>
                    </div>
                    <div className={styles.row_1}>
                        <div className={styles.field_container}>
                            <div className={styles.label_container}>
                                <CustomLabel title='Token budget'></CustomLabel>
                                <Tooltip title="An integer. The default is 500." placement="top">
                                    <img src={ic_question_mark} className={styles.more_info_icon} />
                                </Tooltip>

                            </div>
                            <CustomTextField onChange={(e) => setTokenBudget(e.target.value)} value={tokenBudget} ></CustomTextField>

                        </div>
                        <div className={styles.field_container}>
                            <div className={styles.label_container}>
                                <CustomLabel title='Chunk overlap'></CustomLabel>
                                <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                                    <img src={ic_question_mark} className={styles.more_info_icon} />
                                </Tooltip>
                            </div>
                            <CustomTextField icon={<img src={ic_percentage} className={styles.more_info_icon} />} onChange={(e) => setChunkOverlap(e.target.value)} value={chunkOverlap} ></CustomTextField>
                        </div>
                    </div>

                    <div className={styles.label_container}>
                        <CustomLabel title='Chunking method'></CustomLabel>
                        <Tooltip title="a number percentage between 0 and 80%. The default is 10%." placement="top">
                            <img src={ic_question_mark} className={styles.more_info_icon} />
                        </Tooltip>
                    </div>
                    <CustomModelDropDown type={TYPE_2} handleOnClick={handleModelDropdownClickForChunkingModel} defaultValue={currentSelectedChunkingMethodValue} />
                    {
                        currentSelectedChunkingMethod?.value === 'Recursive Character Chunker' &&
                        <div>
                            <CustomLabel title='Separators'></CustomLabel>
                            <CustomModelDropDown type={TYPE_3} handleOnClick={handleModelDropdownClickForSeparators} defaultValue={currentSelectedSeparatorValue} />
                        </div>
                    }

                    <div className={styles.footer_container}>
                        <div className={styles.button_container}>
                            <div className={styles.upload_button}>
                                <CustomButton
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleUploadDocument}
                                    font_size='12' padding="8px 25px"
                                    title={
                                        is_uploading_document ? (
                                            <>
                                                <img src={ic_loading_gif} width={"20px"} />
                                                Uploading...
                                            </>
                                        ) : "Upload"
                                    }
                                >
                                </CustomButton>
                            </div>
                            <div className={styles.cancel_button}>
                                <CustomButton
                                    currentButtonTpe={TYPE_1_button}
                                    onClick={handleConfirmCancel}
                                    border_color='white' background_color='transparent' font_size='12' padding="8px 18px" title='Cancel'
                                >
                                </CustomButton>
                            </div>
                        </div>

                    </div>
                </div> : ""
            }
        </div>
        <DeleteConfirmationComponent
            isVisible={confirmCancel}
            onConfirm={() => {setIsUploadDocumentSideBarOpen(false); setConfirmCancel(false)  }}
            onCancel={() => { setConfirmCancel(false) }}
            message='You have unsaved changes.'
            type='warning'
        />
    </StyledSideBarContainer >
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
    current_selected_library: getCurrentSelectedLibrary(state),
    is_uploading_document: getIsUploadingDocument(state),
    list_of_documents_to_upload: getListOfDocumentsToUpload(state),
    is_document_uploaded: getIsDocumentUploaded(state),
})

const mapDispatchToProps = (dispatch) => ({
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    updateDocument: (data) => dispatch(updateDocument(data)),
    uploadDocument: (data) => dispatch(uploadDocument(data)),
    setIsListOfDocumentsVisible: (data) => dispatch(setIsListOfDocumentsVisible(data)),
});

export default connect(mapState, mapDispatchToProps)(SidebarToUploadDocuments)