import { Routes } from "../../routes/Routes"
import { CssBaseline } from '@mui/material';

import { GlobalStyles } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/system';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { connect } from "react-redux";

// for now just to test things i have kept theme in file.

import theme1 from "../../../shared/global/theme/theme1";
import theme2 from "../../../shared/global/theme/theme2";
import { useEffect, useState } from "react";
import { getCurrentTheme, getIsThisValidTanent, getRoute } from "../logic/selectors";
import { analyzeTanent, getUserDetails } from "../logic/actions";
import { Page404 } from "../../404";
import ic_icon from '../../../assets/images/icons/icons8-drop-down-80.png'
import { useNavigate } from 'react-router'
const App = ({ currentTheme, isThisValidTanent, getUserDetails, analyzeTanent }) => {
    // we need to fetch theme values based on talent 


    const theme = createTheme(currentTheme);
    useEffect(() => {
        const tenant = window.location.hostname.split('.')[0];
        const capitalizedTenant = tenant.charAt(0).toUpperCase() + tenant.slice(1);
        document.title = "Clairo AI - " + capitalizedTenant;

        analyzeTanent();
        try {
            getUserDetails()
        } catch (error) { }
    }, [])



    return <StyledThemeProvider theme={theme}>
        {isThisValidTanent ? <Routes /> : <Page404 />}
        
    </StyledThemeProvider>

}


const mapState = (state) => ({
    currentTheme: getCurrentTheme(state),
    isThisValidTanent: getIsThisValidTanent(state),

})

const mapDispatchToProps = (dispatch) => ({
    analyzeTanent: (data) => dispatch(analyzeTanent(data)),
    getUserDetails: (data) => dispatch(getUserDetails(data)),

});


export default connect(mapState, mapDispatchToProps)(App)