// lists
export const getListOfUsers = (state) => state.users?.list_of_users
export const getListOfConversations = (state) => state.users?.list_of_conversations
export const getIsLoadingListOfUsers = (state) => state.users?.is_loading_list_of_users
export const getIsInvitingUser = (state) => state.users?.is_inviting_user
export const getIsUpdatingUser = (state) => state.users?.is_updating_user
export const getCurrentSelectedTopic = (state) => state.users?.current_selected_topic
export const getIsLoadingListOfConversations = (state) => state.users?.is_loading_list_of_conversations
export const getIsNewUserAdded = (state) => state.users?.is_new_user_added
export const getCurrentLoggedInUsername = (state) => state.users?.current_logged_in_username
