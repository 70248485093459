import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    list_of_embedding_models: [],
    is_loading_list_of_embedding_models: false,
    list_of_chunking_list_models: [],
    is_loading_list_of_chunking_list_models: false,
    list_of_summarization_models: [],
    is_loading_list_of_summarization_models: false,
    list_of_chat_models: [],
    is_loading_list_of_chat_models: false,
    list_of_search_query_models: [],
    is_loading_list_of_search_query_models: false,
};

const llmsSlice = createSlice({
    name: 'llms',
    initialState,
    reducers: {
        // Reducer and action for list_of_embedding_models
        setListOfEmbeddingModels(state, action) {
            console.log("setListOfEmbeddingModels(state, action) : ", action?.payload)
            state.list_of_embedding_models = action.payload;
        },
        setLoadingListOfEmbeddingModels(state, action) {
            state.is_loading_list_of_embedding_models = action.payload;
        },

        // Reducer and action for list_of_chunking_list_models
        setListOfChunkingListModels(state, action) {
            state.list_of_chunking_list_models = action.payload;
        },
        setLoadingListOfChunkingListModels(state, action) {
            state.is_loading_list_of_chunking_list_models = action.payload;
        },

        // Reducer and action for list_of_summarization_models
        setListOfSummarizationModels(state, action) {
            state.list_of_summarization_models = action.payload;
        },
        setLoadingListOfSummarizationModels(state, action) {
            state.is_loading_list_of_summarization_models = action.payload;
        },

        // Reducer and action for list_of_chat_models
        setListOfChatModels(state, action) {
            state.list_of_chat_models = action.payload;
        },
        setLoadingListOfChatModels(state, action) {
            state.is_loading_list_of_chat_models = action.payload;
        },

        // Reducer and action for list_of_search_query_models
        setListOfSearchQueryModels(state, action) {
            state.list_of_search_query_models = action.payload;
        },
        setLoadingListOfSearchQueryModels(state, action) {
            state.is_loading_list_of_search_query_models = action.payload;
        },
    }
});

export const {
    setListOfEmbeddingModels,
    setLoadingListOfEmbeddingModels,
    setListOfChunkingListModels,
    setLoadingListOfChunkingListModels,
    setListOfSummarizationModels,
    setLoadingListOfSummarizationModels,
    setListOfChatModels,
    setLoadingListOfChatModels,
    setListOfSearchQueryModels,
    setLoadingListOfSearchQueryModels
} = llmsSlice.actions;

export default llmsSlice.reducer;
