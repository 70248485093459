import { ChatAPI, UsersAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import { setCurrentLoggedInUserName, setIsInvitingUser, setIsLoadingListOfConversations, setIsLoadingListOfUsers, setIsMessageSending, setIsNewUserAdded, setIsUpdatingUser, setListOfConversations, setListOfUsers, setNewMessageResponse } from "./slice";

const fetchListOfUsers = (data) => (dispatch) => {
    dispatch(setIsLoadingListOfUsers(true));
    UsersAPI.fetchListOfUsers(data).then((response) => {
        console.log(response)
        if (response.data) {
            // Map the response to your desired format
            console.log('getAllQAChatbotAgentsConfig', response.data)
            const mappedData = response.data.items.map(user => ({
                first_name: user?.first_name,
                last_name: user?.last_name,
                id: user?.id,
                role: user?.role,
                email: user?.email,
                actions_list: ["Edit", "Delete"]
            }))

            console.log("mappedData", mappedData)
            dispatch(setListOfUsers(mappedData))
            dispatch(showToastMessage({ type: 'success', message: "Successfully fetched users" }))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsLoadingListOfUsers(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}


function formatConversationTime(timeString) {
    const date = new Date(timeString);
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    return `${formattedTime} on ${formattedDate}`;
}
const fetchListOfConversations = (data) => (dispatch) => {
    dispatch(setIsLoadingListOfConversations(true));
    UsersAPI.fetchListOfConversations(data).then((response) => {
        console.log(response)
        if (response.data) {
            // Map the response to your desired format
            console.log('fetchListOfConversations', response.data)
            const mappedData = response.data
            .filter(rec => rec.config_id !== null) // Filter out records with null config_id
            .map((rec,index) => ({
                id: rec?.id,
                title: rec?.conversation_summary_short != null 
                ? rec?.conversation_summary_short.replace(/"/g, '') 
                : (rec?.updated_at 
                    ? `Conversation at ${formatConversationTime(rec.updated_at)}`
                    : "Conversation"),
                updated_at: rec?.updated_at,
                config_id: rec?.config_id
            }));
            // Conversation with Agent A at 14:00 on 23 May 2024 // TODO : 
            console.log("mappedData",mappedData)
            dispatch(setListOfConversations(mappedData))
            dispatch(setIsLoadingListOfConversations(false))
            // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched users" }))
        }
    }).catch((error) => {
        console.log(error)
        dispatch(setIsLoadingListOfConversations(false));
        dispatch(showToastMessage({ type: 'error', message: error }))
    })
}

const inviteUser = (data) => (dispatch) => {
    dispatch(setIsInvitingUser(true))
    dispatch(setIsNewUserAdded(false))
    UsersAPI.inviteUser(data).then((response) => {
        console.log(response)
        dispatch(setIsInvitingUser(false));
        dispatch(fetchListOfUsers({}));
        dispatch(setIsNewUserAdded(true))
        dispatch(showToastMessage({ type: 'success', message: "Successfully added user" }))
    }).catch((error) => {
        console.log(error)
        dispatch(setIsNewUserAdded(false))
        dispatch(setIsInvitingUser(false));
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
    })
}


const updateUserData = (data) => (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    UsersAPI.updateUser(data).then((response) => {
        console.log("updateUserData",response)
        dispatch(setIsUpdatingUser(false));
        localStorage.setItem('user',JSON.stringify(response?.data))
        dispatch(setCurrentLoggedInUserName(response?.data))
        dispatch(showToastMessage({ type: 'success', message: "Successfully updated user" }))
        dispatch(fetchListOfUsers({}));
    }).catch((error) => {
        console.log(error)
        dispatch(setIsUpdatingUser(false));
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
    })
}

const updateUserPassword = (data) => (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    UsersAPI.updateUserPassword(data).then((response) => {
        console.log(response)
        dispatch(showToastMessage({ type: 'success', message: "Successfully updated user password" }))
        dispatch(fetchListOfUsers({}));
    }).catch((error) => {
        console.log(error)
        dispatch(setIsUpdatingUser(false));
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
    })
}


const deleteUser = (data) => (dispatch) => {
    dispatch(setIsUpdatingUser(true))
    UsersAPI.deleteUser(data).then((response) => {
        console.log(response)
        dispatch(showToastMessage({ type: 'success', message: "Successfully deleted user " }))
        dispatch(fetchListOfUsers({}));
    }).catch((error) => {
        console.log(error)
        dispatch(setIsUpdatingUser(false));
        dispatch(showToastMessage({ type: 'error', message: error.response.data.error }))
    })
}
export {
    fetchListOfUsers,
    inviteUser,
    updateUserData,
    updateUserPassword,
    deleteUser,
    fetchListOfConversations
}