import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
    name: 'users',
    initialState: {
        list_of_users:[],
        is_loading_list_of_users:false,
        is_inviting_user:false,
        is_updating_user:false,
        current_selected_topic:null,
        list_of_conversations:[],
        is_loading_list_of_conversations:false,
        is_new_user_added:false,
        current_logged_in_username:null,
        is_new_user_added:false,
    },
    reducers: {
        setListOfUsers: (state, action) => {
            state.list_of_users = action.payload;
        },
        setListOfConversations: (state, action) => {
            state.list_of_conversations = action.payload;
        },
        setIsLoadingListOfUsers: (state, action) => {
            state.is_loading_list_of_users = action.payload;
        },
        setIsUpdatingUser: (state, action) => {
            state.is_updating_user = action.payload;
        },
        setIsInvitingUser: (state, action) => {
            state.is_inviting_user = action.payload;
        },
        setCurrentSelectedTopic: (state, action) => {
            state.current_selected_topic = action.payload;
        },
        setIsLoadingListOfConversations: (state, action) => {
            state.is_loading_list_of_conversations = action.payload;
        },
        setCurrentLoggedInUserName: (state, action) => {
            state.current_logged_in_username = action.payload;
        },
        setIsNewUserAdded: (state, action) => {
            state.is_new_user_added = action.payload;
        },
        
    },
});

export const {
    setListOfUsers,
    setIsLoadingListOfUsers,
    setIsInvitingUser,
    setIsUpdatingUser,
    setCurrentSelectedTopic,
    setListOfConversations,
    setIsLoadingListOfConversations,
    setCurrentLoggedInUserName,
    setIsNewUserAdded
} = chatSlice.actions;
export default chatSlice.reducer;
