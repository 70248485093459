import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledTabs, StyledTab, StyledTabPanel } from './styled';
import styles from './styles.module.scss';

const CustomTabs = ({ tabs, onTabChange }) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onTabChange && typeof onTabChange[newValue] === 'function') {
            onTabChange[newValue]();
        }
    };

    return (
        <div className={styles.container}>
            <StyledTabs value={value} onChange={handleChange} aria-label="custom tabs">
                {tabs.map((tab, index) => (
                    <StyledTab label={tab.label} key={index} />
                ))}
            </StyledTabs>
        </div>
    );
};

CustomTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            component: PropTypes.element.isRequired
        })
    ).isRequired,
    onTabChange: PropTypes.arrayOf(PropTypes.func)
};


export { CustomTabs };
