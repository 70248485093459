import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import add_btn from '../../../../../assets/images/icons8-add-48.png'
import { connect } from "react-redux";
import { getIsCreatingLibraryLoading, getIsDocumentUploaded, getIsLibraryDeleted, getIsListOfDocumentsLoading, getIsListOfDocumentsVisible, getIsListOfLibrariesLoading, getIsUploadingDocument, getListOfDocuments, getListOfLibraries, getListOfSeparators } from '../../../logic/library/selectors'
import { showToastMessage } from '../../../../app/logic/actions'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import Documents from '../Documents'
import { setCurrentSelectedLibrary, setIsListOfDocumentsVisible } from '../../../logic/library/slice'
import { CustomTable } from '../../../../../shared/ui/table'
import { listOfColumns } from './table_columns'
import { CustomHeading } from '../../../../../shared/ui/headings'
import { CustomButton } from '../../../../../shared/ui/buttons'
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import Sidebar from './sidebar'
import { deleteLibrary, fetchListOfLibraries } from '../../../logic/library/actions';
import { OverlayContainer, CenteredBox } from '../../../../../shared/ui/overlay_container/styled';
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const Library = ({
    showToastMessage,
    list_of_libraries,
    is_loading_list_of_libraries,
    loadLibrariesList,
    deleteLibrary,
    current_theme,
    is_list_of_documents_visible,
    is_loading_create_library,
    fetchListOfLibraries,
    setCurrentSelectedLibraryRedux,
    setIsListOfDocumentsVisible,
    ...props }) => {

    const [currentSelectedLibrary, setCurrentSelectedLibrary] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [libraryToDelete, setLibraryToDelete] = useState(null);


    useEffect(() => {
        setIsListOfDocumentsVisible(false)
        fetchListOfLibraries()
        setCurrentSelectedLibraryRedux(null)
    }, [])

    useEffect(() => {
        if (!isSidebarVisible) {
            setCurrentSelectedLibrary(null)
        }
    }, [isSidebarVisible])

    useEffect(() => {
        if (!is_loading_create_library) {

        }
    }, [is_loading_create_library])

    const handleTableActions = (option, library) => {
        switch (option) {
            case 'View':
                setCurrentSelectedLibrary(library)
                setIsListOfDocumentsVisible(true)
                setCurrentSelectedLibraryRedux(library)
                break;
            case 'Edit':
                setCurrentSelectedLibrary(library)
                setIsSidebarVisible(true)                
                break;
            case 'Delete':
                setConfirmDelete(true)
                setLibraryToDelete(library);
                break;
            case 'Confirm Delete':
                deleteLibrary(libraryToDelete?.value);
                setConfirmDelete(false);
                setLibraryToDelete(null);
                break;
            default:
                break;
        }
    }

    return is_list_of_documents_visible ? <div className={styles.container_to_hold_documents_list}>
        <Documents library_id_to_load={currentSelectedLibrary} />
    </div> : <div className={styles.container}>
        <div className={styles.list_of_libraries_container}>
            <div className={styles.header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_weight='bold' font_size='20' text='Libraries'></CustomHeading>
                    <CustomHeading color='tertiary' font_size='12'  text="Group knowledge into collections to manage each agent's access to documents"></CustomHeading>
                </div>
                <div className={styles.right_container} >
                    <CustomButton  text_color='default' icon={<img src={add_btn} className={styles.icon} />} currentButtonTpe={TYPE_3_button} title='Create Library' font_size='12' onClick={() => { setIsSidebarVisible(true) }}></CustomButton>
                </div>
            </div>
            <div className={styles.library_table_container}>
                <CustomTable  defaultSortDirection='A-Z' defaultSortField='label' handleTableActions={handleTableActions} listOfColumns={listOfColumns} listOfRows={list_of_libraries} />
                <DeleteConfirmationComponent
                    isVisible={confirmDelete}
                    onConfirm={() => handleTableActions('Confirm Delete')}
                    onCancel={() => { setConfirmDelete(false); setLibraryToDelete(null); }}
                    message='Are you sure you want to delete this library?'
                />
            </div>
        </div>
        {
            isSidebarVisible && <Sidebar setIsSidebarVisible={setIsSidebarVisible} currentSelectedLibrary={currentSelectedLibrary} />
        }

    </div >
}
const mapState = (state) => ({
    is_loading_documents_list: getIsListOfDocumentsLoading(state),
    list_of_documents: getListOfDocuments(state),
    is_loading_create_library: getIsCreatingLibraryLoading(state),
    list_of_libraries: getListOfLibraries(state),
    is_loading_list_of_libraries: getIsListOfLibrariesLoading(state),
    current_theme: getCurrentTheme(state),
    is_list_of_documents_visible: getIsListOfDocumentsVisible(state)
})

const mapDispatchToProps = (dispatch) => ({
    fetchListOfLibraries: (data) => dispatch(fetchListOfLibraries(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    deleteLibrary: (data) => dispatch(deleteLibrary(data)),
    setCurrentSelectedLibraryRedux: (data) => dispatch(setCurrentSelectedLibrary(data)),
    setIsListOfDocumentsVisible: (data) => dispatch(setIsListOfDocumentsVisible(data)),
});

export default connect(mapState, mapDispatchToProps)(Library)