import { UserAPI } from "../../api";

import { getUserDetails, showToastMessage } from "./actions";
import { setRoute, setPositiveReaction, setNegativeReaction, setFeedbackMessage } from "./slice";
import { toggleLoading } from "./slice"

const registerUser = (date) => (dispatch) => {

}

const sendThumbsUp = (data) => (dispatch) => {
    UserAPI.sendReaction(data).then((response)=>{
        console.log('positive response sent', response)
        if(response.data) {
            dispatch(setPositiveReaction(true))
        } else {
            console.log("unable to like this message")
        }
    })
    .catch((err)=>{
        console.log(err)
    })
}

export {registerUser, sendThumbsUp }