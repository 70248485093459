
import { useEffect, useState } from 'react';
import { StyledDropdown, StyledFormControlLabel, StyledTextField } from './styled';
import styles from './styles.module.scss'
import { TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5 } from './drop_down_types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CircularProgress, FormControl, IconButton, Menu, MenuItem, OutlinedInput, Radio, RadioGroup, Select } from '@mui/material';
import { toast } from 'react-toastify';
import { CustomRadio } from './custom_radio';

const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`,
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`,
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`,
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`,
    };
};

function getStyles(name, selectedOptions, theme) {
    const isSelected = selectedOptions?.some(selectedOption => selectedOption?.value === name.value);

    return {
        fontWeight: isSelected ? 'bolder' : 'bold',
        color: isSelected ? theme.palette?.textColors.secondary : theme.palette.textColors.tertiary,
        backgroundColor: isSelected ? theme.palette?.buttonBackgrounds.darkest : 'transparent',
    };
}
const ITEM_HEIGHT = 48;
const CustomDropdown = ({
    font_family = "primary",
    font_size = "14px",
    text_color = "primary",
    background_color = "primary",
    border_color = "primary",
    placeholder_color = "primary",
    placeholder_text = "Please provide value",
    mobileFontSize = "12px",
    tabletFontSize = "13px",
    laptopFontSize = "14px",
    macbook13FontSize = "15px",
    pick_passed_font_size = false,
    width = "100%",
    input_height = "5px",
    unit = "px",
    border_radius = "8px",

    defaultValue,
    setDefaultValue,

    list_type = null,

    defaultEmbeddingModelValue,
    defaultChatModelValue,
    defaultSummarizationModelValue,
    defaultSearchQueryModelModelValue,
    defaultChunkingMethodValue,
    defaultSeparatorMethodValue,
    defaultSelectedLibraryValue,
    defaultSelectedLibrariesValue,
    defaultToneValue,
    defaultAgentTypeValue,
    defaultInstructionsValue,
    defaultQAActionsValue,


    setCurrentSelectedEmbeddingModel,
    setCurrentSelectedChatbotModel,
    setCurrentSelectedSummarizationModel,

    currentSelectedRadioButton,
    setCurrentSelectedRadioButton,

    defaultLLM,
    onChange = () => { console.error("please provide on change to drop down") },
    dropdown_type = "default_type",
    theme,
    is_loading = true,
    options_list = [],
    onClick = (e) => { console.error("please provide on click in drop down") },



    anchors = [],
    currentSelectAnchorIndex = -1,

    public_options = [],
    private_options = [],

    selectedOptionsList,

    anchorEl,
    item,
    is_open = false,
    onClose = () => { console.error("please provide onClose to dropdown for actions") },
    setAnchorEl = () => { console.error('no setAnchorEl is provided') },
    showBackArrow,
    icon = null,
    ...props
}) => {

    const responsiveFontSizes = getResponsiveFontSizes(font_size, unit);
    const finalMobileFontSize = pick_passed_font_size ? mobileFontSize + unit : responsiveFontSizes.mobileFontSize;
    const finalTabletFontSize = pick_passed_font_size ? tabletFontSize + unit : responsiveFontSizes.tabletFontSize;
    const finalLaptopFontSize = pick_passed_font_size ? laptopFontSize + unit : responsiveFontSizes.laptopFontSize;
    const finalMacbook13FontSize = pick_passed_font_size ? macbook13FontSize + unit : responsiveFontSizes.macbook13FontSize;

    const [currentDropdownType, setCurrentDropdownType] = useState('')

    const [selectedValue, setSelectedValue] = useState('');

    const [llmType, setLlmType] = useState('PublicLLM');
    const [options, setOptions] = useState([])


    useEffect(() => {
        setCurrentDropdownType(dropdown_type)
    }, [dropdown_type])

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        if (dropdown_type === TYPE_2) {
            if (llmType === "PublicLLM") {
                const selectedOption = public_options.find(option => option.value === newValue);
                onClick(selectedOption, llmType);
                setSelectedValue(selectedOption?.value);
            } else {
                const selectedOption = private_options.find(option => option.value === newValue);
                onClick(selectedOption, llmType);
                setSelectedValue(selectedOption?.value);
            }
        } else {
            if (options && options.length > 0 && newValue !== undefined && !is_loading) {
                const selectedOption = options.find(option => option.value === newValue);
                setSelectedValue(selectedOption?.value);

                if (selectedOption) {
                    onClick(selectedOption);
                }
            }
        }
    };

    const handleChangeMultiSelect = (event) => {
        const { target: { value } } = event;
        const updatedOptions = value.map(val => options.find(opt => opt.value === val));
        if (onClick) {
            onClick(updatedOptions);
        }
    }

    const DoesThisExistsInList = (item, list) => {
        if (!Array.isArray(list)) {
            return false;
        }
        return list.some((val) => val?.id === item?.id);
    };

    const GetModelObject = (item, list) => {
        if (!Array.isArray(list)) {
            return false;
        }
        return list.find((val) => val?.value === item);
    };


    const handleLlmTypeChange = (event) => {
        event.preventDefault()
        setLlmType(event.target.value);
        let model_value = null;
        let llm_object = null;
        if (event.target.value === "PublicLLM") {
            switch (list_type) {
                case 'listOfSearchQueryModel':
                    model_value = defaultSearchQueryModelModelValue === null || defaultSearchQueryModelModelValue === undefined ? public_options[0]?.value : DoesThisExistsInList(defaultSearchQueryModelModelValue, public_options) ? defaultSearchQueryModelModelValue : public_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, public_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfSummarizationModel':
                    model_value = defaultSummarizationModelValue === null || defaultSummarizationModelValue === undefined ? public_options[0]?.value : DoesThisExistsInList(defaultSummarizationModelValue, public_options) ? defaultSummarizationModelValue : public_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, public_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfChatModels':
                    model_value = defaultChatModelValue === null || defaultChatModelValue === undefined ? public_options[0]?.value : DoesThisExistsInList(defaultChatModelValue, public_options) ? defaultChatModelValue : public_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, public_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfEmbeddingModels':
                    model_value = defaultEmbeddingModelValue === null || defaultEmbeddingModelValue === undefined ? public_options[0]?.value : DoesThisExistsInList(defaultEmbeddingModelValue, public_options) ? defaultEmbeddingModelValue : public_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, public_options)
                    onClick(llm_object, event.target.value)
                    break;
                default:
                    break;
            }
            setCurrentSelectedRadioButton("PublicLLM");
        } else {
            switch (list_type) {
                case 'listOfSearchQueryModel':
                    model_value = defaultSearchQueryModelModelValue === null || defaultSearchQueryModelModelValue === undefined ? private_options[0]?.value : DoesThisExistsInList(defaultSearchQueryModelModelValue, private_options) ? defaultSearchQueryModelModelValue : private_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, private_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfSummarizationModel':
                    model_value = defaultSummarizationModelValue === null || defaultSummarizationModelValue === undefined ? private_options[0]?.value : DoesThisExistsInList(defaultSummarizationModelValue, private_options) ? defaultSummarizationModelValue : private_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, private_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfChatModels':
                    model_value = defaultChatModelValue === null || defaultChatModelValue === undefined ? private_options[0]?.value : DoesThisExistsInList(defaultChatModelValue, private_options) ? defaultChatModelValue : private_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, private_options)
                    onClick(llm_object, event.target.value)
                    break;
                case 'listOfEmbeddingModels':
                    model_value = defaultEmbeddingModelValue === null || defaultEmbeddingModelValue === undefined ? private_options[0]?.value : DoesThisExistsInList(defaultEmbeddingModelValue, private_options) ? defaultEmbeddingModelValue : private_options[0]?.value
                    setSelectedValue(model_value);
                    llm_object = GetModelObject(model_value, private_options)
                    onClick(llm_object, event.target.value)
                    break;
                default:
                    break;
            }
            setCurrentSelectedRadioButton("PrivateLLM");
        }

    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                setAnchorEl(null);
            }
        };
        if (is_open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [is_open, anchorEl, item]);

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfQActions") {
            if (defaultQAActionsValue !== undefined || defaultQAActionsValue !== null) {
                setSelectedValue(defaultQAActionsValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }
    }, [defaultQAActionsValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfInstructions") {
            if (defaultInstructionsValue !== undefined || defaultInstructionsValue !== null) {
                setSelectedValue(defaultInstructionsValue)
            } else {
                setSelectedValue(options[0])
            }
        }

    }, [defaultInstructionsValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfLibraryForMultiSelection") {
            if (defaultSelectedLibrariesValue !== undefined || defaultSelectedLibrariesValue !== null) {
                setSelectedValue(defaultSelectedLibrariesValue)
            } else {
                setSelectedValue(options[0])
            }
        }

    }, [defaultSelectedLibrariesValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfTones") {
            if (defaultToneValue !== undefined && defaultToneValue !== null) {
                setSelectedValue(defaultToneValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }

    }, [defaultToneValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfAgentTypes") {
            if (defaultAgentTypeValue !== undefined && defaultAgentTypeValue !== null) {
                setSelectedValue(defaultAgentTypeValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }

    }, [defaultAgentTypeValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfLibraryForSingleSelection") {
            if (defaultSelectedLibraryValue !== undefined && defaultSelectedLibraryValue !== null) {
                setSelectedValue(defaultSelectedLibraryValue)
            } else {
                setSelectedValue(options[0]?.id)
            }
        }

    }, [defaultSelectedLibraryValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfLibraryForMultiSelection") {
            if (defaultSelectedLibrariesValue !== undefined && defaultSelectedLibrariesValue !== null) {
                setSelectedValue(defaultSelectedLibrariesValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }

    }, [defaultSelectedLibrariesValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfSeparators") {
            if (defaultSeparatorMethodValue !== undefined && defaultSeparatorMethodValue !== null) {
                setSelectedValue(defaultSeparatorMethodValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }

    }, [defaultSeparatorMethodValue, options, list_type])

    useEffect(() => {
        if (options?.length > 0 && list_type === "listOfChunkingMethods") {
            if (defaultChunkingMethodValue !== undefined && defaultChunkingMethodValue !== null) {
                setSelectedValue(defaultChunkingMethodValue)
            } else {
                setSelectedValue(options[0]?.value)
            }
        }

    }, [defaultChunkingMethodValue, options, list_type])

    useEffect(() => {
        if (list_type === "listOfSearchQueryModel") {

            if (defaultSearchQueryModelModelValue != undefined && defaultSearchQueryModelModelValue != null) {
                setLlmType(defaultLLM)
                setSelectedValue(defaultSearchQueryModelModelValue)
                // following are responsible to set a model as default selected and it will get in payload
                console.log("we are here defaultSearchQueryModelModelValue", defaultSearchQueryModelModelValue, defaultLLM)
                if (defaultLLM === 'PublicLLM') {
                    let llm_object = GetModelObject(defaultSearchQueryModelModelValue, public_options)
                    onClick(llm_object, defaultLLM)
                } else {
                    let llm_object = GetModelObject(defaultSearchQueryModelModelValue, private_options)
                    onClick(llm_object, defaultLLM)
                }
            } else {

                if (defaultLLM === 'PublicLLM') {
                    setSelectedValue(public_options[0]?.value)
                    onClick(public_options[0], defaultLLM)
                } else {
                    setSelectedValue(private_options[0]?.value)
                    onClick(private_options[0], defaultLLM)
                }
            }
        }

    }, [defaultSearchQueryModelModelValue, defaultLLM, list_type])

    useEffect(() => {
        if (list_type === "listOfSummarizationModel") {
            if (defaultSummarizationModelValue != undefined && defaultSummarizationModelValue != null) {
                setLlmType(defaultLLM)
                setSelectedValue(defaultSummarizationModelValue)
                // following are responsible to set a model as default selected and it will get in payload
                if (defaultLLM === 'PublicLLM') {
                    let llm_object = GetModelObject(defaultSummarizationModelValue, public_options)
                    onClick(llm_object, defaultLLM)
                } else {
                    let llm_object = GetModelObject(defaultSummarizationModelValue, private_options)
                    onClick(llm_object, defaultLLM)
                }
            } else {

                if (defaultLLM === 'PublicLLM') {
                    setSelectedValue(public_options[0]?.value)
                    onClick(public_options[0], defaultLLM)
                } else {
                    setSelectedValue(private_options[0]?.value)
                    onClick(private_options[0], defaultLLM)
                }
            }
        }

    }, [defaultSummarizationModelValue, defaultLLM, list_type])

    useEffect(() => {
        if (list_type === "listOfChatModels") {
            if (defaultChatModelValue != undefined && defaultChatModelValue != null) {
                setLlmType(defaultLLM)
                setSelectedValue(defaultChatModelValue)
                // following are responsible to set a model as default selected and it will get in payload
                if (defaultLLM === 'PublicLLM') {
                    let llm_object = GetModelObject(defaultChatModelValue, public_options)
                    onClick(llm_object, defaultLLM)
                } else {
                    let llm_object = GetModelObject(defaultChatModelValue, private_options)
                    onClick(llm_object, defaultLLM)
                }
            } else {
                if (defaultLLM === 'PublicLLM') {
                    setSelectedValue(public_options[0]?.value)
                    onClick(public_options[0], defaultLLM)
                } else {
                    setSelectedValue(private_options[0]?.value)
                    onClick(private_options[0], defaultLLM)
                }
            }
        }
    }, [defaultChatModelValue, defaultLLM, list_type])


    useEffect(() => {
        if (list_type === "listOfEmbeddingModels") {
            if (defaultEmbeddingModelValue != undefined && defaultEmbeddingModelValue != null) {
                setLlmType(defaultLLM)
                setSelectedValue(defaultEmbeddingModelValue)

                if (defaultLLM === 'PublicLLM') {
                    let llm_object = GetModelObject(defaultEmbeddingModelValue, public_options)
                    onClick(llm_object, defaultLLM)
                } else {
                    let llm_object = GetModelObject(defaultEmbeddingModelValue, private_options)
                    onClick(llm_object, defaultLLM)
                }

            } else {
                if (defaultLLM === 'PublicLLM') {
                    setSelectedValue(public_options[0]?.value)
                    onClick(public_options[0], defaultLLM)
                } else {
                    setSelectedValue(private_options[0]?.value)
                    onClick(private_options[0], defaultLLM)
                }
            }
        }
    }, [defaultEmbeddingModelValue, defaultLLM, list_type])

    useEffect(() => {
        if (options_list?.length > 0) {
            setOptions(options_list)
            // this condition is responsible for setting up default values as we open side bar
            if (list_type === "listOfLibraryForSingleSelection") {
                if (defaultSelectedLibraryValue == undefined || defaultSelectedLibraryValue == null) {
                    onClick(options_list[0])
                }
            }
            if (list_type === "listOfSeparators") {
                if (defaultSeparatorMethodValue == undefined || defaultSeparatorMethodValue == null) {
                    onClick(options_list[0])
                }
            }
            // 
        }
    }, [options_list, list_type])

    return (
        <div className={styles.container}>
            {
                currentDropdownType === TYPE_1 && <StyledDropdown
                    select
                    value={selectedValue + ""}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: false }}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    backgroundColor: theme?.palette?.inputFieldBackground?.primary || "#242426",
                                    color: theme?.palette?.textColors?.primary || "white",
                                },
                            },
                        },
                    }}

                    disabled={is_loading}

                    fontFamily={font_family}
                    fontSize={font_size}
                    textColor={text_color}
                    backgroundColor={background_color}
                    borderColor={border_color}
                    placeholderColor={placeholder_color}
                    mobileFontSize={finalMobileFontSize}
                    tabletFontSize={finalTabletFontSize}
                    laptopFontSize={finalLaptopFontSize}
                    macbook13FontSize={finalMacbook13FontSize}
                    width={width}
                    borderRadius={border_radius}
                    inputHeight={input_height}
                    placeholder={placeholder_text}
                    {...props}
                >
                    {is_loading ? (
                        <MenuItem disabled>
                            <CircularProgress size={24} /> Loading ...
                        </MenuItem>
                    ) : (
                        options.map((option) => (
                            <MenuItem
                                key={option?.value ?? option?.id}
                                value={option?.value ?? option?.label}
                            >
                                {(list_type == 'listOfSeparators' || list_type == 'listOfTones' || list_type == 'listOfQActions' || list_type == 'listOfLibraryForSingleSelection' || list_type == 'listOfChunkingMethods' || list_type == 'listOfAgentTypes') && list_type !== null && option.label}
                                {(list_type == 'listOfChunkingMethods') && list_type !== null && option.name}
                                {list_type == null && list_type !== 'listOfSeparators' && option?.name}

                            </MenuItem>
                        ))
                    )}
                </StyledDropdown>

            }
            {
                currentDropdownType === TYPE_2 &&
                <div className={styles.type_2_container}>
                    <div className={styles.radio_buttons_container}>

                        <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            row
                            value={currentSelectedRadioButton}
                            onChange={handleLlmTypeChange}
                        >
                            <StyledFormControlLabel value="PublicLLM" control={<CustomRadio />} label="PublicLLM" />
                            <StyledFormControlLabel value="PrivateLLM" control={<CustomRadio />} label="PrivateLLM" />
                        </RadioGroup>
                        </FormControl>
                    </div>
                    <div className={styles.drop_downs_container}>
                        <StyledDropdown
                            select
                            value={selectedValue + ""}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: false }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            backgroundColor: theme?.palette?.inputFieldBackground?.primary || "#242426",
                                            color: theme?.palette?.textColors?.primary || "white",
                                        },
                                    },
                                },
                            }}

                            disabled={is_loading}

                            fontFamily={font_family}
                            fontSize={font_size}
                            textColor={text_color}
                            backgroundColor={background_color}
                            borderColor={border_color}
                            placeholderColor={placeholder_color}
                            mobileFontSize={finalMobileFontSize}
                            tabletFontSize={finalTabletFontSize}
                            laptopFontSize={finalLaptopFontSize}
                            macbook13FontSize={finalMacbook13FontSize}
                            width={width}
                            borderRadius={border_radius}
                            inputHeight={input_height}
                            placeholder={placeholder_text}
                            {...props}
                        >
                            {is_loading ? (
                                <MenuItem disabled>
                                    <CircularProgress size={24} /> Loading ...
                                </MenuItem>
                            ) : currentSelectedRadioButton === "PublicLLM" ? (
                                public_options?.map((option) => (
                                    <MenuItem key={option?.value} value={option?.value}>
                                        {option?.name}
                                    </MenuItem>
                                ))
                            ) : (
                                private_options?.map((option) => (
                                    <MenuItem key={option?.value} value={option?.value}>
                                        {option?.name}
                                    </MenuItem>
                                ))
                            )}
                        </StyledDropdown>

                    </div>


                </div>
            }

            {
                currentDropdownType === TYPE_3 && <FormControl sx={{ m: 1, width: "100%" }} disabled={is_loading}>
                    <Select
                        multiple
                        displayEmpty
                        value={selectedOptionsList?.map(opt => opt?.value)}
                        onChange={handleChangeMultiSelect}
                        input={<OutlinedInput notched={false} style={{
                            color: theme?.palette?.textColors?.primary,
                            backgroundColor: theme?.palette?.inputFieldBackground?.primary,
                            border: `1px solid ${theme?.palette?.borderColors?.primary}`,
                            borderRadius: '15px',
                        }} />}
                        renderValue={selected =>
                            selected?.length > 0
                                ? selected?.map(val => options.find(name => name?.value === val)?.label).join(', ')
                                : <em>Select library</em>
                        }
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    backgroundColor: theme?.palette?.backgroundColors?.primary,
                                    color: theme?.palette?.textColors?.primary,
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 350,
                                },
                            },
                        }}
                        inputProps={{ 'aria-label': 'Without label', style: { color: theme?.palette?.textColors?.primary } }}
                    >
                        {is_loading ? (
                            <MenuItem disabled value="">
                                <CircularProgress size={24} />
                            </MenuItem>
                        ) : (
                            options.map((name) => (
                                <MenuItem key={name.value} value={name.value} style={getStyles(name, selectedOptionsList, theme)}>
                                    {name.label}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            }

            {
                currentDropdownType === TYPE_4 && <div style={{ width: '100%' }}  >
                    <IconButton
                        aria-label="more"
                        aria-controls={is_open ? 'long-menu' : undefined}
                        aria-expanded={is_open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={onClick}
                    >
                        {icon === null ? <MoreVertIcon style={{ color: theme?.palette?.textColors?.secondary != undefined ? theme?.palette?.textColors?.secondary : "white" }} /> : icon}

                    </IconButton>

                    <Menu
                        id="long-menu"
                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl}
                        open={is_open}
                        onClose={() => { setAnchorEl(null) }}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                backgroundColor: theme?.palette?.inputFieldBackground?.primary != undefined ? theme?.palette?.inputFieldBackground?.primary : "#242426",
                                color: theme?.palette?.textColors?.primary != undefined ? theme?.palette?.textColors?.primary : "white",
                                borderRadius: '15px',
                                borderBottom: 'white'
                            },
                        }}
                    >
                        {options.map((option) => (
                            <MenuItem key={option} onClick={() => onClose(option)}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            }

            {
                currentDropdownType === TYPE_5 && <div style={{ width: '100%' }}>
                    <IconButton
                        aria-label="more"
                        aria-controls={is_open ? 'sort-menu' : undefined}
                        aria-expanded={is_open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={onClick}
                    >
                        <SwapVertIcon style={{ color: theme?.palette?.textColors?.primary || "white" }} />
                    </IconButton>
                    <Menu
                        id="sort-menu"
                        MenuListProps={{
                            'aria-labelledby': 'sort-button',
                        }}
                        anchorEl={anchorEl}
                        open={is_open}

                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                                backgroundColor: theme?.palette?.inputFieldBackground?.primary || "#242426",
                                color: theme?.palette?.textColors?.primary || "white",
                                borderRadius: '15px',
                                borderBottom: 'white',
                                position: 'relative',
                            },
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '-10px' }}>
                            {showBackArrow && (
                                <IconButton size="small" onClick={() => onClose('back')} style={{ marginRight: 'auto', paddingLeft: '10px' }}>
                                    <ArrowBackIosIcon style={{ color: theme?.palette?.textColors?.primary || "white" }} />
                                </IconButton>
                            )}
                            <IconButton size="small" onClick={() => {
                                onClose('null')
                            }} style={{ marginLeft: 'auto' }}>
                                <CloseIcon style={{ color: theme?.palette?.textColors?.primary || "white" }} />
                            </IconButton>
                        </div>
                        {options.map((option, index) => (
                            <MenuItem key={index} onClick={() => {
                                onClose(option)
                            }}>
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            }

        </div>
    );
};

export { CustomDropdown };