import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Close_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24ZM8.20312 8.20312C7.73438 8.67188 7.73438 9.375 8.20312 9.79688L10.4062 12L8.20312 14.2031C7.73438 14.6719 7.73438 15.375 8.20312 15.7969C8.625 16.2656 9.32812 16.2656 9.75 15.7969L11.9531 13.5938L14.1562 15.7969C14.625 16.2656 15.3281 16.2656 15.75 15.7969C16.2188 15.375 16.2188 14.6719 15.75 14.2031L13.5469 12L15.75 9.79688C16.2188 9.375 16.2188 8.67188 15.75 8.20312C15.3281 7.78125 14.625 7.78125 14.1562 8.20312L11.9531 10.4062L9.75 8.20312C9.32812 7.78125 8.625 7.78125 8.20312 8.20312Z" fill="white" fill-opacity="0.75"/>
    </svg>
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Close_Shaded)