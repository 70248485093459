export const listOfColumns = [{
    title: "File Name",
    sub_title: "A-Z",
    field_name: "fileName",
    type: "text_with_sorting_option",
    sort_option: [
        'A-Z',
        'Z-A'],
   
    width: "25%"
},
{
    title: "Library",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "library_name",
    sort_option: [
        'A-Z',
        'Z-A'],
    width: "25%"
},
{
    title: "Created",
    sub_title: "A-Z",
    type: "text_with_sorting_option",
    field_name: "created",
    sort_option: [
        'Newest',
        'Oldest'],
    width: "15%"
},
{
    title: "Status",
    sub_title: "Active",
    type: "text_with_sorting_option",
    is_this_chip:true,
    field_name: "status",
    sort_option: [
        'all',
        'active',
        'inactive'],
    width: "15%"
},
{
    title: "Processing Status",
    type: "text_only",
    field_name: "processing_status",
    width: "15%"
},
{
    title: "Actions",
    field_name: "actions_list",
    type: "dropdown_actions",
    width: "5%"
}
]