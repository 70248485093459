import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Arrow_Upward_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="28" height="28" rx="14" fill="#EC950A" fill-opacity="0.1"/>
    <path d="M8.93359 14.2578C8.61719 14.5742 8.05469 14.5391 7.77344 14.1875C7.45703 13.8711 7.49219 13.3086 7.80859 13.0273L13.1875 8.24609C13.5039 7.92969 13.9609 7.92969 14.3125 8.24609L19.6562 13.0273C19.9727 13.3438 20.0078 13.8711 19.6914 14.2227C19.5508 14.3984 19.3047 14.4688 19.0938 14.4688C18.8828 14.4688 18.6719 14.3984 18.5312 14.2578L13.75 10.0039L8.93359 14.2578ZM14.3125 14.9961L19.6562 19.7773C19.9727 20.0938 20.0078 20.6211 19.6914 20.9727C19.5508 21.1484 19.3047 21.2188 19.0938 21.2188C18.8828 21.2188 18.6719 21.1484 18.5312 21.0078L13.75 16.7539L8.96875 20.9727C8.61719 21.2891 8.05469 21.2539 7.77344 20.9375C7.45703 20.5508 7.49219 20.0586 7.84375 19.7422L13.1875 14.9961C13.5039 14.6797 13.9609 14.6797 14.3125 14.9961Z" fill="#EC950A"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Arrow_Upward_Shaded)