
import styles from './styles.module.scss'
import { connect } from "react-redux";
import Chatscreen from '../../../../../shared/ui/chatscreen'
import { setCurrentChatScreenType, setCurrentSelectedChatbot, setListOfMessages } from '../../../../../shared/ui/chatscreen/logic/slice';
import { useEffect } from 'react';
import { generateNewConversationId } from '../../../../../shared/ui/chatscreen/logic/actions';

const Playground = ({
    agent_config_to_play,
    setCurrentSelectedChatbot,
    generateNewConversationId,
    setListOfMessages,
    ...props }) => {

    useEffect(() => {
        setListOfMessages([])
        generateNewConversationId({ config_id: agent_config_to_play?.id })
    }, [])
    useEffect(() => {
        if (agent_config_to_play) {
            setCurrentSelectedChatbot(agent_config_to_play)
        }
    }, [agent_config_to_play])

    return <div className={styles.container}>
        <Chatscreen show_apps_list_in_center={false} show_topics_list_in_center={false} screen_heading_title='Q&A Chatbot Agent Playground' screen_sub_heading_title='Test your Q&A Agents' />
    </div>
}


const mapState = (state) => ({
    // is_loading_list_of_search_query_models: getIsLoadingListOfSearchQueryModels(state),
})

const mapDispatchToProps = (dispatch) => ({
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setCurrentSelectedChatbot: (data) => dispatch(setCurrentSelectedChatbot(data)),
    generateNewConversationId: (data) => dispatch(generateNewConversationId(data)),
});

export default connect(mapState, mapDispatchToProps)(Playground)