import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from '../modules/auth/logic/slice'
import AppReducer from '../modules/app/logic/slice'
import LibraryReducer from '../modules/dashboard/logic/library/slice'
import AgentsReducer from '../modules/dashboard/logic/agents/slice'
import ChatReducer from '../modules/dashboard/logic/chat/slice'
import ChatScreenReducer from '../shared/ui/chatscreen/logic/slice'
import DashboardReducer from '../modules/dashboard/logic/dashboard/slice'
import ProductionDashboardReducer from '../modules/production_dashboard/logic/slice'
import UsersReducer from '../modules/dashboard/logic/users/slice'
import LLMsReducer from '../modules/dashboard/logic/llms/slice'

const store = configureStore({
    reducer: {
        app: AppReducer,
        auth: AuthReducer,
        library: LibraryReducer,
        agents: AgentsReducer,
        chat: ChatReducer,
        chatscreen: ChatScreenReducer,
        dashboard: DashboardReducer,
        production_dashboard: ProductionDashboardReducer,
        users: UsersReducer,
        llms: LLMsReducer,
    }
});



// Types
const { getState } = store;
const RootState = getState();
const AppDispatch = store.dispatch;

export {
    store,
    RootState,
    AppDispatch,
};
