import { BASE_URL, httpGet, httpPost, httpPostWithParameters } from "../../shared/apis/axiosInstance"

const USERS_BASE_URL = `${BASE_URL}/`

const loginUser = (data) => httpPost(`${USERS_BASE_URL}api/token/pair`, data);
const registerUser = (data) => httpPost(`${USERS_BASE_URL}api/user/register/`, data);
const requestPassword = (data) => httpPost(`${USERS_BASE_URL}api/user/request-password/?email=${encodeURIComponent(data)}`, );
const verifyCode = (data) => httpPost(`${USERS_BASE_URL}api/user/verify-code/`, data);
const resetUserPassword = (data) => httpPost(`${USERS_BASE_URL}api/user/reset-password/`, data)
const getUserDetails = (data) => httpGet(`${USERS_BASE_URL}api/users/authed/`, data);
const sendFeedback = (data) => httpPost(`${USERS_BASE_URL}api/conversation/message_feedback/${data.message_id}`, data);
const sendReaction = (data) => httpPost(`${USERS_BASE_URL}api/conversation/message_reaction/${data.message_id}`, data);
const getFeedback = (data) => httpGet(`${USERS_BASE_URL}api/conversation/feedback/${data.message_id}`, data);
const updateUserAvatar = (data) => httpPost(`${USERS_BASE_URL}api/user/update-user-avatar/`, data)
const updateSingleUserPassword = (data) => httpPost(`${USERS_BASE_URL}api/user/user_password_update/`, data)
const updateUserEmail = (data) => httpPost(`${USERS_BASE_URL}api/user/update-email/`, data)
const verifyUserEmail = (data) => httpPost(`${USERS_BASE_URL}api/user/verify-email/`, data)
const requestUserPassword = (data) => httpPost(`${USERS_BASE_URL}api/user/request-password/`, data)

const addUser = (data) => httpPost(`${USERS_BASE_URL}api/user/add-user/`, data)

export {
    loginUser,
    registerUser,
    requestPassword,
    verifyCode,
    getUserDetails,
    sendFeedback,
    sendReaction,
    getFeedback,
    updateUserAvatar,
    updateSingleUserPassword,
    updateUserEmail,
    verifyUserEmail,
    requestUserPassword,
    resetUserPassword,
    addUser,
}