import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Dropup_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="28" height="28" rx="14" fill="#EC950A" fill-opacity="0.1"/>
    <path d="M6.52734 16.4023L13.2422 9.96875C13.4531 9.79297 13.6641 9.6875 13.875 9.6875C14.0859 9.6875 14.2617 9.75781 14.4375 9.89844L21.1523 16.332C21.5039 16.6836 21.5039 17.2109 21.1875 17.5273C20.8711 17.8789 20.3438 17.8789 19.9922 17.5625L13.875 11.7266L7.6875 17.6328C7.37109 17.9492 6.80859 17.9492 6.49219 17.5977C6.17578 17.2461 6.17578 16.7188 6.52734 16.4023Z" fill="#EC950A"/>
    </svg>
    
    
    
    
    
    
    
} 

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Dropup_Shaded)