const TYPE_1 = "button_with_only_text_type_1"
const TYPE_2 = "button_with_only_text_type_2"
const TYPE_3 = "button_with_icon_and_text_type_1"
const TYPE_4 = "button_with_icon_and_text_type_2"

export {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
}


