// dataLists.js

export const _listOfTones = [
    { id: "friendly", value: 'friendly', label: 'Friendly' },
    { id: "professional", value: 'professional', label: 'Professional' },
    { id: "funny", value: 'funny', label: 'Funny' },
    { id: "code", value: 'code', label: 'Code' },
    { id: "excited", value: 'excited', label: 'Excited' },
    { id: "joyful", value: 'joyful', label: 'Joyful' },
    { value: 'engaging', label: 'Engaging' },

];


export const _listOfOptions = [
    { id: "instruction", value: 'instruction', label: 'Instruction' },
    { id: "question", value: 'question', label: 'Question' },
    { id: "extract_information", value: 'extract_information', label: 'Extract Information' },
    { id: "concise", value: 'concise', label: 'Concise answer' },
];


export const _listOfGenAIModels = [
    { value: 'gpt-4-turbo-preview', label: 'GPT-4 Turbo', public: true },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo', public: true },
    { value: 'claude-2.0', label: 'Claude 2.0', public: true },
    { value: 'claude-3.0', label: 'Claude 3.0', public: true },
    { value: 'mistral-large', label: 'Mistral Large', public: true },
    { value: 'mistral-7b', label: 'Mistral 7B', public: false },
    { value: 'mixtral-8-7B', label: 'Mixtral 8x7B', public: false },
    { value: 'mixtral-8-22B', label: 'Mixtral 8x22B', public: true },
    { value: 'llama2-7b-chat', label: 'Llama2 7B Chat', public: false },
    { value: 'llama2-13b-chat', label: 'Llama2 13B Chat', public: false },
    { value: 'llama2-70b-chat', label: 'Llama2 70B Chat', public: false },
];



export const _listOfActions = [
    { id: "sign-up", value: 'sign-up', label: "Sign-Up" },
    { id: "check-in", value: 'check-in', label: "Check-In" }
]

export const _listOfEmbeddingModels = [
    { value: 'text-embedding-ada-002', label: 'Text Embedding Ada 002', public: true },
    { value: 'text-embedding-3-small', label: 'Text Embedding 3 Small', public: true },
    { value: 'text-embedding-3-large', label: 'Text Embedding 3 Large', public: true },
    { value: 'mistral-embed', label: 'Mistral Embeddings', public: true },
    { value: 'nomic-embed-text', label: 'Nomic Embed Text', public: false },
    { value: 'all-minilm', label: 'All Minilm', public: false },
    { value: 'mxbai-embed-large', label: 'Mxbai Embed Large', public: false },
    { value: 'snowflake-arctic-embed', label: 'Snowflake Arctic Embed', public: false },

];

export const _listOfSummarizationModels = [

    { value: 'gpt-4-turbo', label: 'GPT-4 Turbo', public: true },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo', public: true },
    { value: 'claude-2', label: 'Claude 2', public: true },
    { value: 'claude-3', label: 'Claude 3', public: true },
    { value: 'mistral-large', label: 'Mistral Large', public: true },
    { value: 'mistral-7b', label: 'Mistral 7B', public: false },
    { value: 'mistral-8x7b', label: 'Mixtral 8x7B', public: false },
    { value: 'mistral-8x22b', label: 'Mixtral 8x22B', public: true },
    { value: 'llama2-7b-chat', label: 'Llama2 7B Chat', public: false },
    { value: 'llama2-13b-chat', label: 'Llama2 13B Chat', public: false },
    { value: 'llama2-70b-chat', label: 'Llama2 70B Chat', public: false },
];

export const _listOfModels = [
    { value: 'gpt-4-turbo-preview', label: 'GPT-4 Turbo Preview', public: true },
    { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo', public: true },
    { value: 'claude 2.0', label: 'Claude 2.0', public: true },
    { value: 'llama2-70b-chat', label: 'Llama2-70B Chat', public: false },
    { value: 'llama2-13b-chat', label: 'Llama2-13B Chat', public: false },
    { value: 'llama2-7b-chat', label: 'Llama2-7B Chat', public: false },
    { value: 'mixtral-8x7b-instruct', label: 'Mixtral-8x7B Instruct', public: true },
];


export const _list_of_bot_questions = [
    {
        sender: "Cario.ai",
        timeStamp: "9 Jun 2024",
        content: "What is the name of your company?",
        messageType: "text",
        isBot: true,
        isLoading: true,
    },

    {
        sender: "Cario.ai",
        timeStamp: "9 Jun 2024",
        content: "What industry does your business operate in?",
        messageType: "choice",
        isLoading: true,
        isResponseSubmitted: false,
        options: [{
            id: "1",
            title: "Option 1",
            isSelected: false
        },
        {
            id: "2",
            title: "Option 2",
            isSelected: false
        },
        {
            id: "3",
            title: "Option 3",
            isSelected: false
        }], // List of options
        isBot: true,
    },

    {
        sender: "Cario.ai",
        timeStamp: "9 Jun 2024",
        content: "Describe the most manual tasks your business does.",
        messageType: "text",
        isBot: true,
        isLoading: true,
    },

    {
        sender: "Cario.ai",
        timeStamp: "9 Jun 2024",
        content: "Describe the opportunities for GenAI in your company.",
        messageType: "text",
        isBot: true,
        isLoading: true,
    },
]

export const _listOfAgentTypes = [
    { id: "Create Chatbot", value: 'Create Chatbot', label: 'Create Chatbot' },
    { id: "Create QA Chatbot", value: 'Create QA Chatbot', label: 'Create QA Chatbot' },
    { id: "Create Analytics Agent", value: 'Create Analytics Agent', label: 'Create Analytics Agent' },
]