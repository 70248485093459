import { StyledDateRangePicker, StyledDropdown, StyledDropdown_type_2 } from "./styled"

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { MenuItem, CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SortIcon from '../../../assets/images/icons8-sort-30.png'
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
// import SortIcon from '../../../../src/assets/images/icons8-filter-50.png'
import { Icon } from "@mui/material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


const dropdownStyle = {
    backgroundColor: "#242426",
    color: "white"
};

const CustomDropdown_type_1 = ({ options, defaultValue, onClick, current_theme, is_loading = true }) => {
    // State to manage the selected value
    const [selectedValue, setSelectedValue] = useState('');

    // Log to check values
    console.log('CustomDropdown_type_1 Render', { defaultValue, options, selectedValue });

    // Effect to set the default value when the component mounts or defaultValue changes
    useEffect(() => {
        console.log('CustomDropdown_type_1 useEffect', { defaultValue });
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    // Handle selection change
    const handleChange = (event) => {
        const newValue = event.target.value;
        console.log('CustomDropdown_type_1 handleChange', { newValue });
        setSelectedValue(newValue);

        // Check if options array is defined and not empty, and newValue is defined
        if (options && options.length > 0 && newValue !== undefined && !is_loading) {
            const selectedOption = options.find(option => option.value === newValue);
            if (selectedOption) {
                console.log('CustomDropdown_type_1 selectedOption', { selectedOption });
                onClick(selectedOption);
            }
        }
    };

    return (
        <StyledDropdown
            select
            value={selectedValue} // Use the selectedValue state
            onChange={handleChange} // Handle changes
            InputLabelProps={{ shrink: false }} // Disable label shrinking
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            backgroundColor: current_theme?.palette?.inputFieldBackground?.primary || "#242426",
                            color: current_theme?.palette?.textColors?.primary || "white",
                        }, // Example style, replace with your `dropdownStyle`
                    },
                },
            }}
            disabled={is_loading} // Disable the dropdown while loading
        >
            {is_loading ? (
                <MenuItem disabled>
                    {/* Display a loading spinner or text */}
                    <CircularProgress size={24} /> Loading models...
                </MenuItem>
            ) : (
                options.map((option) => (
                    <MenuItem
                        key={option?.value ?? option?.id}
                        value={option?.value ?? option?.label}
                    >
                        {option?.name || option?.value || option?.label}
                    </MenuItem>
                ))
            )}
        </StyledDropdown>
    );
};

const CustomDropdown_type_1_2 = ({ options, defaultValue, onClick, current_theme, is_loading = true }) => {
    // State to manage the selected value
    const [selectedValue, setSelectedValue] = useState('');

    // Log to check values
    console.log('CustomDropdown_type_1 Render', { defaultValue, options, selectedValue });

    // Effect to set the default value when the component mounts or defaultValue changes
    useEffect(() => {
        console.log('CustomDropdown_type_1 useEffect', { defaultValue });
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    // Handle selection change
    const handleChange = (event) => {
        const newValue = event.target.value;
        console.log('CustomDropdown_type_1 handleChange', { newValue });
        setSelectedValue(newValue);

        // Check if options array is defined and not empty, and newValue is defined
        if (options && options.length > 0 && newValue !== undefined && !is_loading) {
            const selectedOption = options.find(option => option.value === newValue);
            if (selectedOption) {
                console.log('CustomDropdown_type_1 selectedOption', { selectedOption });
                onClick(selectedOption);
            }
        }
    };

    return (
        <StyledDropdown
            select
            value={selectedValue} // Use the selectedValue state
            onChange={handleChange} // Handle changes
            InputLabelProps={{ shrink: false }} // Disable label shrinking
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            backgroundColor: current_theme?.palette?.inputFieldBackground?.primary || "#242426",
                            color: current_theme?.palette?.textColors?.primary || "white",
                        }, // Example style, replace with your `dropdownStyle`
                    },
                },
            }}
            disabled={is_loading} // Disable the dropdown while loading
        >
            {is_loading ? (
                <MenuItem disabled>
                    {/* Display a loading spinner or text */}
                    <CircularProgress size={24} /> Loading models...
                </MenuItem>
            ) : (
                options.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))
            )}
        </StyledDropdown>
    );
};

const CustomDateRangePicker = ({ current_theme, setCurrentDateRange }) => {
    const today = dayjs();

    // Set the default value of the DateRangePicker to today
    const startDate = today.subtract(7, 'day');

    // Set the default value of the DateRangePicker to the past 7 days
    const defaultValue = [startDate, today];

    // If you want to make the picker controlled, you can use state to manage the value
    //   const [value, setValue] = React.useState(defaultValue);
    // Initialize dateRange state with today for both start and end dates
    const [dateRange, setDateRange] = useState(defaultValue);
    useEffect(() => {
        setCurrentDateRange(dateRange)
    }, [dateRange])
    console.log(dateRange)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDateRangePicker
                startText=""
                endText=""
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                renderInput={(startProps, endProps) => (
                    <>
                        <TextField {...startProps} />
                        <TextField {...endProps} />
                    </>
                )}
            />
        </LocalizationProvider>

    );
};


const CustomDropdown_type_2 = ({ options, defaultValue, onClick, is_loading, current_theme }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    useEffect(() => {
        setSelectedValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
        const selectedOption = options.find(option => option.value === newValue);
        if (selectedOption) {onClick(selectedOption)};
    };

    return (
        <StyledDropdown_type_2
            select
            value={selectedValue}
            onChange={handleChange}
            InputLabelProps={{ shrink: false }}
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        style: {
                            backgroundColor: current_theme?.palette?.textColors?.primary != undefined ? current_theme?.palette?.inputFieldBackground?.primary : "#242426",
                            color: current_theme?.palette?.textColors?.secondary != undefined ? current_theme?.palette?.textColors?.secondary : "white"
                        },
                    },
                },
            }}
            disabled={is_loading}
        >
            {is_loading ? (
                <MenuItem disabled>
                    <CircularProgress size={24} /> Loading...
                </MenuItem>
            ) : (
                options.map((option) => (
                    <MenuItem key={option.value} value={option.value}
                        onClick={(e) => { onClick(option) }}>
                        {option.label}
                    </MenuItem>
                ))
            )}
        </StyledDropdown_type_2>
    );
};

const ITEM_HEIGHT = 48;

const CustomActionsDropdown_type_1 = ({ document: propDocument, options, open, onClick, onClose, anchorEl, current_theme,icon=null }) => {
    useEffect(() => {
        // Function to handle click outside of the menu
        const handleClickOutside = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                // onClose(options[document], )}
            }
        };

        // Add event listener when the dropdown is open
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, anchorEl, onClose, document]);


    return (
        <div style={{ width: '100%' }}  >
            <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClick}
                style={{ backgroundColor: 'rgba(9255, 90, 0, 0.1)' }}
            >
                 <MoreVertIcon style={{ color: current_theme?.palette?.textColors?.secondary != undefined ? current_theme?.palette?.textColors?.secondary : "white" }} />
            
            </IconButton>

            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        backgroundColor: current_theme?.palette?.inputFieldBackground?.primary != undefined ? current_theme?.palette?.inputFieldBackground?.primary : "#242426",
                        color: current_theme?.palette?.textColors?.primary != undefined ? current_theme?.palette?.textColors?.primary : "white",
                        borderRadius: '15px',
                        borderBottom: 'white'
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={(event) => onClose(propDocument, option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

const CustomActionsDropdown_type_2 = ({ document: propDocument, options, open, onClick, onClose, anchorEl, current_theme }) => {
    useEffect(() => {
        // Function to handle click outside of the menu
        const handleClickOutside = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                onClose(); // Close the menu
            }
        };

        // Add event listener when the dropdown is open
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, anchorEl, onClose, document]);

    // console.log('options', options)

    return (
        <div style={{ width: '100%' }}>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'sort-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClick}
            >
                <FilterAltIcon style={{ color: current_theme?.palette?.textColors?.primary != undefined ? current_theme?.palette?.textColors?.primary : "white" }} />
            </IconButton>
            <Menu
                id="sort-menu"
                MenuListProps={{
                    'aria-labelledby': 'sort-button',
                }}
                anchorEl={anchorEl}
                open={open}
                // onClose={onClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        backgroundColor: current_theme?.palette?.inputFieldBackground?.secondary != undefined ? current_theme?.palette?.inputFieldBackground?.secondary : "#242426",
                        color: current_theme?.palette?.textColors?.primacy != undefined ? current_theme?.palette?.textColors?.primary : "white",
                        borderRadius: '15px',
                        borderBottom: 'white'
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option} onClick={() => onClose(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

// with cross button
const CustomActionsDropdown_type_3 = ({ options, open, onClick, onClose, anchorEl, current_theme, showBackArrow }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (anchorEl && !anchorEl.contains(event.target)) {
                onClose(null); // Close the menu
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, anchorEl, onClose]);

    const handleCloseMenu = () => {
        onClose(); // Call the onClose function to close the menu
    };

    return (
        <div style={{ width: '100%' }}>
            <IconButton
                aria-label="more"
                aria-controls={open ? 'sort-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClick}
            >
                <SwapVertIcon style={{ color: current_theme?.palette?.textColors?.primary || "white" }} />
            </IconButton>
            <Menu
                id="sort-menu"
                MenuListProps={{
                    'aria-labelledby': 'sort-button',
                }}
                anchorEl={anchorEl}
                open={open}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        backgroundColor: current_theme?.palette?.inputFieldBackground?.primary || "#242426",
                        color: current_theme?.palette?.textColors?.primary || "white",
                        borderRadius: '15px',
                        borderBottom: 'white',
                        position: 'relative',
                    },
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '-10px' }}>
                    {showBackArrow && (
                        <IconButton size="small" onClick={() => onClose('back')} style={{ marginRight: 'auto', paddingLeft: '10px' }}>
                            <ArrowBackIosIcon style={{ color: current_theme?.palette?.textColors?.primary || "white" }} />
                        </IconButton>
                    )}
                    <IconButton size="small" onClick={() => onClose('null')} style={{ marginLeft: 'auto' }}>
                        <CloseIcon style={{ color: current_theme?.palette?.textColors?.primary || "white" }} />
                    </IconButton>
                </div>
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => onClose(option)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

function getStyles(name, selectedOptions, theme) {
    // Check if the current option is selected by looking for its value in the selectedOptions array
    const isSelected = selectedOptions?.some(selectedOption => selectedOption?.value === name.value);

    // Return styling based on whether the option is selected
    return {
        fontWeight: isSelected ? 'bolder' : 'bold',
        color: isSelected ? theme.palette.textColors.secondary : theme.palette.textColors.tertiary, // Example: change color based on selection
        backgroundColor: isSelected ? theme.palette.buttonBackgrounds.darkest : 'transparent', // Change background color based on selection
    };
}

function CustomMultiSelectDropdown({ options, setOption, option, defaultValue, is_loading, onClick, current_theme }) {
    // Initialize selected options from defaultValue on component mount
    useEffect(() => {
        if (defaultValue) {
            const defaultOptions = defaultValue.map(value => options.find(option => option.value === value)).filter(Boolean);
            setOption(defaultOptions);
        }
    }, [defaultValue, options, setOption]);

    const handleChange = (event) => {
        const { target: { value } } = event; // `value` here is the array of selected option values
        // Map back to the full option objects to maintain the structure
        const updatedOptions = value.map(val => options.find(opt => opt.value === val));
        setOption(updatedOptions); // Update the parent component state

        if (onClick) {
            onClick(updatedOptions); // Pass the full option objects to the onClick handler
        }
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: "100%" }} disabled={is_loading}>
                <Select
                    multiple
                    displayEmpty
                    value={option?.map(opt => opt?.value)} // Ensure `option` is always an array
                    onChange={handleChange}
                    input={<OutlinedInput notched={false} style={{
                        color: current_theme?.palette?.textColors?.primary,
                        backgroundColor: current_theme?.palette?.inputFieldBackground?.primary,
                        border: `1px solid ${current_theme?.palette?.borderColors?.primary}`,
                        borderRadius: '15px', // Add border radius here
                    }} />}
                    renderValue={selected =>
                        selected?.length > 0
                            ? selected?.map(val => options.find(name => name?.value === val)?.label).join(', ')
                            : <em>Select library</em>
                    }
                    MenuProps={{
                        PaperProps: {
                            style: {
                                backgroundColor: current_theme?.palette?.backgroundColors?.primary,
                                color: current_theme?.palette?.textColors?.primary,
                                maxHeight: 48 * 4.5 + 8,
                                width: 350,
                            },
                        },
                    }}
                    inputProps={{ 'aria-label': 'Without label', style: { color: current_theme?.palette?.textColors?.primary } }}
                >
                    {is_loading ? (
                        <MenuItem disabled value="">
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        options.map((name) => (
                            <MenuItem key={name.value} value={name.value} style={getStyles(name, option, current_theme)}>
                                {name.label}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </div>

    );
}

export { CustomDropdown_type_1, CustomDropdown_type_1_2, CustomActionsDropdown_type_1, CustomActionsDropdown_type_2, CustomActionsDropdown_type_3, CustomDateRangePicker, CustomDropdown_type_2, CustomMultiSelectDropdown }