
import { BASE_URL, httpDelete, httpGet, httpPost, httpPut } from "../../shared/apis/axiosInstance"
const USERS_BASE_URL = `${BASE_URL}/`

const sendChatMessageToServer = (data) => httpPost(`${USERS_BASE_URL}api/chatbot/chat/`, data);
const sendQuestionMessageToServer = (data) => httpPost(`${USERS_BASE_URL}api/chatbot/questions/`, data);
const attachDocuments = (data,con_id) => httpPost(`${USERS_BASE_URL}api/conversation/${con_id}/add_document/`, data);
const createNewConversation = (data) => httpPost(`${USERS_BASE_URL}api/conversation/create/?config_id=${data.config_id}`, data);
const getConversationInfo = (data) => httpGet(`${USERS_BASE_URL}api/conversation/retrieve/${data.id}`, data);

export {
    sendChatMessageToServer,
    sendQuestionMessageToServer,
    createNewConversation,
    getConversationInfo,
    attachDocuments,
}