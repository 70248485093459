import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import FormControlLabel  from '@mui/material/FormControlLabel';

const StyledTableContainer = styled.div`
 
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
  `;

  const StyledFormControlLabel = styled(FormControlLabel)`
  color:white
  `;


const StyledTabContainer = styled.div`
  display: flex;
  width: 20%;
  flex-direction: row;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
 
  border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
  padding: 1%;

`;

const StyledUploadFilesContainer = styled.div`
border-radius:20px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 25px;
  }
`;
const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 15px;
  }
`;
const StyledHeading3 = styled(Typography)`
  && {
    cursor:pointer;
     color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
    /* Use the provided color if available, otherwise fallback to the theme's primary text color */
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 700;
    line-height: normal;
    font-size: 15px;
  }
`;
const StyledHeading4 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 12px;
  }
`;
const StyledHeading5 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 25px;
  }
`;
const StyledHeading6 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 25px;
  }
`;
const StyledHeading7 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 18px;
  }
`;
const StyledHeading8 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.secondary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 18px;
  }
`;

const StyledHeading9 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 15px;
  }
`;

const StyledHeading10 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 100;
    line-height: normal;
    font-size: 10px;
  }
`;

const StyledHeading11 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.secondary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    font-size: 15px;
  }
`;

const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
    color: ${({ theme }) => theme.palette.textColors.default}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      border: 1px solid ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      color: ${({ theme }) => theme.palette.textColors.primary}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.secondary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;
const MyStyledButton_type2 = styled(Button)`
  && {
    background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
    color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.primary};
      color: ${({ theme }) => theme.palette.textColors.default}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;

  }
`;
const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;

const StyledChip = styled.div`
  background-color:  ${({ theme }) => theme.palette.backgroundColors.light};
  color:${({ color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
  border-radius:25px;
  width:50%;
  text-align:center;
  padding:1rem
 
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 300;
    line-height: normal;
    cursor:pointer;
    font-size: 15px;
`;

const MoreList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  top: 100%; /* Position it below the "more" icon */
  right: 0;
  display: ${props => (props.isOpen ? 'block' : 'none')}; /* Conditional display */
`;

export default MoreList;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  min-height:70%;
  max-height:fit-content;
  transform: translate(-50%, -50%);
  width: 50%;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
  border: 1px solid ${({ theme }) => theme.palette.borderColors.darkest};
  box-shadow: 24px;
  padding: 16px;
`;

const StyledTextField = styled(TextField)`
  && {
    display: flex;
    width:100%;
    
    input {
      height: 10px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 15px;
    border: 1px solid  ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
  
    
    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;

const StyledDropdown = styled(TextField)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
    color: ${({ theme }) => theme.palette.textColors.primary};

    /* Hide label */
    .MuiInputLabel-root {
      display: none;
    }

    /* Change text color */
    .MuiSelect-root {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change the arrow icon color */
    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change text field color when focused */
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }

    & .MuiOutlinedInput-root.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Change text color here */
    }

    /* Change text field color when not focused */
    & .MuiOutlinedInput-root:not(.Mui-focused) {
      color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Change text color when not focused */
    }

   
  }
`;

const StyledTextarea = styled(TextareaAutosize)`
  && {
    display: block;
    max-width: 98%;

    min-width: 98%;
    min-height: 100px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-weight: 400;
    border-radius: 15px;
    color:  ${({ theme }) => theme.palette.textColors.quaternary}; 
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  
    &::placeholder {
      color: ${({ theme }) => theme.palette.textColors.quaternary};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }
  }
`;

const StyledLabel = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-size: 14px;
  }
`;


const StyledTagsListContainer = styled.div`
display:flex;
justify-content:start;
flex-direction:row;
width:100%;
height:100%;
flex-wrap: wrap; /* Items will wrap to new lines */
justify-content: flex-start;
overflow:hidden;
align-items:center;
background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`

const StyledTagItem = styled.span`
min-width:fit-content;
height:30px;
color:${({ theme }) => theme.palette.textColors.primary};
border-radius:20px;
border: 1px solid ${({ theme }) => theme.palette.borderColors.secondary};
margin:2px;
`

const StyledTagChip = styled(Chip)`
  && {
    color: ${({ theme }) => theme.palette.textColors.chip_text_color};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background-color: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};
    cursor:pointer;
    font-size: 14px;
  }
`;

const StyledChipCreateButton = styled.div`

    color: ${({ theme }) => theme.palette.textColors.primary};
    
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background-color: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};
    cursor:pointer;
    font-size: 14px;
    width:50%;
    height:100;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`;

const StyledChipTextField = styled(TextField)`
  && {
    display: flex;
    width:65%;
    height: 28px;
    input {
      height: 0px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    
    border: 1px solid  transparent;
    background: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};

    /* Shadow/xs */
  
    
    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color:transparent;
      }
    }
  }
`;

const StyledTagChipIcon = styled(TagFacesIcon)`
  && {
    color: ${({ theme }) => theme.palette.textColors.chip_text_color};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    background-color: ${({ theme }) => theme.palette.backgroundColors.tag_chip_bg};
    cursor:pointer;
    font-size: 14px;
    
  }
`;

const StyledSwitch = styled(Switch)`
  && {
    & .MuiSwitch-switchBase.Mui-checked {
      color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      &:hover {
        background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      }
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
    }

    & .MuiSwitch-track.Mui-disabled {
      background-color: ${({ theme }) => theme.palette.backgroundColors.light}; /* Change the color when the switch is disabled and checked */
    }
  }
`;

export {
  StyledHeading1,
  StyledHeading2,
  MyStyledButton_type1,
  StyledTableContainer,
  StyledHeading3,
  StyledHeading4,
  StyledDivider,
  StyledChip,
  MoreList,
  ModalContainer,
  StyledHeading5,
  MyStyledButton_type2,
  StyledTextField,
  StyledLabel,
  StyledTextarea,
  StyledTagItem,
  StyledTagsListContainer,
  StyledTagChip,
  StyledChipTextField,
  StyledChipCreateButton,
  StyledHeading6,
  StyledHeading7,
  StyledHeading8,
  StyledHeading9,
  StyledHeading10,
  StyledUploadFilesContainer,
  StyledDropdown,
  StyledHeading11,
  StyledTabContainer,
  StyledSwitch,
  StyledSideBarContainer,
  StyledFormControlLabel,
}