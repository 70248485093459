import { UserAPI } from "../../api";

import { getUserDetails, showToastMessage } from "../../app/logic/actions";
import { setIsUserDetailsUpdated, setRoute } from "../../app/logic/slice";
import { toggleLoading } from "./slice"

const handleLoginUser = ({ email, password }) => async (dispatch) => {
    try {
        dispatch(toggleLoading(true));
        const res = await UserAPI.loginUser({ email, password });
        localStorage.setItem('access', res.data.access);
        localStorage.setItem('refresh', res.data.refresh);

        setTimeout(async () => {
            try {
                const response = await UserAPI.getUserDetails({});
                localStorage.setItem("user", JSON.stringify(response.data));
                dispatch(setIsUserDetailsUpdated({}));
                dispatch(toggleLoading(false));
                if (response.data.role === "super_user") {
                    dispatch(setRoute("dashboard/home"));
                } else {
                    dispatch(setRoute("user/dashboard"));
                }
            } catch (error) {
                dispatch(toggleLoading(false));
            }
        }, 2000);
    } catch (err) {
        if (err.response?.data?.detail) {
            dispatch(showToastMessage({ type: 'error', message: err.response.data.detail }));
        } else if (err.error) {
            dispatch(showToastMessage({ type: 'error', message: err.error }));
        }
        dispatch(toggleLoading(false));
    }
};


const handleRegisterUser = ({ email, password, first_name, last_name }) => async (dispatch) => {
    try {
        dispatch(toggleLoading(true));

        const res = await UserAPI.registerUser({ email, password, first_name, last_name });

        setTimeout(() => {
            if (res.data) {
                dispatch(showToastMessage({ type: 'success', message: "Account created successfully" }));
            } else {
                dispatch(showToastMessage({ type: 'error', message: "Could not create account successfully" }));
            }
            dispatch(toggleLoading(false));
        }, 3000);
    } catch (err) {
        dispatch(showToastMessage({ type: 'error', message: err.response?.data?.error }));
        dispatch(toggleLoading(false));
    }
};


const handleRequestPassword = (email) => async (dispatch) => {
    dispatch(toggleLoading(true))
    try {
        const response = await UserAPI.requestPassword(email)
        dispatch(showToastMessage({type:'success', message:"Code sent to email"}))
        dispatch(toggleLoading(false));
 
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: 'Error sending email'}))
        dispatch(toggleLoading(false));
    }
}

const handleVerifyCode = (data) => async (dispatch) => {
    dispatch(toggleLoading(true));
    try {
        const response = await UserAPI.verifyCode(data);
        const { token } = response.data;
        if (!token) {
            throw new Error('Invalid code'); 
        }
        dispatch(showToastMessage({ type: 'success', message: "Correct code" }));
        dispatch(toggleLoading(false));
        return response.data.token; 
    } catch (err) {
        dispatch(showToastMessage({ type: 'error', message: 'Error with code' }));
        dispatch(toggleLoading(false));
        throw err;
    }
}

const handleResetUserPassword = (data) => async (dispatch) => {
    dispatch(toggleLoading(true))
    try {
        const response = UserAPI.resetUserPassword(data)
        dispatch(showToastMessage({type:'success', message:"Successfully changed password"}))
        dispatch(toggleLoading(false))
    } catch (error) {
        dispatch(showToastMessage({ type: 'error', message: 'Error updating password'}))
        dispatch(toggleLoading(false))
    }
}

export { handleLoginUser, handleRegisterUser, handleRequestPassword, handleVerifyCode, handleResetUserPassword }