import styles from './styles.module.scss'
import { StyledAgentMainContainer, StyledSlider, StyledTextarea, } from './styled'
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import ic_delete_file from '../../../../../assets/images/ic_delete_file.svg'
import { connect } from "react-redux";
import { _listOfModels, _listOfOptions, _listOfTones, _list_of_bot_questions, _listOfActions } from './data';
import { getUserDetails, showToastMessage } from '../../../../app/logic/actions';
import { getCurrentTheme, isUserDetailsUpdated } from '../../../../app/logic/selectors';
import { createQuestionChatbotAgentConfig, fetchQAChatbotAgent, updateQAAgentConfig } from '../../../logic/agents/actions';
import { getAgentConfigToEdit, getAgentConfigToTest, getIsAgentPlaygroundVisible, getIsConfigCreated } from '../../../logic/agents/selectors';
import Ic_Adjustment_Settings_Shaded from '../../../../../shared/ui/editable_icons/Ic_Adjustment_Settings_Shaded';
import { setIsAgentPlaygroundVisible } from '../../../logic/agents/slice';
import { setIsConfigCreated } from '../../../logic/agents/slice';
import { CustomLabel } from '../../../../../shared/ui/label';
import CustomTextField from '../../../../../shared/ui/text_field';
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { TYPE_4 } from '../../../../../shared/ui/models_dropdowns/models_types';
import General_dropdowns from '../../../../../shared/ui/general_dropdowns';
import { TYPE_2, TYPE_5 } from '../../../../../shared/ui/general_dropdowns/general_types';
import { CustomButton } from '../../../../../shared/ui/buttons';
import { TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types';
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomSwitch } from '../../../../../shared/ui/switch';
import { setCurrentChatScreenType, setCurrentSelectedChatbot, setIsChatbotAdvancedSettingsOpen, setIsQAbotAdvancedSettingsOpen } from '../../../../../shared/ui/chatscreen/logic/slice';
import { getCurrentChatScreenType } from '../../../../../shared/ui/chatscreen/logic/selectors';
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
const QAChatBotAgent = ({
    current_theme,
    setIsAgentConfigScreenOpen,
    showToastMessage,
    createQuestionChatbotAgentConfig,
    isUserDetailsUpdated,
    getUserDetails,
    agent_config_to_edit,
    new_updated_agent_config,
    updateQAAgentConfig,
    agent_config_to_test,
    fetchQAChatbotAgent,
    setCurrentAgentToEditForQA,

    setIsAgentPlaygroundVisible,
    fetchMessagesHistory,
    setOpenSideBar,
    setIsConfigCreated,

    is_agent_playground_visible,
    is_agent_created_or_updated,
    current_chat_screen_type,
    setIsQAbotAdvancedSettingsOpen,
    setIsChatbotAdvancedSettingsOpen,
    setCurrentChatScreenType,

    setCurrentSelectedChatbot,

    ...props }) => {

    const [isTesting, setIsTesting] = useState(false);
    const [currentConfigIdToUseForTesting, setCurrentConfigIdToUseForTesting] = useState(-1)
    const [agentName, setAgentName] = useState('');
    const [completionLimit, setCompletionLimit] = useState(90)
    const [tone, setTone] = useState(null);
    const [toneValue, setToneValue] = useState('friendly');
    const [isToneEnabled, setIsToneEnabled] = useState(false);
    const [instruction, setInstruction] = useState(null);
    const [instructionValue, setInstructionValue] = useState(null);
    const [currentCreativity, setCurrentCreativity] = useState(0.8)
    const [currentSelectedChatbotModel, setCurrentSelectedChatbotModel] = useState([]);
    const [currentSelectedChatbotModelValue, setCurrentSelectedChatbotModelValue] = useState(null);
    const [llmTypeForChatbotModel, setLLMTypeForChatbotModel] = useState('PublicLLM');
    const [agentConfigToEdit, setAgentConfigToEdit] = useState(null);
    const [listOfQuestionsToAsk, setListOfQuestionsToAsk] = useState([
        { id: 1, question: '', placeholder: 'Enter question 1' }
    ]);

    const messageContainerRef = useRef(null);
    const [prompt, setPrompt] = useState("")
    const [disclaimer, setDisclaimer] = useState("")
    const [error, setError] = useState('')
    const [confirmCancel, setConfirmCancel] = useState(false)

    useEffect(() => {
        if (agent_config_to_edit != null)
            setAgentConfigToEdit(agent_config_to_edit)
    }, [agent_config_to_edit]);

    useEffect(() => {
        if (new_updated_agent_config != null) {
            const mappedData = {
                type: new_updated_agent_config?.action === "check-in" ? "Intent" : "Question",
                id: new_updated_agent_config.id,
                name: new_updated_agent_config.agent_name,
                model: new_updated_agent_config.model,
                generativeModel: new_updated_agent_config.model,
                tokenLimit: '-',
                isToneActive: new_updated_agent_config.is_tone_active,
                tone: new_updated_agent_config.tone,
                isActionActive: new_updated_agent_config.is_action_active,
                action: new_updated_agent_config.action,
                creativity: new_updated_agent_config.creativity,
                questions: new_updated_agent_config.questions, 
                systemPrompt: new_updated_agent_config.system_prompt,
                disclaimer: new_updated_agent_config.disclaimer,
                is_test: new_updated_agent_config.is_test,
                qaQuestions: new_updated_agent_config.qa_questions ? Object.keys(new_updated_agent_config.qa_questions).map(key => ({
                    question: new_updated_agent_config.qa_questions[key].question_prompt,
                    answer: new_updated_agent_config.qa_questions[key].answer,
                    defaultPosition: new_updated_agent_config.qa_questions[key].default_position
                })) : [],
                createdAt: new_updated_agent_config.created_at.substring(0, 10),
                topics: new_updated_agent_config?.topics
            }

            setAgentConfigToEdit(mappedData)
        }

    }, [new_updated_agent_config])


    useEffect(() => {
        if (is_agent_created_or_updated) {
            setCurrentAgentToEditForQA(null)
            setOpenSideBar(false);
            setIsConfigCreated(false)
        }
    }, [is_agent_created_or_updated])

    const scrollToBottom = () => {
        const scroll = messageContainerRef?.current?.scrollHeight - messageContainerRef?.current?.clientHeight;
        messageContainerRef?.current?.scrollTo(0, scroll);
    };

    useEffect(() => {
        getUserDetails({})
    }, [])

    useEffect(() => {
        if (agentConfigToEdit != null) {
            setCurrentConfigIdToUseForTesting(agentConfigToEdit.id)
        }
    }, [agentConfigToEdit]);

    useEffect(() => {
        try {
            if (agentConfigToEdit != null && agentConfigToEdit != undefined) {
                const creativityValue = parseFloat(agentConfigToEdit.creativity);
                if (!isNaN(creativityValue)) {
                    setCurrentCreativity(creativityValue);
                } else {
                    setCurrentCreativity(0.8);
                }
    
                const questionsString = agentConfigToEdit.questions;
                const listOfQuestions_Rerendered = questionsString?.map((q, index) => ({
                    id: index,
                    question: q.replace(/'/g, ''),
                    placeholder: 'Enter question 1'
                }));
    
                const currentSelectedInstruction = agentConfigToEdit?.action;
                const currentSelectedTone = agentConfigToEdit?.tone;
                setInstructionValue(currentSelectedInstruction);
                setInstruction({ value: currentSelectedInstruction, id: currentSelectedInstruction });
                setToneValue(currentSelectedTone);
                setIsTesting(agentConfigToEdit.is_test);
                setPrompt(agentConfigToEdit.systemPrompt || '');
                setAgentName(agentConfigToEdit.name || '');
                setIsToneEnabled(agentConfigToEdit.isToneActive || false);
    
                const type_selected_for_chat_bot_model = agentConfigToEdit?.model?.llm_type;
                const model_selected_for_chat_bot = agentConfigToEdit?.model?.value;
                setCurrentSelectedChatbotModelValue(model_selected_for_chat_bot);
                setLLMTypeForChatbotModel(type_selected_for_chat_bot_model);
    
                setDisclaimer(agentConfigToEdit?.disclaimer);
                setListOfQuestionsToAsk(listOfQuestions_Rerendered);
            }
        } catch (error) {
            setError("An error occurred while updating agent configuration:", error);
        }
    }, [agentConfigToEdit, agent_config_to_test, isTesting]);
    

    function valuetext(value) {
        setCurrentCreativity(value)
        return `${value}`;
    }

    const handleAddNewQuestion = () => {
        const newQuestionId = listOfQuestionsToAsk.length + 1;
        setListOfQuestionsToAsk(prevQuestions => [
            ...prevQuestions,
            { id: newQuestionId, question: '', placeholder: `Enter question ${newQuestionId}` }
        ]);
    };

    const handleChangeQuestion = (id, value) => {
        setListOfQuestionsToAsk(prevQuestions => {
            const updatedQuestions = prevQuestions.map(question => {
                if (question.id === id) {
                    return { ...question, question: value };
                }
                return question;
            });
            return updatedQuestions;
        });
    };

    const handleRemoveQuestion = (id) => {
        setListOfQuestionsToAsk(prevQuestions => prevQuestions.filter(question => question.id !== id));
    };
    
    const validateFields = () => {
        if (agentName.length === 0) {
            showToastMessage({ message: "Cannot leave agent name field empty", type: "error" });
            return false;
        }
        if (completionLimit.length === 0) {
            showToastMessage({ message: "Cannot leave completion limit field empty", type: "error" });
            return false;
        }
        return true;
    };
    
    const buildConfig = (is_test) => {
        return {
            ...(agentConfigToEdit && { id: agentConfigToEdit.id }),
            agent_name: agentName,
            tone: getToneValue(),
            is_tone_active: isToneEnabled,
            model: getModelValue(),
            action: getActionValue(),
            is_action_active: true,
            creativity: `${currentCreativity}`,
            questions: listOfQuestionsToAsk.map(q => q.question),
            is_test: is_test,
            disclaimer: disclaimer,
            system_prompt: prompt,
            topics: []
        };
    };
    
    const getToneValue = () => {
        return tone?.value == null 
            ? agentConfigToEdit?.tone == null 
                ? 'friendly' 
                : agentConfigToEdit.tone 
            : tone.value;
    };
    
    const getModelValue = () => {
        return currentSelectedChatbotModel?.id ?? agentConfigToEdit?.id;
    };
    
    const getActionValue = () => {
        return instruction?.value == null 
            ? agentConfigToEdit?.action == null 
                ? 'sign-up' 
                : agentConfigToEdit.action 
            : instruction.value;
    };
    
    const saveConfig = (config, is_test) => {
        const actionType = (() => {
            if (is_test && !agentConfigToEdit && !is_agent_playground_visible) return 'create';
            if (is_test && agentConfigToEdit && !is_agent_playground_visible) return 'updateSave';
            if (is_agent_playground_visible && agentConfigToEdit) return 'updateSave';
            if (agentConfigToEdit) return 'updateSave';
            if (!is_agent_playground_visible) return 'create';
            return null;
        })();
    
        switch (actionType) {
            case 'create':
                showToastMessage({ message: "Creating configuration", type: "info" });
                createQuestionChatbotAgentConfig(config);
                break;
            case 'updateSave':
                showToastMessage({ message: "Updating and saving...", type: "info" });
                updateQAAgentConfig(config);
                break;
            default:
                break;
        }
    };

    const handleSaveConfig = (is_test) => {
        try {
            setIsTesting(is_test);
            if (!validateFields()) return;
            const config = buildConfig(is_test);
            saveConfig(config, is_test);
        } catch (error) {
            showToastMessage({ message: "An error occurred while saving the configuration", type: "error" });
        }
    };

    const handleModelDropdownClickForChatbotModel = (selected_chat_bot_model, llm_type) => {
        setCurrentSelectedChatbotModel(selected_chat_bot_model)
        setCurrentSelectedChatbotModelValue(selected_chat_bot_model?.value)
        setLLMTypeForChatbotModel(llm_type)
    }

    const handleGeneralDropdownForTone = (selected_tone_value) => {
        setTone(selected_tone_value)
    }

    const handleGeneralDropdownForQAction = (selected_instruction_value) => {
        setInstruction(selected_instruction_value)
    }

    const handleConfirmCancel = () => {
        setConfirmCancel(true)
    }

    return <div className={styles.container}>
        <div className={styles.agent_config_container}>
            <div className={styles.qa_chat_bot_main_container}>
                <div className={styles.header_container}>
                </div>
                <StyledAgentMainContainer className={styles.body_container}>
                    <div className={styles.left_container}>
                        <div className={styles.body_container}>
                            <div className={styles.row_1}>
                                <CustomLabel title='Agent Name'></CustomLabel>
                                <CustomTextField value={agentName} onChange={(e) => setAgentName(e.target.value)} placeholder='Enter agent name' />
                            </div>
                            <div className={styles.row_2}>
                                <CustomTextField value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder='Prompt' multiline='true' minRows={7}></CustomTextField>
                            </div>
                            <div className={styles.row_3}>
                                <CustomTextField value={disclaimer} onChange={(e) => setDisclaimer(e.target.value)} placeholder='Disclaimer' multiline='true' minRows={7}></CustomTextField>
                            </div>
                            <div className={styles.row_4}>
                                <div className={styles.additional_toggle}>
                                    <div>
                                        <p style={{ color: 'white' }}>Chatbot model</p>
                                        <CustomModelDropDown type={TYPE_4} handleOnClick={handleModelDropdownClickForChatbotModel} defaultValue={currentSelectedChatbotModelValue} defaultLLM={llmTypeForChatbotModel} />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.row_6}>
                                <div className={styles.left_container}>
                                    <CustomSwitch checked={isToneEnabled} onChange={(e) => setIsToneEnabled(e.target.checked)} />
                                    <CustomLabel title='Tone'></CustomLabel>
                                </div>
                                <div className={styles.right_container}>
                                    <General_dropdowns type={TYPE_2} handleOnClick={handleGeneralDropdownForTone} defaultValue={toneValue} />
                                </div>
                            </div>
                            <div className={styles.row_7}>
                                <CustomLabel title='Action'></CustomLabel>
                                <General_dropdowns type={TYPE_5} handleOnClick={handleGeneralDropdownForQAction} defaultValue={instructionValue} />
                            </div>
                            <div className={styles.row_8}>
                                <div className={styles.header_container}>
                                    <div className={styles.title}><CustomLabel title='Creativity'></CustomLabel></div>
                                    <div className={styles.value}> <CustomLabel title={currentCreativity}></CustomLabel></div>
                                </div>
                                <Box className={styles.slider_container}>
                                    <StyledSlider
                                        defaultValue={0.8}
                                        getAriaValueText={valuetext}
                                        value={currentCreativity} // Control the component with the state
                                        onChange={(e, newValue) => setCurrentCreativity(newValue)} // Inline handler
                                        step={0.1}
                                        // marks={labelValues}
                                        max={1}
                                    />
                                </Box>
                            </div>

                            <div className={styles.row_9}>
                                <CustomLabel title='Questions'></CustomLabel>
                            </div>
                            <div className={styles.list_of_questions}>
                                {listOfQuestionsToAsk?.map((question, index) => (
                                    <div key={question.id} className={styles.list_item_container}>
                                        <div className={styles.question_container}>
                                            <CustomTextField
                                                value={question.question}
                                                onChange={(e) => handleChangeQuestion(question.id, e.target.value)}
                                                placeholder={question.placeholder}
                                                icon={index > 0 && <img src={ic_delete_file} onClick={() => handleRemoveQuestion(question.id)} alt="Delete question" />}
                                            />
                                        </div>
                                    </div>
                                ))}

                                <div className={styles.list_item_container}>
                                    <div className={styles.add_question_btn_container}>
                                        <CustomButton font_size='10' currentButtonTpe={TYPE_1_button} background_color='quaternary' text_color='secondary' onClick={handleAddNewQuestion} title='Add New Question'></CustomButton>
                                    </div>
                                </div>
                            </div>

                            {
                                is_agent_playground_visible == false ? <div className={styles.action_button_container}>
                                    <div className={styles.btns_container}>
                                        <CustomButton font_size='10' currentButtonTpe={TYPE_1_button} onClick={() => { handleSaveConfig(agent_config_to_edit != null) }} title={agent_config_to_edit != null ? "Update" : "Create"}></CustomButton>
                                        <CustomButton
                                            currentButtonTpe={TYPE_1_button}
                                            onClick={handleConfirmCancel}
                                            border_color='white' background_color='transparent' font_size='12' padding="8px 18px" title='Cancel'
                                        >
                                        </CustomButton>
                                    </div>

                                    <div className={styles.playground_btn_container} onClick={(e) => {
                                        setIsAgentPlaygroundVisible(true)
                                        setIsQAbotAdvancedSettingsOpen(true)
                                        setIsChatbotAdvancedSettingsOpen(false)
                                        setCurrentSelectedChatbot(agentConfigToEdit)
                                        setCurrentChatScreenType("play_ground")
                                    }}>
                                        <div className={styles.icon}>
                                            <Ic_Adjustment_Settings_Shaded />
                                        </div>
                                        <div className={styles.text}>
                                            <CustomHeading text='Playground'></CustomHeading>
                                        </div>

                                    </div>
                                </div> : <div className={styles.action_button_container}>
                                    <div className={styles.apply_changes_btn}>
                                        <CustomButton font_size='10' currentButtonTpe={TYPE_1_button} onClick={() => { handleSaveConfig(true) }} title='Apply Changes'></CustomButton>
                                        <CustomButton font_size='10' currentButtonTpe={TYPE_1_button} onClick={() => { setIsAgentPlaygroundVisible(false) }} title='Cancel'></CustomButton>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <DeleteConfirmationComponent
                        isVisible={confirmCancel}
                        onConfirm={() => { setOpenSideBar(false); setConfirmCancel(false)  }}
                        onCancel={() => { setConfirmCancel(false) }}
                        message='You have unsaved changes.'
                        type='warning'
                    />
                </StyledAgentMainContainer>
            </div>

        </div>
    </div>


}


const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
    isUserDetailsUpdated: isUserDetailsUpdated(state),
    new_updated_agent_config: getAgentConfigToEdit(state),
    agent_config_to_test: getAgentConfigToTest(state),

    is_agent_playground_visible: getIsAgentPlaygroundVisible(state),
    is_agent_created_or_updated: getIsConfigCreated(state),
    current_chat_screen_type: getCurrentChatScreenType(state)
})

const mapDispatchToProps = (dispatch) => ({
    getUserDetails: (data) => dispatch(getUserDetails(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    updateQAAgentConfig: (data) => dispatch(updateQAAgentConfig(data)),
    fetchQAChatbotAgent: (data) => dispatch(fetchQAChatbotAgent(data)),
    createQuestionChatbotAgentConfig: (data) => dispatch(createQuestionChatbotAgentConfig(data)),
    setIsAgentPlaygroundVisible: (data) => dispatch(setIsAgentPlaygroundVisible(data)),
    setIsConfigCreated: (data) => dispatch(setIsConfigCreated(data)),
    setIsQAbotAdvancedSettingsOpen: (data) => dispatch(setIsQAbotAdvancedSettingsOpen(data)),
    setIsChatbotAdvancedSettingsOpen: (data) => dispatch(setIsChatbotAdvancedSettingsOpen(data)),
    setCurrentChatScreenType: (data) => dispatch(setCurrentChatScreenType(data)),
    setCurrentSelectedChatbot: (data) => dispatch(setCurrentSelectedChatbot(data)),
});

export default connect(mapState, mapDispatchToProps)(QAChatBotAgent)