import { StyledSideBarContainer, StyledQASideBarContainer, StyledAnalyticsSideBarContainer } from './styled'
import styles from './styles.module.scss'
import { connect } from "react-redux";
import { useEffect, useState } from 'react';
import ChatBotAgent from './ChatBotAgent';
import QAChatBotAgent from './QAChatBotAgent';
import { getIsAgentPlaygroundVisible, getIsConfigCreated, getIsDeletingAgentConfig, getIsLoadingListOfAgents, getListOfAgents, getListOfAnalystAgents, getListOfQAAgents } from '../../../logic/agents/selectors';
import { deleteAgentConfig, deleteAQAgentConfig, fetchListOfAnalystAgents, fetchListOfChatbotAgents, fetchListOfQAChatbotAgents, deleteAnalystAgentConfig, updateAnalystAgentConfig } from '../../../logic/agents/actions';
import { setAgentConfigToTest, setIsAgentPlaygroundVisible } from '../../../logic/agents/slice';
import { getCurrentTheme } from '../../../../app/logic/selectors';
import { useNavigate } from 'react-router';
import Ic_Close_Shaded from '../../../../../shared/ui/editable_icons/Ic_Close_Shaded'
import { fetchLLms, fetchChunkingMethods } from '../../../logic/llms/actions'
import Playground from '../Playground';
import { CustomTable } from '../../../../../shared/ui/table';
import { listOfColumns } from './table_columns';
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomButton } from '../../../../../shared/ui/buttons';
import { TYPE_1 } from '../../../../../shared/ui/buttons/button_types';
import { setIsQAbotAdvancedSettingsOpen } from '../../../../../shared/ui/chatscreen/logic/slice';

import AnalyticsAgent from './AnalyticsAgent';
import { TYPE_7 } from '../../../../../shared/ui/general_dropdowns/general_types';
import general_dropdowns from '../../../../../shared/ui/general_dropdowns';
import General_dropdowns from '../../../../../shared/ui/general_dropdowns';
import { TYPE_5 } from '../../../../../shared/ui/general_dropdowns/general_types';

import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';


const Agents = ({
    is_loading_list_of_agents,
    loadListOfAgents,
    list_of_agents,
    deleteAgentConfig,
    is_deleting_agent_config,
    setIsAgentPlaygroundVisible,
    setAgentConfigToTest,
    current_theme,
    is_config_created,
    list_of_qa_agents,
    deleteAQAgentConfig,
    fetchListOfQAChatbotAgents,
    is_agent_playground_visible,
    setIsQAbotAdvancedSettingsOpen,
    list_of_analyst_agent,
    loadListOfAnalystAgents,
    deleteAnalystAgentConfig,
    ...props }) => {

    const navigate = useNavigate();
    const [currentAgentOpenedScreen, setCurrentAgentOpenedScreen] = useState(<ChatBotAgent />)
    const [isAgentConfigScreenOpen, setIsAgentConfigScreenOpen] = useState(false);
    const [openCreateSideBar, setOpenSideBar] = useState(false)
    const [openQASideBar, setOpenQASideBar] = useState(false)
    const [openAnalyticsSideBar, setOpenAnalyticsSideBar] = useState(false)
    const [currentAgentSelectedToEdit, setCurrentAgentToEdit] = useState(null)
    const [currentAgentSelectedToEditForQA, setCurrentAgentToEditForQA] = useState(null)
    const [currentAnalyticsAgentToEdit, setCurrentAnalyticsAgentToEdit] = useState(null)
    const [currentSelectedAgentToCreate, setCurrentSelectedAgentToCreate] = useState(null)

    const [currentSelectedAgentType, setCurrentSelectedAgentType] = useState(null)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [agentToDelete, setAgentToDelete] = useState(null);
    const [RAGAgentToDelete, setRAGAgentToDelete] = useState(null);


    useEffect(() => {
        if (!is_loading_list_of_agents) {
            loadListOfAgents({})
            fetchListOfQAChatbotAgents({})
            loadListOfAnalystAgents({})
        }
    }, [is_deleting_agent_config])

    useEffect(() => {
        loadListOfAgents({})
        fetchListOfQAChatbotAgents({})
        setIsAgentPlaygroundVisible(false)
    }, [])


    useEffect(() => {
        if (is_config_created) {
            setOpenSideBar(false)
            setOpenQASideBar(false)
            setOpenAnalyticsSideBar(false)
        }
    }, [is_config_created])

    useEffect(() => {
        if (is_agent_playground_visible) {
            setOpenQASideBar(false)
        }
    }, [is_agent_playground_visible])

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const [shuffledArray, setShuffledArray] = useState([])

    useEffect(() => {
        if (list_of_agents != null && list_of_qa_agents != null&& list_of_analyst_agent!=null) {
            const combinedArray = [...list_of_qa_agents, ...list_of_agents,...list_of_analyst_agent];
            setShuffledArray(combinedArray)

        }
    }, [list_of_agents, list_of_qa_agents,list_of_analyst_agent])

    const handleOpenSpecificBotConfigScreen = (selectedOption) => {
        switch (selectedOption.value) {
            case "chatbot":
                setCurrentAgentToEdit(null)
                setOpenSideBar(true)
                setOpenQASideBar(false)
                break;
            case "qa":
                setOpenQASideBar(true)
                setOpenSideBar(false)
                break;
            case "analyst":
                setOpenAnalyticsSideBar(true)
                setOpenSideBar(false)
                setOpenQASideBar(false)
                setCurrentAnalyticsAgentToEdit(null)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (currentSelectedAgentToCreate) {
            handleOpenSpecificBotConfigScreen(currentSelectedAgentToCreate)
        }
    }, [currentSelectedAgentToCreate])

    const handleTableActions = (option, agent) => {
        switch (option) {
            case 'Edit':
                if (agent?.library) {
                    setCurrentAgentToEdit(agent)
                    setOpenSideBar(true)
                    setOpenQASideBar(false)
                }
                else if (agent?.agent_type == 'Analytics') {
                    setCurrentAnalyticsAgentToEdit(agent)
                    setOpenAnalyticsSideBar(true)
                    setOpenQASideBar(false)
                    setOpenSideBar(false)
                }
                else {
                    setCurrentAgentToEditForQA(agent)
                    setOpenQASideBar(true)
                    setOpenSideBar(false)
                    setOpenAnalyticsSideBar(false)
                }
                break;
            case 'Delete':
                if (agent?.library) {
                    // deleteAgentConfig(agent?.id)
                    setConfirmDelete(true)
                    setRAGAgentToDelete(agent?.id)

                }
                else if (agent?.agent_type == 'Analytics') {
                    deleteAnalystAgentConfig(agent?.id)
                }
                else {
                    setConfirmDelete(true)
                    setAgentToDelete(agent?.id)
                }
                break;
                case 'Confirm Delete':
                    if (RAGAgentToDelete || agent?.library) {
                        deleteAgentConfig(RAGAgentToDelete);
                    } else if (agentToDelete) {
                        deleteAQAgentConfig(agentToDelete);
                    }
                    setConfirmDelete(false);
                    setAgentToDelete(null);
                    break;
            default:
                break;
        }
    }

    const handleGeneralDropdownForAgentType = (selected_agent_type_value) => {
        const agentType = selected_agent_type_value.label;
        setCurrentSelectedAgentType(agentType);
        switch (agentType) {
            case 'Create Chatbot':
                setOpenSideBar(true);
                setOpenQASideBar(false);
                setOpenAnalyticsSideBar(false);
                setCurrentSelectedAgentType(null)
                break;
            case 'Create QA Chatbot':
                setOpenSideBar(false);
                setOpenQASideBar(true);
                setOpenAnalyticsSideBar(false);
                setCurrentSelectedAgentType(null)
                break;
            case 'Create Analytics Agent':
                setOpenSideBar(false);
                setOpenQASideBar(false);
                setOpenAnalyticsSideBar(true);
                setCurrentSelectedAgentType(null)
                break;
            default:
                setOpenSideBar(false);
                setOpenQASideBar(false);
                setOpenAnalyticsSideBar(false);
                setCurrentSelectedAgentType(null)
                break;
        }
    };

    const closeSideBar = () => {
        setOpenSideBar(false)
        setOpenQASideBar(false)
        setOpenAnalyticsSideBar(false)
        setCurrentAgentToEdit(null)
        setCurrentAnalyticsAgentToEdit(null)
        setCurrentSelectedAgentType(null)
    }


    return is_agent_playground_visible ? <div className={styles.playground_container}>
        <Playground agent_config_to_play={currentAgentSelectedToEditForQA} />
    </div> : <div className={styles.container}>

        <div className={styles.list_of_agents_container}>
            <div className={styles.header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_size='20' font_weight='bold' text='Agents'></CustomHeading>
                    <CustomHeading color='tertiary' text='Manage the prompts and model that drive the Generative AI Agents'></CustomHeading>
                </div>
                <div className={styles.right_container} >
                    <div>
                        <General_dropdowns type={TYPE_7} current_theme={current_theme} handleOnClick={handleGeneralDropdownForAgentType}></General_dropdowns>
                    </div>
                </div>
            </div>
            <div className={styles.body_container}>
                <CustomTable defaultSortDirection='A-Z' defaultSortField='name' handleTableActions={handleTableActions} listOfColumns={listOfColumns} listOfRows={shuffledArray} />
                <DeleteConfirmationComponent
                    isVisible={confirmDelete}
                    onConfirm={() => handleTableActions('Confirm Delete')}
                    onCancel={() => { setConfirmDelete(false) }}
                    message='Are you sure you want to delete this agent?'
                />
            </div>
        </div>

        <StyledSideBarContainer className={openCreateSideBar ? styles.side_bar_opened : styles.side_bar_closed}>
            <div className={styles.main_header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_size='18' font_weight='bold' text='Create Chatbot agent'></CustomHeading>
                </div>
                <div onClick={closeSideBar} className={styles.right_container}>
                    <Ic_Close_Shaded />
                </div>
            </div>

            <ChatBotAgent agent_config_to_edit={currentAgentSelectedToEdit} setCurrentAgentToEdit={setCurrentAgentToEdit} setOpenSideBar={setOpenSideBar}></ChatBotAgent>

        </StyledSideBarContainer>
        <StyledQASideBarContainer className={openQASideBar ? styles.qa_side_bar_opened : styles.qa_side_bar_closed}>
            <div className={styles.main_header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_size='18' font_weight='bold' text='Create QA Chatbot agent'></CustomHeading>
                </div>
                <div onClick={closeSideBar} className={styles.right_container}>
                    <Ic_Close_Shaded />
                </div>
            </div>
            <QAChatBotAgent agent_config_to_edit={currentAgentSelectedToEditForQA} setCurrentAgentToEditForQA={setCurrentAgentToEditForQA} setOpenSideBar={setOpenQASideBar}></QAChatBotAgent>
        </StyledQASideBarContainer>

        <StyledAnalyticsSideBarContainer className={openAnalyticsSideBar ? styles.analytics_side_bar_opened : styles.analytics_side_bar_closed}>
            <div className={styles.main_header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_size='18' font_weight='bold' text='Create Analytics Agent'></CustomHeading>
                </div>
                <div onClick={closeSideBar} className={styles.right_container}>
                    <Ic_Close_Shaded />
                </div>
            </div>
            <AnalyticsAgent agent_config_to_edit={currentAnalyticsAgentToEdit} setCurrentAnalyticsAgentToEdit={setCurrentAnalyticsAgentToEdit} setOpenSideBar={setOpenAnalyticsSideBar}></AnalyticsAgent>
        </StyledAnalyticsSideBarContainer>
    </div>
}

// export default Agents
const mapState = (state) => ({
    list_of_agents: getListOfAgents(state),
    list_of_analyst_agent: getListOfAnalystAgents(state),
    list_of_qa_agents: getListOfQAAgents(state),
    is_loading_list_of_agents: getIsLoadingListOfAgents(state),
    is_deleting_agent_config: getIsDeletingAgentConfig(state),
    current_theme: getCurrentTheme(state),
    is_config_created: getIsConfigCreated(state),
    is_agent_playground_visible: getIsAgentPlaygroundVisible(state),
})
// 
const mapDispatchToProps = (dispatch) => ({
    loadListOfAgents: (data) => dispatch(fetchListOfChatbotAgents(data)),
    loadListOfAnalystAgents: (data) => dispatch(fetchListOfAnalystAgents(data)),
    deleteAgentConfig: (data) => dispatch(deleteAgentConfig(data)),
    setAgentConfigToTest: (data) => dispatch(setAgentConfigToTest(data)),
    fetchListOfQAChatbotAgents: (data) => dispatch(fetchListOfQAChatbotAgents(data)),
    deleteAQAgentConfig: (data) => dispatch(deleteAQAgentConfig(data)),
    fetchLLms: (data) => dispatch(fetchLLms(data)),
    fetchChunkingMethods: (data) => dispatch(fetchChunkingMethods(data)),
    setIsAgentPlaygroundVisible: (data) => dispatch(setIsAgentPlaygroundVisible(data)),
    setIsQAbotAdvancedSettingsOpen: (data) => dispatch(setIsQAbotAdvancedSettingsOpen(data)),
    deleteAnalystAgentConfig: (data) => dispatch(deleteAnalystAgentConfig(data)),
    updateAnalystAgentConfig: (data) => dispatch(updateAnalystAgentConfig(data))
});

export default connect(mapState, mapDispatchToProps)(Agents)