import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Folder_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.625 9.5L0.5 16.625V3.5C0.5 1.85938 1.8125 0.5 3.5 0.5H8.98438C9.78125 0.5 10.5312 0.828125 11.0938 1.39062L12.3594 2.65625C12.9219 3.21875 13.6719 3.5 14.4688 3.5H20C21.6406 3.5 23 4.85938 23 6.5V8H7.25C6.17188 8 5.1875 8.5625 4.625 9.5ZM5.9375 10.25C6.21875 9.78125 6.6875 9.5 7.25 9.5H26C26.5156 9.5 27.0312 9.82812 27.2656 10.2969C27.5469 10.7656 27.5469 11.3281 27.2656 11.7969L22.0156 20.7969C21.7344 21.2188 21.2656 21.5 20.75 21.5H2C1.4375 21.5 0.96875 21.2188 0.6875 20.75C0.40625 20.2812 0.40625 19.7188 0.6875 19.25L5.9375 10.25Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}/>
    </svg>
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Folder_Shaded)