import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Image_Attachment_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.8125 3.1875C16.4531 1.82812 14.25 1.82812 12.8906 3.1875L3.89062 12.1875C1.6875 14.3906 1.6875 17.9062 3.89062 20.1094C6.09375 22.3125 9.60938 22.3125 11.8125 20.1094L18.9375 12.9844C19.2188 12.7031 19.7344 12.7031 20.0156 12.9844C20.2969 13.2656 20.2969 13.7812 20.0156 14.0625L12.8906 21.1875C10.125 23.9531 5.57812 23.9531 2.8125 21.1875C0.046875 18.375 0.046875 13.875 2.8125 11.1094L11.8125 2.10938C13.7812 0.1875 16.9219 0.1875 18.8906 2.10938C20.8125 4.07812 20.8125 7.21875 18.8906 9.1875L10.2656 17.8125C8.95312 19.125 6.75 18.9844 5.57812 17.5312C4.59375 16.2656 4.6875 14.4844 5.8125 13.3594L12.9375 6.23438C13.2188 5.95312 13.7344 5.95312 14.0156 6.23438C14.2969 6.51562 14.2969 7.03125 14.0156 7.3125L6.89062 14.4375C6.28125 15 6.23438 15.9375 6.75 16.5938C7.35938 17.3438 8.53125 17.4375 9.1875 16.7344L17.8125 8.10938C19.1719 6.75 19.1719 4.54688 17.8125 3.1875Z" fill="#FF5A00"/>
    </svg>
    
    
    
    
    
} 

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Image_Attachment_Shaded)