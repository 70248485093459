const TYPE_1 = "single_selection_without_radio_buttons"
const TYPE_2 = "single_selection_with_radio_buttons"
const TYPE_3 = "multi-selected"
const TYPE_4 = "single_selected_actions_list_without_close_icon"
const TYPE_5 = "single_selected_actions_list_with_close_icon"
const TYPE_6 = "single_selected_sorting_options_list"
const TYPE_7 = "single_selected_with_default"

export {
    TYPE_1,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7
}


