import { DashboardAPI } from "../../../api";
import { showToastMessage } from "../../../app/logic/actions";
import {
    setAnalytics, setIsLoadingAnalytics, setNumberOfUsers, setIsLoadingNumberOfUsers, setErrorNumberOfUsers,
    setNumberOfDocuments, setIsLoadingNumberOfDocuments, setErrorNumberOfDocuments,
    setNumberOfConfigurations, setIsLoadingNumberOfConfigurations, setErrorNumberOfConfigurations,
    setNumberOfConversationsAndMessages, setIsLoadingNumberOfConversationsAndMessages, setErrorNumberOfConversationsAndMessages,
} from "./slice";

// Existing fetchAnalytics function...

const fetchNumberOfUsers = () => (dispatch) => {
    dispatch(setIsLoadingNumberOfUsers(true));
    DashboardAPI.getNumberOfUsers().then((response) => {
        dispatch(setNumberOfUsers(response.data));
        dispatch(setIsLoadingNumberOfUsers(false));
        // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched number of users"}));
    }).catch((error) => {
        console.error(error);
        dispatch(setErrorNumberOfUsers(error.message || 'Failed to fetch number of users'));
        dispatch(setIsLoadingNumberOfUsers(false));
    });
};

const fetchNumberOfDocuments = () => (dispatch) => {
    dispatch(setIsLoadingNumberOfDocuments(true));
    DashboardAPI.getNumberOfDocuments().then((response) => {
        
        dispatch(setNumberOfDocuments(response.data));
        dispatch(setIsLoadingNumberOfDocuments(false));
        // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched number of documents"}));
    }).catch((error) => {
        console.error(error);
        dispatch(setErrorNumberOfDocuments(error.message || 'Failed to fetch number of documents'));
        dispatch(setIsLoadingNumberOfDocuments(false));
    });
};

const fetchNumberOfConfigurations = () => (dispatch) => {
    dispatch(setIsLoadingNumberOfConfigurations(true));
    DashboardAPI.getNumberOfConfigurations().then((response) => {
        dispatch(setNumberOfConfigurations(response.data));
        dispatch(setIsLoadingNumberOfConfigurations(false));
        // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched number of configurations"}));
    }).catch((error) => {
        console.error(error);
        dispatch(setErrorNumberOfConfigurations(error.message || 'Failed to fetch number of configurations'));
        dispatch(setIsLoadingNumberOfConfigurations(false));
    });
};

const fetchNumberOfConversationsAndMessages = () => (dispatch) => {
    dispatch(setIsLoadingNumberOfConversationsAndMessages(true));
    DashboardAPI.getNumberOfConversationsAndMessages().then((response) => {
        dispatch(setNumberOfConversationsAndMessages(response.data));
        dispatch(setIsLoadingNumberOfConversationsAndMessages(false));
        // dispatch(showToastMessage({ type: 'success', message: "Successfully fetched number of conversations and messages"}));
    }).catch((error) => {
        console.error(error);
        dispatch(setErrorNumberOfConversationsAndMessages(error.message || 'Failed to fetch number of conversations and messages'));
        dispatch(setIsLoadingNumberOfConversationsAndMessages(false));
    });
};

export {
  fetchNumberOfUsers, fetchNumberOfDocuments,
    fetchNumberOfConfigurations, fetchNumberOfConversationsAndMessages,
};
