// lists
export const getListOfAgents = (state) => state.agents?.list_of_agents
export const getListOfQAAgents = (state) => state.agents?.list_of_qa_agents
export const getListOfAnalystAgents = (state) => state.agents?.list_of_analyst_agents

// 
// objects
export const getAgentConfigToEdit = (state) => state.agents?.agent_config_to_edit
export const getAgentConfigToTest = (state) => state.agents?.agent_config_to_test

// is loading
export const getIsLoadingListOfAgents = (state) => state.agents?.is_loading_list_of_agents
export const getIsCreatingAgentConfig = (state) => state.agents?.is_creating_agent_config
export const getIsUpdatingAgentFlags = (state) => state.agents?.is_updating_agent_flags
export const getIsDeletingAgentConfig = (state) => state.agents?.is_deleting_agent_config
export const getIsConfigCreated = (state) => state.agents?.is_config_created
export const getIsLoadingAgentDetails = (state) => state.agents?.is_loading_agent_details
export const getIsAgentPlaygroundVisible = (state) => state.agents?.is_agent_playground_visible
