import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Dropdown_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="28" height="28" rx="14" fill="#EC950A" fill-opacity="0.1"/>
    <path d="M21.1875 13.1328L14.4727 19.5664C14.2617 19.7422 14.0508 19.8125 13.875 19.8125C13.6641 19.8125 13.4531 19.7422 13.2773 19.6016L6.52734 13.1328C6.17578 12.8164 6.17578 12.2539 6.49219 11.9375C6.80859 11.5859 7.37109 11.5859 7.6875 11.9023L13.875 17.8086L20.0273 11.9023C20.3438 11.5859 20.9062 11.5859 21.2227 11.9375C21.5391 12.2539 21.5391 12.8164 21.1875 13.1328Z" fill="#EC950A"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Dropdown_Shaded)