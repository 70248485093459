import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Logout_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.5 3.5H4.5C3.65625 3.5 3 4.20312 3 5V17C3 17.8438 3.65625 18.5 4.5 18.5H7.5C8.29688 18.5 9 19.2031 9 20C9 20.8438 8.29688 21.5 7.5 21.5H4.5C2.01562 21.5 0 19.4844 0 17V5C0 2.51562 2.01562 0.5 4.5 0.5H7.5C8.29688 0.5 9 1.20312 9 2C9 2.84375 8.29688 3.5 7.5 3.5ZM23.625 11.8438L16.875 18.2188C16.5469 18.5 16.0781 18.5938 15.6562 18.4062C15.2344 18.2656 15 17.8438 15 17.375V14H9C8.15625 14 7.5 13.3438 7.5 12.5V9.5C7.5 8.70312 8.15625 8 9 8H15V4.625C15 4.20312 15.2344 3.78125 15.6562 3.59375C16.0781 3.45312 16.5469 3.5 16.875 3.82812L23.625 10.2031C23.8594 10.4375 24 10.7188 24 11C24 11.3281 23.8594 11.6094 23.625 11.8438Z" fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"} />
    </svg>


}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Logout_Shaded)