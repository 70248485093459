import React, {useState} from 'react'
import { showToastMessage } from '../../../../app/logic/actions'
import { addUser } from '../../../logic/profile/actions';
import { connect } from "react-redux";
import { CustomHeading } from '../../../../../shared/ui/headings';
import { CustomButton } from '../../../../../shared/ui/buttons'
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import { CustomLabel } from '../../../../../shared/ui/label'
import CustomTextField from '../../../../../shared/ui/text_field';
import { StyledSideBarContainer } from './styled';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import styles from './styles.module.scss'
import CustomModelDropDown from '../../../../../shared/ui/models_dropdowns'
import { StyledFormControlLabel } from './styled';

const SidebarToAddUser = ({
    setOpenCreateUserSideBar,
    addUser,
    showToastMessage,
    ...props}
    ) => {
    
    const [newUserName, setNewUserName] = useState('')
    const [newUserLastName, setNewUserLastName] = useState('')
    const [isThereNeedToSendEmail, setIsThereNeedToSendEmail] = useState(true)
    const [invite_user_password, setInviteUserPassword] = useState('')
    const [email, setEmail] = useState("")
    const [isStaff, setIsStaff] = useState(true)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [role, setRole] = useState("")
    const [user_password, setNewPassword] = useState("")
    const [roleType, setRoleType] = useState('test_user');

    const handleAddUser = () => {
        const fieldValidations = [
            { field: newUserName, message: "Cannot leave first name field empty" },
            { field: newUserLastName, message: "Cannot leave last name field empty" },
            { field: email, message: "Cannot leave email field empty" },
            { field: invite_user_password, message: "Cannot leave password field empty" }
        ];
        for (let validation of fieldValidations) {
            if (validation.field.length === 0) {
                showToastMessage({ type: 'error', message: validation.message });
                return;
            }
        }
        addUser({
            email: email,
            first_name: newUserName,
            last_name: newUserLastName,
            password: user_password,
            bypass_email_domain_check: isThereNeedToSendEmail,
            is_staff: isStaff,
            is_superuser: isSuperAdmin,
            role: role
        });
    }

    const handleUserAdmin = (value) => {
        switch (value) {
            case 'test_user':
                setIsStaff(true);
                setIsSuperAdmin(false);
                break;
            case 'super_user':
                setIsSuperAdmin(true);
                setIsStaff(true);
                break;
            case 'agent_user':
                setIsSuperAdmin(false);
                setIsStaff(false);
                break;
            default:
                break;
        }
        setRole(value);
    };

    const handleTypeRoleChange = (event) => {
        setRoleType('test_user')
        setRoleType(event.target.value);
    };   
    
      return (
        <StyledSideBarContainer className={styles.side_bar_opened}>
            <CustomHeading font_weight='bold' font_size='20' text='Add a new user'></CustomHeading>
            <div>
                <FormControl>
                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    row
                    value={roleType}
                    onChange={handleTypeRoleChange}
                    >
                    <StyledFormControlLabel value="test_user" control={<Radio/>} label="Staff" onClick={() => handleUserAdmin('test_user')}></StyledFormControlLabel>
                    <StyledFormControlLabel value="super_user" control={<Radio/>} label="Super User" onClick={() => handleUserAdmin('super_user')} ></StyledFormControlLabel>
                    <StyledFormControlLabel value="agent_user" control={<Radio/>} label="Agent User" onClick={() => handleUserAdmin('agent_user')} ></StyledFormControlLabel>

                    </RadioGroup>   
                </FormControl>
                </div>
                    <CustomLabel title="First Name"></CustomLabel>
                    <CustomTextField placeholder='Enter first name' value={newUserName} onChange={(e) => { setNewUserName(e.target.value) }} ></CustomTextField>
                    <CustomLabel title="Last Name"></CustomLabel>
                    <CustomTextField placeholder='Enter last name' value={newUserLastName} name="new-username" onChange={(e) => { setNewUserLastName(e.target.value) }} ></CustomTextField>
                    <CustomLabel title="Email">Email</CustomLabel>
                    <CustomTextField placeholder='Enter email' value={email} onChange={(e) => { setEmail(e.target.value) }} ></CustomTextField>
                <div style={{position:'relative',width:'100%'}}>
                    <CustomLabel title="Password">Password</CustomLabel>
                    <CustomTextField placeholder='Enter password' value={invite_user_password} onChange={(e) => { setInviteUserPassword(e.target.value) }} className={styles.input_field}/>
                </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <CustomButton currentButtonTpe={TYPE_3_button} title='Invite' onClick={handleAddUser}></CustomButton>
                <CustomButton currentButtonTpe={TYPE_3_button} title='Cancel' onClick={() => setOpenCreateUserSideBar(false)}></CustomButton>
            </div>       
        </StyledSideBarContainer> 
  )
}

const mapDispatchToProps = (dispatch) => ({
    addUser: (data) => dispatch(addUser(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
});

export default connect(null, mapDispatchToProps)(SidebarToAddUser)