import { useEffect, useRef, useState } from 'react';
import { getCurrentConversationId, getCurrentCRoomName, getCurrentSelectedChatbot, getCurrentServerResponse, getCurrentUserMessage, getHasChatAPIFailed, getHasSSESentResponseSinceLastMessage, getListOfMessages, getNewMessageResponseFromChatAPI } from '../../logic/selectors';
import styles from './styles.module.scss';
import { connect } from "react-redux";
import { MessageBubble } from '../message_bubble';
import { addNewMessage, setCurrentServerResponse, setCurrentUserMessage, setHasSSESentResponseSinceLastMessage, setListOfMessages } from '../../logic/slice';
import { RAG_BASE_URL } from '../../../../apis/axiosInstance';
import { sendThumbsUp, sendThumbsDown } from '../../logic/actions';
import { showToastMessage } from '../../../../../modules/app/logic/actions';

const getResponsiveFontSizes = (baseFontSize, unit) => {
    const fontSizeNum = parseFloat(baseFontSize);
    return {
        mobileFontSize: `${fontSizeNum * 0.8}${unit}`, // 80% of the base size
        tabletFontSize: `${fontSizeNum * 0.9}${unit}`, // 90% of the base size
        laptopFontSize: `${fontSizeNum * 1.1}${unit}`, // 110% of the base size
        macbook13FontSize: `${fontSizeNum * 1.05}${unit}`, // 105% of the base size
    };
};

const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
const generateRandomId = () => {
    return Math.random().toString(36).substring(7); // Generates a random alphanumeric ID
}

const MessagesList = ({
    // Selectors
    list_of_messages,
    current_conversation_id,
    current_room_name,
    current_selected_chatbot,
    current_user_message,
    current_server_response,
    has_sse_sent_response_since_last_message,
    new_message_response_from_chat_api,
    has_chat_api_failed,
    // Reducers
    setCurrentUserMessage,
    addNewMessage,
    setListOfMessages,
    setCurrentServerResponse,
    sendThumbsUp,
    sendThumbsDown,
    showToastMessage,
    setHasSSESentResponseSinceLastMessage,
    ...props
}) => {

    const [listOfMessagesToRender, setListOfMessagesToRender] = useState([])
    const [currentBotMessageId, setCurrentBotMessageId] = useState(null);
    const messageContainerRef = useRef(null);


    useEffect(() => {
        if (list_of_messages?.length > 0) {
            setTimeout(() => {
                setListOfMessagesToRender(list_of_messages)
            }, 100);
        }
    }, [list_of_messages])

    useEffect(() => {
        
        if (current_user_message != null) {
            let msg_id = generateRandomId()
            setCurrentBotMessageId(msg_id)
            addNewMessage({
                id: msg_id,
                message: '-',
                message_sender: 'bot',
                message_created_at: new Date().toLocaleString('en-US', options),
                message_sender_user_name: current_selected_chatbot?.name,
                message_type: 'text',
                is_loading: true
            })

            setCurrentUserMessage(null)

        }else{
            console.log("current_user_message",current_user_message)
        }
        scrollToBottom();
    }, [current_user_message])

    useEffect(() => {
        if (current_server_response && currentBotMessageId &&  !has_chat_api_failed) {
            handleMessageLoading(currentBotMessageId, JSON.parse(current_server_response))
        }
    }, [current_server_response])


    useEffect(() => {
        if (has_chat_api_failed) {
            handleSetMessageWhichAreLoadingAsError(`Oops! Something didn't go as planned. Please try again later`)
        }
    }, [has_chat_api_failed])

    useEffect(() => {
        if (current_conversation_id != null && current_room_name != null) {
            const eventSource = new EventSource(`${RAG_BASE_URL}/events/${current_room_name}/`);
            eventSource.onmessage = (event) => {
                setHasSSESentResponseSinceLastMessage(true)
                setCurrentServerResponse(event.data)
            };

            eventSource.onerror = (error) => {
                console.log(error)
            };

            return () => {
                eventSource.close();
            };
        }
    }, [current_conversation_id, current_room_name])

    useEffect(() => {
        if (current_selected_chatbot?.name) {
            setListOfMessagesToRender([])
        }
    }, [current_selected_chatbot])


    const scrollToBottom = () => {
        const scroll = messageContainerRef?.current?.scrollHeight - messageContainerRef?.current?.clientHeight;
        messageContainerRef?.current?.scrollTo(0, scroll);
    };

    const handleMessageLoading = (messageId, content = 'default_content') => {
        if (content.content !== "") {

        let newListOfMessages = list_of_messages.map((message) => {
            if (message?.id === messageId || message?.id === content?.message_id) {
                let newMessage = { ...message };

                if (newMessage.message === null || typeof newMessage.message === 'object') {
                    newMessage.message = "";
                }

                newMessage.is_loading = false;
                newMessage.message += content.content;
                newMessage.id = content.message_id;
                return newMessage;
            } else {
                return message;
            }
        });

        setListOfMessages(newListOfMessages);

        scrollToBottom();

        }
    };

    const handleSetMessageWhichAreLoadingAsError = (error_message) => {
        let newListOfMessages = list_of_messages.map((message) => {
            if (message.is_loading) {
                let newMessage = { ...message };
                newMessage.is_loading = false;
                newMessage.message = error_message;
                newMessage.has_failed = true;
                return newMessage;
            } else {
                return message;
            }
        });

        setListOfMessages(newListOfMessages);

        scrollToBottom();


    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            showToastMessage({ type: 'success', message: 'Text copied to clipboard' })
        } catch (error) {
            // setError(error)
        }
    };

    const handleReactions = async (messageId, action) => {
        const jsonPayload = {
            message_id: messageId,
            is_liked: action === 'like'
        };
        try {
            if (action === 'like') {
                await sendThumbsUp(jsonPayload);
            } else if (action === 'dislike') {
                await sendThumbsDown(jsonPayload);
            }
        } catch (error) {
            // setError(error)
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.list_of_messages_container} ref={messageContainerRef}>
                {listOfMessagesToRender.map((message, index) => (
                    <div key={index} className={message.message_sender === 'user' ? styles.user_message : styles.bot_message}>
                        <MessageBubble {...message} handleReactions={handleReactions} copyToClipboard={copyToClipboard} />
                    </div>
                ))}
            </div>
        </div>)
};


const mapState = (state) => ({
    current_conversation_id: getCurrentConversationId(state),
    current_room_name: getCurrentCRoomName(state),
    current_selected_chatbot: getCurrentSelectedChatbot(state),
    list_of_messages: getListOfMessages(state),
    current_user_message: getCurrentUserMessage(state),
    current_server_response: getCurrentServerResponse(state),
    has_sse_sent_response_since_last_message: getHasSSESentResponseSinceLastMessage(state),
    new_message_response_from_chat_api: getNewMessageResponseFromChatAPI(state),
    has_chat_api_failed: getHasChatAPIFailed(state),
})

const mapDispatchToProps = (dispatch) => ({
    setCurrentUserMessage: (data) => dispatch(setCurrentUserMessage(data)),
    addNewMessage: (data) => dispatch(addNewMessage(data)),
    setListOfMessages: (data) => dispatch(setListOfMessages(data)),
    setCurrentServerResponse: (data) => dispatch(setCurrentServerResponse(data)),
    sendThumbsUp: (data) => dispatch(sendThumbsUp(data)),
    sendThumbsDown: (data) => dispatch(sendThumbsDown(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
    setHasSSESentResponseSinceLastMessage: (data) => dispatch(setHasSSESentResponseSinceLastMessage(data)),
});

export default connect(mapState, mapDispatchToProps)(MessagesList)