import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Mic_Attachment_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="38" height="44" viewBox="0 0 38 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.75 19C26.125 19 26.5 19.375 26.5 19.75V22C26.5 25.8906 23.5 29.0781 19.75 29.4531V32.5H22.75C23.125 32.5 23.5 32.875 23.5 33.25C23.5 33.6719 23.125 34 22.75 34H15.25C14.8281 34 14.5 33.6719 14.5 33.25C14.5 32.875 14.8281 32.5 15.25 32.5H18.25V29.4531C14.3594 29.0781 11.5 25.6562 11.5 21.7656V19.75C11.5 19.375 11.8281 19 12.25 19C12.625 19 13 19.375 13 19.75V21.8125C13 25 15.3906 27.8125 18.5781 28C22.0469 28.2344 25 25.4688 25 22V19.75C25 19.375 25.3281 19 25.75 19ZM19 26.5C16.5156 26.5 14.5 24.4844 14.5 22V14.5C14.5 12.0156 16.5156 10 19 10C21.4844 10 23.5 12.0625 23.5 14.5V22C23.5 24.4844 21.4844 26.5 19 26.5ZM16 14.5V22C16 23.6875 17.3125 25 19 25C20.6406 25 22 23.6875 22 22V14.5C22 12.8594 20.6406 11.5 19 11.5C17.3125 11.5 16 12.8594 16 14.5Z" fill="#FF5A00"/>
    </svg>
    



}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
}) 

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Mic_Attachment_Shaded)