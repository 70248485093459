import React from 'react';
import styles from './styles.module.scss';
import { OverlayContainer, CenteredBox, DeleteButtonContainer } from './styled';
import { CustomButton } from '../buttons';
import { TYPE_1 as TYPE_1_button } from '../buttons/button_types';

const deleteConfirmation = ({ 
  isVisible, 
  onConfirm, 
  onCancel, 
  message, 
  documents, 
  type = 'delete',
}) => {
  if (!isVisible) return null;

  const renderMessage = () => {
    if (type === 'delete') {
      return message || 'Are you sure you want to delete this item?';
    } else if (type === 'warning') {
      return message || 'You have unsaved changes. Are you sure you want to leave?';
    }
  };

  const renderButtons = () => {
    if (type === 'delete') {
      return (
        <>
          <CustomButton currentButtonTpe={TYPE_1_button} title='Delete' font_size='12' onClick={onConfirm} />
          <CustomButton currentButtonTpe={TYPE_1_button} background_color='primary' title='Cancel' font_size='12' onClick={onCancel} />
        </>
      );
    } else if (type === 'warning') {
      return (
        <>
          <CustomButton currentButtonTpe={TYPE_1_button} title='Continue' font_size='12' onClick={onCancel} />
          <CustomButton currentButtonTpe={TYPE_1_button} background_color='primary' title='Discard' font_size='12' onClick={onConfirm} />
        </>
      );
    }
  };

  return (
    <OverlayContainer>
      <CenteredBox>
        {renderMessage()}
        {documents && documents.length > 0 && type === 'delete' && (
          <div>
            <p>The following documents will be deleted:</p>
            <ul className={styles.styled_list}>
              {documents.map((doc, index) => (
                <li key={index}>{doc}</li>
              ))}
            </ul>
          </div>
        )}
        <DeleteButtonContainer>
          {renderButtons()}
        </DeleteButtonContainer>
      </CenteredBox>
    </OverlayContainer>
  );
};

export default deleteConfirmation;
