import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Left_Arrow_Rounded_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="14" fill="#EC950A" fill-opacity="0.1"/>
    <path d="M11.9727 6.9375L18.4062 13.6523C18.5469 13.8281 18.6523 14.0391 18.6523 14.25C18.6523 14.4609 18.5469 14.6719 18.4062 14.8125L11.9727 21.5273C11.6562 21.8789 11.0938 21.8789 10.7773 21.5625C10.4258 21.2461 10.4258 20.7188 10.7422 20.3672L16.6484 14.2148L10.7422 8.09766C10.4258 7.78125 10.4258 7.21875 10.7773 6.90234C11.0938 6.58594 11.6562 6.58594 11.9727 6.9375Z" fill="#EC950A"/>
    </svg>
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Left_Arrow_Rounded_Shaded)