import {  Pagination } from '@mui/material';
import styled from 'styled-components';

// Breakpoints for responsive design
const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  },
};

const StyledPagination = styled(Pagination)`
  && {
    .MuiPaginationItem-root {
      border-radius: ${({ borderRadius }) => borderRadius || '0'};
      &:not(.Mui-selected) {
        color: ${({ textColor, theme }) => theme.palette.textColors[textColor] || theme.palette.textColors.primary};
      }
    }

    .Mui-selected {
      color: ${({ selectedTextColor, theme }) => theme.palette.textColors[selectedTextColor] || theme.palette.textColors.secondary};
      border: 1px solid ${({ borderColor, theme }) => theme.palette.borderColors[borderColor] || theme.palette.borderColors.primary};
      &:hover {
        background-color: ${({ hoverBackgroundColor, theme }) => theme.palette.buttonBackgrounds[hoverBackgroundColor] || theme.palette.buttonBackgrounds.primary};
      }
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
      && {
        .MuiPaginationItem-root {
          font-size: ${({ mobileFontSize }) => mobileFontSize || '12px'};
        }
      }
    }

    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      && {
        .MuiPaginationItem-root {
          font-size: ${({ tabletFontSize }) => tabletFontSize || '14px'};
        }
      }
    }

    @media only screen and (min-width: ${breakpoints.laptop}) {
      && {
        .MuiPaginationItem-root {
          font-size: ${({ laptopFontSize }) => laptopFontSize || '16px'};
        }
      }
    }

    @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
           (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
           (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
           (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
      && {
        .MuiPaginationItem-root {
          font-size: ${({ macbook13FontSize }) => macbook13FontSize || '18px'};
        }
      }
    }
  }
`;

export { StyledPagination };
