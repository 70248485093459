import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
const StyledMainContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
`;

const StyledBotContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  height:90%;
  border-radius:20px;
  padding:1%;
  display:flex;
  flex-direction:row;
`;

const StyledBotMsgContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.darkest};
  max-width: 70%;
  min-width: fit-content;
  border-radius:25px;
  padding:1%;
  display:flex;
  flex-direction:column;
  border-radius: 10px;
  height: fit-content;
`;


const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    // text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;

    font-size: 15px;
  }
`;

const StyledHeading2 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 45px;
  }
`;

const StyledHeading3 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 20px;
  }
`;


const StyledHeading4 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 30px;
  }
`;

const StyledHeading5 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 15px;
  }
`;
const StyledHeading6 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 12px;
  }
`;

const StyledHeading7 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.primary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.quaternary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 12px;
  }
`;

const StyledHeading8 = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.quaternary};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.quaternary};
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 16px;
    
    font-size: 12px;
  }
`;
const StyledTabBar = styled.div`
  width: 100%;
  height: 3px; /* Adjust the height as needed */
  background-color: ${({ color, theme }) => theme.palette.dividerColor[color] || theme.palette.dividerColor.tertiary};
  border-radius: 5px;
`;

const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    color: ${({ color, bg_color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
    &:hover {
      background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;

    border: 1px solid  ${({ color, bg_color, theme }) => theme.palette.borderColors[bg_color] || theme.palette.borderColors.tertiary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    // margin-left: 10px;
    
    width: auto; /* Adjust width to content */
    height: auto; /* Adjust height to content */
  }
`;

const MyStyledButton_type2 = styled(Button)`
  && {
    background-color: ${({ color, bg_color, theme }) => theme.palette.backgroundColors[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    color: ${({ color, bg_color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
    &:hover {
      background-color: ${({ color, bg_color, theme }) => theme.palette.backgroundColors[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;

    border: 1px solid  ${({ color, bg_color, theme }) => theme.palette.borderColors[bg_color] || theme.palette.borderColors.darkest};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    // margin-left: 10px;
    
    width: auto; /* Adjust width to content */
    height: auto; /* Adjust height to content */
  }
`;
const MyStyledButton_type3 = styled(Button)`
  && {
    background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    color: ${({ color, bg_color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.default};
    &:hover {
      background-color: ${({ color, bg_color, theme }) => theme.palette.buttonBackgrounds[bg_color] || theme.palette.buttonBackgrounds.tertiary};
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 10px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;

    border: 1px solid  ${({ color, bg_color, theme }) => theme.palette.borderColors[bg_color] || theme.palette.borderColors.tertiary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    // margin-left: 10px;
    
    width: fit-content; /* Adjust width to content */
   
  }
`;
const StyledTextField = styled(TextField)`
  && {
    display: flex;
    width: 90%;
    
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.tertiary};

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    input {
      margin-left:4%;
      height: 5px;
      font-size: 12px;
      font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;

export {
    StyledMainContainer,
    StyledBotContainer,
    StyledBotMsgContainer,
    StyledHeading1,
    StyledHeading2,
    StyledHeading3,
    StyledHeading4,
    StyledHeading5,
    StyledHeading6,
    StyledHeading7,
    StyledHeading8,
    StyledTabBar,
    MyStyledButton_type1,
    MyStyledButton_type2,
    MyStyledButton_type3,
    StyledTextField
}