import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Arrow_Right_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.shaded ? current_theme?.palette?.icons_colors?.shaded : "#FF5A00"}
    return <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.35547 7.39453C8.78711 7.79297 8.78711 8.49023 8.35547 8.88867L1.98047 15.2637C1.58203 15.6953 0.884766 15.6953 0.486328 15.2637C0.0546875 14.8652 0.0546875 14.168 0.486328 13.7695L6.09766 8.125L0.486328 2.51367C0.0546875 2.11523 0.0546875 1.41797 0.486328 1.01953C0.884766 0.587891 1.58203 0.587891 1.98047 1.01953L8.35547 7.39453Z" fill="#AEAEB2"/>
    </svg>
    
    
    
    
    
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Arrow_Right_Shaded)