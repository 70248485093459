import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const StyledTagItem = styled.span`
min-width:fit-content;
gap:10px;
padding:10px;
color:${({ theme }) => theme.palette.textColors.primary};
border-radius:8px;

background-color: ${({ theme }) => theme.palette.backgroundColors.tag_bg_color};
`

const StyledDivider = styled.div`
  width: 100%;
  height: 1px; /* Adjust the height as needed */
  background-color: ${({ theme }) => theme.palette.dividerColor.primary}; /* You can use your theme's color */
  margin: 8px 0; /* Adjust the margin as needed */
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary} !important;
  `;

const StyledhighlightedRow = styled.div`
  && {
  background-color: ${({ backgroundColor }) =>
    backgroundColor
      ? 'rgba(255, 90, 0, 0.3)'
      : 'rgba(255, 90, 0, 0.3)'};
  border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.textColors.secondary}
  }
  `;


const StyledTagTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.textColors.chip_text_color};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family:  ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    font-size: 12px;
  }
`;

const StyledTextarea = styled(TextareaAutosize)`
  && {
    display: block;
    width: 95%;
    min-height: 100px; /* Adjust the initial height as needed */
    padding: 8px;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    color:  ${({ theme }) => theme.palette.textColors.primary}; 
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};
  
    &::placeholder {
      color: ${({ theme }) => theme.palette.textColors.darkest};
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }
  }
`;

export {
  StyledDivider,
  StyledTextarea,
  StyledTagItem,
  StyledSideBarContainer,
  StyledTagTitle,
  StyledhighlightedRow,
}