import { Typography } from '@mui/material';
import styled from 'styled-components';

const breakpoints = {
  mobile: '600px',
  tablet: '1023px',
  laptop: '1024px',
  macbook13: {
    minDeviceWidth: '1440px',
    maxDeviceWidth: '2800px',
    minDeviceHeight: '900px',
    maxDeviceHeight: '1800px',
  }
};

const StyledLabel = styled(Typography)`
  && {
    color: ${({ color, theme }) => theme.palette.textColors[color] || theme.palette.textColors.label_color};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ fontFamily, theme }) => theme.palette.fontFamilies[fontFamily] || theme.palette.fontFamilies.primary};
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight || 300};
    line-height: normal;
    font-size: ${({ fontSize }) => fontSize || '14px'};
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    && {
      font-size: ${({ mobileFontSize }) => mobileFontSize || '12px'};
    }
  }

  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    && {
      font-size: ${({ tabletFontSize }) => tabletFontSize || '13px'};
    }
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    && {
      font-size: ${({ laptopFontSize }) => laptopFontSize || '14px'};
    }
  }

  @media (min-device-width: ${breakpoints.macbook13.minDeviceWidth}) and 
         (max-device-width: ${breakpoints.macbook13.maxDeviceWidth}) and 
         (min-device-height: ${breakpoints.macbook13.minDeviceHeight}) and 
         (max-device-height: ${breakpoints.macbook13.maxDeviceHeight}) {
    && {
      font-size: ${({ macbook13FontSize }) => macbook13FontSize || '15px'};
    }
  }
`;

export { StyledLabel };
