import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

const StyledMainContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.primary};
`;

const StyledSideBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;

const StyledNavBarContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.secondary};
`;


const StyledSwitch = styled(Switch)`
  && {

    &.MuiSwitch-root {
      color: ${({ theme }) => theme.palette.backgroundColors.default};
    }

    & .MuiSwitch-switchBase.Mui-checked {
      color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      &:hover {
        
        background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
      }
    }

    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      
      background-color: ${({ theme }) => theme.palette.backgroundColors.checked_switch_color};
    }

    & .MuiSwitch-track.Mui-disabled {
      
      background-color: ${({ theme }) => theme.palette.backgroundColors.light}; /* Change the color when the switch is disabled and checked */
    }
  }
`;

const StyledSearchTextField = styled(TextField)`
  && {
    display: flex;
    width:100%;
    
    input {
      height: 10px;
      color:  ${({ theme }) => theme.palette.textColors.primary}; 
    }
    /* Text md/Regular */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 8px;
    border: 1px solid  ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
  

    & label.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    & .MuiOutlinedInput-root {      
      &.Mui-focused fieldset {
        border-color: ${({ theme }) => theme.palette.borderColors.primary};
      }
    }
  }
`;

const StyledHeading1 = styled(Typography)`
  && {
    color: ${({ color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary};
  
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
    font-style: bold;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
    font-size: 1rem;
    
  }
`;


const MyStyledButton_type1 = styled(Button)`
  && {
    background-color: ${({backgroundColor, theme }) => backgroundColor ? theme.palette.buttonBackgrounds[backgroundColor] : theme.palette.buttonBackgrounds.primary};
    color: ${({color, theme }) => color ? theme.palette.textColors[color] : theme.palette.textColors.primary}; /* Set the text color to white */
    &:hover {
      background-color: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};
      color: ${({ theme }) => theme.palette.textColors.primary}
    }

    /* Additional styles */
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 13px;
    font-style: normal;
    font-weight: 900;
    
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    text-transform: capitalize;
    margin-top:10px;
    margin-left:5px

  }

  }
`;




export {
    StyledMainContainer,
    StyledSideBarContainer,
    StyledNavBarContainer,
    StyledSearchTextField,
    StyledHeading1,
    StyledSwitch,
    MyStyledButton_type1
}