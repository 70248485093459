import { StyledSideBarContainer, StyledFormControlLabel } from './styled'
import styles from './styles.module.scss'
import { useEffect, useState, useMemo } from 'react'
import { connect } from "react-redux";
import { showToastMessage } from '../../../../app/logic/actions'
import { getCurrentTheme } from '../../../../app/logic/selectors'
import { deleteUser, fetchListOfUsers, inviteUser, updateUserData, updateUserPassword } from '../../../logic/users/actions'
import { getIsInvitingUser, getIsLoadingListOfUsers, getIsNewUserAdded, getIsUpdatingUser, getListOfUsers } from '../../../logic/users/selectors'
import add_btn from '../../../../../assets/images/icons8-add-48.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { CustomHeading } from '../../../../../shared/ui/headings'
import { CustomLabel } from '../../../../../shared/ui/label'
import { CustomButton } from '../../../../../shared/ui/buttons'
import CustomTextField from '../../../../../shared/ui/text_field';
import { TYPE_3 as TYPE_3_button, TYPE_1 as TYPE_1_button } from '../../../../../shared/ui/buttons/button_types'
import { CustomTable } from '../../../../../shared/ui/table'
import { listOfColumns } from './table_columns'
import DeleteConfirmationComponent from '../../../../../shared/ui/overlay_container/deleteConfirmation';

const ITEM_HEIGHT = 48;
const Users = ({
    current_theme,
    is_loading_list_of_users,
    list_of_users,
    get_is_inviting_user,
    updateUserData,
    addUser, loadListOfUsers,
    is_updating_user,
    deleteUser,
    is_new_user_added,
    updateUserPassword,
    showToastMessage,
    ...props }) => {
    const [tagsList, setTagsList] = useState([{ key: 0, label: 'Create new tag' }]);
    const [isUpdatingPassword, setIsEditingPassword] = useState(false)
    const [currentSelectedUser, setCurrentSelectedUser] = useState(null)
    const [isEditingUser, setIsEditingUser] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [user_password, setUserPassword] = useState("")
    const [role, setRole] = useState("")
    const [isStaff, setIsStaff] = useState(true)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [isThereNeedToSendEmail, setIsThereNeedToSendEmail] = useState(true)
    const [filteredUsers, setFilteredUsers] = useState(list_of_users);
    const [listOfUsers, setListOfUsers] = useState(list_of_users);
    const [openCreateUserSideBar, setOpenCreateUserSideBar] = useState(false);
    const [userId, setUserId] = useState(null)
    const handleOpenUserSideBar = () => setOpenCreateUserSideBar(true);

    const [confirmDelete, setConfirmDelete] = useState(false)
    const [userToDelete, setUserToDelete] = useState(null);
    const [confirmCancel, setConfirmCancel] = useState(false)

    const [roleType, setRoleType] = useState('test_user');
    const fieldValidations = useMemo(() => [
        { field: firstName, message: "Cannot leave first name field empty" },
        { field: lastName, message: "Cannot leave last name field empty" },
        { field: email, message: "Cannot leave email field empty" },
        { field: user_password, message: "Cannot leave password field empty" }
    ], [firstName, lastName, email, user_password])

    useEffect(() => {
        loadListOfUsers()
    }, [])

    useEffect(() => {
        setListOfUsers(list_of_users)
        setFilteredUsers(list_of_users)
    }, [list_of_users])

    useEffect(()=>{
        if(is_new_user_added){
            setOpenCreateUserSideBar(false)
        }
    },[is_new_user_added])

    useEffect(() => {
        if (isEditingUser && currentSelectedUser) {
            setFirstName(currentSelectedUser.first_name || '');
            setLastName(currentSelectedUser.last_name || '');
            setEmail(currentSelectedUser.email || '');
            setUserPassword(currentSelectedUser.user_password || '');
            setRole(currentSelectedUser.role || '');
            setIsStaff(currentSelectedUser.role === 'test_user');
            setIsSuperAdmin(currentSelectedUser.role === 'super_user');
        }
    }, [isEditingUser, currentSelectedUser]);

    const handleTypeRoleChange = (event) => {
        setRoleType('test_user')
        setRoleType(event.target.value);
    };

    const handleCloseLibraryModal = () => {
        resetUserInfo();
        resetEditingState();
    };

    const resetUserInfo = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setRole("");
        setTagsList([{ key: 0, label: 'Create new tag' }]);
    };

    const resetEditingState = () => {
        setIsEditingUser(false);
        setIsThereNeedToSendEmail(true);
        setIsEditingPassword(false);
        setOpenCreateUserSideBar(false);
    };

    const handleTableActions = (option, user) => {
        switch (option) {
            case 'Edit':
                setIsEditingUser(true)
                setCurrentSelectedUser(user)
                setOpenCreateUserSideBar(true)
                setFirstName(user?.first_name);
                setLastName(user?.last_name);
                setEmail(user?.email);
                setIsStaff(user?.role === "test_user" ? true : false)
                setIsSuperAdmin(user?.role === "super_user" ? true : false)
                break;
            case 'Delete':
                setConfirmDelete(true)
                setUserId({user_id:user?.id})
                setUserToDelete(userId)
                break;
            case 'Confirm Delete':
                deleteUser(userToDelete)
                setConfirmDelete(false)
                setUserToDelete(null)
                break;
            default:
                break;
        }
    }

    const handleAddUser = () => {
        try {
            for (let validation of fieldValidations) {
                if (validation.field.length === 0) {
                    showToastMessage({ type: 'error', message: validation.message });
                    return;
                }
            }

            addUser({
                email: email,
                first_name: firstName,
                last_name: lastName,
                password: user_password,
                bypass_email_domain_check: isThereNeedToSendEmail,
                is_staff: isStaff,
                is_superuser: isSuperAdmin,
                role: role
            });
        } catch (error) {
            console.log(error)
            showToastMessage({ type: 'error', message: "Unable to add user. Please try again." });
        }
    };

    const handleUpdateUser = () => {
        try {
            updateUserData({
                user_id: currentSelectedUser?.id,
                data: {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    avatar: "DEFAULT",
                    is_staff: isStaff,
                    is_superuser: isSuperAdmin,
                    role: role
                }
            });
        } catch (error) {
            showToastMessage({ type: 'error', message: "Unable to update user. Please try again." });
        }
    };

    const handleUserAdmin = (value) => {
        switch (value) {
            case 'test_user':
                setIsStaff(true);
                setIsSuperAdmin(false);
                break;
            case 'super_user':
                setIsSuperAdmin(true);
                setIsStaff(true);
                break;
            case 'agent_user':
                setIsSuperAdmin(false);
                setIsStaff(false);
                break;
            default:
                break;
        }
        setRole(value);
    }

    const handleConfirmCancel = () => {
        setConfirmCancel(true)
    }

    return <div className={styles.container}>
        <div className={styles.list_of_document_container}>
            <div className={styles.header_container}>
                <div className={styles.left_container}>
                    <CustomHeading font_size='20' font_weight='bold' text="Users" ></CustomHeading>
                    <CustomHeading font_size='12' color='tertiary' text="Manage user access to Agents" ></CustomHeading>
                </div>
                <div className={styles.right_container}>
                    <CustomButton padding="8px 15px" text_color='default' font_size='12' icon={<img src={add_btn} className={styles.icon} />} currentButtonTpe={TYPE_3_button} title='Add User' onClick={() => { handleOpenUserSideBar() }}></CustomButton>
                </div>
            </div>
            <div className={styles.body_container}>
                <CustomTable handleTableActions={handleTableActions} listOfColumns={listOfColumns} listOfRows={filteredUsers} />
                <DeleteConfirmationComponent
                    isVisible={confirmDelete}
                    onConfirm={() => handleTableActions('Confirm Delete')}
                    onCancel={() => { setConfirmDelete(false) }}
                    message='Are you sure you want to delete this user?'
                />
            </div>
        </div>
        <StyledSideBarContainer className={openCreateUserSideBar ? styles.side_bar_opened : styles.side_bar_closed}>
            <CustomHeading text={isEditingUser ? "Update User" : "Invite User"} font_size="18" font_weight='bold'></CustomHeading>
            <div className={styles.input_fields}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        row
                        value={roleType}
                        onChange={handleTypeRoleChange}
                    >
                        <StyledFormControlLabel value="test_user" control={<Radio />} label="Staff" onClick={() => handleUserAdmin('test_user')}></StyledFormControlLabel>
                        <StyledFormControlLabel value="super_user" control={<Radio />} label="Super User" onClick={() => handleUserAdmin('super_user')} ></StyledFormControlLabel>
                        <StyledFormControlLabel value="agent_user" control={<Radio />} label="Agent User" onClick={() => handleUserAdmin('agent_user')} ></StyledFormControlLabel>
                    </RadioGroup>
                </FormControl>
                <CustomLabel title="First Name"></CustomLabel>
                <CustomTextField placeholder='Enter first name' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} ></CustomTextField>
                <CustomLabel title="Last Name"></CustomLabel>
                <CustomTextField placeholder='Enter last name' value={lastName} onChange={(e) => { setLastName(e.target.value) }} ></CustomTextField>
                <CustomLabel title="Email"></CustomLabel>
                <CustomTextField placeholder='Enter email' value={email} onChange={(e) => { setEmail(e.target.value) }} ></CustomTextField>
                <CustomLabel title="Password"></CustomLabel>
                <CustomTextField placeholder='Enter password' value={user_password} onChange={(e) => { setUserPassword(e.target.value) }} />
            </div>
            <div className={styles.buttons_container}>
                <CustomButton
                    currentButtonTpe={TYPE_1_button}
                    onClick={isEditingUser ? handleUpdateUser : handleAddUser}
                    title={isEditingUser ? is_updating_user ? "Updating" : "Update" : get_is_inviting_user ? "Inviting..." : "Invite"}
                    className={styles.btn}
                >
                </CustomButton>
                <CustomButton
                    currentButtonTpe={TYPE_1_button}
                    backgroundColor='primary'
                    hoverBackgroundColor='secondary'
                    onClick={handleConfirmCancel}
                    title={"Cancel"}
                    className={styles.btn}
                >
                </CustomButton>
            </div>
            <DeleteConfirmationComponent
                isVisible={confirmCancel}
                onConfirm={() => {setOpenCreateUserSideBar(false); setConfirmCancel(false)  }}
                onCancel={() => { setConfirmCancel(false) }}
                message='You have unsaved changes.'
                type='warning'
            />
        </StyledSideBarContainer>
    </div>
}
const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
    is_loading_list_of_users: getIsLoadingListOfUsers(state),
    list_of_users: getListOfUsers(state),
    get_is_inviting_user: getIsInvitingUser(state),
    is_updating_user: getIsUpdatingUser(state),
    is_new_user_added:getIsNewUserAdded(state)
})

const mapDispatchToProps = (dispatch) => ({
    addUser: (data) => dispatch(inviteUser(data)),
    loadListOfUsers: (data) => dispatch(fetchListOfUsers(data)),
    updateUserData: (data) => dispatch(updateUserData(data)),
    updateUserPassword: (data) => dispatch(updateUserPassword(data)),
    deleteUser: (data) => dispatch(deleteUser(data)),
    showToastMessage: (data) => dispatch(showToastMessage(data)),
});

export default connect(mapState, mapDispatchToProps)(Users)
