import { setCurrentTheme, setCurrentThemeClone, setIsSavingTheme, setIsThisValidTenant, setIsUserDetailsUpdated } from "./slice"
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import { TanentAPI, UserAPI } from "../../api";
import { store } from "../../../store/store";
import './toastifyStyles.css'
import successIcon from '../../../assets/images/icons8-tick-64.png'
import closeIcon from '../../../assets/images/icons8-close-50.png'
import errorIcon from '../../../assets/images/icons8-close-50 (1).png'
import warningIcon from '../../../assets/images/icons8-warning-50.png'
import infoIcon from '../../../assets/images/icons8-info-50.png'
 
export const default_theme = {
    palette: {
        textColors: {
            primary: '#FFF',
            secondary: '#FF5A00',
            tertiary: 'rgba(255, 255, 255, 0.75)',
            quaternary: '#AEAEB2',
            default: "black",
            chip_text_color: "#EC950A",
            label_color:"#FFFFFFBF"
        },
        fontFamilies: {
            primary: 'Brockmann, sans-serif',
            secondary: 'Poppins, sans-serif',
            tertiary: 'Brockmann, sans-serif',
            quaternary: 'Brockmann, sans-serif',
        },
        backgroundColors: {
            primary: '#191819',
            secondary: '#222022',
            tertiary: ' #1E1D1E',
            quaternary: '#242426',
            tag_chip_bg: "#382F23",
            checked_switch_color: "#EC950A",
            slider_color: "#FF5A00",
            drop_down_hover:"#FF5A00",
            default: "white",
            darkest: '#7C7C80',
            light: '#444446',
            tag_bg_color:"#EC950A1A",
            transparent:"transparent",
            file_msg_container_color:"#3E3E40",
            white:"white"
        },

        inputFieldBackground: {
            primary: '#191819',
            secondary: '#444446',
            tertiary: ' #242426',
            quaternary: '#AEAEB2',
        },
        buttonBackgrounds: {
            primary: '#444446',
            secondary: '#FF5A00',
            tertiary: '#FF5A00',
            quaternary: "#382F23",
            card_buttons: '#191819',
            transparent:"transparent",
        },
        borderColors: {
            primary: '#444446',
            secondary: '#FF5A00',
            tertiary: '#FF5A00',
            darkest: '#7C7C80',
            white:"white"
        },
        boxShadows: {
            primary: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'
        },
        dividerColor: {
            primary: "#444446",
            secondary: '#FF5A00',
            tertiary: '#FF5A00',
        }
        ,
        toastBackgroundColors: {
            info: '#01E6FF',
            error: '#FF0000',
            success: '#42C63A',
            warning: '#FD8D03',
        },
        icons_colors: {
            shaded: "#FF5A00",
            un_shaded: "#AEAEB2",
        }
    }
}

const analyzeTanent = (data) => (dispatch) => {

    TanentAPI.testTanent({}).then((response) => {
        try {
            console.log(Object.keys(response.data.theme).length)

            // if (Object.keys(response.data.theme).length > 0) {
            if (false) {
                dispatch(setCurrentTheme(response?.data?.theme != undefined ? response?.data?.theme : default_theme))
                dispatch(setCurrentThemeClone(response?.data?.theme != undefined ? response?.data?.theme : default_theme))
            } else {
                dispatch(setCurrentTheme(default_theme))
                dispatch(setCurrentThemeClone(default_theme))
            }
        } catch (error) {
            dispatch(showToastMessage({ message: "Could not load theme preference..", type: "error" }))
            dispatch(setCurrentTheme(default_theme))
            dispatch(setCurrentThemeClone(default_theme))

        }
        dispatch(setIsThisValidTenant(true))
    }).catch((error) => {
        dispatch(showToastMessage({ message: "Invalid tanent", type: "error" }))
        dispatch(setIsThisValidTenant(false))
    })
}


function getIconByType(type) {
    switch (type) {
        case 'info':
            return infoIcon;
        case 'error':
            return errorIcon;
        case 'success':
            return successIcon;
        case 'warning':
            return warningIcon;
        default:
            return ''; // Return an empty string or a default icon if needed
    }
}

const showToastMessage = (data) => (dispatch) => {
    const currentTheme = store.getState().app.currentTheme || default_theme; // Default to an empty object if currentTheme is undefined
    const palette = currentTheme.palette || default_theme.palette; // Default to an empty object if palette is undefined

    let bgColor;
    let className;
    let icon;

    switch (data.type) {
        case 'info':
            bgColor = palette.backgroundColors.light
            className = 'info-toast'
            icon = infoIcon;
            break;
        case 'error':
            bgColor = palette.backgroundColors.light
            className = 'error-toast'
            icon = errorIcon
            break;
        case 'success':
            bgColor = palette.backgroundColors.light
            className = 'success-toast'
            icon = successIcon
            break;
        case 'warning':
            bgColor = palette.backgroundColors.light
            className = 'warning-toast'
            icon = warningIcon
            break;
        default:
            bgColor = 'black';
            break;
    }

    const toastMessage = document.createElement('div');
    toastMessage.classList.add('toast-message');

    const iconImg = document.createElement('img');
    iconImg.src = getIconByType(data.type);
    iconImg.alt = 'Icon';
    iconImg.style.width = '20px';
    iconImg.style.height = '20px';
    iconImg.style.marginRight = '5px';
    iconImg.style.verticalAlign = 'middle';
    toastMessage.appendChild(iconImg);

    const messageText = document.createTextNode(data.message);
    toastMessage.appendChild(messageText);

    const closeButton = document.createElement('img');
    closeButton.src = closeIcon;
    closeButton.alt = 'Close Icon';
    closeButton.style.width = '15px';
    closeButton.style.height = '15px';
    closeButton.style.marginLeft = '10px';
    closeButton.style.cursor = 'pointer';
    closeButton.addEventListener('click', () => {
        toastMessage.parentElement.remove();
    });
    toastMessage.appendChild(closeButton);

    Toastify({
        // text: `${icon} ${data.message}`,
        node: toastMessage,
        duration: 3000,
        newWindow: true,
        close: false,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center`, or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
            background: bgColor, // Use the dynamically determined background color
        },
        className: className,
        onClick: function () { } // Empty callback for now
    }).showToast();
}

const updateTheme = (data) => (dispatch) => {
    dispatch(setCurrentTheme(data));
}

const saveTheme = (data) => (dispatch) => {
    dispatch(setIsSavingTheme(true))
    dispatch(showToastMessage({ message: "Saving theme...", type: "info" }))
    TanentAPI.updateTheme(data).then((response) => {
        console.log("response-saveTheme:", response)
        if (response?.data) {
            dispatch(showToastMessage({ message: "Saved theme", type: "success" }))
        }
        dispatch(setIsSavingTheme(false))
    }).catch((error) => {
        console.log(error)
        dispatch(setIsSavingTheme(false))
    })

}

const getUserDetails = (data) => (dispatch) => {
    UserAPI.getUserDetails(data).then((response) => {
        sessionStorage.setItem("user", JSON.stringify(response.data))
        dispatch(setIsUserDetailsUpdated({}))
    }).catch((error) => {
        console.log(error)
    })
}

export {
    analyzeTanent,
    showToastMessage,
    updateTheme,
    getUserDetails,
    saveTheme,
}