import { connect } from "react-redux";
import { getCurrentTheme } from "../../../modules/app/logic/selectors";
const Ic_Expand_Un_Shaded = ({ current_theme, ...props }) => {
    // fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}
    return <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.125 0C1.73438 0 2.25 0.515625 2.25 1.125V16.875C2.25 17.5312 1.73438 18 1.125 18C0.46875 18 0 17.5312 0 16.875V1.125C0 0.515625 0.46875 0 1.125 0ZM11.25 3.32812C11.7188 3.79688 11.7188 4.5 11.25 4.92188L8.29688 7.875H21.6562L18.7031 4.92188C18.2344 4.5 18.2344 3.79688 18.7031 3.32812C19.125 2.90625 19.8281 2.90625 20.25 3.32812L25.125 8.20312C25.5938 8.67188 25.5938 9.375 25.125 9.79688L20.25 14.6719C19.8281 15.1406 19.125 15.1406 18.7031 14.6719C18.2344 14.25 18.2344 13.5469 18.7031 13.0781L21.6562 10.125H8.29688L11.25 13.0781C11.7188 13.5469 11.7188 14.25 11.25 14.6719C10.8281 15.1406 10.125 15.1406 9.70312 14.6719L4.82812 9.79688C4.35938 9.375 4.35938 8.67188 4.82812 8.20312L9.70312 3.32812C10.125 2.90625 10.8281 2.90625 11.25 3.32812ZM30 16.875C30 17.5312 29.4844 18 28.875 18C28.2188 18 27.75 17.5312 27.75 16.875V1.125C27.75 0.515625 28.2188 0 28.875 0C29.4844 0 30 0.515625 30 1.125V16.875Z" fill={current_theme?.palette?.icons_colors?.un_shaded ? current_theme?.palette?.icons_colors?.un_shaded : "#AEAEB2"}/>
    </svg>  
}

const mapState = (state) => ({
    current_theme: getCurrentTheme(state),
})

const mapDispatchToProps = (dispatch) => ({
    // onLogin: (data) => dispatch(loginUser(data))
});

// export default AuthenticationPage

export default connect(mapState, mapDispatchToProps)(Ic_Expand_Un_Shaded)