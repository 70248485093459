
import styled from 'styled-components';

const StyledCurrentSelectedBotContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.quaternary};
  width:97% !important;
  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;

const StyledListOfBotsAsDropDown = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  width:97% !important;
  padding:2%;
  display:flex;
  flex-direction:column;
  border-radius: 7px;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  height: fit-content;
  cursor:pointer;
  font-family: ${({ theme }) => theme.palette.fontFamilies.primary};
`;


const StyledListOfBotsAsDropDownItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.backgroundColors.light};
  width:97% !important;
  border-radius: 7px;
  cursor:pointer;
  // border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
  
`;

export { StyledCurrentSelectedBotContainer,StyledListOfBotsAsDropDown,StyledListOfBotsAsDropDownItemContainer};