import TextField from '@mui/material/TextField';
// import { DateRangePicker as MuiDateRangePicker } from '@mui/lab';
import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro'

import styled from 'styled-components';

const StyledDateRangePicker = styled(MuiDateRangePicker)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
    color: ${({ theme }) => theme.palette.textColors.primary};

    /* Hide label */
    .MuiInputLabel-root {
      display: none;
    }

    /* Change text color */
    .MuiOutlinedInput-root {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change the arrow icon color */
    .MuiIconButton-root {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change text field color when focused */
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
      border-radius: 15px;
    }

    & .MuiOutlinedInput-root.Mui-focused {
      border-radius: 15px;
      color: ${({ theme }) => theme.palette.textColors.primary}; /* Change text color here */
    }

    /* Change text field color when not focused */
    & .MuiOutlinedInput-root:not(.Mui-focused) {
      border-radius: 15px;
      color: ${({ theme }) => theme.palette.textColors.primary}; /* Change text color when not focused */
    }
  }
`;

const StyledDropdown = styled(TextField)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.inputFieldBackground.primary};

    /* Shadow/xs */
    color: ${({ theme }) => theme.palette.textColors.primary};

    /* Hide label */
    .MuiInputLabel-root {
      display: none;
    }

    /* Change text color */
    .MuiSelect-root {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change the arrow icon color */
    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    
    /* Change text field color when focused */
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
      border-radius: 15px;
    }

    & .MuiOutlinedInput-root.Mui-focused {
      border-radius: 15px;
      color: ${({ theme }) => theme.palette.textColors.primary}; /* Change text color here */
    }

    /* Change text field color when not focused */
    & .MuiOutlinedInput-root:not(.Mui-focused) {
      border-radius: 15px;
      color: ${({ theme }) => theme.palette.textColors.primary}; /* Change text color when not focused */
    }

   
  }
`;

const StyledDropdown_type_2 = styled(TextField)`
  && {
    width: 100%;
    font-family: ${({ theme }) => theme.palette.fontFamilies.secondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.palette.borderColors.primary};
    background: ${({ theme }) => theme.palette.buttonBackgrounds.secondary};

    /* Shadow/xs */
    color: ${({ theme }) => theme.palette.textColors.primary};

    /* Hide label */
    .MuiInputLabel-root {
      display: none;
    }

    /* Change text color */
    .MuiSelect-root {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change the arrow icon color */
    .MuiSelect-icon {
      color: ${({ theme }) => theme.palette.textColors.primary};
    }

    /* Change text field color when focused */
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.borderColors.primary};
    }

    & .MuiOutlinedInput-root.Mui-focused {
      color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Change text color here */
    }

    /* Change text field color when not focused */
    & .MuiOutlinedInput-root:not(.Mui-focused) {
      color: ${({ theme }) => theme.palette.textColors.quaternary}; /* Change text color when not focused */
    }

   
  }
`;

export { StyledDropdown,StyledDropdown_type_2,StyledDateRangePicker };